import React from 'react';
import Chart from 'react-apexcharts';


class LineBarChart extends React.Component {
    constructor(props) {
      super(props);
  
      this.state = {
        options: {
          chart: {
            id: 'line-bar-chart'
          },
          xaxis: {
            categories: [
              'High Risk CVEs',
              'Exploitable Obsolete Tech',
              'Dangling Subdomains',
              'Vulnerable SSL Certificates',
              'Expired SSL Certificates',
              'Vulnerable Obsolete Tech',
              'Open Cloud Buckets'
            ]
          },
          colors: ['#008FFB', '#00E396'],
          yaxis: [{
            axisTicks: {
              show: true
            },
            axisBorder: {
              show: true,
              color: '#008FFB'
            },
            labels: {
              style: {
                colors: '#008FFB',
              }
            },
            title: {
              // text: 'No. of Instance',
              style: {
                color: '#008FFB',
              },
            },
            min: 0,  // Minimum value for the left side
            // max: 600, // Maximum value for the left side
          }, {
            opposite: true,
            axisTicks: {
              show: true
            },
            axisBorder: {
              show: true,
              color: '#00E396'
            },
            labels: {
              style: {
                colors: '#00E396',
              }
            },
            title: {
              // text: 'No. of Companies',
              style: {
                color: '#00E396',
              },
            },
            min: 0,  // Minimum value for the right side
            // max: 60, // Maximum value for the right side
          }],          
        },
        series: [
          {
            name: 'No. of Instance',
            type: 'column',
            data: [
              props?.attacksurface?.totalInstancesOfHighRiskVulns,
              props?.attacksurface?.totalInstancesOfExploitableObsoleteTechnologies,
              props?.attacksurface?.totalInstancesOfDanglingSubdomains, 
              props?.attacksurface?.totalInstancesOfVulnerableSslCertificates,
              props?.attacksurface?.totalInstancesOfExpiredSslCertificates, 
              props?.attacksurface?.totalInstancesOfVulnerableObsoleteTechnologies,
               ]
          },
          {
          name: 'No. of Companies',
          type: 'line',
          data: [
            props?.attacksurface?.totalCompaniesWithHighRiskVulns,
            props?.attacksurface?.totalConpaniesWithExploitableObsoleteTechnologies,
            props?.attacksurface?.totalCompaniesWithDanglingSubdomains,
            props?.attacksurface?.totalCompaniesWithVulnerableSslCertificates,
             props?.attacksurface?.totalCompaniesWithExpiredSslCertificates,  
             props?.attacksurface?.totalCompaniesWithVulnerableObsoleteTechnologies
          ]
        }],
      };
    }
  
    render() {
      return (
        <div id="chart">
          <Chart options={this.state.options} series={this.state.series} type="line" height={400} />
        </div>
      );
    }
  }
  
  export default LineBarChart;
  