import React, { Component } from "react";
import { Link, useParams } from "react-router-dom";

import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { login, googleSignIn, verifyOTP } from "../../actions/auth";
import $ from "jquery";
import { toastr } from "react-redux-toastr";
import googleImage from "../../assets/images/googleImage.png";
import { color, maxWidth } from "@material-ui/system";
import brandsek from "../../assets/images/brandsek-logo.png"
import { GOOGLE_LOGIN_URL } from "../../utils/constant";

var Model = require("./model");

const styles = {
  position: "absolute",
  top: "19px",
  right: "15px",
  width: "34px",
  textAlign: "center",
  height: "34px",
  zIndex: "2",
  cursor: "pointer"
};
class Login extends Component {
  state = {
    user: null,
    visiblePassword: false,
    authMethod: null,
    loading: false,
    showPassword: false,
    setOtp: ''
  };

  componentDidUpdate() {
    if (this.props.auth.user && this.state.loading) {
      this.setState({
        user: this.props.auth.user,
        loading: false,
      });
      if (this.props.auth.user.passwordReset) {
        if (this.props.auth.user.accessLevel == "Client")
          this.props.history.push("/");
        else if (
          this.props.auth.user.accessLevel == "MSSP" ||
          this.props.auth.user.accessLevel == "Threat Analyst"
        )
          this.props.history.push("/clients");
        else {
          this.props.history.push("/");
        }
      } else {
        this.props.history.push("/reset-password");
      }
    }
  }

  handleLogin = (event) => {
    event.preventDefault();
    console.log('login')
    let email = $("#email").val();
    let password = $("#password").val();
    let otp;
    if (email == "") {
      toastr.error("Error", "Please enter email id");
      return false;
    }
    if (password == "") {
      toastr.error("Error", "Please enter password");
      return false;
    }
    if (this.state.authMethod == "TwoFactor") {
      otp = $("#twoFactor").val();
      if (otp == "") {
        toastr.error("Error", "Please enter otp");
        return false;
      }
    }
    this.setState({ loading: true });
    if (this.state.authMethod == "TwoFactor") {
      this.props.login({
        email,
        password,
        otp
      });
    } else {
      this.props.login({
        email,
        password,
      });
    }
  };

  verifyOTP = () => {
    let otp = $("#otp").val();
    //console.log(this.props.auth.twoFactor, "this.props.auth");
    this.props.verifyOTP({
      token: this.props.auth.twoFactor.authToken,
      otp: otp,
    });
  };

  onNext = (e) => {
    e.preventDefault();
    let email = $("#email").val();
    // this.setState({ loading: true }, function () {
    Model.default.getAuthMethod(email).then((response) => {
      if (response.data.error) {
        toastr.error("Error", response.data.userMessage);
      } else {
        this.setState({
          loading: false,
          authMethod: response.data.data,
        });
      }
    });
    // });
  };

  loginByGoogle = (event) => {
    event.preventDefault();
    console.log(GOOGLE_LOGIN_URL, 'GOOGLE_LOGIN_URL')
    window.open(GOOGLE_LOGIN_URL);
    // let email = $("#email").val();
    // this.props.googleSignIn(email);
  };

  handleNumChange = (object) => {
    if (object.target.value.length > object.target.maxLength) {
      object.target.value = object.target.value.slice(0, object.target.maxLength)
    }
  };

  render() {
    // console.log(window.location.href, "this.props.auth");
    let brandsek = '../../assets/images/image-gallery/Invinsense_logo_white.png';
    let browser = window.location.href.split("/")
    if (browser[2].split(".")[0] && browser[2].split(".")[0] == "brandshield") {
      brandsek = '../../assets/images/image-gallery/Final_Logo.png'
    } else if (browser[2].split(".")[0] && browser[2].split(".")[0] == "invinsense") {
      brandsek = '../../assets/images/image-gallery/Invinsense_logo_white.png?v=1'
    }
    // console.log(browser, 'browser')
    return (
      <>
        <div class="pattern">
          <span class="red"></span>
          <span class="indigo"></span>
          <span class="blue"></span>
          <span class="green"></span>
          <span class="orange"></span>
        </div>

        <div className="page-wrapper page-wrapper_login">
          <div className="section bg-default wf-section">
            <div className="container-default position-relative w-container">
              <div className="inner-container _625px center">
                <div data-w-id="af797e45-9721-3e02-9bca-78d7b063fc49" className="card _24px sign-in-card">
                  <div className="sign-form w-form">
                    <div
                      data-w-id="742728b9-6335-1041-77e8-4ea5e5785154"
                      data-animation="default"
                      data-collapse="medium"
                      data-duration="400"
                      data-easing="ease"
                      data-easing2="ease"
                      role="banner"
                      class="header-wrapper w-nav"
                      style={{ paddingTop: "0px" }}
                    >
                      <div className="container-default w-container">
                        <div className="header-content-wrapper">
                          <div
                            data-w-id="742728b9-6335-1041-77e8-4ea5e5785157"
                            className="header-middle"
                          >
                            <a className="header-logo-link center w-nav-brand text-center">
                              <img
                                src={brandsek}
                                style={{ maxWidth: "200px" }}
                                loading="eager"
                                className="header-logo"
                              />
                            </a>
                          </div>
                        </div>
                      </div>
                    </div>

                    <form
                      className="form-auth-small m-t-20"
                    >
                      <div className="sign-card-content">
                        <h1 className="display-2 text-center mg-bottom-24px keep"
                          style={{ marginBottom: "35px" }}
                        >Sign in.</h1>
                        <div className="mg-bottom-24px keep">
                          <div className="w-layout-grid grid-1-column gap-row-0">
                            <div>
                              <input type="email" className="input input-form top w-input"
                                name="Email" data-name="Email" placeholder="Email  address" id="email"></input>
                            </div>
                            {this.state.authMethod && this.state.authMethod == "default" && (
                              <div className="has-feedback position-relative">
                                <input className="input input-form bottom w-input"

                                  type={this.state.showPassword ? "text" : "password"}
                                  name="Password" data-name="Password" placeholder="Password" id="password"></input>
                                <i
                                  style={{
                                    position: "absolute",
                                    top: "29px",
                                    right: "15px",
                                    width: "34px",
                                    textAlign: "center",
                                    height: "34px",
                                    zIndex: "2",
                                    cursor: "pointer",
                                    fontSize: "16px"
                                  }}
                                  className={
                                    this.state.showPassword
                                      ? "form-control-feedback fa fa-eye-slash"
                                      : "form-control-feedback fa fa-eye"
                                  }
                                  onClick={() =>
                                    this.setState({ showPassword: !this.state.showPassword })
                                  }
                                ></i>
                              </div>
                            )}

                            {this.state.authMethod && this.state.authMethod == "TwoFactor" && (
                              <>
                                <div className="has-feedback position-relative">
                                  <input className="input input-form w-input"

                                    type={this.state.showPassword ? "text" : "password"}
                                    name="Password" data-name="Password" placeholder="Password" id="password"></input>
                                  <i
                                    style={{
                                      position: "absolute",
                                      top: "29px",
                                      right: "15px",
                                      width: "34px",
                                      textAlign: "center",
                                      height: "34px",
                                      zIndex: "2",
                                      cursor: "pointer",
                                      fontSize: "16px"
                                    }}
                                    className={
                                      this.state.showPassword
                                        ? "form-control-feedback fa fa-eye-slash"
                                        : "form-control-feedback fa fa-eye"
                                    }
                                    onClick={() =>
                                      this.setState({ showPassword: !this.state.showPassword })
                                    }
                                  ></i>
                                </div>
                                <div className="has-feedback position-relative">
                                  <input className="input input-form bottom w-input"

                                    type="text"
                                    name="twoFactor" data-name="twoFactor" placeholder="OTP" id="twoFactor"></input>

                                </div>
                              </>
                            )}
                          </div>
                        </div>
                        <div className="mg-bottom-24px keep">
                          <div className="text-200 medium color-neutral-100">
                            <Link to="/forgotpassword">Forgot password?</Link>
                          </div>
                        </div>
                        {this.props.auth.twoFactor && (
                          <div className="form-group has-feedback">
                            <label htmlFor="password" className="control-label sr-only">
                              Otp
                            </label>
                            <input
                              style={{ paddingRight: "30px" }}
                              type="number"
                              className="form-control-new round"
                              id="otp"
                              // value={this.state.setOtp}
                              maxLength={6}
                              onChange={this.handleNumChange}
                              placeholder="One Time Password"
                            />
                            {/* <i className={this.state.showPassword ? "form-control-feedback fa fa-eye-slash" : "form-control-feedback fa fa-eye"} onClick={() => this.setState({showPassword : !this.state.showPassword})}></i> */}
                          </div>
                        )}
                        {this.state.authMethod && (
                          <input type="submit" value="Sign in" style={{ color: "black" }}
                            className="loginButton btn-primary width-100 w-button"
                            onClick={this.handleLogin}></input>
                        )}
                        {!this.state.authMethod && (
                          <input type="submit" value="Next" style={{ color: "black" }}
                            className="loginButton btn-primary width-100 w-button"
                            onClick={this.onNext}></input>
                        )}
                      </div>
                      <div className="divider _48px"></div>
                      <div className="sign-card-content">

                        {
                          browser[2].split(".")[0] != "brandshield" && (
                            <button onClick={this.loginByGoogle}
                              className="loginGoogle btn-secondary sign-in-button mg-bottom-16px keep w-button w-100">
                              <span className="social-icon-font button-icon-left"></span>Sign in with google</button>
                          )}
                        {/* <a href="https://www.facebook.com/" target="_blank" class="btn-secondary sign-in-button mg-bottom-24px w-button"><span class="social-icon-font button-icon-left"></span>Sign in with facebook</a> */}
                        {/* <div class="text-center">
                    <div class="text-200 medium color-neutral-100">Don’t have an account? <a href="../utility-pages/sign-up.html" class="text-no-wrap">Sign Up</a>
                    </div>
                  </div> */}
                      </div>
                    </form>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </>
    );
  }
}

function mapStateToProps({ auth }) {
  return {
    auth,
  };
}
function mapDispatchToProps(dispatch) {
  return bindActionCreators(
    {
      login,
      googleSignIn,
      verifyOTP,
    },
    dispatch
  );
}

export default connect(mapStateToProps, mapDispatchToProps)(Login);
