import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { Link  , useParams} from "react-router-dom";
import { fetchSubDomains, createApiScan, fetchScheduler } from "../../actions/scheduler";
import moment from 'moment';
import DatePicker from "react-datepicker";
import { toastr } from "react-redux-toastr";
import Pagination from "../Pagination/Pagination";
import AccessPage from "../Shared/accessPage";

const ScanPage = () => {
  const dispatch = useDispatch();

  const [page, setPage] = useState(1);
  const [PageSize, setPerPage] = useState(10);
  const [firstLoad,setFirstLoad] = useState(true);
  const [assesment, selectAssesment] = useState("")
  const [selectedIp, selectIP] = useState([])
  const [selectedSubDomain, selectSubDomain] = useState([])
  const [apiArray, selectApiArray] = useState([]);

  const [schecule, setSchecule] = useState(false);
  const [date , selectDate] = useState(null);
  const [comments, setComments] = useState("");
  const randomNumber = Math.floor(1000 + Math.random() * 9000);

  const [scanName, setScanName] = useState(randomNumber);

  const [isModal, setModal] = useState(false);
  const [modalHeading, setModalHeading] = useState("");
  const [modalBody, setModalBody] = useState([]);

  // const id = JSON.parse(localStorage.getItem("user")).organisationId;
  const id = useSelector(
    (state) => state?.summary?.localClient?._id || state?.summary?.clients?.[0]?._id
  );

  useEffect(() => {
    fetchScheduler(id, dispatch);
    fetchSubDomains(id,dispatch)
  }, [id]);

  const state = useSelector((state) => state?.scheduler?.scheduler?.data);
  const assesmentArray = useSelector((state) => state?.scheduler?.schedulerSubDomain);
  if(firstLoad && assesmentArray && (assesmentArray.ips || assesmentArray.subdDomain)){
    setFirstLoad(false);
    let ipValue = assesmentArray.ips?.map((row) => row._id+"#"+row.ip_str)
    selectIP(ipValue);
    let subDomainValue = assesmentArray.subdDomain?.map((row) => row._id+"#"+row.subDomainName)
    selectSubDomain(subDomainValue);
  }
  const currentDate = moment().format("YYYYMMDD");

  //console.log(state,"scan_details");

  const numberOnly = (id) => {
    // Get element by id which passed as parameter within HTML element event
      var element = document.getElementById(id);
      // This removes any other character but numbers as entered by user
      element.value = element.value.replace(/[^0-9]/gi, "");
  }

  const showModal = (val) => {
    // if(apiArray.length == 0){
    // selectApiArray(val);
    // }

    setModal(true);
    setModalBody(val)
  }

  const selectAssesmentFun = (e) => {
    if(e == "AF")
      selectApiArray(selectedIp);
    else
      selectApiArray(selectedSubDomain);

    selectAssesment(e)

  }

  const selectIp = (e,_index) => {
    //console.log(e.target.checked,_index,'e,_index')
    if(e.target.checked){
      let temp = apiArray;
        temp.push(_index);
        selectApiArray(temp);
      //console.log(temp?.length,'temp add')

    }else{
      let temp = apiArray;
      temp = temp.filter((drink, index) => drink !== _index);
      // temp.splice(temp.indexOf(_index),1);
      selectApiArray(temp);
      //console.log(temp?.length,'temp minus')
    }
  }

  const startScan = () => {
    if(scanName == ""){
      toastr.error("Error", "Please enter scan name.");
      return false;
    }
    if(apiArray && apiArray.length == 0){
      toastr.error("Error", "Please select sub domain to scan.");
      return false;
    }
    createApiScan
      ({
        subDomain: apiArray,
        scanName: currentDate + scanName,
        // id: this.state.apiScan.id,
        organisationId: id,
        scheduler: true,
        scheduleDate: date,
        schedule: schecule,
        comments: comments,
        assesment: assesment
        // customSubDomain: nu
      },dispatch)
  }

  let currentTableData = [];
  if (state && state.length > 0) {
    // setFirstLoad(false)
    const firstPageIndex = (page - 1) * PageSize;
    const lastPageIndex = firstPageIndex + PageSize;
    currentTableData = state?.slice(firstPageIndex, lastPageIndex);
  }

  return (
    <>
      <div className="container-fluid">
        <AccessPage></AccessPage>
        <div className="block-header">
          <div className="row clearfix">
            <div className="col-md-6 col-sm-12">
              <h2>Scan Details</h2>
              <nav aria-label="breadcrumb">
                <ol className="breadcrumb">
                  <li className="breadcrumb-item">
                    <Link to="/">Home</Link>
                  </li>
                  <li className="breadcrumb-item active" aria-current="page">
                    Details
                  </li>
                </ol>
              </nav>
            </div>
          </div>
        </div>
        <div className="row clearfix">
          <div className="col-xl-12 col-lg-12 col-md-12">
            <div className="card">
              <div className="header">
                <h2>Basic Information</h2>
              </div>
              <div className="body">
                <div className="row clearfix">
                  <div className="col-lg-6 col-md-12">
                    <div className="input-group mb-3">
                        <div className="input-group-prepend">
                            <span className="input-group-text">{currentDate}</span>
                        </div>
                        <input
                          type="text"
                          className="form-control"
                          // placeholder="Scan Name"
                          id="scanName"
                          onInput={(e) => numberOnly("scanName")}
                          defaultValue={randomNumber}
                          onChange={(e) => setScanName(e.target.value)}
                          maxLength={4}
                        />
                    </div>
                  </div>
                  <div className="col-lg-4 col-md-12">
                    <div className="form-group">
                      <select className="form-control" onChange={(e) => selectAssesmentFun(e.target.value)}>
                        <option value="">-- Select Assessment Type --</option>
                        <option value="AF">Infrastructure</option>
                        <option value="AX">Application</option>
                      </select>
                    </div>
                  </div>

                  <div className="col-lg-4 col-md-12">
                    <div className="form-group">
                      {assesment && assesment == "AF" && (
                        <span>
                          <span style={{cursor:"pointer"}} onClick={() => showModal(selectedIp)}>{apiArray?.length}</span> / <span>{assesmentArray.ips?.length}</span> records selected.
                        </span>
                      )}
                      {assesment && assesment == "AX" && (
                        <span>
                          <span style={{cursor:"pointer"}} onClick={() => showModal(selectedSubDomain)}>{apiArray?.length}</span> / <span>{assesmentArray.subdDomain?.length}</span> records selected.
                        </span>
                      )}
                    </div>
                  </div>
                  <div className="col-lg-12 col-md-12">
                    <div className="form-group">
                      <textarea
                        rows="4"
                        type="text"
                        className="form-control"
                        placeholder="Comments"
                        onChange={(e) => setComments(e.target.value)}
                      ></textarea>
                    </div>
                  </div>
                  <div className="col-12">
                    <ul className="list-group mb-3 tp-setting">
                      <li className="list-group-item">
                        Schedule Later
                        <div className="float-right">
                          <label className="switch" onChange={(e) => setSchecule(e.target.checked)}>
                            <input type="checkbox" />
                            <span className="slider round"></span>
                          </label>
                        </div>
                      </li>
                    </ul>
                  </div>
                  <div className="col-12">
                  {schecule && (
                    <>
                    <div className="form-group">
                      <label class="col-md-3 control-label">Schedule Date</label>
                      <div className="col-md-9">
                        <DatePicker
                          selected={new Date()}
                          minDate={new Date()}
                          showTimeSelect
                          timeFormat="p"
                          timeIntervals={1}
                          onChange={(date) => selectDate(date)}
                          className="form-control datetimepicker"
                          dateFormat="MMMM d, yyyy h:mm aa"
                        />
                      </div>
                      </div>
                    </>
                  )}
                  </div>
                </div>
                <button
                  type="button"
                  className="btn btn-round btn-primary mr-1"
                  onClick={() => startScan()}
                >
                  {schecule ?  "Schedule Scan" : "Start Scan"}
                </button>{" "}
                &nbsp;&nbsp;
              </div>
            </div>
          </div>
          <div className="col-12 col-sm-12">
            <div className="card">
              <div className="header">
                <h2>Scan Summary</h2>
              </div>
              <div className="table-responsive">
                <table className="table table-custom spacing5 mb-0">
                  <thead>
                    <tr>
                      <th>#No</th>
                      <th>Scan Name</th>
                      <th>URL/IP</th>
                      {/* <th>Issues Found </th> */}
                      <th>Scan Initated At</th>
                      <th>Comment</th>
                      {/* <th>Scan End Date</th> */}
                    </tr>
                  </thead>
                  <tbody>
                    {currentTableData?.map((row,index) => {
                      // let target = row?.targets && row.targets.length > 0 ? row.targets[row.targets.length - 1] : "";
                      // let webscan = row?.targets?.filter((val) => val.scanId == row.scanId);
                      return(
                        <tr>
                          <td>#{(page - 1) * 10 + 1 + index}</td>
                          <td>{row?.scanName || "NA"}</td>
                          <td>{row?.subDomains?.map((row) =>{
                              return(
                                <span>{JSON.stringify(row)?.replace(/[\[\]']+/g, "")?.replace(/['"]+/g, "")} <br/></span>
                              )
                          })} </td>
                          {/* <td>{webscan?.length || 0}</td> */}
                          <td>{row?.createdAt ? moment(row?.createdAt).format("DD-MM-YYYY") : "NA"}</td>
                          <td>{row?.comments || "NA"}</td>
                        </tr>
                      )
                    })}
                  </tbody>
                </table>
              </div>
              <div className="dataTables_paginate">
                {state && state.length > 0 && (
                  <Pagination
                    className="pagination-bar"
                    currentPage={page}
                    totalCount={state?.length}
                    pageSize={PageSize}
                    onPageChange={(page) => setPage(page)}
                  />
                )}
              </div>
            </div>
          </div>
        </div>
        <div
        className={`modal fade Form-Wizard-modal ${
          isModal ? "d-block show" : ""
        }`}
      >
        <div className="modal-dialog modal-lg assetsDiscoveryModal">
          <div className="modal-content">
            <div className="modal-header">
              <h5 className="modal-title" id="exampleModalLabel">
                {modalHeading}
              </h5>
              <button
                type="button"
                data-dismiss="modal"
                aria-label="Close"
                onClick={() => setModal(false)}
              >
                <span aria-hidden="true">×</span>
              </button>
            </div>
            <div className="modal-body pricing_page">
              <div id="wizard_horizontal">
                <section>
                  <div className="card">
                    <div className="table-responsive">
                      <table className="table table-hover table-custom spacing5">
                        <thead>
                          <tr>
                            <th><input type='checkbox'/></th>
                            <th>Ip Name</th>
                          </tr>
                        </thead>
                        <tbody>
                          {modalBody?.map((row,index) => {
                            //console.log(apiArray?.indexOf(row) != -1,row.split("#")[1],'modalBodyBal')
                            return(
                            <tr key={row.split("#")[1]+"modalBody"}>
                              <td><input type='checkbox' defaultChecked={apiArray?.indexOf(row) != -1} onChange={(e) => selectIp(e, row)}/></td>
                              <td>{row.split("#")[1]}</td>
                            </tr>
                            )
                          })}
                          
                        </tbody>
                      </table>
                    </div>
                    {/* </div> */}
                  </div>
                </section>
              </div>
            </div>
          </div>
        </div>
      </div>
      </div>
    </>
  );
};
export default ScanPage;
