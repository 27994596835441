import React, { Component } from 'react';
import ReactApexChart from "react-apexcharts";
import moment from 'moment';

class BasicLineColumn extends Component {
    constructor(props) {
        super(props);
       //console.log(props,'basic line')
        this.state = {
            options: {
                chart: {
                    height: 350,
                    type: 'line',
                    toolbar: {
                        show: false,
                    },
                },
                colors: props?.color,
                stroke: {
                    width: [0, 4]
                },
                plotOptions: {
                    bar: {
                      horizontal: false,
                      borderRadius: 10,
                      columnWidth: "10%"
                    },
                  },
                // labels: ["Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"],
                // labels: ['01 Jan 2001', '02 Jan 2001', '03 Jan 2001', '04 Jan 2001', '05 Jan 2001', '06 Jan 2001', '07 Jan 2001', '08 Jan 2001', '09 Jan 2001', '10 Jan 2001', '11 Jan 2001', '12 Jan 2001'],
                labels:  props?.dates?.map((row) => moment(row).format('DD-MM-YYYY')),
                xaxis: {
                    // type: 'datetime'
                },
                yaxis: [{
                    title: {
                        text: props?.yAxis || 'Total Scan',
                    },

                }, {
                    opposite: true,
                    title: {
                        text: 'Compliance Percentage'
                    }
                }]
            },
            series: [{
                name: props?.yAxis || 'Total Scan',
                type: 'column',
                data: props?.ipArray
            }, {
                name: 'Compliance Percentage',
                type: 'line',
                data: props?.openPortArray
            }]
        };
    }
    render() {
        return (
            <div>
                <ReactApexChart
                    options={this.state?.options}
                    series={this.state?.series}
                    type="line"
                    height="350px"
                    // width="100%"
                />
            </div>
        );
    }
}

export default BasicLineColumn;
