export const techstack = {
    'Bootstrap' : '5.2.3' ,
    'jQuery UI' : '1.13.2' ,
    'jQuery' : '3.7.1' ,
    'FancyBox' : '4' ,
    'Popper' : '2.11.6' ,
    'Windows Server' : '10' ,
    'Microsoft ASP.NET' : '4.8.1' ,
    'IIS' : '10' ,
    'jQuery' : '3.7.1' ,
    'animate.css' : '4.1.1' ,
    'Bootstrap' : '5.2.3' ,
    'Font Awesome' : '6.3.0' ,
    'cdnjs' : '6.1.4' ,
    'Modernizr' : '3.5.0' ,
    'core-js' : '3.33.0' ,
    'reCAPTCHA' : '3' ,
    'WordPress' : '6.2' ,
    'MySQL' : '8.0.32' ,
    'PHP' : '8.2' ,
    'wpBakery' : '6.10.0' ,
    'ExactMetrics' : '7.9. 0' ,
    'Yoast SEO' : '20.1' ,
    'WP-PageNavi' : '2.94.0' ,
    'Shortcodes Ultimate' : '5.12.7' ,
    'OWL Carousel' : '2.3.4' ,
    'Twitter Emoji (Twemoji)' : '14' ,
    'jQuery Migrate' : '3.4.0' ,
    'Flickity' : '3.0.0' ,
    'CookieYes' : '3.0.8' ,
    'Gravity Forms' : '2.6.3.4' ,
    'Zendesk Chat' : '1.0.0' ,
    'Zendesk' : '5.0.3' ,
    'Cart Functionality' : '5.3.4' ,
    'WooCommerce' : '7.4.0' ,
    'Contact Form 7' : '7.5.7.1' ,
    'TypeScript' : '4.9.5' ,
    'Chart.js' : '4.2.1' ,
    'Brightcove' : '8.0.0' ,
    'Angular' : '15' ,
    'Zone.js' : '0.12.0' ,
    'OptinMonster' : '2.12.0' ,
    'Crazy Egg' : '2.9' ,
    'webpack' : '5.75.0' ,
    'Popper' : '2.11.6' ,
    'MediaElement.js' : '6.0.1' ,
    'Boomerang' : '1.4.7' ,
    'Akamai' : '11' ,
    'Yoast SEO Premium' : '19.4' ,
    'GSAP' : '3.11.4' ,
    'Google Tag Manager for WordPress' : '1.16' ,
    'Zoominfo' : '1.36.0' ,
    'jQuery UI' : '1.13.2' ,
    'Hotjar' : '1.0.14' ,
    'Affirm' : '3.159.6' ,
    'Revslider' : '6.6.10' ,
    'Swiper Slider' : '9.0.5' ,
    'Lightbox' : '3.0.1' ,
    'toastr' : '2.1.4' ,
    'Sentry' : '23.2.0' ,
    'Ruby' : '3.1.2' ,
    'Node.js' : '19.6.0' ,
    'Ruby on Rails' : '7.0. 2' ,
    'React' : '18.2.0' ,
    'Express' : '4.18.2' ,
    'Polyfill' : '7.12.1' ,
    'Lodash' : '4.17.21' ,
    'Prism' : '1.29.0' ,
    'Google Maps' : '10.66. 1' ,
    'TablePress' : '2' ,
    'ZURB Foundation' : '6.7.5' ,
    'Nginx' : '1.25.1' ,
    'EasyDigitalDownloads' : '3' ,
    'MachoThemes NewsMag' : '2.4.4' ,
    'Google AdSense' : '2' ,
    'Twitter Ads' : '11' ,
    'Mouse Flow' : '1.0.3' ,
    'Microsoft Clarity' : '0.7.5' ,
    'Elementor' : '3.8.1' ,
    'Super Socializer' : '7.13.35' ,
    'WordPress' : '6.2' ,
    'MySQL' : '8.0.32' ,
    'PHP' : '8.2' ,
    'Plyr' : '3.8.1' ,
    'MediaElement.js' : '6.0.1' ,
    'Bootstrap' : '5.2.3' ,
    'Apache' : '2.4.55' ,
    'MonsterInsights' : '8.10 1' ,
    'Yoast SEO' : '20.1' ,
    'Contact Form 7' : '7.5.7.1' ,
    'Twitter Emoji (Twemoji)' : '14.0.0' ,
    'Twitter Ads' : '11' ,
    'jQuery UI' : '1.13.2' ,
    'jQuery Migrate' : '3.4.0' ,
    'jQuery' : '3.7.1' ,
    'core-js' : '3.33.0' ,
    'Swiper Slider' : '9.0.5' ,
    'Firebase' : '9.8.4' ,
    'CKEditor' : '4.19.1' ,
    'Chart.js' : '4.2.1' ,
    'animate.css' : '4.1.1' ,
    'Bootstrap' : '5.2.3' ,
    'jsDelivr' : '7.0.0' ,
    'Font Awesome' : '6.3.0' ,
    'cdnjs' : '6.1.4' ,
    'Moment.js' : '2.29.4' ,
    'Lodash' : '4.17.21' ,
    'jQuery' : '3.7.1' ,
    'DataTables' : 'v5.1.3' ,
    'core-js' : '3.33.0' ,
    'webpack' : '5.75.0' ,
    'Popper' : '2.11.6' ,
    'Ubuntu' : '22.04' ,
    'Nginx' : '1.25.1' ,
    'React' : '18.2.0' ,
    'Amazon Web Services' : '2' ,
    'Nginx' : '1.25.1' ,
    'MobX' : '6.6.1' ,
    'Node.js' : '19.6.0' ,
    'Express' : '4.18.2' ,
    'Sentry' : '23.2.0' ,
    'Amazon Cloudfront' : '1.161.0' ,
    'React' : '18.2.0' ,
    'styled-components' : '5.3.6' ,
    'Lodash' : '4.17.21' ,
    'JSS' : '10.9.2' ,
    'cdnjs' : '6.1.4' ,
    'core-js' : '3.33.0' ,
    'Ruby' : '3.1.2' ,
    'RackCache' : '1.13. 0' ,
    'Cart Functionality' : '5.3.4' ,
    'TypeScript' : '4.9.5' ,
    'Bootstrap' : '5.2.3' ,
    'Zone.js' : '0.12.0' ,
    'Razorpay' : '1.6.22' ,
    'Angular' : '14.0.1' ,
    'Unpkg' : '16.7.0' ,
    'jQuery' : '3.7.1' ,
    'webpack' : '5.75.0' ,
    'Ruby on Rails' : '7.0. 2' ,
    'Envoy' : '1.22.2' ,
    'Transifex' : '1.2.0' ,
    'Prototype' : '1.7.3' ,
    'Handlebars' : '4.7.7' ,
    'Typekit' : '5.7.1.1' ,
    'Select2' : '4.1.0' ,
    'Moment.js' : '2.29.4' ,
    'Modernizr' : '3.5.0' ,
    'jQuery UI' : '1.13.2' ,
    'Freshchat' : '5.0.0' ,
    'Prism' : '1.29.0' ,
    'WordPress' : '6.2' ,
    'MySQL' : '8.0.32' ,
    'PHP' : '8.2' ,
    'Firebase' : '9.8.0.32.4' ,
    'Highcharts' : '9. 3.0' ,
    'OWL Carousel' : '2.3.4' ,
    'Mixpanel' : '2.45.0' ,
    'Microsoft Clarity' : '0.7.5' ,
    'jQuery Migrate' : '3.4.0' ,
    'Disqus' : '11.0.6' ,
    'Criteo' : '2021.1' ,
    'CleverTap' : '4.5.0' ,
    'Elementor' : '3.8.1' ,
    'GSAP' : '3.10.4' ,
    'Extendify' : '0.8.4' ,
    'Hello Elementor' : '2.5.0' ,
    'Twitter Emoji (Twemoji)' : '14' ,
    'Gravity Forms' : '2.6.3.4' ,
    'Swiper Slider' : '9.0.5' ,
    'Next.js' : '17.0.2' ,
    'Google AdSense' : '2' ,
    'Chart.js' : '4.2.1' ,
    'Ubuntu' : '22.04' ,
    'Mautic' : '4.4.0' ,
    'LottieFiles' : '5.5.7' ,
    'Socket.io' : '4.5.1' ,
    'AngularJS' : '1.8.3' ,
    'Font Awesome' : '6.3.0' ,
    'New Relic' : '8.14.1' ,
    'Drupal' : '9.4.1' ,
    'PHP' : '8.2' ,
    'Nginx' : '1.25.1' ,
    'Cart Functionality' : '5.3.4' ,
    'Bootstrap' : '5.2.3' ,
    'OWL Carousel' : '2.3.4' ,
    'Font Awesome' : '6.3.0' ,
    'cdnjs' : '6.1.4' ,
    'New Relic' : '8.14.1' ,
    'jQuery Migrate' : '3.4.0' ,
    'jQuery' : '3.7.1' ,
    'Hotjar' : '1.0.14' ,
    'core-js' : '3.33.0' ,
    'Popper' : '2.11.6' ,
    'Apache' : '2.4.55' ,
    'Google Hosted Libraries' : '1.78' ,
    'Python' : '3.11.0' ,
    'Materialize CSS' : '1.0.0' ,
    'Django' : '4.1.7' ,
    'Nginx' : '1.25.1' ,
    'Select2' : '4.1.0' ,
    'jQuery' : '3.7.1' ,
    'animate.css' : '4.1.1' ,
    'Bootstrap' : '5.2.3' ,
    'OWL Carousel' : '2.3.4' ,
    'Font Awesome' : '6.3.0' ,
    'Adobe Target' : '22.10.1' ,
    'TypeScript' : '4.9.5' ,
    'Zone.js' : '0.12.0' ,
    'Angular' : '14.0.1' ,
    'Twitter Ads' : '11' ,
    'Hammer.js' : '2.0.8' ,
    'core-js' : '3.33.0' ,
    'webpack' : '5.75.0' ,
    'Apache Tomcat' : '10.1.5' ,
    'Oracle HTTP Server' : '12.2. 1.3.' ,
    'Chart.js' : '4.2.1' ,
    'Lodash' : '4.17.21' ,
    'Windows Server' : '10.0.20348.1487' ,
    'Microsoft ASP.NET' : '4.8.1' ,
    'IIS' : '10' ,
    'Crazy Egg' : '2.9' ,
    'Amazon Web Services' : '2' ,
    'Ionicons' : '6.0.2' ,
    'Node.js' : '19.6.0' ,
    'Express' : '4.18.2' ,
    'Google Hosted Libraries' : '1.78' ,
    'cdnjs' : '6.1.4' ,
    'Popper' : '2.11.6' ,
    'PHP' : '8.2' ,
    'Apache' : '2.4.55' ,
    'jQuery UI' : '1.13.2' ,
    'jQuery Migrate' : '3.4.0' ,
    'OpenResty' : '1.21.4.1' ,
    'SweetAlert' : '2.1.2' ,
    'Cart Functionality' : '5.3.4' ,
    'Razorpay' : '1.6.22' ,
    'DataTables' : 'v5.1.3' ,
    'NVD3' : '1.8.6' ,
    'D3' : '7.8.2' ,
    'RequireJS' : '2.3.6' ,
    'AngularJS' : '1.8.3' ,
    'Moment.js' : '2.29.4' ,
    'Google Maps' : '10.66. 1' ,
    'Slick' : '3.4.1' ,
    'Moment.js' : '2.29.4' ,
    'Lodash' : '4.17.21' ,
    'jQuery' : '3.7.1' ,
    'FingerprintJS' : '3.3.3' ,
    'Windows Server' : '10.0.20348.1487' ,
    'Kendo UI' : '2022.2.621' ,
    'Bootstrap' : '5.2.3' ,
    'IIS' : '10.0 v1809' ,
    'jQuery Migrate' : '3.4.0' ,
    'Apache' : '2.4.55' ,
    'Slick' : '3.4.1' ,
    'jQuery UI' : '1.13.2' ,
    'Hammer.js' : '2.0.8' ,
    'Microsoft ASP.NET' : '4.8.1' ,
    'Azure' : '1.6. 11.3' ,
    'TypeScript' : '4.9.5' ,
    'Angular' : '14.0.1' ,
    'Zone.js' : '0.12.0' ,
    'Nginx' : '1.25.1' ,
    'Robin' : '5.3.11' ,
    'PHP' : '8.2' ,
    'Emotion' : '11.0.0' ,
    'cdnjs' : '6.1.4' ,
    'Font Awesome' : '6.3.0' ,
    'core-js' : '3.33.0' ,
    'webpack' : '5.75.0' ,
    'Babel' : '7.11.0' ,
    'RequireJS' : '2.3.6' ,
    'React' : '18.2.0' ,
    'Polyfill' : '7.12.1' ,
    'Modernizr' : '3.5.0' ,
    'Matomo Analytics' : '4.9. 0' ,
    'Laravel' : '9' ,
    'Axios' : '1.1.3' ,
    'Java' : 'Java 19' ,
    'animate.css' : '4.1.1' ,
    'Amazon Web Services' : '2' ,
    'Twitter typeahead.js' : '0.11.1' ,
    'reCAPTCHA' : 'v3' ,
    'Microsoft HTTPAPI' : '2' ,
    'CKEditor' : '4.19.1' ,
    'Google Maps' : '10.66. 1' ,
    'AngularJS' : '1.8.3' ,
    'Backbone.js' : '1.4.1' ,
    'Underscore.js' : '1.13.6' ,
    'toastr' : '2.1.4' ,
    'Clipboard.js' : '2.0.11' ,
    'Select2' : '4.1.0' ,
    'Ubuntu' : '22.04 LTS' ,
    'WordPress' : '6.2' ,
    'MySQL' : '8.0.32' ,
    'SPNEGO' : '1.0.1' ,
    'LazySizes' : '5.3.2' ,
    'Hotjar' : '1.0.14' ,
    'Sentry' : '23.2.0' ,
    'New Relic' : '8.14.1' ,
    'Windows Server' : '10.0.20348.1487' ,
    'Microsoft ASP.NET' : '4.8.1' ,
    'IIS' : '10.0 v1809' ,
    'Amazon Web Services' : '2' ,
    'PHP' : '8.2' ,
    'Laravel' : '9' ,
    'animate.css' : '4.1.1' ,
    'Bootstrap' : '5.2.3' ,
    'OWL Carousel' : '2.3.4' ,
    'Font Awesome' : '6.3.0' ,
    'Moment.js' : '2.29.4' ,
    'Lodash' : '4.17.21' ,
    'jQuery' : '3.7.1' ,
    'core-js' : '3.33.0' ,
    'Axios' : '1.1.3' ,
    'Apache' : '2.4.55' ,
    'Moodle' : '4' ,
    'React' : '18.2.0' ,
    'JSS' : '10.9.2' ,
    'Nginx' : '1.25.1' ,
    'LiteSpeed' : '5.3' ,
    'Lodash' : '4.17.21' ,
    'Java' : 'Java 19' ,
    'YUI' : '3.18.1' ,
    'jQuery Migrate' : '3.4.0' ,
    'jQuery' : '3.7.1' ,
    'Ubuntu' : '22.04 LTS' ,
    'PHP' : '8.2' ,
    'WordPress' : '6.2' ,
    'Elementor' : '3.8.1' ,
    'MySQL' : '8.0.32' ,
    'Apache' : '2.4.55' ,
    'WP Rocket' : '5.2.3' ,
    'Yoast SEO' : '20.1' ,
    'LazySizes' : '5.3.2' ,
    'jQuery UI' : '1.13.2' ,
    'core-js' : '3.33.0' ,
    'Swiper Slider' : '9.0.5' ,
    'Chart.js' : '4.2.1' ,
    'Vue.js' : 'v3.2.44' ,
    'Select2' : '4.1.0' ,
    'List.js' : '2.31' ,
    'Atlassian Jira' : '8.22.6' ,
    'Java' : 'Java 19' ,
    'CKEditor' : '4.19.1' ,
    'React' : '18.2.0' ,
    'jQuery-pjax' : '2.0.1' ,
    'Backbone.js' : '1.4.1' ,
    'styled-components' : '5.3.6' ,
    'Underscore.js' : '1.13.6' ,
    'Select2' : '4.1.0' ,
    'Moment.js' : '2.29.4' ,
    'Lodash' : '4.17.21' ,
    'jQuery UI' : '1.13.2' ,
    'jQuery Migrate' : '3.4.0' ,
    'jQuery' : '3.7.1' ,
    'core-js' : '3.33.0' ,
    'Sentry' : '23.2.0' ,
    'Leaflet' : '1.9.2' ,
    'JSS' : '10.9.2' ,
    'Nginx' : '1.25.1' ,
    'Livefyre' : '2.0.9' ,
    'Gatsby' : '4.17.2' ,
    'webpack' : '5.75.0' ,
    'Cart Functionality' : '5.3.4' ,
    'Handlebars' : '4.7.7' ,
    'Smile' : '6.1.0' ,
    'Google Hosted Libraries' : '1.78' ,
    'cdnjs' : '6.1.4' ,
    'Modernizr' : '3.5.0' ,
    'LazySizes' : '5.3.2' ,
    'Boomerang' : '1.4.7' ,
    'Google Cloud' : '1.12.0' ,
    'Node.js' : '19.6.0' ,
    'Express' : '4.18.2' ,
    'Envoy' : '1.22.2' ,
    'PDF.js' : '2.4.7' ,
    'Chart.js' : '4.2.1' ,
    'Ant Design' : '4.21.4' ,
    'Hotjar' : '1.0.14' ,
    'Font Awesome' : '6.3.0' ,
    'Babel' : '7.11.0' ,
    'Firebase' : '9.8.4' ,
    'Dart' : '2.17' ,
    'Angular Material' : '14.0.3' ,
    'AngularJS' : '1.8.3' ,
    'toastr' : '2.1.4' ,
    'Socket.io' : '4.5.1' ,
    'FancyBox' : 'v4+' ,
    'Next.js' : '17.0.2' ,
    'WordPress' : '6.2' ,
    'MySQL' : '8.0.32' ,
    'PHP' : '8.2' ,
    'WP Rocket' : '5.2.3' ,
    'OWL Carousel' : '2.3.4' ,
    'Gravity Forms' : '2.6.3.4' ,
    'Unpkg' : '16.7.0' ,
    'D3' : '7.8.2' ,
    'jQuery Mobile' : '1.4.5' ,
    'Mixpanel' : '2.45.0' ,
    'Bootstrap' : '5.2.3' ,
    'Mailmunch' : '3.1.2' ,
    'Elementor' : '3.8.1' ,
    'Apache' : '2.4.55' ,
    'Contact Form 7' : '7.5.7.1' ,
    '' : '7.0.0' ,
    'Swiper Slider' : '9.0.5' ,
    'Amazon Web Services' : '2' ,
    'Squarespace' : '7.1' ,
    'Stimulus' : '3.0.1' ,
    'YUI' : '3.18.1' ,
    'animate.css' : '4.1.1' ,
    'Vue.js' : 'v3.2.44' ,
    'Covet.pics' : '0.98.0' ,
    'Extendify' : '0.8.4' ,
    'reCAPTCHA' : 'v3' ,
    'Windows Server' : '10.0.20348.1487' ,
    'Microsoft ASP.NET' : '4.8.1' ,
    'IIS' : '10.0 v1809' ,
    'Twitter Emoji (Twemoji)' : '14' ,
    'crypto-js' : '4.1.1' ,
    'PHP' : '8.2' ,
    'CentOS' : 'CentOS 8.0-1905' ,
    'OpenSSL' : '3.0.8' ,
    'GSAP' : '3.11.4' ,
    'Apache' : '2.4.55' ,
    'Matomo Analytics' : '4.9. 0' ,
    'jQuery' : '3.7.1' ,
    'Hammer.js' : '2.0.8' ,
    'Flickity' : '3.0.0' ,
    'FancyBox' : 'v4+' ,
    'core-js' : '3.33.0' ,
    'Java' : 'Java 19' ,
    'OpenGSE' : '1' ,
    'TypeScript' : '4.9.5' ,
    'Angular' : '14.0.1' ,
    'Amazon Web Services' : '2' ,
    'Zone.js' : '0.12.0' ,
    'Nginx' : '1.25.1' ,
    'webpack' : '5.75.0' ,
    'MariaDB' : '10.10.0' ,
    'PHP' : '8.2' ,
    'Varnish' : '7.0.1' ,
    'Pantheon' : '8.1' ,
    'Fastly' : '2.0.0' ,
    'Windows Server' : '10.0.20348.1487' ,
    'Microsoft ASP.NET' : '4.8.1' ,
    'animate.css' : '4.1.1' ,
    'Bootstrap' : '5.2.3' ,
    'IIS' : '10.0 v1809' ,
    'Snowplow Analytics' : '3' ,
    'Slick' : '3.4.1' ,
    'Font Awesome' : '6.3.0' ,
    'jQuery' : '3.7.1' ,
    'CentOS' : 'CentOS 8.0-1905' ,
    'OpenSSL' : '3.0.8' ,
    'Apache' : '2.4.55' ,
    'Ubuntu' : '22.04 LTS' ,
    'Phusion Passenger' : '6.0. 13' ,
    'Kestrel' : '2.2.0' ,
    'jQuery Migrate' : '3.4.0' ,
    'Hotjar' : '1.0.14' ,
    'jsDelivr' : '7.0.0' ,
    'Laravel' : '9' ,
    'Stripe' : '2020-08-27' ,
    'Lodash' : '4.17.21' ,
    'Axios' : '1.1.3' ,
    'Sentry' : '23.2.0' ,
    'Polyfill' : '7.12.1' ,
    'Chart.js' : '4.2.1' ,
    'VideoJS' : '7.21.0' ,
    'React' : '18.2.0' ,
    'cdnjs' : '6.1.4' ,
    'Twitter Ads' : '11' ,
    'Select2' : '4.1.0' ,
    'Moment.js' : '2.29.4' ,
    'jQuery UI' : '1.13.2' ,
    'core-js' : '3.33.0' ,
    'Google Hosted Libraries' : '1.78' ,
    'Prism' : '1.29.0' ,
    'Typekit' : '5.7.1.1' ,
    'Java' : 'Java 19' ,
    'JavaServer Pages' : '2.5' ,
    'Drupal' : '9.4.1' ,
    'OWL Carousel' : '2.3.4' ,
    'FancyBox' : 'v4+' ,
    'Popper' : '2.11.6' ,
    'F5 BigIP' : '16.1.3' ,
    'Microsoft HTTPAPI' : '2' ,
    'Node.js' : '19.6.0' ,
    'Express' : '4.18.2' ,
    'Ruby' : '3.1.2' ,
    'Leaflet' : '1.9.2' ,
    'Ruby on Rails' : '7.0. 2' ,
    'Turbolinks' : '2.5. 3' ,
    'Livefyre' : '2.0.9' ,
    'Hammer.js' : '2.0.8' ,
    'JSS' : '10.9.2' ,
    'Python' : '3.11.0' ,
    'Backbone.js' : '1.4.1' ,
    'Underscore.js' : '1.13.6' ,
    'Dynatrace' : '1.244' ,
    'YUI' : '3.18.1' ,
    'Squarespace' : '7.1' ,
    'Modernizr' : '3.5.0' ,
    'reCAPTCHA' : 'v3' ,
    'Azure' : '1.6. 11.3' ,
    'Materialize CSS' : '1.0.0' ,
    'MobX' : '6.6.1' ,
    'Atlassian Jira' : '8.22.6' ,
    'jQuery-pjax' : '2.0.1' ,
    'TinyMCE' : '5.10.5' ,
    'ZURB Foundation' : '6.7.5' ,
    'AngularJS' : '1.8.3' ,
    'SPNEGO' : '1.0.1' ,
    'Prototype' : '1.7.3' ,
    'Knockout.js' : 'v3.5.1' ,
    'PIXIjs' : '6.4.2' ,
    'AlertifyJS' : '1.13.1' ,
    'Emotion' : '11.0.0' ,
    'Highcharts' : '9. 3.0' ,
    'D3' : '7.8.2' ,
    'Babel' : '7.11.0' ,
    'git' : '2.37.0' ,
    'Gerrit' : '3.6' ,
    'Polymer' : '3.5.1' ,
    'Vanilla' : '2021.012' ,
    'Swiper Slider' : '9.0.5' ,
    'WordPress' : '6.2' ,
    'MySQL' : '8.0.32' ,
    'Yoast SEO Premium' : '19.4' ,
    'Yoast SEO' : '20.1' ,
    'Twitter Emoji (Twemoji)' : '14' ,
    'Matomo Analytics' : '4.9. 0' ,
    'New Relic' : '8.14.1' ,
    'LazySizes' : '5.3.2' ,
    'Ionicons' : '6.0.2' ,
    'script.aculo.us' : '1.8' ,
    'Vaadin' : '23' ,
    'Windows Server' : '10.0.20348.1487' ,
    'Microsoft ASP.NET' : '4.8.1' ,
    'IIS' : '10.0 v1809' ,
    'WordPress' : '6.2' ,
    'MySQL' : '8.0.32' ,
    'PHP' : '8.2' ,
    'Nginx' : '1.25.1' ,
    'WP Rocket' : '5.2.3' ,
    'Contact Form 7' : '7.5.7.1' ,
    'Yoast SEO' : '20.1' ,
    'jQuery UI' : '1.13.2' ,
    'jQuery' : '3.7.1' ,
    'FancyBox' : 'v4+' ,
    'Amazon Web Services' : '2' ,
    'Java' : 'Java 19' ,
    'TypeScript' : '4.9.5' ,
    'amCharts' : '5.2.10' ,
    'Bootstrap' : '5.2.3' ,
    'Ionic' : '6.3.01' ,
    'Angular' : '14.0.1' ,
    'Zone.js' : '0.12.0' ,
    'Moment.js' : '2.29.4' ,
    'Hammer.js' : '2.0.8' ,
    'DataTables' : 'v5.1.3' ,
    'core-js' : '3.33.0' ,
    'webpack' : '5.75.0' ,
    'Bootstrap' : '5.2.3' ,
    'RequireJS' : '2.3.6' ,
    'Backbone.js' : '1.4.1' ,
    'Underscore.js' : '1.13.6' ,
    'jQuery UI' : '1.13.2' ,
    'Font Awesome' : '6.3.0' ,
    'Lodash' : '4.17.21' ,
    'jQuery' : '3.7.1' ,
    'Matomo Analytics' : '4.9. 0' ,
    'core-js' : '3.33.0' ,
    'Windows Server' : '10.0.20348.1487' ,
    'Microsoft ASP.NET' : '4.8.1' ,
    'IIS' : '10.0 v1809' ,
    'Microsoft HTTPAPI' : '2' ,
    'Python' : '3.11.0' ,
    'YUI' : '3.18.1' ,
    'WordPress' : '6.2' ,
    'MySQL' : '8.0.32' ,
    'PHP' : '8.2' ,
    'CentOS' : 'CentOS 8.0-1905' ,
    'OpenSSL' : '3.0.8' ,
    'Amazon Web Services' : '2' ,
    'Apache' : '2.4.55' ,
    'Yoast SEO' : '20.1' ,
    'Twitter Emoji (Twemoji)' : '14' ,
    'jQuery Migrate' : '3.4.0' ,
    'reCAPTCHA' : 'v3' ,
    'Chart.js' : '4.2.1' ,
    'Vue.js' : 'v3.2.44' ,
    'toastr' : '2.1.4' ,
    'Nginx' : '1.25.1' ,
    'webpack' : '5.75.0' ,
    'Babel' : '7.11.0' ,
    'Google Maps' : '10.66. 1' ,
    'MediaElement.js' : '6.0.1' ,
    'Boomerang' : '1.4.7' ,
    'Windows Server' : '10.0.20348.1487' ,
    'Microsoft ASP.NET' : '4.8.1' ,
    'IIS' : '10.0 v1809' ,
    'Modernizr' : '3.5.0' ,
    'jQuery' : '3.7.1' ,
    'FancyBox' : 'v4+' ,
    'Imperva' : 'v4.8.1' ,
    'Bootstrap' : '5.2.3' ,
    'Google Hosted Libraries' : '1.78' ,
    'Typekit' : '5.7.1.1' ,
    'jQuery UI' : '1.13.2' ,
    'Apache' : '2.4.55' ,
    'Slick' : '3.4.1' ,
    'jQuery Migrate' : '3.4.0' ,
    'Hammer.js' : '2.0.8' ,
    'Knockout.js' : 'v3.5.1' ,
    'Cart Functionality' : '5.3.4' ,
    'MySQL' : '8.0.32' ,
    'PHP' : '8.2' ,
    'RequireJS' : '2.3.6' ,
    'Moment.js' : '2.29.4' ,
    'New Relic' : '8.14.1' ,
    'Lodash' : '4.17.21' ,
    'Microsoft HTTPAPI' : '2' ,
    'Highcharts' : '9. 3.0' ,
    'D3' : '7.8.2' ,
    'Java' : 'Java 19' ,
    'ZURB Foundation' : '6.7.5' ,
    'Handlebars' : '4.7.7' ,
    'reCAPTCHA' : 'v3' ,
    'core-js' : '3.33.0' ,
    'Babel' : '7.11.0' ,
    'Swiper Slider' : '9.0.5' ,
    'SWFObject' : '2.2.1' ,
    'Varnish' : '7.0.1' ,
    'Lightbox' : '3.0.1' ,
    'Chart.js' : '4.2.1' ,
    'Kendo UI' : '2022.2.621' ,
    'Polyfill' : '7.12.1' ,
    'Font Awesome' : '6.3.0' ,
    'Microsoft HTTPAPI' : '2' ,
    'Windows Server' : '10.0.20348.1487' ,
    'Microsoft ASP.NET' : '4.8.1' ,
    'IIS' : '10.0 v1809' ,
    'animate.css' : '4.1.1' ,
    'Bootstrap' : '5.2.3' ,
    'Apache' : '2.4.55' ,
    'OWL Carousel' : '2.3.4' ,
    'Font Awesome' : '6.3.0' ,
    'LazySizes' : '5.3.2' ,
    'jQuery' : '3.7.1' ,
    'reCAPTCHA' : 'v3' ,
    'Akamai' : '11' ,
    'Windows Server' : '10.0.20348.1487' ,
    'Bootstrap' : '5.2.3' ,
    'IIS' : '10.0 v1809' ,
    'jsDelivr' : '7.0.0' ,
    'jQuery' : '3.7.1' ,
    'Popper' : '2.11.6' ,
    'Drupal' : '9.4.1' ,
    'PHP' : '8.2' ,
    'animate.css' : '4.1.1' ,
    'Google Hosted Libraries' : '1.78' ,
    'Font Awesome' : '6.3.0' ,
    'cdnjs' : '6.1.4' ,
    'jQuery UI' : '1.13.2' ,
    'Hotjar' : '1.0.14' ,
    'Apache' : '2.4.55' ,
    'OWL Carousel' : '2.3.4' ,
    'Matomo Analytics' : '4.9. 0' ,
    'Lodash' : '4.17.21' ,
    'Java' : 'Java 19' ,
    'Microsoft HTTPAPI' : '2' ,
    'Windows Server' : '10.0.20348.1487' ,
    'Microsoft ASP.NET' : '4.8.1' ,
    'IIS' : '10.0 v1809' ,
    'animate.css' : '4.1.1' ,
    'Bootstrap' : '5.2.3' ,
    'Apache' : '2.4.55' ,
    'OWL Carousel' : '2.3.4' ,
    'Font Awesome' : '6.3.0' ,
    'LazySizes' : '5.3.2' ,
    'jQuery' : '3.7.1' ,
    'reCAPTCHA' : 'v3' ,
    'React' : '18.2.0' ,
    'React' : '18.2.0' ,
    'WordPress' : '6.2' ,
    'MySQL' : '8.0.32' ,
    'PHP' : '8.2' ,
    'Vue.js' : 'v3.2.44' ,
    'Element UI' : '2.15.9' ,
    'WordPress Super Cache' : '1.7.9' ,
    'Yoast SEO' : '20.1' ,
    'Contact Form 7' : '7.5.7.1' ,
    'Twitter Emoji (Twemoji)' : '14' ,
    'jQuery Migrate' : '3.4.0' ,
    'jQuery' : '3.7.1' ,
    'Hotjar' : '1.0.14' ,
    'core-js' : '3.33.0' ,
    'PHP' : '8.2' ,
    'Chart.js' : '4.2.1' ,
    'Bootstrap' : '5.2.3' ,
    'toastr' : '2.1.4' ,
    'Handlebars' : '4.7.7' ,
    'SweetAlert' : '2.1.2' ,
    'Google Hosted Libraries' : '1.78' ,
    'Select2' : '4.1.0' ,
    'Moment.js' : '2.29.4' ,
    'jQuery' : '3.7.1' ,
    'reCAPTCHA' : 'v3' ,
    'Popper' : '2.11.6' ,
    'WordPress' : '6.2' ,
    'MySQL' : '8.0.32' ,
    'Google Maps' : '10.66. 1' ,
    'Yoast SEO' : '20.1' ,
    'Contact Form 7' : '7.5.7.1' ,
    'OWL Carousel' : '2.3.4' ,
    'Font Awesome' : '6.3.0' ,
    'jQuery UI' : '1.13.2' ,
    'jQuery Migrate' : '3.4.0' ,
    'Revslider' : '6.6.10' ,
    'TypeScript' : '4.9.5' ,
    'Zone.js' : '0.12.0' ,
    'Angular' : '14.0.1' ,
    'cdnjs' : '6.1.4' ,
    'Windows Server' : '10.0.20348.1487' ,
    'IIS' : '10.0 v1809' ,
    'Akamai' : '11' ,
    'Handlebars' : '4.7.7' ,
    'RequireJS' : '2.3.6' ,
    'Slick' : '3.4.1' ,
    'cdnjs' : '6.1.4' ,
    'LazySizes' : '5.3.2' ,
    'jQuery' : '3.7.1' ,
    'core-js' : '3.33.0' ,
    'Boomerang' : '1.4.7' ,
    'Nginx' : '1.25.1' ,
    'Bootstrap' : '5.2.3' ,
    'Google Hosted Libraries' : '1.78' ,
    'Popper' : '2.11.6' ,
    'Microsoft ASP.NET' : '4.8.1' ,
    'Azure' : 'v1.6. 11.3' ,
    'React' : '18.2.0' ,
    'Java' : 'Java 19' ,
    'UNIX' : 'V7' ,
    'Apache Tomcat' : '10.1.5' ,
    'Apache' : '2.4.55' ,
    'Varnish' : '7.0.1' ,
    'Microsoft HTTPAPI' : '2' ,
    'Apache' : '2.4.55' ,
    'TypeScript' : '4.9.5' ,
    'Zone.js' : '0.12.0' ,
    'Angular' : '14.0.1' ,
    'Nginx' : '1.25.1' ,
    'jQuery' : '3.7.1' ,
    'webpack' : '5.75.0' ,
    'Lodash' : '4.17.21' ,
    'core-js' : '3.33.0' ,
    'Imperva' : 'v4.8' ,
    'PHP' : '8.2' ,
    'Laravel' : '9' ,
    'DreamWeaver' : '21.3' ,
    'Ubuntu' : '22.04 LTS' ,
    'UNIX' : 'V7' ,
    'OpenSSL' : '3.0.8' ,
    'React' : '18.2.0' ,
    'JSS' : '10.9.2' ,
    'Bootstrap' : '5.2.3' ,
    'Python' : '3.11.0' ,
    'Backbone.js' : '1.4.1' ,
    'Underscore.js' : '1.13.6' ,
    'Moment.js' : '2.29.4' ,
    'jQuery UI' : '1.13.2' ,
    'jQuery Migrate' : '3.4.0' ,
    'Red Hat' : '9' ,
    'Java' : 'Java 19' ,
    'Apache Tomcat' : '10.1.5' ,
    'Cart Functionality' : '5.3.4' ,
    'animate.css' : '4.1.1' ,
    'MediaElement.js' : '6.0.1' ,
    'JavaServer Pages' : 'Java 19' ,
    'Slick' : '3.4.1' ,
    'Pingdom' : '3.1' ,
    'Font Awesome' : '6.3.0' ,
    'FlexSlider' : '2.7.2' ,
    'Modernizr' : '3.5.0' ,
    'DataTables' : 'v5.1.3' ,
    'AngularJS' : '1.8.3' ,
    'FingerprintJS' : '3.3.3' ,
    'Atlassian Jira' : '8.22.6' ,
    'D3' : '7.8.2' ,
    'jQuery-pjax' : '2.0.1' ,
    'Handlebars' : '4.7.7' ,
    'Select2' : '4.1.0' ,
    'Highcharts' : '9. 3.0' ,
    'Varnish' : '7.0.1' ,
    'Popper' : '2.11.6' ,
    'Node.js' : '19.6.0' ,
    'Vue.js' : 'v3.2.44' ,
    'Nuxt.js' : '2.15.8' ,
    'jsDelivr' : '7.0.0' ,
    'Windows Server' : '10.0.20348.1487' ,
    'Microsoft ASP.NET' : '4.8.1' ,
    'IIS' : '10.0 v1809' ,
    'cdnjs' : '6.1.4' ,
    'MochiWeb' : '2.20.1' ,
    'CKEditor' : '4.19.1' ,
    'ExtJS' : '7.6.1' ,
    'Amazon Web Services' : '2' ,
    'SweetAlert2' : '11.6.8' ,
    'JavaServer Faces' : '2.3' ,
    'Twitter Ads' : '11' ,
    'Yii' : '2.0.45' ,
    'Debian' : '11' ,
    'WordPress' : '6.2' ,
    'MySQL' : '8.0.32' ,
    'GSAP' : '3.11.4' ,
    'WordPress Super Cache' : '1.7.9' ,
    'Contact Form 7' : '7.5.7.1' ,
    'Twitter Emoji (Twemoji)' : '14' ,
    'reCAPTCHA' : 'v3' ,
    'Moodle' : '4' ,
    'RequireJS' : '2.3.6' ,
    'YUI' : '3.18.1' ,
    'Socket.io' : '4.5.1' ,
    'Clipboard.js' : '2.0.11' ,
    'Chart.js' : '4.2.1' ,
    'Kestrel' : '2.2.0' ,
    'styled-components' : '5.3.6' ,
    'Emotion' : '11.0.0' ,
    'Google Hosted Libraries' : '1.78' ,
    'Sentry' : '23.2.0' ,
    'Ruby' : '3.1.2' ,
    'Ruby on Rails' : '7.0. 2' ,
    'toastr' : '2.1.4' ,
    'CentOS' : 'CentOS 8.0-1905' ,
    'Azure' : '1.6. 11.3' ,
    'Docker' : '20.10.17' ,
    'Atlassian Confluence' : '6.15' ,
    'Babel' : '7.11.0' ,
    'Express' : '4.18.2' ,
    'Autoptimize' : '3.0.4' ,
    'Yoast SEO' : '20.1' ,
    'LazySizes' : '5.3.2' ,
    'Django' : '4.1.7' ,
    'TypeScript' : '4.9.5' ,
    'Bootstrap' : '5.2.3' ,
    'Angular' : '14.0.1' ,
    'Zone.js' : '0.12.0' ,
    'Akamai' : '11' ,
    'jQuery' : '3.7.1' ,
    'webpack' : '5.75.0' ,
    'Amazon Web Services' : '2' ,
    'Java' : 'Java 19' ,
    'Apache Tomcat' : '10.1.5' ,
    'Windows Server' : '10.0.20348.1487' ,
    'ZURB Foundation' : '6.7.5' ,
    'Microsoft ASP.NET' : '4.8.1' ,
    'React' : '18.2.0' ,
    'IIS' : '10.0 v1809' ,
    'Modernizr' : '3.5.0' ,
    'jQuery UI' : '1.13.2' ,
    'AngularJS' : '1.8.3' ,
    'Vue.js' : 'v3.2.44' ,
    'core-js' : '3.33.0' ,
    'Apache' : '2.4.55' ,
    'cdnjs' : '6.1.4' ,
    'Popper' : '2.11.6' ,
    'Debian' : '11' ,
    'Vercel' : 'v24' ,
    'Microsoft HTTPAPI' : '2' ,
    'Nginx' : '1.25.1' ,
    'Azure' : '1.6. 11.3' ,
    'Knockout.js' : 'v3.5.1' ,
    'jQuery Migrate' : '3.4.0' ,
    'WordPress' : '6.2' ,
    'MySQL' : '8.0.32' ,
    'PHP' : '8.2' ,
    'reCAPTCHA' : 'v3' ,
    'CentOS' : 'CentOS 8.0-1905' ,
    'Laravel' : '9' ,
    'OpenSSL' : '3.0.8' ,
    'NVD3' : '1.8.6' ,
    'D3' : '7.8.2' ,
    'Select2' : '4.1.0' ,
    'Moment.js' : '2.29.4' ,
    'Node.js' : '19.6.0' ,
    'Express' : '4.18.2' ,
    'Google Hosted Libraries' : '1.78' ,
    'Font Awesome' : '6.3.0' ,
    'Chart.js' : '4.2.1' ,
    'Matomo Analytics' : '4.9. 0' ,
    'Ubuntu' : '22.04 LTS' ,
    'Slick' : '3.4.1' ,
    'styled-components' : '5.3.6' ,
    'Lodash' : '4.17.21' ,
    'Twitter typeahead.js' : '0.11.1' ,
    'CodeMirror' : '6.0.1' ,
    'Sentry' : '23.2.0' ,
    'Handlebars' : '4.7.7' ,
    'Ember.js' : '4.3.0' ,
    'Didomi' : '1.62.0' ,
    'Babel' : '7.11.0' ,
    'Drupal' : '9.4.1' ,
    'SPNEGO' : '1.0.1' ,
    'LazySizes' : '5.3.2' ,
    'Flickity' : '3.0.0' ,
    'Axios' : '1.1.3' ,
    'wpBakery' : '6.10.0' ,
    'WP Rocket' : '5.2.3' ,
    'Typekit' : '5.7.1.1' ,
    'Hotjar' : '1.0.14' ,
    'JSS' : '10.9.2' ,
    'Highcharts' : '9. 3.0' ,
    'Lightbox' : '3.0.1' ,
    'Swagger UI' : '4.8. 0' ,
    'Symfony' : '6.1.2' ,
    'JavaServer Faces' : '2.3' ,
    'Prototype' : '1.7.3' ,
    'Socket.io' : '4.5.1' ,
    'Ionicons' : '6.0.2' ,
    'Marked' : '4.0.17' ,
    'lit-html' : '2.2.6' ,
    'lit-element' : '3.2.0' ,
    'Datadog' : '7.37.1' ,
    'CodeIgniter' : 'v3.1.13' ,
    'jsDelivr' : '7.0.0' ,
    'Cart Functionality' : '5.3.4' ,
    'YUI' : '3.18.1' ,
    'All in One SEO Pack' : '4.2.2' ,
    'Twenty Fifteen' : '3.2' ,
    'OWL Carousel' : '2.3.4' ,
    'Twitter Ads' : '11' ,
    'Disqus' : '11.0.6' ,
    'Apache' : '2.4.55' ,
    'Google Maps' : '10.66. 1' ,
    'Bootstrap' : '5.2.3' ,
    'Nginx' : '1.25.1' ,
    'cdnjs' : '6.1.4' ,
    'jQuery' : '3.7.1' ,
    'Popper' : '2.11.6' ,
    'Apache' : '2.4.55' ,
    'Amazon Web Services' : '2' ,
    'Google Cloud' : '1.12.0' ,
    'FingerprintJS' : '3.3.3' ,
    'Node.js' : '19.6.0' ,
    'Vue.js' : 'v3.2.44' ,
    'Nuxt.js' : '2.15.8' ,
    'GSAP' : '3.11.4' ,
    'Outbrain' : '4.9.0' ,
    'Lodash' : '4.17.21' ,
    'core-js' : '3.33.0' ,
    'Crazy Egg' : '2.9' ,
    'webpack' : '5.75.0' ,
    'Java' : 'Java 19' ,
    'Chart.js' : '4.2.1' ,
    'Bootstrap' : '5.2.3' ,
    'JavaServer Pages' : '2.5' ,
    'Modernizr' : '3.5.0' ,
    'jQuery UI' : '1.13.2' ,
    'jQuery' : '3.7.1' ,
    'Envoy' : '2.45.0' ,
    'React' : '18.2.0' ,
    'jsDelivr' : '7.0.0' ,
    'Zendesk' : '1.0.0' ,
    'Moment.js' : '2.29.4' ,
    'Twitter Ads' : '11' ,
    'Impact' : '1.16.5' ,
    'Apache Tomcat' : '10.1.5' ,
    'Microsoft HTTPAPI' : '2' ,
    'WordPress' : '6.2' ,
    'MySQL' : '8.0.32' ,
    'PHP' : '8.2' ,
    'AngularJS' : '1.8.3' ,
    'wpBakery' : '6.10.0' ,
    'W3 Total Cache' : '2.2.3' ,
    'Extendify' : '0.8.4' ,
    'OWL Carousel' : '2.3.4' ,
    'Twitter Emoji (Twemoji)' : '14' ,
    'jQuery Migrate' : '3.4.0' ,
    'Boomerang' : '1.4.7' ,
    'Akamai' : '11' ,
    'Swiper Slider' : '9.0.5' ,
    'Nginx' : '1.25.1' ,
    'Backbone.js' : '1.4.1' ,
    'Underscore.js' : '1.13.6' ,
    'Babel' : '7.11.0' ,
    'Windows Server' : '10.0.20348.1487' ,
    'Microsoft ASP.NET' : '4.8.1' ,
    'IIS' : '10' ,
    'Font Awesome' : '6.3.0' ,
    'FancyBox' : 'v4+' ,
    'reCAPTCHA' : 'v3' ,
    'SPNEGO' : '1.0.1' ,
    'TinyMCE' : '5.10.5' ,
    'TypeScript' : '4.9.5' ,
    'GraphQL' : '16.6.0' ,
    'styled-components' : '5.3.6' ,
    'JSS' : '10.9.2' ,
    'Next.js' : '17.0.2' ,
    'Apollo' : '2.34.0' ,
    'Sentry' : '23.2.0' ,
    'Dynatrace' : '1.244' ,
    'MobX' : '6.6.1' ,
    'Pulse Secure' : '9.1' ,
    'Emotion' : '11.0.0' ,
    'Google Hosted Libraries' : '1.78' ,
    'PDF.js' : '2.4.7' ,
    'Sitecore' : '10.1' ,
    'Select2' : '4.1.0' ,
    'LazySizes' : '5.3.2' ,
    'Red Hat' : '9' ,
    'MUI' : '5.8.6' ,
    'Brightcove' : '8.0.0' ,
    'OpenResty' : '1.21.4.1' ,
    'anime.js' : '3.2.1' ,
    'animate.css' : '4.1.1' ,
    'Azure' : '1.6. 11.3' ,
    'Tailwind CSS' : '3. 0' ,
    'Element UI' : '2.15.9' ,
    'Jenkins' : '2.346.1' ,
    'Jetty' : '11' ,
    'Angular' : '14.0.1' ,
    'Zone.js' : '0.12.0' ,
    'Unpkg' : '16.7.0' ,
    'Haskell' : '2020' ,
    'Express' : '4.18.2' ,
    'RequireJS' : '2.3.6' ,
    'Bynder' : '4.0.3' ,
    'Amazon Web Services' : '2' ,
    'Ruby' : '3.1.2' ,
    'Ruby on Rails' : '7.0. 2' ,
    'Varnish' : '7.0.1' ,
    'Stimulus' : '3.0.1' ,
    'Turbolinks' : '2.5. 3' ,
    'Lodash' : '4.17.21' ,
    'Font Awesome' : '6.3.0' ,
    'Nginx' : '1.25.1' ,
    'Node.js' : '19.6.0' ,
    'React' : '18.2.0' ,
    'Express' : '4.18.2' ,
    'core-js' : '3.33.0' ,
    'webpack' : '5.75.0' ,
    'Sentry' : '23.2.0' ,
    'Quill' : '1.3.7' ,
    'Backbone.js' : '1.4.1' ,
    'styled-components' : '5.3.6' ,
    'Underscore.js' : '1.13.6' ,
    'Amplitude' : '8.18.5' ,
    'Highlight.js' : '11.7.0' ,
    'Apache' : '2.4.55' ,
    'Highcharts' : '9. 3.0' ,
    'Bootstrap' : '5.2.3' ,
    'Polyfill' : '7.12.1' ,
    'Twitter typeahead.js' : '0.11.1' ,
    'jQuery' : '3.7.1' ,
    'reCAPTCHA' : '3' ,
    'Fastly' : '2.0.0' ,
    'Envoy' : '1.22.2' ,
    'Zendesk' : '1.0.0' ,
    'Moment.js' : '2.29.4' ,
    'Bootstrap' : '5.2.3' ,
    'animate.css' : '4.1.1' ,
    'Amazon Web Services' : '2' ,
    'Font Awesome' : '6.3.0' ,
    'Modernizr' : '3.5.0' ,
    'jQuery UI' : '1.13.2' ,
    'jQuery' : '3.7.1' ,
    'DataTables' : '5.1.3' ,
    'crypto-js' : '4.1.1' ,
    'Popper' : '2.11.6' ,
    'Windows Server' : '10.0.20348.1487' ,
    'Microsoft ASP.NET' : '4.8.1' ,
    'IIS' : '10' ,
    'TypeScript' : '4.9.5' ,
    'Zone.js' : '0.12.0' ,
    'Angular' : '14.0.1' ,
    'webpack' : '5.75.0' ,
    'Hammer.js' : '2.0.8' ,
    'Java' : '19' ,
    'Java Servlet' : '5' ,
    'AngularJS' : '1.8.3' ,
    'SweetAlert' : '2.1.2' ,
    'reCAPTCHA' : '3' ,
    'Moment.js' : '2.29.4' ,
    'Dojo' : '1.8' ,
    'Apache' : '2.4.55' ,
    'Select2' : '4.1.0' ,
    'Axios' : '1.1.3' ,
    'Adobe Target' : '22.10.1' ,
    'Knockout.js' : '3.5.1' ,
    'jQuery Migrate' : '3.4.0' ,
    'Nginx' : '1.25.1' ,
    'Highcharts' : '9. 3.0' ,
    'OWL Carousel' : '2.3.4'
 }