import React, { useEffect, useState } from "react";
import { axios } from "axios";
import { useSelector, useDispatch } from "react-redux";
import { Link, useHistory, useParams } from "react-router-dom";
import { searchCVE } from "../../actions/cveInformation";
import { toastr } from "react-redux-toastr";
import Pagination from "../Pagination/Pagination";
import moment from "moment";
import {
  searchProduct,
  searchVendor,
  vendorProduct,
} from "../../actions/vendorandProduct";

const VendorProduct = () => {
  const dispatch = useDispatch();
  const [search, setSearch] = useState("");
  const [searchValue, setSearchValue] = useState("");
  const [vendorProductName, setVendorProduct] = useState("");
  const [vendor, setVendor] = useState("");
  const [page, setPage] = useState(1);
  const [pageProduct, setPageProduct] = useState(1);
  const [hide, setHide] = useState(false);
  const [limit, setlimit] = useState(10);
  const vendorName = sessionStorage.getItem('vendorName');  
const vendorId = sessionStorage.getItem('vendorId');

  const vendorData = useSelector(
    (state) => state?.vendorandProduct?.response?.data
  );
  const productData = useSelector(
    (state) => state?.vendorandProduct?.responseProduct?.data
  );

  useEffect(() => {
    if (hide == true) {
      vendorProduct(
        dispatch,
        vendorProductName,
        pageProduct,
        10,
        "vendorProduct"
      );
    } else {
      vendorProduct(dispatch, search, pageProduct, 10, "allProducs");
    }
    searchVendor(dispatch, vendor, page, limit);
  }, [dispatch, search, vendor, page, limit, vendorProductName ,pageProduct]);

  return (
    <div className="container-fluid">
      <div className="block-header">
        <div className="row clearfix">
          <div className="col-md-6 col-sm-12">
            <h2 style={{ color: "white" }}>Vendors & Products</h2>
            <nav aria-label="breadcrumb">
              <ol className="breadcrumb">
                <li className="breadcrumb-item">
                  {vendorId ? <>
                  <Link to="/vendor"
                      onClick={(() => {
                        sessionStorage.clear();
                      })}
                    >Forest View</Link>
                  </> : <Link to="/">Home</Link>}
                </li>
                {
                  vendorName ?
                    <li
                      className="breadcrumb-item active"
                      aria-current="page"
                      style={{ color: "white" }}
                    >
                      {vendorName}
                    </li> : ''
                }
                <li
                  className="breadcrumb-item active"
                  aria-current="page"
                  style={{ color: "white" }}
                >
                  Vendors & Products
                </li>
              </ol>
            </nav>
          </div>
        </div>
      </div>
      <div className="row justify-content-center mb-5">
        <div className="col-lg-5 col-md-6">
          <div className="input-group">
            <input
              placeholder="Search in vendors and products"
              value={searchValue}
              name="name"
              onChange={(e) => {
                setSearchValue(e.target.value);
              }}
              className="form-control"
            ></input>
          </div>
        </div>
        <div className="col-lg-2 col-md-6">
          <button
            //   onClick={cveFunction}
            type="button"
            style={{ backgroundColor: "#b0f127", fontWeight: "bold" }}
            className="btn btn-sm btn-success"
            onClick={() => {
              setSearch(searchValue);
              setVendor(searchValue);
              setHide(false);
            }}
          >
            Search
          </button>
        </div>
      </div>
      <div className="row block-header">
        <div className="col-md-6">
          <h2 className="outline_h2">Vendors {vendorData?.totalVendors}</h2>
          {vendorData && (
            <div className="col-md-12">
              <div className="table-responsive">
                <table className="table table-custom spacing5 mb-0 mt-3">
                  <thead>
                    <tr>
                      <th>Vendor</th>
                      <th>Products</th>
                    </tr>
                  </thead>
                  <tbody>
                    {vendorData?.vendors?.map((res, i) => {
                      return (
                        <tr>
                          <td>{res?.vendorName}</td>
                          <td
                            onClick={() => {
                              setVendorProduct(res?.vendorName);
                              if (res?.vendorName) {
                                vendorProduct(
                                  dispatch,
                                  res?.vendorName,
                                  pageProduct,
                                  10,
                                  "vendorProduct"
                                );
                              }
                              setHide(true);
                            }}
                            style={{ cursor: "pointer" }}
                          >
                            {res?.productsCount}
                          </td>
                        </tr>
                      );
                    })}
                  </tbody>
                </table>
              </div>
              <div className="dataTables_paginate">
                {vendorData?.totalPages > 0 && (
                  <Pagination
                    className="pagination-bar"
                    currentPage={page}
                    totalCount={vendorData?.totalVendors}
                    pageSize={10}
                    onPageChange={(page) => {
                      setPage(page);
                      searchVendor(dispatch, vendor, page, limit);
                    }}
                  />
                )}
              </div>
            </div>
          )}
        </div>
        <div className="col-md-6">
          <h2 className="outline_h2">Products {productData?.totalProducts}</h2>
          {productData && (
            <div className="col-md-12">
              <div className="table-responsive">
                <table className="table table-custom spacing5 mb-0 mt-3">
                  <thead>
                    <tr>
                      <th>Vendor</th>
                      <th>Products</th>
                      <th>Link</th>
                    </tr>
                  </thead>
                  <tbody>
                    {productData?.products?.map((res, i) => {
                      return (
                        <tr>
                          <td>{res?.vendorName}</td>
                          <td>{res?.productName}</td>
                          <td>
                            <Link
                              to={`/product-vendor/vendorName=${encodeURIComponent(
                                res?.vendorName
                              )}&productName=${encodeURIComponent(
                                res?.productName
                              )}`}
                              className="p-2 rounded-md bg-slate-200"
                              key={i}
                            >
                              {res?.vendorName + res?.productName}
                            </Link>
                          </td>
                        </tr>
                      );
                    })}
                  </tbody>
                </table>
              </div>
              <div className="dataTables_paginate">
                {productData?.totalProducts > 10 && (
                  <Pagination
                    className="pagination-bar"
                    currentPage={pageProduct}
                    totalCount={productData?.totalProducts}
                    pageSize={10}
                    onPageChange={(page) => {
                      console.log("call",page);
                      setPageProduct(page);
                      if (hide == true) {
                        vendorProduct(
                          dispatch,
                          search,
                          pageProduct,
                          10,
                          "vendorProduct"
                        );
                      } else {
                        vendorProduct(
                          dispatch,
                          search,
                          page,
                          10,
                          "allProducs"
                        );
                      }
                    }}
                  />
                )}
              </div>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};
export default VendorProduct;
