import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { Link, useParams } from "react-router-dom";
import {
  fetchLeakedCredentials,
  fetchLeakedCredentialsTotal,
  fetchLeakedCredentialsAction,
  fetchLeakedCredentialsGraph,
} from "../../actions/leakedCredentials";
import moment from "moment";
import BasicLineColumn from "../common/apexChart/lineChart";
import Pagination from "../Pagination/Pagination";
import * as FileSaver from "file-saver";
import * as XLSX from "xlsx";
import $ from "jquery";
import TrialComponent from "../common/trialComponent";
import NoData from "../common/noDataComponetnt";
import ScanMessage from "../common/scanningMessage";
import AccessPage from "../Shared/accessPage";
import axios from "axios";
import { API_URL } from "../../utils/constant";
import MenuItem from "@mui/material/MenuItem";
import Box from "@mui/material/Box";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";

const StolenCredentials = () => {
  // const id = JSON.parse(localStorage.getItem("user")).organisationId;
  const { orgId } = useParams();
  const id = useSelector(state => {
    if (orgId) {
      return orgId;
    } else {
      return state?.summary?.localClient?._id || state?.summary?.clients?.[0]?._id;
    }
  });
  const client = useSelector(
    (state) => state?.summary?.localClient || state?.summary?.clients?.[0]
  );
  const dispatch = useDispatch();
  const [search, setSearch] = useState("");
  const [toggleArray, setToggleArray] = useState({});
  const [page, setPage] = useState(1);
  const [PageSize, setPerPage] = useState(10);
  const [filterValue, setFilterValue] = useState("all");
  const [totalItems, setTotalItems] = useState(0);
  const [category, setCategory] = useState("all");
  const [isModal, setModal] = useState("");
  const [forValue, setForValue] = useState("forever");
  const [lookalikeAppId, setLookalikeAppId] = useState([]);
  const [actionNote, setActionNote] = useState("");
  const [action, setAction] = useState("");
  const [selectedValue, setSelectedValue] = useState("pleaseSelect");
  const vendorName = sessionStorage.getItem('vendorName');
  const vendorId = sessionStorage.getItem('vendorId');

  const state = useSelector(
    (state) => state?.leakedCredential?.response?.data?.data
  );
  const total = useSelector(
    (state) => state?.leakedCredential?.response?.data?.totalLeakedCredentials
  );
  const scanCompleted = useSelector(
    (state) => state?.leakedCredential?.scanCompleted
  );

  const auth = useSelector((state) => state?.auth?.user);

  let oldDnsDetails = useSelector(
    (state) => state.leakedCredential?.stolenGraph?.data
  );

  let totalCount = useSelector(
    (state) => state.leakedCredential?.stolenTotal?.data
  );

  let totalIp = 0,
    plainPasswords = 0;
  if (oldDnsDetails?.scanDetails) {
    // ipArray.push(oldDnsDetails?.scanDetails || 0);
    totalIp = oldDnsDetails?.ipLength || 0;
    plainPasswords = oldDnsDetails?.plainPassword || 0;
  }

  useEffect(() => {
    if (orgId) {
      fetchLeakedCredentials(orgId, dispatch, page, category, filterValue, search);
    } else {
      fetchLeakedCredentials(id, dispatch, page, category, filterValue, search);
    }
  }, [id, dispatch, page, category, filterValue, search]);

  useEffect(() => {
    setTotalItems(total);
  }, [total]);

  useEffect(() => {
    fetchLeakedCredentialsGraph(id, dispatch);
  }, [id, dispatch]);

  useEffect(() => {
    fetchLeakedCredentialsTotal(id, dispatch);
  }, [id, dispatch]);

  let response = state;

  const plainPasswordPercent = ((plainPasswords || 0) / (totalIp || 0)) * 100;

  let mapData = response;

  // filterValue

  let currentTableData = [];
  //console.log(mapData,'mapDAta')
  if (mapData && mapData.length > 0) {
    currentTableData = mapData;
  }

  const handleSearchChange = (e) => {
    setSearch(e.target.value);
  };

  const addModal = () => {
    setModal("Form Wizard modal");
  };

  const [selectedIds, setSelectedIds] = useState([]);
  const [multiCheckTrue, setMultiCheckTrue] = useState(false);

  const multicheck = (data) => {
    const allIds = data?.map((item) => item._id);
    if (selectedIds.length > 0 || selectedIds.length == 0) {
      setMultiCheckTrue(true);
      setSelectedIds(allIds);
    }
    if (multiCheckTrue == true) {
      setSelectedIds([]);
      setMultiCheckTrue(false);
    }
  };

  const singleClick = (id, data) => {
    setSelectedIds((prevSelectedIds) => {
      if (prevSelectedIds.includes(id)) {
        return prevSelectedIds.filter((selectedId) => selectedId !== id);
      } else {
        return [...prevSelectedIds, id];
      }
    });
    if (selectedIds.length > 10) {
      setMultiCheckTrue(true);
    } else {
      setMultiCheckTrue(false);
    }
  };


  const snooze = (_id, event) => {
    if (event == "") {
    }

    if (event == "riskAccepted") {
    }

    if (event == "markAsFalsePositive") {
    }

    if (event == "markAsRemidiated") {
      let ids = [];
      ids.push(_id);
      const data = {
        action: "markAsRemidiated",
        leakedCredentialsIds: selectedIds == 0 ? ids : selectedIds,
        actionNote: "",
        for: "forever",
      };
      fetchLeakedCredentialsAction(
        id,
        dispatch,
        page,
        category,
        filterValue,
        search,
        data
      );
      setSelectedValue("pleaseSelect");
      setActionNote("");
      setSelectedIds([]);
    }
  };

  const submit = () => {
    const data = {
      action: action,
      leakedCredentialsIds: selectedIds == 0 ? lookalikeAppId : selectedIds,
      actionNote: actionNote,
      for: forValue,
    };
    fetchLeakedCredentialsAction(
      id,
      dispatch,
      page,
      category,
      filterValue,
      search,
      data
    );
    setModal("");
    setSelectedValue("pleaseSelect");
    setActionNote("");
    setSelectedIds([]);
  };

  const Unsnoozed = (_id) => {
    let ids = [];
    ids.push(_id);
    const data = {
      action: "resetAction",
      leakedCredentialsIds: selectedIds == 0 ? ids : selectedIds,
    };
    fetchLeakedCredentialsAction(
      id,
      dispatch,
      page,
      category,
      filterValue,
      search,
      data
    );
    setSelectedIds([]);
  };

  const downloadFile = async () => {
    try {
      const session = localStorage.getItem("session");
      axios.defaults.headers.common["Authorization"] = `Bearer ${session}`;
      const response = await axios.get(
        `${API_URL}/dehashed/search/${id}/excel`,
        {
          responseType: "blob",
        }
      );
      const url = window.URL.createObjectURL(new Blob([response.data]));
      const link = document.createElement("a");
      link.href = url;
      link.setAttribute("download", "StolenCredentials.xlsx");
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
      window.URL.revokeObjectURL(url);
    } catch (error) {
      console.error(error);
    }
  };

  const toggleIcon = (_id, value) => {
    //console.log(_id);
    let val = toggleArray;
    if (val[_id]) {
      $("#" + _id + "_password").html("******");
      $("#" + _id + "_password_icon").attr("class", "fa fa-eye");
      // fa fa-eye-slash
      delete val[_id];
      setToggleArray({ ...val });
    } else {
      $("#" + _id + "_password").html(value);
      $("#" + _id + "_password_icon").attr("class", "fa fa-eye-slash");
      val[_id] = true;
      setToggleArray({ ...val });
    }
  };

  return (
    <div className="container-fluid">
      <AccessPage></AccessPage>
      <div className="block-header">
        <div className="row clearfix">
          <div className="col-md-6 col-sm-12">
            <h1 style={{ color: "white" }}>Stolen Credentials</h1>
            <nav aria-label="breadcrumb">
              <ol className="breadcrumb">
                <li className="breadcrumb-item">
                  {vendorId ? <>
                    <Link to="/vendor"
                      onClick={(() => {
                        sessionStorage.clear();
                      })}
                    >Forest View</Link>
                  </> : <Link to="/">Home</Link>}
                </li>
                {
                  vendorName ?
                    <li
                      className="breadcrumb-item active"
                      aria-current="page"
                      style={{ color: "white" }}
                    >
                      {vendorName}
                    </li> : ''
                }
                <li
                  className="breadcrumb-item active"
                  aria-current="page"
                  style={{ color: "white" }}
                >
                  Details
                </li>
              </ol>
              <br />
              <span><b>Rating : {totalCount?.individualRating}</b></span>
            </nav>
          </div>
          <div className="col-md-6 col-sm-12 text-right hidden-xs">
            <span>
              {client?.subscriptionType != "trial" && mapData?.length !== 0 && (
                <a
                  onClick={(e) => {
                    e.preventDefault();
                    downloadFile();
                  }}
                  href="/add-report"
                  style={{ color: "black", fontWeight: "bold" }}
                  className="btn btn-primary mr-1"
                >
                  <i
                    className="fa fa-file-excel-o"
                    style={{ color: "black" }}
                  ></i>{" "}
                  Download Details
                </a>
              )}
            </span>
          </div>
        </div>
      </div>

      {typeof scanCompleted !== "undefined" && !scanCompleted && (
        <div className="card border-success">
          <ScanMessage />
        </div>
      )}

      <div className="row clearfix">
        <div className="col-lg-4 col-md-12">
          <div className="card w_card3">
            <div className="body" style={{ minHeight: "460px" }}>
              <div className="row text-center">
                <div className="col-12 pb-4 pt-4">
                  <label className="mb-0">Records Found</label>
                  <h4 className="font-30 font-weight-bold text-col-blue">
                    {totalCount?.totalLeakedCredentials || 0}
                  </h4>
                </div>
              </div>

              <div className="form-group">
                <label className="d-block" style={{ cursor: "pointer" }}>
                  Plain Text Password{" "}
                  <span className="float-right">
                    {Math.floor(
                      (totalCount?.totalLeakedCredentialsWithPlainPassword /
                        totalCount?.totalLeakedCredentials) *
                      100 || 0
                    ) + "%"}
                  </span>
                </label>
                <div className="progress progress-xxs">
                  <div
                    className="progress-bar progress-bar-success"
                    role="progressbar"
                    aria-valuenow={
                      Math.floor(
                        (totalCount?.totalLeakedCredentialsWithPlainPassword /
                          totalCount?.totalLeakedCredentials) *
                        100 || 0
                      ) + "%"
                    }
                    aria-valuemin="0"
                    aria-valuemax="100"
                    style={{
                      width:
                        Math.floor(
                          (totalCount?.totalLeakedCredentialsWithPlainPassword /
                            totalCount?.totalLeakedCredentials) *
                          100 || 0
                        ) + "%",
                    }}
                  ></div>
                </div>
              </div>
              {client?.subscriptionType == "trial" && (
                <div className="card border-warning">
                  <TrialComponent />
                </div>
              )}
            </div>
          </div>
        </div>
        <div className="col-lg-8 col-md-12 col-sm-12">
          <div className="card">
            <div className="body" style={{ minHeight: "460px" }}>
              <h6 style={{ color: "white" }}>Previous scan details</h6>
              {/* <p className="text-muted">
                  The global and country campaign stats show how popular your ad
                  is relative to your other ads.
                </p> */}
              {/* <BasicLineColumn /> */}
              {oldDnsDetails && (
                <BasicLineColumn
                  dates={oldDnsDetails?.dates}
                  ipArray={oldDnsDetails?.scanDetails}
                  color={["#b0f127"]}
                  yAxis="Records Found"
                />
              )}
            </div>
          </div>
        </div>
      </div>
      <ul className="nav nav-tabs mb-2" id="myTabs" role="tablist">
        <li className="nav-item">
          <a
            className={`nav-table-tab nav-link ${category === "all" ? "active" : ""
              }`}
            onClick={() => {
              setCategory("all");
              setPage(1);
              setFilterValue("all");
              setSearch("");
              setSelectedIds([]);
            }}
          >
            Current
          </a>
        </li>
        <li className="nav-item">
          <a
            className={`nav-link nav-table-tab ${category === "risk-accepted" ? "active" : ""
              }`}
            onClick={() => {
              setCategory("risk-accepted");
              setPage(1);
              setFilterValue("all");
              setSearch("");
              setSelectedIds([]);
            }}
          >
            Risk Accepted
          </a>
        </li>
        <li className="nav-item">
          <a
            className={`nav-link nav-table-tab ${category === "false-positive" ? "active" : ""
              }`}
            onClick={() => {
              setCategory("false-positive");
              setPage(1);
              setFilterValue("all");
              setSearch("");
              setSelectedIds([]);
            }}
          >
            False Positive
          </a>
        </li>
        <li className="nav-item">
          <a
            className={`nav-link nav-table-tab ${category === "remidiated" ? "active" : ""
              }`}
            onClick={() => {
              setCategory("remidiated");
              setPage(1);
              setFilterValue("all");
              setSearch("");
              setSelectedIds([]);
            }}
          >
            Remediated
          </a>
        </li>
      </ul>
      <div className="card">
        <div className="body">
          <div className="row">
            <div className="col-sm-4">
              <select
                className="custom-select custom-select-sm form-control form-control-sm"
                value={filterValue}
                onChange={(e) => setFilterValue(e.target.value)}
              >
                <option value="all">All</option>
                <option value="plain-password">Plain Password</option>
                <option value="encrypted-password">Encrypted Password</option>
              </select>
            </div>
            <div className="col-sm-4">
              <div
                data-test="datatable-input"
                class="mdb-datatable-filter flex-row"
              >
                <input
                  class="form-control form-control-sm ml-0 my-1"
                  type="text"
                  placeholder="Search"
                  aria-label="Search"
                  value={search}
                  onChange={handleSearchChange}
                />
              </div>
            </div>
            <div className="col-sm-4">
              {selectedIds.length > 1 && category === "all" && (
                <Box sx={{ width: 200 }}>
                  <FormControl fullWidth>
                    <Select
                      labelId="demo-simple-select-label"
                      id="demo-simple-select"
                      value={selectedValue}
                      onChange={(event) => {
                        setAction(event.target.value);
                        snooze("", event.target.value);
                      }}
                    >
                      <MenuItem value="pleaseSelect">
                        Snooze Options
                      </MenuItem>
                      <MenuItem
                        value="riskAccepted"
                        onClick={() => {
                          addModal();
                        }}
                      >
                        Risk Accepted
                      </MenuItem>
                      <MenuItem
                        value="markAsFalsePositive"
                        onClick={() => {
                          addModal();
                        }}
                      >
                        False Positive
                      </MenuItem>
                      <MenuItem value="markAsRemidiated">
                        Remediated
                      </MenuItem>
                    </Select>
                  </FormControl>
                </Box>
              )}
              {selectedIds.length > 1 &&
                (category == "false-positive" ||
                  category == "risk-accepted") && (
                  <button
                    className="btn btn-primary btn-sm mr-1 Unsnooze"
                    onClick={() => Unsnoozed("")}
                  >
                    Unsnooze
                  </button>
                )}
            </div>
          </div>

          <div className="row clearfix">
            <div className="col-12 col-sm-12">
              <div className="card">
                <div className="table-responsive">
                  <table className="table table-custom spacing5 mb-0">
                    <thead>
                      {client?.subscriptionType == "trial" ? (
                        <tr>
                          <th>Breached Count</th>
                          <th>Breached Database</th>
                          <th>Email Address</th>
                          <th>Password</th>
                          <th>Last Breach</th>
                          {auth?.accessLevel != "Threat Analyst" && (category === "all" ||
                            category === "risk-accepted" ||
                            category === "false-positive") && (
                              <th style={{ width: "80" }}>Action</th>
                            )}
                        </tr>
                      ) : (
                        <tr>
                          {auth?.accessLevel != "Threat Analyst" && (category === "all" ||
                            category === "risk-accepted" ||
                            category === "false-positive") && (
                              <th>
                                <input
                                  type="checkbox"
                                  checked={
                                    selectedIds == 0 ? false : multiCheckTrue
                                  }
                                  onClick={() => {
                                    multicheck(currentTableData);
                                  }}
                                  className="checkUncheck"
                                />
                              </th>
                            )}
                          <th>Password Type</th>
                          <th>Email Address</th>
                          <th>Password</th>
                          {/* <th>Breached Database</th> */}
                          <th>Source</th>
                          <th>Description</th>
                          <th style={{ width: "100px" }}>Date</th>
                          {auth?.accessLevel != "Threat Analyst" && (category === "all" ||
                            category === "risk-accepted" ||
                            category === "false-positive") && (
                              <th style={{ width: "80" }}>Action</th>
                            )}
                        </tr>
                      )}
                    </thead>
                    <tbody>
                      {client?.subscriptionType == "trial" ? (
                        <tr>
                          <td colSpan={5}>
                            {/* <NoData /> */}
                            <NoData />
                          </td>
                        </tr>
                      ) : (
                        currentTableData?.map((row, index) => {
                          let password = row?.pwd?.split("") || ["N", "A"];
                          let date = row?.imp
                            ? row?.imp?.toString().substr(0, 4) +
                            "-" +
                            row?.imp?.toString().substr(4, 2) +
                            "-" +
                            row?.imp?.toString().substr(6, 2)
                            : "NA";
                          return (
                            <tr key={index}>
                              {auth?.accessLevel != "Threat Analyst" && (category === "all" ||
                                category === "risk-accepted" ||
                                category === "false-positive") && (
                                  <td>
                                    <input
                                      onClick={() => singleClick(row?._id)}
                                      checked={selectedIds?.includes(row?._id)}
                                      type="checkbox"
                                      className="checkUncheck"
                                    />
                                  </td>
                                )}
                              <td>
                                <span
                                  className={
                                    row?.dec == 0
                                      ? `badge badge-success ml-0 mr-0`
                                      : `badge badge-danger ml-0 mr-0`
                                  }
                                >
                                  {row?.dec == 0
                                    ? "Encrypted"
                                    : "Plain Password"}
                                </span>
                              </td>
                              <td
                                style={{ whiteSpace: "unset", width: "100px" }}
                              >
                                {row?.eml || "NA"}
                                {/* <span className="badge badge-danger ml-0 mr-0">{row?.sources?.length || 1}</span> */}
                              </td>
                              <td style={{ width: "100px" }}>
                                <span
                                  style={{
                                    whiteSpace: "pre-wrap",
                                    overflowWrap: "break-word",
                                    display: "flex",
                                  }}
                                >
                                  <span id={`${row._id}_password`}>******</span>
                                  <i
                                    class="fa fa-eye"
                                    style={{
                                      marginLeft: "10px",
                                      cursor: "pointer",
                                      fontSize: "16px",
                                    }}
                                    id={`${row._id}_password_icon`}
                                    onClick={() =>
                                      toggleIcon(row._id, password)
                                    }
                                  ></i>
                                </span>
                              </td>
                              <td>{row?.src || "NA"}</td>
                              <td style={{ whiteSpace: "unset" }}>
                                {row?.atr || "NA"}
                              </td>
                              <td style={{ whiteSpace: "unset" }}>
                                {row?.createdAt ? moment(row?.createdAt).format(
                                  "DD-MM-YYYY HH:MM"
                                )
                                  : "NA"}
                              </td>

                              {auth?.accessLevel != "Threat Analyst" && category === "all" && (
                                <td>
                                  <Box sx={{ width: 200 }}>
                                    <FormControl fullWidth>
                                      {/* <InputLabel
                                          sx={{ color: "#fff" }}
                                          id="demo-simple-select-label"
                                        >
                                          Action
                                        </InputLabel> */}
                                      <Select
                                        labelId="demo-simple-select-label"
                                        id="demo-simple-select"
                                        value={selectedValue}
                                        onChange={(event) => {
                                          setAction(event.target.value);
                                          snooze(row?._id, event.target.value);
                                        }}
                                      >
                                        <MenuItem value="pleaseSelect">
                                          Snooze Options
                                        </MenuItem>
                                        <MenuItem
                                          value="riskAccepted"
                                          onClick={() => {
                                            addModal();
                                            setLookalikeAppId([row._id]);
                                          }}
                                        >
                                          Risk Accepted
                                        </MenuItem>
                                        <MenuItem
                                          value="markAsFalsePositive"
                                          onClick={() => {
                                            addModal();
                                            setLookalikeAppId([row._id]);
                                          }}
                                        >
                                          False Positive
                                        </MenuItem>
                                        <MenuItem value="markAsRemidiated">
                                          Remediated
                                        </MenuItem>
                                      </Select>
                                    </FormControl>
                                  </Box>
                                </td>
                              )}
                              {auth?.accessLevel != "Threat Analyst" && (category === "risk-accepted" ||
                                category === "false-positive") && (
                                  <td>
                                    <button
                                      className="btn btn-primary btn-sm mr-1 Unsnooze"
                                      onClick={() => Unsnoozed(row?._id)}
                                    >
                                      Unsnooze
                                    </button>
                                  </td>
                                )}
                              {/* <td>
                          <span className="badge badge-danger ml-0 mr-0">{row?.sources?.length || 1}</span>
                        </td>
                        <td>{row.sources?.map(database_name=><span>{database_name}<br/></span>)}</td>
                        <td>{row?.line?.split(":")[0] || "NA"}</td>
                        <td>{row?.line?.split(":")[1] || "NA"}</td>
                        <td>{row?.last_breach || "NA"}</td> */}
                              {/* <td>{row.username.map(username=><span>{username}<br/></span>)}</td>
                        <td>{row.phone.map(phone=><span>{phone}<br/></span>)}</td>
                        <td>{row.ip_address.map(ip_address=><span>{ip_address}<br/></span>)}</td>
                        <td>{row?.address || "NA"}</td> */}
                            </tr>
                          );
                        })
                      )}
                      {/* <tr>
                    <td>
                      <span className="badge badge-danger ml-0 mr-0">1</span>
                    </td>
                    <td>LinkedIn Breach</td>

                    <td>aditya@ohphish.com</td>

                    <td>Password@1234</td>
                    <td>technobhardwaj33</td>
                    <td>9599292473</td>
                    <td>127.0.0.1</td>
                    <td>India</td>
                  </tr> */}
                    </tbody>
                  </table>
                </div>
                <div className="dataTables_paginate">
                  {totalItems > 0 && (
                    <Pagination
                      className="pagination-bar"
                      currentPage={page}
                      totalCount={totalItems}
                      pageSize={PageSize}
                      onPageChange={(page) => setPage(page)}
                    />
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div
        className={`modal fade Form-Wizard-modal ${isModal === "Form Wizard modal" ? "d-block show" : ""
          }`}
      >
        <div className="modal-dialog modal-lg" id="clientModal">
          <div className="modal-content">
            <div className="modal-header">
              <h5 className="modal-title" id="exampleModalLabel">
                {action == "markAsFalsePositive"
                  ? "Mark As False Positive"
                  : "Risk Accepted"}
              </h5>
              <button
                type="button"
                data-dismiss="modal"
                aria-label="Close"
                onClick={() => {
                  setModal("");
                  setActionNote("");
                }}
              >
                <span aria-hidden="true">×</span>
              </button>
            </div>
            <div className="modal-body pricing_page modal_mob">
              <div className="col-xl-12 col-lg-12 col-md-12">
                <div className="card">
                  <div className="row clearfix">
                    <div className="col-md-12">
                      <label className="col-lg-6 col-md-12">Snooze for</label>
                      <div className="form-group ol-lg-12 col-md-12">
                        <select
                          className="custom-select custom-select-sm form-control form-control-sm"
                          onChange={(e) => setForValue(e.target.value)}
                        >
                          <option value="forever">Forever</option>
                          <option value="3 months">3 Months</option>
                          <option value="6 months">6 Months</option>
                          <option value="12 months">12 Months</option>
                        </select>
                      </div>
                    </div>
                    <div className="col-md-12">
                      <label className="col-lg-12 col-md-12">Detail</label>
                      <div className="form-group col-lg-12 col-md-12">
                        <textarea
                          onChange={(e) => setActionNote(e.target.value)}
                          value={actionNote}
                          className="form-control"
                          rows="3"
                        ></textarea>
                      </div>
                    </div>
                  </div>
                  <div className="row">
                    <div
                      className="col-lg-12 col-md-12"
                      style={{ marginRight: "28px" }}
                    >
                      <button
                        type="button"
                        style={{ color: "black", fontWeight: "bold" }}
                        onClick={submit}
                        className="btn btn-round btn-primary mr-1 pull-right"
                      >
                        Submit
                      </button>
                      &nbsp;&nbsp;
                      <button
                        type="button"
                        onClick={() => {
                          setModal("");
                          setActionNote("");
                        }}
                        className="btn btn-round btn-default pull-right"
                        style={{ marginRight: "10px" }}
                      >
                        Cancel
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
export default StolenCredentials;
