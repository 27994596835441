import React, { useEffect, useState } from "react";
import { Link, useParams } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import { fetchDashboardReport } from "../../actions/summary";
import Box from "@mui/material/Box";
import LinearProgress from "@mui/material/LinearProgress";
import moment from "moment";
import AccessPage from "../Shared/accessPage";
import Splinechart from "../common/splinechart";

var Model = require("./model");

const Dashboard = () => {
  const dispatch = useDispatch();
  const { orgId } = useParams();
  const [loading, setLoading] = useState(false);

  const [riskScoreVA, setRiskScoreVa] = useState("");
  const [riskScoreExpiredSSL, setRiskScoreExpiredSSL] = useState("");
  const [riskScoreVulnerableSSL, setRiskScoreVulnerableSSL] = useState("");
  const [riskScoreDangling, setRiskScoreDangling] = useState("");
  const vendorName = sessionStorage.getItem('vendorName');  
  const vendorId = sessionStorage.getItem('vendorId');
  const id = useSelector(state => {
    if (orgId) {
        return orgId;
    } else {
        return state?.summary?.localClient?._id || state?.summary?.clients?.[0]?._id;
    }
});
  const client = useSelector(
    (state) => state?.summary?.localClient || state?.summary?.clients?.[0]
  );

  useEffect(() => {
    if (id) fetchDashboardReport(id, "Data", dispatch);
    else fetchDashboardReport(id, "Data", dispatch);
  }, [id]);
  let summary = useSelector((state) => state?.summary?.dashboardSummary);
  let summaryLoading = useSelector((state) => state?.summary?.loading);

  let securityGraphValue = summary?.Rating?.map((row) => row?.darkWebSecurityImpact);
  let securityGraphDate = summary?.Rating?.map((row) =>
    moment(row?.createdAt).format("DD-MM-YYYY")
  );

  const generateReport = () => {
    if (loading) {
      return false;
    }
    let data = {
      id:id,
      asm:'darkweb'
    }
    setLoading(true);
    Model.default.generateReport(data).then((response) => {
      setLoading(false);
      if (!response.error) {
        window.open(response.data.docx);
      }
    });
  };

  // Generate Grade for Passive VA

  // let passiveVaComplaint = Math.sqrt(summary?.ipVulnerability?.toFixed(2));
  // let passiveVaBase = Math.sqrt(summary?.assetsDiscovery);

  // let passiveVaValue = parseFloat(
  //   ((passiveVaComplaint / passiveVaBase) * 100)?.toFixed(2)
  // );

  // if (!isNaN(passiveVaValue) && riskScoreVA == "") {
  //   if (passiveVaValue == 0) {
  //     setRiskScoreVa("A1");
  //   } else if (passiveVaValue < 20) {
  //     setRiskScoreVa("A2");
  //   } else if (passiveVaValue < 35) {
  //     setRiskScoreVa("B1");
  //   } else if (passiveVaValue < 55) {
  //     setRiskScoreVa("B2");
  //   } else if (passiveVaValue < 75) {
  //     setRiskScoreVa("C1");
  //   } else {
  //     setRiskScoreVa("C2");
  //   }
  // }

  // let squareRootOfExpiredSSLComplaint = Math.sqrt(summary?.SSlEXpired)?.toFixed(
  //   2
  // );
  // let squareRootOfExpiredSSLBase = Math.sqrt(
  //   summary?.SSlCertificate?.toFixed(2)
  // );

  // let expiredSSLValue = parseFloat(
  //   (
  //     (squareRootOfExpiredSSLComplaint / squareRootOfExpiredSSLBase) *
  //     100
  //   )?.toFixed(2)
  // );

  // if (!isNaN(expiredSSLValue) && riskScoreExpiredSSL == "") {
  //   if (expiredSSLValue == 0) {
  //     setRiskScoreExpiredSSL("A1");
  //   } else if (expiredSSLValue < 20) {
  //     setRiskScoreExpiredSSL("A2");
  //   } else if (expiredSSLValue < 35) {
  //     setRiskScoreExpiredSSL("B1");
  //   } else if (expiredSSLValue < 55) {
  //     setRiskScoreExpiredSSL("B2");
  //   } else if (expiredSSLValue < 75) {
  //     setRiskScoreExpiredSSL("C1");
  //   } else {
  //     setRiskScoreExpiredSSL("C2");
  //   }
  // }

  // End of generate grade for expired SSL

  // Generate Grade for Vulnerable SSL

  // let vulnerableSSLComplaint = Math.sqrt(
  //   summary?.SSlVulnerable?.toFixed(2)
  // );

  // let vulnerableSSLValue = parseFloat(
  //   ((vulnerableSSLComplaint / squareRootOfExpiredSSLBase) * 100)?.toFixed(2)
  // );

  // if (!isNaN(vulnerableSSLValue) && riskScoreVulnerableSSL == "") {
  //   if (vulnerableSSLValue == 0) {
  //     setRiskScoreVulnerableSSL("A1");
  //   } else if (vulnerableSSLValue < 20) {
  //     setRiskScoreVulnerableSSL("A2");
  //   } else if (vulnerableSSLValue < 35) {
  //     setRiskScoreVulnerableSSL("B1");
  //   } else if (vulnerableSSLValue < 55) {
  //     setRiskScoreVulnerableSSL("B2");
  //   } else if (vulnerableSSLValue < 75) {
  //     setRiskScoreVulnerableSSL("C1");
  //   } else {
  //     setRiskScoreVulnerableSSL("C2");
  //   }
  // }

  // End of generate grade for Vulnerable SSL

  // Genereate grade for dangling domains

  // const dangling = summary?.danglingData?.filter(
  //   (row) =>
  //     row?.dnsData?.ip &&
  //     ((row?._doc?.techStack?.urls?.[`https://${row?._doc?.subDomainName}/`]
  //       ?.status || "NA") == "404" ||
  //       (row?._doc?.techStack?.urls?.[`https://${row?._doc?.subDomainName}/`]
  //         ?.status || "NA") == "NA")
  // );

  // let danglingBase = Math.sqrt(summary?.subdomains?.toFixed(2));

  // let danglingValue = parseFloat(
  //   ((dangdanglingBase) * 100)?.toFixed(2)
  // );

  // if (!isNaN(danglingValue) && riskScoreDangling == "") {
  //   if (danglingValue == 0) {
  //     setRiskScoreDangling("A1");
  //   } else if (danglingValue < 20) {
  //     setRiskScoreDangling("A2");
  //   } else if (danglingValue < 35) {
  //     setRiskScoreDangling("B1");
  //   } else if (danglingValue < 55) {
  //     setRiskScoreDangling("B2");
  //   } else if (danglingValue < 75) {
  //     setRiskScoreDangling("C1");
  //   } else {
  //     setRiskScoreDangling("C2");
  //   }
  // }

  const getArrow = (val) => {
    if (val > 0) return "fa fa-arrow-up fa-1x arrowRed";
    else return "fa fa-arrow-down fa-1x arrowGreen";
  };
  // End of generate grade for danglong domains

  return (
    <div className="container-fluid">
      <AccessPage></AccessPage>
      {(summaryLoading || loading) && (
        <Box sx={{ width: "100%" }}>
          <LinearProgress />
        </Box>
      )}
      <div className="block-header">
        <div className="row clearfix">
          <div className="col-md-6 col-sm-12">
            <h1 style={{ color: "white" }}>Summary</h1>
            <nav aria-label="breadcrumb">
              <ol className="breadcrumb">
                <li className="breadcrumb-item">
                  {vendorId ? <>
                  <Link to="/vendor"
                      onClick={(() => {
                        sessionStorage.clear();
                      })}
                    >Forest View</Link>
                  </> : <Link to="/">Home</Link>}
                </li>
                {
                  vendorName ?
                    <li
                      className="breadcrumb-item active"
                      aria-current="page"
                      style={{ color: "white" }}
                    >
                      {vendorName}
                    </li> : ''
                }
                <li
                  className="breadcrumb-item active"
                  aria-current="page"
                  style={{ color: "white" }}
                >
                  Data Exposure Details
                </li>
              </ol>
            </nav>
          </div>
          <div className="col-md-6 col-sm-12 text-right hidden-xs">
            <span
              className="btn btn-sm btn-primary mr-1"
              title=""
              onClick={() => generateReport()}
            >
              <i className="fa fa-file" style={{ color: "#060606",fontWeight:"bold" }}></i>
              <span
                style={{ color: "#060606", padding: "10px", fontWeight: "bold" }}
              >
                Download Report
              </span>
            </span>
          </div>
        </div>
      </div>

      <div className="row clearfix">
        <div className="col-lg-9">
          <div className="card">
            <div className="body" style={{ minHeight: "364px" }}>
              <div className="row">
                <div className="col-lg-12">
                  <h6>Data Exposure Health Tracker</h6>
                  <div className="card">
                    {securityGraphValue && (
                      <Splinechart
                        value={securityGraphValue}
                        date={securityGraphDate}
                        name={"Data Exposure Score"}
                        color="#b0f127"
                      />
                    )}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="col-md-3">
          <div className="card">
            <div className="body" style={{ minHeight: "364px" }}>
              <h6>Top Issues</h6>
              <div className="list-group">
                {
                  summary?.issueArray?.map((row) => {
                    return (
                      <a
                        href={row?.link}
                        className="list-group-item list-group-item-action"
                        style={{ color: "white" }}
                      >
                        {row?.msg}
                      </a>
                    )
                  })
                }
              
              </div>
            </div>
          </div>
        </div>
      </div>
      {id && (
        <>
          <div className="row clearfix" id="summaryBox2">
            <div className="col-lg-12 col-md-12 col-sm-12">
              <div className="row clearfix">
                <div className="col-sm-2">
                  <span className="card">
                    <Link 
                to={`/stolen-credentials${vendorId ? '/vendor/' + vendorId : ''}`}
                    >
                      <div
                        className="card w_card3"
                        style={{ cursor: "pointer" }}
                      >
                        <div className="body">
                          <div className="text-center">
                            <i
                              className="fa fa-key"
                              style={{ color: "#B0F127" }}
                            ></i>
                            <h5 className="m-t-20 mb-0">
                              {summary?.LeakCredential || 0}
                            </h5>
                            <p className="text-muted1">Stolen Credentials</p>
                          </div>
                        </div>
                        <div className="card-footer">
                          <div>
                            <span className="text-muted1">
                              {!(summaryLoading || loading) ? (
                                <span className="dashboardFirstHeading">
                                  {summary?.stolenCredNewAdded &&
                                  summary?.stolenCredNewAdded !== 0 ? (
                                    <i
                                      className={getArrow(
                                        summary?.stolenCredNewAdded
                                      )}
                                    ></i>
                                  ) : (
                                    ""
                                  )}
                                  {Math.abs(summary?.stolenCredNewAdded || 0)}{" "}
                                  v/s last scan
                                </span>
                              ) : (
                                ""
                              )}
                            </span>
                          </div>
                        </div>
                      </div>
                    </Link>
                  </span>
                </div>

                <div className="col-sm-2">
                  <span className="card">
                    <Link 
                to={`/leaked-sessions${vendorId ? '/vendor/' + vendorId : ''}`}
                    >
                      <div
                        className="card w_card3"
                        style={{ cursor: "pointer" }}
                      >
                        <div className="body">
                          <div className="text-center">
                            <i
                              className="fa fa-refresh"
                              style={{ color: "#B0F127" }}
                            ></i>
                            <h5 className="m-t-20 mb-0">
                              {summary?.LeakedSession || 0}
                            </h5>
                            <p className="text-muted1">Leaked Sessions</p>
                          </div>
                        </div>
                        <div className="card-footer">
                          <div>
                            <span className="text-muted1">
                              {!(summaryLoading || loading) ? (
                                <span className="dashboardFirstHeading">
                                  {summary?.leakedSessionNewAdded &&
                                  summary?.leakedSessionNewAdded !== 0 ? (
                                    <i
                                      className={getArrow(
                                        summary?.leakedSessionNewAdded
                                      )}
                                    ></i>
                                  ) : (
                                    ""
                                  )}
                                  {Math.abs(
                                    summary?.leakedSessionNewAdded || 0
                                  )}{" "}
                                  v/s last scan
                                </span>
                              ) : (
                                ""
                              )}
                            </span>
                          </div>
                        </div>
                      </div>
                    </Link>
                  </span>
                </div>

                <div className="col-sm-2">
                  <span className="card">
                    <Link 
                to={`/source-code${vendorId ? '/vendor/' + vendorId : ''}`}
                    >
                      <div
                        className="card w_card3"
                        style={{ cursor: "pointer" }}
                      >
                        <div className="body">
                          <div className="text-center">
                            <i
                              className="fa fa-code"
                              style={{ color: "#B0F127" }}
                            ></i>
                            <h5 className="m-t-20 mb-0">
                              {summary?.SourceCode || 0}
                            </h5>
                            <p className="text-muted1">Source Code Leakage</p>
                          </div>
                        </div>
                        <div className="card-footer">
                          <div>
                            <span className="text-muted1">
                              {!(summaryLoading || loading) ? (
                                <span className="dashboardFirstHeading">
                                  {/* <i className="fa fa-arrow-up fa-1x"
                          style={{ color: "#ff0000",fontSize:"20px" }}></i>      */}
                                  {0} v/s last scan
                                </span>
                              ) : (
                                ""
                              )}
                            </span>
                          </div>
                        </div>
                      </div>
                    </Link>
                  </span>
                </div>
                <div className="col-sm-2">
                  <span className="card">
                    <Link 
                to={`/pastebin-search${vendorId ? '/vendor/' + vendorId : ''}`}
                    >
                      <div
                        className="card w_card3"
                        style={{ cursor: "pointer" }}
                      >
                        <div className="body">
                          <div className="text-center">
                            <i
                              className="fa fa-clipboard"
                              style={{ color: "#B0F127" }}
                            ></i>
                            <h5 className="m-t-20 mb-0">
                              {summary?.PasteBin || 0}
                            </h5>
                            <p className="text-muted1">Pastebin Search</p>
                          </div>
                        </div>
                        <div className="card-footer">
                          <div>
                            <span className="text-muted1">
                              {!(summaryLoading || loading) ? (
                                <span className="dashboardFirstHeading">
                                  {summary?.pastebinNewAdded &&
                                  summary?.pastebinNewAdded !== 0 ? (
                                    <i
                                      className={getArrow(
                                        summary?.pastebinNewAdded
                                      )}
                                    ></i>
                                  ) : (
                                    ""
                                  )}
                                  {Math.abs(summary?.pastebinNewAdded || 0)} v/s
                                  last scan
                                </span>
                              ) : (
                                ""
                              )}
                            </span>
                          </div>
                        </div>
                      </div>
                    </Link>
                  </span>
                </div>
                <div className="col-sm-2">
                  <span className="card">
                    <Link
                to={`/postman-monitoring${vendorId ? '/vendor/' + vendorId : ''}`}
                    >
                      <div
                        className="card w_card3"
                        style={{ cursor: "pointer" }}
                      >
                        <div className="body">
                          <div className="text-center">
                            <i
                              className="fa fa-clipboard"
                              style={{ color: "#B0F127" }}
                            ></i>
                            <h5 className="m-t-20 mb-0">
                              {summary?.PostmanMonitoring || 0}
                            </h5>
                            <p className="text-muted1">Postman Monitoring</p>
                          </div>
                        </div>
                        <div className="card-footer">
                          <div>
                            <span className="text-muted1">
                              {!(summaryLoading || loading) ? (
                                <span className="dashboardFirstHeading">
                                  {/* {summary?.PostmanMonitoring &&
                                  summary?.PostmanMonitoring !== 0 ? (
                                    <i
                                      className={getArrow(
                                        0
                                      )}
                                    ></i>
                                  ) : (
                                    ""
                                  )} */}
                                  {Math.abs(0)} v/s
                                  last scan
                                </span>
                              ) : (
                                ""
                              )}
                            </span>
                          </div>
                        </div>
                      </div>
                    </Link>
                  </span>
                </div>
                <div className="col-sm-2">
                  <span className="card">
                    <Link 
                to={`/pii${vendorId ? '/vendor/' + vendorId : ''}`}
                    >
                      <div
                        className="card w_card3"
                        style={{ cursor: "pointer" }}
                      >
                        <div className="body">
                          <div className="text-center">
                            <i
                              className="fa fa-clipboard"
                              style={{ color: "#B0F127" }}
                            ></i>
                            <h5 className="m-t-20 mb-0">
                              {summary?.personalData || 0}
                            </h5>
                            <p className="text-muted1">Personal Information</p>
                          </div>
                        </div>
                        <div className="card-footer">
                          <div>
                            <span className="text-muted1">
                              {!(summaryLoading || loading) ? (
                                <span className="dashboardFirstHeading">
                                  {summary?.pastebinNewAdded &&
                                  summary?.pastebinNewAdded !== 0 ? (
                                    <i
                                      className={getArrow(
                                        summary?.pastebinNewAdded
                                      )}
                                    ></i>
                                  ) : (
                                    ""
                                  )}
                                  {Math.abs(summary?.pastebinNewAdded || 0)} v/s
                                  last scan
                                </span>
                              ) : (
                                ""
                              )}
                            </span>
                          </div>
                        </div>
                      </div>
                    </Link>
                  </span>
                </div>
                <div className="col-sm-2">
          <span className="card">
            <Link  to={`/malware-credentials${vendorId ? '/vendor/' + vendorId : ''}`} >
              <div className="card w_card3" style={{ cursor: "pointer" }}>
                <div className="body">
                  <div className="text-center">
                    <i
                      className="fa fa-laptop"
                      style={{ color: "#B0F127" }}
                    ></i>
                    <h5 className="m-t-20 mb-0">
                      {summary?.MalwareCredential || 0}
                    </h5>
                    <p className="text-muted1">Malware Infected Machines</p>
                  </div>
                </div>
                <div className="card-footer">
                  <div>
                    <span className="text-muted1">
                      {!(summaryLoading || loading) ? (
                        <span className="dashboardFirstHeading">
                          {summary?.malwareCredNewAdded &&
                          summary?.malwareCredNewAdded !== 0 ? (
                            <i
                              className={getArrow(summary?.malwareCredNewAdded)}
                            ></i>
                          ) : (
                            ""
                          )}
                          {Math.abs(summary?.malwareCredNewAdded || 0)} v/s last
                          scan
                        </span>
                      ) : (
                        ""
                      )}
                    </span>
                  </div>
                </div>
              </div>
            </Link>
          </span>
               </div>
               {id && (
          <div className="col-sm-2">
            <span className="card">
              <Link to={`/cloud-bucket${vendorId ? '/vendor/' + vendorId : ''}`}>
                <div className="card w_card3" style={{ cursor: "pointer" }}>
                  <div className="body">
                    <div className="text-center">
                      <i
                        className="fa fa-cloud"
                        style={{ color: "#B0F127" }}
                      ></i>
                      <h5 className="m-t-20 mb-0">
                        {summary?.CloudBucket || 0}
                      </h5>
                      <p className="text-muted1">Cloud Storage</p>
                    </div>
                  </div>
                  <div className="card-footer">
                    <div>
                      <span className="text-muted1">
                        {!(summaryLoading || loading) ? (
                          <span className="dashboardFirstHeading">
                            {/* <i className="fa fa-arrow-up fa-1x"
                          style={{ color: "#ff0000",fontSize:"20px" }}></i> */}
                            {0} v/s last scan
                          </span>
                        ) : (
                          ""
                        )}
                      </span>
                    </div>
                  </div>
                </div>
              </Link>
            </span>
          </div>
        )}
             {id && (
             <div className="col-sm-2">
            <span className="card">
              <Link to={`/dark-web${vendorId ? '/vendor/' + vendorId : ''}`}>
                <div className="card w_card3" style={{ cursor: "pointer" }}>
                  <div className="body">
                    <div className="text-center">
                      <i
                        className="fa fa-cloud"
                        style={{ color: "#B0F127" }}
                      ></i>
                      <h5 className="m-t-20 mb-0">
                        {summary?.DarkWeb || 0}
                      </h5>
                      <p className="text-muted1">Dark Web Monitoring</p>
                    </div>
                  </div>
                  <div className="card-footer">
                    <div>
                      <span className="text-muted1">
                        {!(summaryLoading || loading) ? (
                          <span className="dashboardFirstHeading">
                            {/* <i className="fa fa-arrow-up fa-1x"
                          style={{ color: "#ff0000",fontSize:"20px" }}></i> */}
                            {0} v/s last scan
                          </span>
                        ) : (
                          ""
                        )}
                      </span>
                    </div>
                  </div>
                </div>
              </Link>
            </span>
          </div>
        )}
           {id && (
             <div className="col-sm-2">
            <span className="card">
              <Link to={`/telegram-monitoring${vendorId ? '/vendor/' + vendorId : ''}`}>
                <div className="card w_card3" style={{ cursor: "pointer" }}>
                  <div className="body">
                    <div className="text-center">
                      <i
                        className="fa fa-cloud"
                        style={{ color: "#B0F127" }}
                      ></i>
                      <h5 className="m-t-20 mb-0">
                        {summary?.Telegram || 0}
                      </h5>
                      <p className="text-muted1">Telegram Monitoring</p>
                    </div>
                  </div>
                  <div className="card-footer">
                    <div>
                      <span className="text-muted1">
                        {!(summaryLoading || loading) ? (
                          <span className="dashboardFirstHeading">
                            {/* <i className="fa fa-arrow-up fa-1x"
                          style={{ color: "#ff0000",fontSize:"20px" }}></i> */}
                            {0} v/s last scan
                          </span>
                        ) : (
                          ""
                        )}
                      </span>
                    </div>
                  </div>
                </div>
              </Link>
            </span>
          </div>
        )}
              </div>
            </div>
          </div>
        </>
      )}
    </div>
  );
};
export default Dashboard;
