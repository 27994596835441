import React, { Component } from 'react';
import ReactApexChart from "react-apexcharts";

class ForestChart extends Component {
    constructor(props) {
        super(props);
        const largest = parseFloat(props?.data?.ratings);
        this.state = {
            series: [{
                name: "Forest Rating",
                data: props?.data?.ratings.map((r)=>
                    r.toFixed(2)
                )
            }],
            options: {
                chart: {
                    height: 285,
                    type: 'area',
                    toolbar: {
                        show: false
                    }
                },
                dataLabels: {
                    enabled: false
                },
                colors: ['#b0f127'], // Color for the series
                markers: {
                    size: 3,
                    strokeColor: '#b0f127', // Marker stroke color
                },
                stroke: {
                    width: 2,
                    curve: 'smooth',
                },
                xaxis: {
                    type: 'category',
                    categories:   props?.data?.dates?.map(date =>
                         new Date(date).toLocaleDateString()),
                    labels: {
                        style: {
                            colors: '#9aa0ac', // X-axis labels color
                        }
                    }
                },
                yaxis: {
                    tickAmount: 8,
                    min: 0,
                    max: largest + 50 // Adjust the maximum value
                },
                legend: {
                    show: false
                }
            },
        };
    }
    render() {
        return (
            <div>
                <ReactApexChart
                    options={this.state.options}
                    series={this.state.series}
                    type="area"
                    height="230px"
                />
            </div>
        );
    }
}

export default ForestChart;
