import React, { useEffect, useState, useRef } from "react";
import { useSelector, useDispatch } from "react-redux";
import { Link, useParams } from "react-router-dom";
import {
  getResultByAccoutId,
  getScanByAccoutId,
} from "../../actions/cloudAccount";
import moment from "moment";
import Pagination from "../Pagination/Pagination";
import ReactTooltip from "react-tooltip";
import * as FileSaver from "file-saver";
import * as XLSX from "xlsx";
import { Col, Nav, Row, Tab, Tabs } from "react-bootstrap";

const CloudAccountDetails = () => {
  const { accountId } = useParams();
  const dispatch = useDispatch();
  const [page, setPage] = useState(1);
  const [PageSize, setPerPage] = useState(10);

  useEffect(() => {
    getScanByAccoutId(accountId, dispatch);
    getResultByAccoutId(accountId, dispatch);
  }, [accountId]);

  const account = useSelector((state) => state?.cloudAccount?.accounts);

  const logs = useSelector((state) => state?.cloudAccount?.results);

  let currentTableData = [];
  if (account && account?.scans?.length > 0) {
    // setFirstLoad(false)
    const firstPageIndex = (page - 1) * PageSize;
    const lastPageIndex = firstPageIndex + PageSize;
    currentTableData = account?.scans?.slice(firstPageIndex, lastPageIndex);
    setTimeout(function () {
      ReactTooltip.rebuild();
    }, 1000);
  }

  let cloudLogs = [];
  if (logs && logs.length > 0) {
    // setFirstLoad(false)
    const firstPageIndex = (page - 1) * PageSize;
    const lastPageIndex = firstPageIndex + PageSize;
    cloudLogs = logs?.slice(firstPageIndex, lastPageIndex);
    setTimeout(function () {
      ReactTooltip.rebuild();
    }, 1000);
  }

  const downloadCsvData = account?.scans?.map((res) => ({
    Name: account?.clouDetail?.name,
    LastScanned: moment(res?.createdAt).format("YYYY-MM-DD"),
    Fail: res?.scanResult?.filter((row) => row?.status == "FAIL")?.length,
    Warning: res?.scanResult?.filter((row) => row?.status == "WARNING")?.length,
    Pass: res?.scanResult?.filter((row) => row?.status == "OK")?.length,
    Unknown: res?.scanResult?.filter((row) => row?.status == "UNKNOWN")?.length,
  }));

  const exportToCSV = (csvData, fileName) => {
    const ws = XLSX.utils.json_to_sheet(csvData);
    const wb = { Sheets: { data: ws }, SheetNames: ["data"] };
    const excelBuffer = XLSX.write(wb, { bookType: "xlsx", type: "array" });
    const data = new Blob([excelBuffer], {
      type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8",
    });
    FileSaver.saveAs(data, fileName + ".xlsx");
  };

  return (
    <>
      <div className="container-fluid">
        <ReactTooltip multiline={true} />
        <div className="block-header">
          <div className="row clearfix">
            <div className="col-md-6 col-sm-12">
              <h2>Account Details</h2>
              <nav aria-label="breadcrumb">
                <ol className="breadcrumb">
                  <li className="breadcrumb-item">
                    <Link to="/">Home</Link>
                  </li>
                  <li className="breadcrumb-item active" aria-current="page">
                    Details
                  </li>
                </ol>
              </nav>
            </div>
            <div className="col-md-6 col-sm-12 text-right hidden-xs">
              <Link
                onClick={(e) => {
                  e.preventDefault();
                  exportToCSV(downloadCsvData, "CloudAccountDetails");
                }}
                to="/"
                className="btn btn-sm btn-primary mr-1"
                title=""
              >
                Export
              </Link>
            </div>
          </div>
        </div>
        <div className="row clearfix">
          <div className="col-lg-12 col-md-12">
            <div className="card">
              {/* <div className="header">
              <h2>Cloud Account Name</h2>
            </div> */}
              <div className="body">
                <Tabs defaultActiveKey="home" id="uncontrolled-tab-example">
                  <Tab eventKey="home" title="Scans" tabClassName="custom_tab">
                    {/* <h6>Home</h6> */}
                    <div className="row clearfix">
                      <div className="col-12">
                        <div className="card">
                          <div className="body">
                            <div className="row">
                              <div className="col-lg-2 col-md-4 col-sm-6">
                                <div className="input-group">
                                  <input
                                    type="text"
                                    className="form-control"
                                    placeholder="Search"
                                  />
                                </div>
                              </div>

                              <div className="col-lg-2 col-md-4 col-sm-6"></div>

                              <div className="col-lg-2 col-md-4 col-sm-6"></div>
                              <div className="col-lg-2 col-md-4 col-sm-6"></div>
                              <div className="col-lg-2 col-md-4 col-sm-6"></div>
                              <div className="col-lg-2 col-md-4 col-sm-6"></div>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="col-12">
                        <div className="table-responsive">
                          <table className="table table-custom spacing5 mb-0">
                            <thead>
                              <tr>
                                <th>#</th>
                                <th>Name</th>
                                <th>Last Scanned</th>
                                <th>Results</th>
                              </tr>
                            </thead>
                            <tbody>
                              {currentTableData?.map((row, index) => {
                                let fail = row?.scanResult?.filter(
                                  (res) => res?.status == "FAIL"
                                );
                                let warning = row?.scanResult?.filter(
                                  (res) => res?.status == "WARNING"
                                );
                                let unknown = row?.scanResult?.filter(
                                  (res) => res?.status == "UNKNOWN"
                                );
                                let pass = row?.scanResult?.filter(
                                  (res) => res?.status == "OK"
                                );
                                return (
                                  <tr>
                                    <td>{(page - 1) * 10 + 1 + index}</td>
                                    <td>
                                      <Link
                                        to={`/scan-results/${row?._id}`}
                                        style={{ cursor: "pointer" }}
                                      >
                                        <span>{account?.clouDetail?.name}</span>
                                      </Link>
                                    </td>
                                    <td>
                                      <Link
                                        to={`/scan-results/${row?._id}`}
                                        style={{ cursor: "pointer" }}
                                      >
                                        <span>
                                          {moment(row?.createdAt)
                                            .startOf("hour")
                                            .fromNow()}
                                        </span>
                                      </Link>
                                    </td>

                                    <td>
                                      <Link
                                        to={`/scan-results/${row?._id}`}
                                        style={{ cursor: "pointer" }}
                                      >
                                        <span>
                                          <span
                                            id={`clouddAccountFail${index}`}
                                            data-tip={"Fail"}
                                            style={{
                                              padding: "0px 12px",
                                              backgroundColor: "#FF435E",
                                              color: "#ffffff",
                                              borderTopLeftRadius: "4px",
                                              borderBottomLeftRadius: "4px",
                                            }}
                                          >
                                            {fail?.length || 0}
                                          </span>
                                          <span
                                            id={`clouddAccountWarning${index}`}
                                            data-tip={"Warning"}
                                            style={{
                                              padding: "0px 12px",
                                              backgroundColor: "#FF8E50",
                                              color: "#ffffff",
                                            }}
                                          >
                                            {warning?.length || 0}
                                          </span>
                                          <span
                                            id={`clouddAccountUnknown${index}`}
                                            data-tip={"Unknown"}
                                            style={{
                                              padding: "0px 12px",
                                              backgroundColor: "#DADADB",
                                              color: "#000000",
                                            }}
                                          >
                                            {unknown?.length || 0}
                                          </span>
                                          <span
                                            id={`clouddAccountPass${index}`}
                                            data-tip={"Pass"}
                                            style={{
                                              padding: "0px 12px",
                                              backgroundColor: "#20D047",
                                              color: "#ffffff",
                                              borderTopRightRadius: "4px",
                                              borderBottomRightRadius: "4px",
                                            }}
                                          >
                                            {pass?.length || 0}
                                          </span>
                                        </span>
                                      </Link>
                                    </td>
                                  </tr>
                                );
                              })}
                            </tbody>
                          </table>
                        </div>
                        <div className="dataTables_paginate">
                          {account && account.length > 0 && (
                            <Pagination
                              className="pagination-bar"
                              currentPage={page}
                              totalCount={account?.length}
                              pageSize={PageSize}
                              onPageChange={(page) => setPage(page)}
                            />
                          )}
                        </div>
                      </div>
                    </div>
                  </Tab>
                  <Tab
                    eventKey="profile"
                    title="Events"
                    tabClassName="custom_tab"
                  >
                    <div className="row clearfix">
                      <div className="col-12">
                        <div className="card">
                          <div className="body">
                            <div className="row">
                              <div className="col-lg-2 col-md-4 col-sm-6">
                                <div className="input-group">
                                  <input
                                    type="text"
                                    className="form-control"
                                    placeholder="Search"
                                  />
                                </div>
                              </div>

                              <div className="col-lg-2 col-md-4 col-sm-6"></div>

                              <div className="col-lg-2 col-md-4 col-sm-6"></div>
                              <div className="col-lg-2 col-md-4 col-sm-6"></div>
                              <div className="col-lg-2 col-md-4 col-sm-6"></div>
                              <div className="col-lg-2 col-md-4 col-sm-6"></div>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="col-12">
                        <div className="table-responsive">
                          <table className="table table-custom spacing5 mb-0">
                            <thead>
                              <tr>
                                <th>#</th>
                                <th>Ticket Status</th>
                                <th>Action</th>
                                <th>Resource</th>
                                <th>Timestamp</th>
                              </tr>
                            </thead>
                            <tbody>
                              {cloudLogs.map((entity, index) => {
                                return (
                                  <tr>
                                    <td>{(page - 1) * 10 + 1 + index}</td>
                                    <td>
                                      <span
                                        className={
                                          entity.isTicketClosed
                                            ? "badge badge-success"
                                            : typeof entity.isTicketClosed ==
                                              "undefined"
                                            ? "badge badge-danger"
                                            : "badge badge-warning"
                                        }
                                      >
                                        {entity.isTicketClosed
                                          ? "Validated"
                                          : typeof entity.isTicketClosed ==
                                            "undefined"
                                          ? "Response Pending"
                                          : "Invalidated"}
                                      </span>
                                    </td>

                                    <td>
                                      {entity.data.protoPayload.methodName}
                                    </td>
                                    <td>
                                      {entity.data.protoPayload.resourceName}
                                    </td>
                                    <td>
                                      <span className="badge badge-warning">
                                        <td>
                                          {moment(
                                            entity.data.receiveTimestamp
                                          ).format("DD-MM-YYYY hh:mm")}
                                        </td>
                                      </span>
                                    </td>
                                  </tr>
                                );
                              })}
                            </tbody>
                          </table>
                        </div>
                        <div className="dataTables_paginate">
                          {logs && logs.length > 0 && (
                            <Pagination
                              className="pagination-bar"
                              currentPage={page}
                              totalCount={logs?.length}
                              pageSize={PageSize}
                              onPageChange={(page) => setPage(page)}
                            />
                          )}
                        </div>
                      </div>
                    </div>
                  </Tab>
                </Tabs>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
export default CloudAccountDetails;
