import React, { Component } from "react";
import { Link, useParams } from "react-router-dom";
import { forgotPassword } from "../../actions/auth";
import { toastr } from "react-redux-toastr";
import $ from "jquery";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import brandsek from "../../assets/images/brandsek-logo.svg";
class ForgotPassword extends Component {
  resetPassword = (e) => {
    e.preventDefault();
    let email = $("#signup-password").val();
    if (email == "") {
      toastr.error("Error", "Please enter email");
      return false;
    }

    this.props.forgotPassword({
      email,
    });
  };

  render() {
    let brandsek = '../../assets/images/image-gallery/Invinsense_logo_white.png';
    let browser = window.location.href.split("/")
    if (browser[2].split(".")[0] && browser[2].split(".")[0] == "brandshield") {
      brandsek = '../../assets/images/image-gallery/Final_Logo.png'
    } else if (browser[2].split(".")[0] && browser[2].split(".")[0] == "invinsense") {
      brandsek = '../../assets/images/image-gallery/Invinsense_logo_white.png?v=1'
    }
    return (
      <>
        <div class="pattern">
          <span class="red"></span>
          <span class="indigo"></span>
          <span class="blue"></span>
          <span class="green"></span>
          <span class="orange"></span>
        </div>
        <div class="page-wrapper page-wrapper_login">
          <div class="section bg-default wf-section">
            <div class="container-default position-relative w-container">
              <div class="inner-container _625px center">
                <div
                  data-w-id="af797e45-9721-3e02-9bca-78d7b063fc49"
                  class="card _24px sign-in-card"
                >
                  <div class="sign-form w-form">
                    <div
                      data-w-id="742728b9-6335-1041-77e8-4ea5e5785154"
                      data-animation="default"
                      data-collapse="medium"
                      data-duration="400"
                      data-easing="ease"
                      data-easing2="ease"
                      role="banner"
                      class="header-wrapper w-nav"
                      style={{ paddingTop: "0px" }}
                    >
                      <div className="container-default w-container">
                        <div className="header-content-wrapper">
                          <div
                            data-w-id="742728b9-6335-1041-77e8-4ea5e5785157"
                            className="header-middle"
                          >
                            <a className="header-logo-link center w-nav-brand text-center">
                              <img
                                src={brandsek}
                                style={{ maxWidth: "200px" }}
                                loading="eager"
                                className="header-logo"
                              />
                            </a>
                          </div>
                        </div>
                      </div>
                    </div>
                    <form id="wf-form-Sign-In-Form" className="form-auth-small m-t-20">
                      <div class="sign-card-content">
                        <h1 class="display-2 text-center mg-bottom-24px keep" style={{ marginBottom: "35px" }}>
                          Forgot Password.
                        </h1>
                        <div class="mg-bottom-24px keep">
                          <div class="w-layout-grid grid-1-column gap-row-0">
                            <div>
                              <input
                                type="email"
                                className="input input-form top w-input"
                                id="signup-password"
                                name="Email"
                                data-name="Email"
                                placeholder="Email  address"
                              ></input>
                            </div>
                          </div>
                        </div>
                        <div class="mg-bottom-24px keep">
                          {/* <div class="text-200 medium color-neutral-100">
                            <a href="../contact.html">Forgot password?</a>
                          </div> */}
                        </div>
                        <input
                          type="submit"
                          onClick={this.resetPassword}
                          value="Reset"
                          data-wait="Please wait..."
                          id="w-node-f1bc498d-290c-3270-f633-c82f5b0ae8f3-42a43387"
                          class="btn-primary width-100 w-button"
                          style={{ color: "black" }}
                        ></input>
                      </div>
                      <div class="divider _48px"></div>
                      <div class="sign-card-content">
                        <div class="text-center">
                          <div class="text-200 medium color-neutral-100">
                            Know your password?
                            <Link to="/login">Login</Link>
                          </div>
                        </div>
                      </div>
                    </form>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </>
    );
  }
}

function mapStateToProps({ auth }) {
  return {
    auth,
  };
}
function mapDispatchToProps(dispatch) {
  return bindActionCreators(
    {
      forgotPassword,
    },
    dispatch
  );
}

export default connect(mapStateToProps, mapDispatchToProps)(ForgotPassword);
