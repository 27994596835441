import React, { useState, useEffect } from 'react';
import ReactDOM from 'react-dom';
import { Provider } from 'react-redux';
import configureStore from './store';
import './index.css';
import 'react-redux-toastr/lib/css/react-redux-toastr.min.css'
import App from './App';
import * as serviceWorker from './serviceWorker';
import Loader from './components/Shared/loader';
import axios from 'axios';
import { toastr } from "react-redux-toastr";



const store = configureStore();

const AppWithInterceptors = () => {
  const [loading, setLoading] = useState(false);

  const showSpinner = () => {
    setLoading(true);
  };

  const hideSpinner = () => {
    setLoading(false);
  };

  useEffect(() => {
    const requestInterceptor = axios.interceptors.request.use((config) => {
      showSpinner();
      return config;
    });

    const responseInterceptor = axios.interceptors.response.use(
      (response) => {
        hideSpinner();
        if (response?.status == 401 || response?.data?.status == '401') {
          toastr.success("Error", response.data.userMessage);
          window.location.reload()
          localStorage.clear()
          sessionStorage.clear()
          window.location.replace("/login");
        }
          hideSpinner();
        return response;
      },
      (error) => {
        hideSpinner();
        return Promise.reject(error);
      }
    );

    return () => {
      axios.interceptors.request.eject(requestInterceptor);
      axios.interceptors.response.eject(responseInterceptor);
    };
  }, []); 

  return (
    <Provider store={store}>
      <React.StrictMode>
        {loading && <Loader />}
        <App />
      </React.StrictMode>
    </Provider>
  );
};

ReactDOM.render(<AppWithInterceptors />, document.getElementById('root'));

serviceWorker.unregister();
