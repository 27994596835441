import React, { useEffect, useState, useRef } from "react";
import { useSelector, useDispatch } from "react-redux";
import * as FileSaver from "file-saver";
import * as XLSX from "xlsx";
import { Link  , useParams} from "react-router-dom";
import { webDefacement } from "../../actions/fakeMobileApp";
import moment from "moment";
import Pagination from "../Pagination/Pagination";
import NoData from "../common/noDataComponetnt";
import ScanMessage from "../common/scanningMessage";
import AccessPage from "../Shared/accessPage";


const SocialMediaProfile = () => {
  // const id = JSON.parse(localStorage.getItem("user")).organisationId;
  const { orgId } = useParams();
  const id = useSelector(state => {
    if (orgId) {
        return orgId;
    } else {
        return state?.summary?.localClient?._id || state?.summary?.clients?.[0]?._id;
    }
});  const client = useSelector(
    (state) => state?.summary?.localClient || state?.summary?.clients?.[0]
  );
  const myRef = useRef(null);
  const dispatch = useDispatch();
  let verifyStatus;
  const [filterData, setfilterData] = useState("");
  const [statusFilterVal, setStatusFilter] = useState([]);
  const [page, setPage] = useState(1);
  const [PageSize, setPerPage] = useState(10);
  const state = useSelector(
    (state) => state?.fakeMobileApp?.webDefacement
  );
  const scanCompleted = useSelector(
    (state) => state?.fakeMobileApp?.scanCompleted
  );

  //console.log("web defacement", state);
  useEffect(() => {
    webDefacement(id, dispatch);
  }, [id]);

  let mapData = state;

  const downloadCsvData = state?.map((res,index) => {
    let source = res?.source || res?.socialMediaPlatform || "NA";

    return {
      Number: index + 1,
      Link: res?.url,
      ModifiedOn: res?.last_modified_time ? moment(new Date(parseInt(res?.last_modified_time))).format("YYYY-MM-DD") : "NA",
      "PageSize(KB)": res?.page_size || "NA",
      "ScriptModified%": res?.script_modified_percentage || "NA",
      "AnchorModified%": res?.anchor_modified_percentage || "NA",
      "TextModified%": res?.text_modified_percentage || "NA",
      "IframeModified%": res?.iframe_modified_percentage || "NA",
      "ImageModified%": res?.image_modified_percentage || "NA",
      "Link Modified%":res?.link_modified_percentage || "NA"
    };
  });

  const exportToCSV = (csvData, fileName) => {
    const ws = XLSX.utils.json_to_sheet(csvData);
    const wb = { Sheets: { data: ws }, SheetNames: ["data"] };
    const excelBuffer = XLSX.write(wb, { bookType: "xlsx", type: "array" });
    const data = new Blob([excelBuffer], {
      type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8",
    });
    FileSaver.saveAs(data, fileName + ".xlsx");
  };
  let currentTableData = [];

  if (mapData && mapData.length > 0) {
    // setFirstLoad(false)
    const firstPageIndex = (page - 1) * PageSize;
    const lastPageIndex = firstPageIndex + PageSize;
    currentTableData = mapData?.slice(firstPageIndex, lastPageIndex);
  }
  return (
    <>
      <div className="container-fluid">
        <AccessPage></AccessPage>
        <div className="block-header">
          <div className="row clearfix">
            <div className="col-md-6 col-sm-12">
              <h1>Web Defacement Monitor Report</h1>
              <nav aria-label="breadcrumb">
                <ol className="breadcrumb">
                  <li className="breadcrumb-item">
                    <Link to="/">Home</Link>
                  </li>
                  <li className="breadcrumb-item active" aria-current="page">
                    Details
                  </li>
                </ol>
              </nav>
            </div>
            <div className="col-md-6 col-sm-12">
              {client?.subscriptionType != "trial" && downloadCsvData?.length !== 0 && (
                <a
                  onClick={(e) => {
                    e.preventDefault();
                    exportToCSV(downloadCsvData, "WebDefacement");
                  }}
                  href="/add-report"
                  className="btn btn-primary mr-1 pull-right"
                >
                  <i className="fa fa-file-excel-o"></i>
                </a>
              )}
            </div>
          </div>
        </div>

        {typeof scanCompleted !== "undefined" && !scanCompleted && (
          <div className="card border-success">
            <ScanMessage />
          </div>
        )}

        <div className="row clearfix">
          <div className="col-lg-12 col-md-12">
            <div className="table-responsive" ref={myRef}>
              <table className="table table-custom spacing5 mb-0">
                <thead>
                  <tr>
                    <th>#No</th>
                    <th>Link</th>
                    <th>Modified on</th>
                    <th>Page Size(KB)</th>
                    <th>Script Modified %</th>
                    <th>Anchor Modified %</th>
                    <th>Text Modified %</th>
                    <th>Iframe Modified %</th>
                    <th>Image Modified %</th>
                    <th>Link Modified %</th>
                  </tr>
                </thead>
                <tbody>
                {client?.subscriptionType == "trial"
                    ?
                    <tr>
                      <td colSpan={6}><NoData /></td>
                    </tr>
                    : currentTableData?.map((row,index) => {
                      //console.log(row?.last_modified_time,new Date(parseInt(row?.last_modified_time)),'row?.last_modified_time')
                      return(
                        <tr>
                          <td>{(page - 1) * 10 + 1 + index}</td>
                          <td>
                            <a href={row?.url} target="_blank">{row?.url}</a>
                          </td>
                          <td>{row?.last_modified_time ? moment(new Date(parseInt(row?.last_modified_time))).format("DD-MM-YYYY hh:mm a") : "-"}</td>
                          <td>{row?.page_size || "-"}</td>
                          <td>{row?.script_modified_percentage || "NA"} {row?.script_modified_percentage > 0 ? <i style={{color:"green"}} class="fa fa-arrow-up"></i> : "-"}</td>
                          <td>{row?.anchor_modified_percentage || "NA"}</td>
                          <td>{row?.text_modified_percentage || "NA"}</td>
                          <td>{row?.iframe_modified_percentage || "NA"}</td>
                          <td>{row?.image_modified_percentage || "NA"}</td>
                          <td>{row?.link_modified_percentage || "NA"}</td>
                        </tr>
                      )
                    })
                }
                </tbody>
              </table>
            </div>
            <div className="dataTables_paginate">
              {mapData && mapData?.length > 0 && (
                <Pagination
                  className="pagination-bar"
                  currentPage={page}
                  totalCount={mapData?.length}
                  pageSize={PageSize}
                  onPageChange={(page) => setPage(page)}
                />
              )}
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default SocialMediaProfile;
