import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { Link, useParams } from "react-router-dom";
import { telegram, telegramAction, telegramTotal } from "../../actions/leakedCredentials";
import BasicLineColumn from "../common/apexChart/lineChart";
import Pagination from "../Pagination/Pagination";
import * as FileSaver from "file-saver";
import * as XLSX from "xlsx";
import $, { data } from "jquery";
import MenuItem from "@mui/material/MenuItem";
import Box from "@mui/material/Box";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import axios from "axios";
import { API_URL } from "../../utils/constant";

const TelegramMonitoring = () => {
  const { orgId } = useParams();
  const id = useSelector(state => {
    if (orgId) {
      return orgId;
    } else {
      return state?.summary?.localClient?._id || state?.summary?.clients?.[0]?._id;
    }
  });
  const client = useSelector(
    (state) => state?.summary?.localClient || state?.summary?.clients?.[0]
  );

  const dispatch = useDispatch();
  const [toggleArray, setToggleArray] = useState({});
  const [loading, setLoading] = useState(true);
  const [response, setResponse] = useState([]);
  const [search, setSearch] = useState("");
  const [filterData, setfilterData] = useState("all");
  const [page, setPage] = useState(1);
  const [PageSize, setPerPage] = useState(10);
  const [isModalTrue, setModalSet] = useState(false);
  const [detail, setdetail] = useState("");
  const [totalItems, setTotalItems] = useState(0);
  const [category, setCategory] = useState("all");
  const [isModal, setModal] = useState("");
  const [forValue, setForValue] = useState("forever");
  const [lookalikeAppId, setLookalikeAppId] = useState([]);
  const [actionNote, setActionNote] = useState("");
  const [action, setAction] = useState("");
  const [selectedValue, setSelectedValue] = useState("pleaseSelect");
  const vendorName = sessionStorage.getItem('vendorName');
  const vendorId = sessionStorage.getItem('vendorId');

  const state = useSelector(
    (state) => state?.leakedCredential?.telegramData?.data?.data
  );

  const auth = useSelector((state) => state?.auth?.user);

  const totalCount = useSelector(
    (state) => state?.leakedCredential?.telegramData?.data?.totalRecords
  );

  const recordFound = useSelector(
    (state) => state?.leakedCredential?.leakedres?.data?.totalTelegramForumRecords
  );

  console.log(recordFound, 'recordFound')
  let oldDnsDetails = useSelector(
    (state) => state.leakedCredential?.telegramData?.scanDetail
  );

  let ipArray = [];
  let dateArray = [];
  if (oldDnsDetails?.scanDetails) {
    dateArray.push(oldDnsDetails?.scanDetail[0]?.createdAt);
    ipArray.push(oldDnsDetails?.scanDetail?.length || 0);
  }

  if (state) {
    ipArray.push(state?.length);
  }

  useEffect(() => {
    telegram(id, dispatch, page, category);
  }, [id, dispatch, page, category]);

  useEffect(() => {
    setTotalItems(totalCount)
  }, [totalCount])

  useEffect(() => {
    telegramTotal(id, dispatch)
  }, [id, dispatch])


  const addModal = () => {
    setModal("Form Wizard modal");
  };

  const [selectedIds, setSelectedIds] = useState([]);
  const [multiCheckTrue, setMultiCheckTrue] = useState(false);

  const multicheck = (data) => {
    const allIds = data?.map((item) => item._id);
    if (selectedIds.length > 0 || selectedIds.length == 0) {
      setMultiCheckTrue(true);
      setSelectedIds(allIds);
    }
    if (multiCheckTrue == true) {
      setSelectedIds([]);
      setMultiCheckTrue(false);
    }
  };

  const singleClick = (id, data) => {
    setSelectedIds((prevSelectedIds) => {
      if (prevSelectedIds.includes(id)) {
        return prevSelectedIds.filter((selectedId) => selectedId !== id);
      } else {
        return [...prevSelectedIds, id];
      }
    });
    if (selectedIds.length > 10) {
      setMultiCheckTrue(true);
    } else {
      setMultiCheckTrue(false);
    }
  };

  const snooze = (_id, event) => {
    if (event == "") {
    }

    if (event == "riskAccepted") {
    }

    if (event == "markAsFalsePositive") {
    }

    if (event == "markAsRemidiated") {
      let ids = [];
      ids.push(_id);
      const data = {
        action: "markAsRemidiated",
        recordIds: selectedIds == 0 ? ids : selectedIds,
        actionNote: "",
        for: "forever",
      };
      telegramAction(id, dispatch, page, category, data);
      setSelectedValue("pleaseSelect");
      setActionNote("");
      setSelectedIds([]);
    }
  };

  const Unsnoozed = (_id) => {
    let ids = [];
    ids.push(_id);
    const data = {
      action: 'resetAction',
      recordIds: selectedIds == 0 ? ids : selectedIds,
    };
    telegramAction(id, dispatch, page, category, data);
    setSelectedIds([]);
  }

  const submit = () => {
    const data = {
      action: action,
      recordIds: selectedIds == 0 ? lookalikeAppId : selectedIds,
      actionNote: actionNote,
      for: forValue,
    };
    telegramAction(id, dispatch, page, category, data);
    setModal("");
    setSelectedValue("pleaseSelect");
    setActionNote("");
    setSelectedIds([]);
  };


  const [hasPushedData, setHasPushedData] = useState(false);

  if (state && loading && !hasPushedData) {
    let temp = state;
    console.log(temp, "temp");
    setResponse([...response, ...temp]); // Using the spread operator to push elements of temp array
    setHasPushedData(true);
  }

  let mapData = response;

  let currentTableData = [];
  if (mapData && mapData.length > 0) {
    currentTableData = mapData;
  }

  const downloadFile = async () => {
    try {
      const session = localStorage.getItem("session");
      axios.defaults.headers.common["Authorization"] = `Bearer ${session}`;
      const response = await axios.get(
        `${API_URL}/telegram-monitoring/${id}/excel`,

        {
          responseType: "blob",
        }
      );
      const url = window.URL.createObjectURL(new Blob([response.data]));
      const link = document.createElement("a");
      link.href = url;
      link.setAttribute("download", "Telegram Monitoring.xlsx");
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
      window.URL.revokeObjectURL(url);
    } catch (error) {
      console.error(error);
    }
  };

  return (
    <div className="container-fluid">
      <div className="block-header">
        <div className="row clearfix">
          <div className="col-md-6 col-sm-12">
            <h1 style={{ color: "white" }}>Telegram monitoring</h1>
            <nav aria-label="breadcrumb">
              <ol className="breadcrumb">
                <li className="breadcrumb-item">
                  {vendorId ? <>
                    <Link to="/vendor"
                      onClick={(() => {
                        sessionStorage.clear();
                      })}
                    >Forest View</Link>
                  </> : <Link to="/">Home</Link>}
                </li>
                {
                  vendorName ?
                    <li
                      className="breadcrumb-item active"
                      aria-current="page"
                      style={{ color: "white" }}
                    >
                      {vendorName}
                    </li> : ''
                }
                <li
                  className="breadcrumb-item active"
                  aria-current="page"
                  style={{ color: "white" }}
                >
                  Telegram Monitoring
                </li>
              </ol>
              <br />
              <span>
                <b>Rating : {client?.currentRating?.dark_web_personalInfo}</b>
              </span>
            </nav>
          </div>
          <div className="col-md-6 col-sm-12 text-right hidden-xs">
            <span>
              {mapData?.length === 0 ? null : (
                <a
                  onClick={(e) => {
                    e.preventDefault();
                    downloadFile()
                  }}
                  href="/add-report"
                  style={{ color: "black", fontWeight: "bold" }}
                  className="btn btn-primary mr-1"
                >
                  <i
                    className="fa fa-file-excel-o"
                    style={{ color: "black" }}
                  ></i>{" "}
                  Download Details
                </a>
              )}
            </span>
          </div>
        </div>
      </div>

      <div className="row clearfix">
        <div className="col-lg-4 col-md-12">
          <div className="card">
            <div className="body" style={{ minHeight: "440px" }}>
              {/* <div className="header">
                  <h2>Details</h2>
                </div> */}
              <div className="row text-center">
                <div className="col-12 pb-4 pt-4">
                  <label className="mb-0">Records Found</label>
                  <h4 className="font-30 font-weight-bold text-col-blue">
                    {recordFound || 0}
                  </h4>
                </div>
              </div>

              {/* <div className="form-group">
                <label className="d-block" style={{ cursor: 'pointer' }} onClick={() => setfilterData("plain")}>
                  Plain Text Password{" "}
                  <span className="float-right">
                    {plainPasswordPercent?.toFixed(0)}%
                  </span>
                </label>
                <div className="progress progress-xxs">
                  <div
                    className="progress-bar progress-bar-success"
                    role="progressbar"
                    aria-valuenow={plainPasswordPercent?.toFixed(0)}
                    aria-valuemin="0"
                    aria-valuemax="100"
                    style={{ width: plainPasswordPercent?.toFixed(0) + "%" }}
                  ></div>
                </div>
              </div> */}

              {/* <div className="form-group">
                <label className="d-block" style={{ cursor: 'pointer' }} onClick={() => setfilterData("multiple")}>
                  Users Breached Multiple Times{" "}
                  <span className="float-right">
                    {breachedPercent?.toFixed(0)}%
                  </span>
                </label>
                <div className="progress progress-xxs">
                  <div
                    className="progress-bar progress-bar-warning"
                    role="progressbar"
                    aria-valuenow={breachedPercent?.toFixed(0)}
                    aria-valuemin="0"
                    aria-valuemax="100"
                    style={{ width: breachedPercent?.toFixed(0) + "%" }}
                  ></div>
                </div>
              </div> */}

              {/* <div className="form-group">
                <label className="d-block" style={{ cursor: 'pointer' }} onClick={() => setfilterData("contact")}>
                  Contact Number/ address found{" "}
                  <span className="float-right">
                    {phonePasswordPercent?.toFixed(0)}%
                  </span>
                </label>
                <div className="progress progress-xxs">
                  <div
                    className="progress-bar progress-bar-info"
                    role="progressbar"
                    aria-valuenow={phonePasswordPercent?.toFixed(0)}
                    aria-valuemin="0"
                    aria-valuemax="100"
                    style={{ width: phonePasswordPercent?.toFixed(0) + "%" }}
                  ></div>
                </div>
              </div> */}
            </div>
          </div>
        </div>
        <div className="col-lg-8 col-md-12 col-sm-12">
          <div className="card">
            <div className="body" style={{ minHeight: "440px" }}>
              <h6>Previous scan Details</h6>
              {oldDnsDetails && (
                <BasicLineColumn
                  dates={dateArray}
                  ipArray={ipArray}
                  color={["#b0f127"]}
                  yAxis="Records Found"
                />
              )}
            </div>
          </div>
        </div>
      </div>
      <ul className="nav nav-tabs mb-2" id="myTabs" role="tablist">
        <li className="nav-item">
          <a
            className={`nav-table-tab nav-link ${category === "all" ? "active" : ""
              }`}
            onClick={() => {
              setCategory("all");
              setPage(1);
              setfilterData("all");
              setSelectedIds([]);
            }}
          >
            Current
          </a>
        </li>
        <li className="nav-item">
          <a
            className={`nav-link nav-table-tab ${category === "risk-accepted" ? "active" : ""
              }`}
            onClick={() => {
              setCategory("risk-accepted");
              setPage(1);
              setfilterData("all");
              setSelectedIds([]);
            }}
          >
            Risk Accepted
          </a>
        </li>
        <li className="nav-item">
          <a
            className={`nav-link nav-table-tab ${category === "false-positive" ? "active" : ""
              }`}
            onClick={() => {
              setCategory("false-positive");
              setPage(1);
              setfilterData("all");
              setSelectedIds([]);
            }}
          >
            False Positive
          </a>
        </li>
        <li className="nav-item">
          <a
            className={`nav-link nav-table-tab ${category === "remidiated" ? "active" : ""
              }`}
            onClick={() => {
              setCategory("remidiated");
              setPage(1);
              setfilterData("all");
              setSelectedIds([]);
            }}
          >
            Remediated
          </a>
        </li>
      </ul>
      <div className="card">
        <div className="body">
          <div className="row">
            <div className="col-sm-4">
              {selectedIds.length > 1 && category === "all" && (
                <Box sx={{ width: 200 }}>
                  <FormControl fullWidth>
                    <Select
                      labelId="demo-simple-select-label"
                      id="demo-simple-select"
                      value={selectedValue}
                      onChange={(event) => {
                        setAction(event.target.value);
                        snooze("", event.target.value);
                      }}
                    >
                      <MenuItem value="pleaseSelect">Snooze Options</MenuItem>
                      <MenuItem
                        value="riskAccepted"
                        onClick={() => {
                          addModal();
                        }}
                      >
                        Risk Accepted
                      </MenuItem>
                      <MenuItem
                        value="markAsFalsePositive"
                        onClick={() => {
                          addModal();
                        }}
                      >
                        False Positive
                      </MenuItem>
                      <MenuItem value="markAsRemidiated">Remediated</MenuItem>
                    </Select>
                  </FormControl>
                </Box>
              )}
              {selectedIds.length > 1 &&
                (category == "false-positive" ||
                  category == "risk-accepted") && (
                  <button
                    className="btn btn-primary btn-sm mr-1 Unsnooze"
                    onClick={() => Unsnoozed("")}
                  >
                    Unsnooze
                  </button>
                )}
            </div>
          </div>
          <div className="row clearfix">
            <div className="col-lg-12 col-md-12">
              <div className="table-responsive">
                <table className="table table-custom spacing5 mb-0">
                  <thead>
                    <tr>
                      {auth?.accessLevel != "Threat Analyst" && (category === "all" ||
                        category === "risk-accepted" ||
                        category === "false-positive") && (
                          <th>
                            <input
                              type="checkbox"
                              checked={selectedIds == 0 ? false : multiCheckTrue}
                              onClick={() => {
                                multicheck(currentTableData);
                              }}
                              className="checkUncheck"
                            />
                          </th>
                        )}
                      <th>Message</th>
                      <th>Telegram Group</th>
                      <th>Message Link</th>
                      <th>Chat Detail</th>
                      <th>Date</th>
                      {auth?.accessLevel != "Threat Analyst" && (category === "all" ||
                        category === "risk-accepted" ||
                        category === "false-positive") && (
                          <th style={{ width: "80" }}>Action</th>
                        )}
                    </tr>
                  </thead>
                  <tbody>
                    {currentTableData?.map((row, index) => {
                      return (
                        <tr>
                          {auth?.accessLevel != "Threat Analyst" && (category === "all" ||
                            category === "risk-accepted" ||
                            category === "false-positive") && (
                              <td>
                                <input
                                  onClick={() => singleClick(row?._id)}
                                  checked={selectedIds?.includes(row?._id)}
                                  type="checkbox"
                                  className="checkUncheck"
                                />
                              </td>
                            )}
                          <td
                            title={row?.message}
                            style={{
                              maxWidth: "250px",
                              overflow: "hidden",
                              textOverflow: "ellipsis",
                              whiteSpace: "nowrap",
                            }}
                          >{row?.message ? row?.message : "N/A"}</td>

                          <td>{row?.group_name ? row?.group_name : "N/A"}</td>
                          <td>
                            <a href={row?.message_link}
                              target="_blank"
                              style={{
                                maxWidth: "250px",
                                overflow: "hidden",
                                textOverflow: "ellipsis",
                                whiteSpace: "nowrap",
                              }}
                            >
                              {row?.message_link ? row?.message_link : "N/A"}
                            </a>
                          </td>
                          <td>
                            <button className="btn btn-primary btn-sm mr-1 Unsnooze"
                              onClick={() => {
                                setModalSet(true);
                                setdetail(row?.message)
                              }}>
                              Detail
                            </button>
                            {row?.s3_media_url ? (
                              <button
                                className="btn btn-primary btn-sm mr-1 Unsnooze"
                                onClick={() => window.open(row?.s3_media_url, '_blank')}
                              >
                                Attachment
                              </button>) : (null)}

                          </td>
                          <td>{row?.date}</td>
                          {auth?.accessLevel != "Threat Analyst" && category === "all" && (
                            <td>
                              <Box sx={{ width: 200 }}>
                                <FormControl fullWidth>
                                  {/* <InputLabel
                                          sx={{ color: "#fff" }}
                                          id="demo-simple-select-label"
                                        >
                                          Action
                                        </InputLabel> */}
                                  <Select
                                    labelId="demo-simple-select-label"
                                    id="demo-simple-select"
                                    value={selectedValue}
                                    onChange={(event) => {
                                      setAction(event.target.value);
                                      snooze(row?._id, event.target.value);
                                    }}
                                  >
                                    <MenuItem value="pleaseSelect">
                                      Snooze Options
                                    </MenuItem>
                                    <MenuItem
                                      value="riskAccepted"
                                      onClick={() => {
                                        addModal();
                                        setLookalikeAppId([row._id]);
                                      }}
                                    >
                                      Risk Accepted
                                    </MenuItem>
                                    <MenuItem
                                      value="markAsFalsePositive"
                                      onClick={() => {
                                        addModal();
                                        setLookalikeAppId([row._id]);
                                      }}
                                    >
                                      False Positive
                                    </MenuItem>
                                    <MenuItem value="markAsRemidiated">
                                      Remediated
                                    </MenuItem>
                                  </Select>
                                </FormControl>
                              </Box>
                            </td>
                          )}
                          {auth?.accessLevel != "Threat Analyst" && (category === "risk-accepted" || category === "false-positive") && (
                            <td>
                              <button
                                className="btn btn-primary btn-sm mr-1 Unsnooze"
                                onClick={() => Unsnoozed(row?._id)}
                              >
                                Unsnooze
                              </button>
                            </td>
                          )}
                        </tr>
                      );
                    })}
                  </tbody>
                </table>
              </div>
              <div className="dataTables_paginate">
                {totalItems > 0 && (
                  <Pagination
                    className="pagination-bar"
                    currentPage={page}
                    totalCount={totalItems}
                    pageSize={PageSize}
                    onPageChange={(page) => setPage(page)}
                  />
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
      <div
        className={`modal fade Form-Wizard-modal ${isModalTrue == true ? "d-block show" : ""
          }`}
      >
        <div className="modal-dialog modal-lg" id="clientModal">
          <div className="modal-content">
            <div className="modal-header">
              <h5 className="modal-title" id="exampleModalLabel">
                Details
              </h5>
              <button
                type="button"
                data-dismiss="modal"
                aria-label="Close"
                onClick={() => setModalSet(false)}
              >
                <span aria-hidden="true">×</span>
              </button>
            </div>
            <div className="modal-body pricing_page">
              <div className="col-xl-12 col-lg-12 col-md-12">
                <div className="card">
                  <p>
                    Message - &nbsp {detail}
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div
        className={`modal fade Form-Wizard-modal ${isModal === "Form Wizard modal" ? "d-block show" : ""
          }`}
      >
        <div className="modal-dialog modal-lg" id="clientModal">
          <div className="modal-content">
            <div className="modal-header">
              <h5 className="modal-title" id="exampleModalLabel">
                {action == "markAsFalsePositive"
                  ? "Mark As False Positive"
                  : "Risk Accepted"}
              </h5>
              <button
                type="button"
                data-dismiss="modal"
                aria-label="Close"
                onClick={() => {
                  setModal("");
                  setActionNote("");
                }}
              >
                <span aria-hidden="true">×</span>
              </button>
            </div>
            <div className="modal-body pricing_page modal_mob">
              <div className="col-xl-12 col-lg-12 col-md-12">
                <div className="card">
                  <div className="row clearfix">
                    <div className="col-md-12">
                      <label className="col-lg-6 col-md-12">Snooze for</label>
                      <div className="form-group ol-lg-12 col-md-12">
                        <select
                          className="custom-select custom-select-sm form-control form-control-sm"
                          onChange={(e) => setForValue(e.target.value)}
                        >
                          <option value="forever">Forever</option>
                          <option value="3 months">3 Months</option>
                          <option value="6 months">6 Months</option>
                          <option value="12 months">12 Months</option>
                        </select>
                      </div>
                    </div>
                    <div className="col-md-12">
                      <label className="col-lg-12 col-md-12">Detail</label>
                      <div className="form-group col-lg-12 col-md-12">
                        <textarea
                          onChange={(e) => setActionNote(e.target.value)}
                          value={actionNote}
                          className="form-control"
                          rows="3"
                        ></textarea>
                      </div>
                    </div>
                  </div>
                  <div className="row">
                    <div
                      className="col-lg-12 col-md-12"
                      style={{ marginRight: "28px" }}
                    >
                      <button
                        type="button"
                        style={{ color: "black", fontWeight: "bold" }}
                        onClick={submit}
                        className="btn btn-round btn-primary mr-1 pull-right"
                      >
                        Submit
                      </button>
                      &nbsp;&nbsp;
                      <button
                        type="button"
                        onClick={() => {
                          setModal("");
                          setActionNote("");
                        }}
                        className="btn btn-round btn-default pull-right"
                        style={{ marginRight: "10px" }}
                      >
                        Cancel
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default TelegramMonitoring;
