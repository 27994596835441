import React, { useEffect, useState, useRef } from "react";
import { Link, useParams } from "react-router-dom";
import moment from "moment";
import GaugeChart from "react-gauge-chart";
import ScanMessage from "../common/scanningMessage";
import { useSelector, useDispatch } from "react-redux";
import {
  fetchVulnerableSsl,
  fetchVulnerableTotal,
  fetchVulnerableGraph,
  fetchVulnerableSslAction,
} from "../../actions/sub-domain";
import Pagination from "../Pagination/Pagination";
import ReactTooltip from "react-tooltip";
import * as FileSaver from "file-saver";
import * as XLSX from "xlsx";
import BasicLineColumn from "../common/apexChart/basicLineColumn";
import AccessPage from "../Shared/accessPage";
import axios from "axios";
import { API_URL } from "../../utils/constant";
import MenuItem from "@mui/material/MenuItem";
import Box from "@mui/material/Box";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";

const Dashboard5 = () => {
  const subdomains_new = useSelector(
    (state) => state?.subDomain?.vulnerableCertificate?.data?.data
  );

  const totalItem = useSelector(
    (state) => state?.subDomain?.vulnerableCertificate?.data
  );

  const vulnerableTotals = useSelector(
    (state) => state?.subDomain?.vulnerableCertificatetotal?.data
  );

  const auth = useSelector((state) => state?.auth?.user);

  let vulnerableTotal = 10;

  const scanCompleted = useSelector(
    (state) => state?.subDomain?.subDomainScanCompleted
  );
  const scanDetails = useSelector(
    (state) => state?.subDomain?.vulnerableGraph?.data
  );
  const [page, setPage] = useState(1);
  const [PageSize, setPerPage] = useState(10);

  const [filterData, setfilterData] = useState("all");
  const [search, setSearch] = useState("");

  const queryParameters = new URLSearchParams(window.location.search);
  const latest = queryParameters.get("latest");

  const [category, setCategory] = useState("all");
  const [totalItems, setTotalItems] = useState(0);
  const [isModal, setModal] = useState("");
  const [forValue, setForValue] = useState("forever");
  const [lookalikeAppId, setLookalikeAppId] = useState([]);
  const [actionNote, setActionNote] = useState("");
  const [action, setAction] = useState("");
  const [selectedValue, setSelectedValue] = useState("pleaseSelect");

  const vendorName = sessionStorage.getItem('vendorName');


  if (latest && filterData == "all") setfilterData("Latest");

  const myRef = useRef(null);

  let ipArray = [];
  for (let details = 0; details < scanDetails?.scanDetails?.length; details++) {
    ipArray.push(scanDetails?.scanDetails[details]);
  }

  const executeScroll = (val) => {
    setfilterData(val);
    myRef.current.scrollIntoView();
  };
  const dispatch = useDispatch();
  const { orgId } = useParams();
  const id = useSelector(state => {
    if (orgId) {
      return orgId;
    } else {
      return state?.summary?.localClient?._id || state?.summary?.clients?.[0]?._id;
    }
  });
  const client = useSelector(
    (state) => state?.summary?.localClient || state?.summary?.clients?.[0]
  );

  useEffect(() => {
    if (orgId) {
      fetchVulnerableSsl(orgId, dispatch, page, category, search, filterData);
    } else {
      fetchVulnerableSsl(id, dispatch, page, category, search, filterData);
    }
  }, [id, dispatch, page, category, search, filterData]);

  useEffect(() => {
    fetchVulnerableTotal(id, dispatch);
  }, [id, dispatch]);

  useEffect(() => {
    fetchVulnerableGraph(id, dispatch);
  }, [id, dispatch]);

  useEffect(() => {
    setTotalItems(totalItem?.totalProfiles);
  }, [totalItem?.totalProfiles]);

  const addModal = () => {
    setModal("Form Wizard modal");
  };

  const [selectedIds, setSelectedIds] = useState([]);
  const [multiCheckTrue, setMultiCheckTrue] = useState(false);

  const multicheck = (data) => {
    const allIds = data?.map((item) => item._id);
    if (selectedIds.length > 0 || selectedIds.length == 0) {
      setMultiCheckTrue(true);
      setSelectedIds(allIds);
    }
    if (multiCheckTrue == true) {
      setSelectedIds([]);
      setMultiCheckTrue(false);
    }
  };

  const singleClick = (id, data) => {
    setSelectedIds((prevSelectedIds) => {
      if (prevSelectedIds.includes(id)) {
        return prevSelectedIds.filter((selectedId) => selectedId !== id);
      } else {
        return [...prevSelectedIds, id];
      }
    });
    if (selectedIds.length > 10) {
      setMultiCheckTrue(true);
    } else {
      setMultiCheckTrue(false);
    }
  };


  const snooze = (_id, event) => {
    if (event == "") {
    }

    if (event == "riskAccepted") {
    }

    if (event == "markAsFalsePositive") {
    }

    if (event == "markAsRemidiated") {
      let ids = [];
      ids.push(_id);
      const data = {
        action: "markAsRemidiated",
        subDomainIds: selectedIds == 0 ? ids : selectedIds,
        actionNote: "",
        for: "forever",
      };
      fetchVulnerableSslAction(
        id,
        dispatch,
        page,
        category,
        search,
        filterData,
        data
      );
      setSelectedValue("pleaseSelect");
      setActionNote("");
      setSelectedIds([]);
    }
  };

  const Unsnoozed = (_id) => {
    let ids = [];
    ids.push(_id);
    const data = {
      action: 'resetAction',
      subDomainIds: selectedIds == 0 ? ids : selectedIds,
    };
    fetchVulnerableSslAction(
      id,
      dispatch,
      page,
      category,
      search,
      filterData,
      data
    );
    setSelectedIds([]);
  }

  const submit = () => {
    const data = {
      action: action,
      subDomainIds: selectedIds == 0 ? lookalikeAppId : selectedIds,
      actionNote: actionNote,
      for: forValue,
    };
    fetchVulnerableSslAction(
      id,
      dispatch,
      page,
      category,
      search,
      filterData,
      data
    );
    setModal("");
    setSelectedValue("pleaseSelect");
    setActionNote("");
    setSelectedIds([]);
  };

  let currentTableData = [];

  let mapData = subdomains_new;

  if (mapData && mapData.length > 0) {
    currentTableData = mapData;
  }
  if (mapData && mapData.length > 0) {
    setTimeout(function () {
      ReactTooltip.rebuild();
    }, 1000);
  }

  const downloadFile = async () => {
    try {
      const session = localStorage.getItem("session");
      axios.defaults.headers.common["Authorization"] = `Bearer ${session}`;
      const response = await axios.get(`${API_URL}/vulnerable-ssl/${id}/excel`, {
        responseType: "blob",
      });
      const url = window.URL.createObjectURL(new Blob([response.data]));
      const link = document.createElement("a");
      link.href = url;
      link.setAttribute("download", "SSL Certificate Details.xlsx");
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
      window.URL.revokeObjectURL(url);
    } catch (error) {
      console.error(error);
    }
  };

  return (
    <>
      <div className="container-fluid">
        <AccessPage></AccessPage>
        <ReactTooltip multiline={true} />
        <div className="block-header">
          <div className="row clearfix">
            <div className="col-md-6 col-sm-12">
              <h1>Vulnerable SSL Certificates</h1>
              <nav aria-label="breadcrumb">
                <ol className="breadcrumb">
                  <li className="breadcrumb-item">
                    <Link to="/">Home</Link>
                  </li>
                  {
                    vendorName ?
                      <li
                        className="breadcrumb-item active"
                        aria-current="page"
                        style={{ color: "white" }}
                      >
                        {vendorName}
                      </li> : ''
                  }
                  <li className="breadcrumb-item active" aria-current="page">
                    Details
                  </li>
                </ol>
                <br />
                <span><b>Rating : {vulnerableTotals?.individualRating}</b></span>
              </nav>
              {/* <p>Vulberability Grade : {riskScore || "Calculating..."}</p>
              <p>Expiry Grade : {riskExpiryScore || "Calculating..."}</p> */}
            </div>
            <div className="col-md-6 col-sm-12 text-right hidden-xs">
              {mapData?.length === 0 ? null : (
                <span>
                  <a
                    onClick={(e) => {
                      e.preventDefault();
                      downloadFile();
                    }}
                    href="/add-report"
                    style={{ color: "black", fontWeight: "bold" }}
                    className="btn btn-primary mr-1"
                  >
                    <i
                      className="fa fa-file-excel-o"
                      style={{ color: "black" }}
                    ></i>{" "}
                    Download Details
                  </a>
                </span>
              )}
            </div>
          </div>
        </div>

        {typeof scanCompleted !== "undefined" && !scanCompleted && (
          <div className="card border-success">
            <ScanMessage />
          </div>
        )}

        <div className="row clearfix">
          <div className="col-lg-3 col-md-4">
            <div className="card">
              <div className="body" style={{ minHeight: "582px" }}>
                <div className="header">
                  <h2>Compliance Percentage</h2>
                </div>
                <div>
                  {!isNaN(vulnerableTotals?.complaincePercentage) && (
                    <GaugeChart
                      id="gauge-chart3"
                      nrOfLevels={30}
                      colors={["#FFA500", "#ff0000"]}
                      arcWidth={0.3}
                      percent={parseInt(vulnerableTotals?.complaincePercentage) / 100}
                    />
                  )}
                </div>
                <div>
                  <div className="form-group">
                    <label className="d-block">
                      Vulnerable Certificates{" "}
                      <span className="float-right">
                        {vulnerableTotals?.totalVulnerableSubdomains}
                      </span>
                    </label>
                    <div className="progress progress-xxs">
                      <div
                        className="progress-bar progress-bar-warning"
                        role="progressbar"
                        aria-valuenow={(Math.floor(
                          vulnerableTotals?.totalVulnerableSubdomains /
                          vulnerableTotals?.totalSubDomains || 0
                        ) * 100 || 0)?.toFixed(1)}
                        aria-valuemin="0"
                        aria-valuemax="100"
                        style={{
                          width: Math.floor(
                            vulnerableTotals?.totalVulnerableSubdomains /
                            vulnerableTotals?.totalSubDomains || 0
                          ) * 100 + "%",
                        }}
                      ></div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className="col-lg-9 col-md-8">
            <div className="row">
              <div className="col-xl-4 col-lg-6 col-md-6 col-sm-12">
                <div className="card">
                  <div
                    className="body"
                    style={{ cursor: "pointer" }}
                    onClick={() => executeScroll("vulnerable")}
                  >
                    <div className="row clearfix">
                      <div className="col-7">
                        <h5 className="mb-0">
                          {vulnerableTotals?.totalVulnerableSubdomains}
                        </h5>
                        <small className="info">Vulnerable</small>
                      </div>
                      <div className="col-5 text-right">
                        <h2 className="m-b-0">
                          {Math.floor(
                            (vulnerableTotals?.totalVulnerableSubdomains /
                              vulnerableTotals?.totalSubDomains || 0) * 100
                          )}
                          %
                        </h2>
                      </div>
                      <div className="col-12">
                        <div className="progress progress-xxs progress-transparent custom-color-orange mb-0 mt-3">
                          <div
                            className="progress-bar"
                            data-transitiongoal={Math.floor(
                              (vulnerableTotals?.totalVulnerableSubdomains /
                                vulnerableTotals?.totalSubDomains || 0) * 100
                            )}
                            aria-valuenow={Math.floor(
                              (vulnerableTotals?.totalVulnerableSubdomains /
                                vulnerableTotals?.totalSubDomains || 0) * 100
                            )}
                            style={{
                              width:
                                Math.floor(
                                  (vulnerableTotals?.totalVulnerableSubdomains /
                                    vulnerableTotals?.totalSubDomains || 0) *
                                  100
                                ) + "%",
                            }}
                          ></div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-xl-4 col-lg-6 col-md-6 col-sm-12">
                <div className="card">
                  <div
                    className="body"
                    style={{ cursor: "pointer" }}
                    onClick={() => executeScroll("60")}
                  >
                    <div className="row clearfix">
                      <div className="col-7">
                        <h5 className="mb-0">
                          {vulnerableTotals?.totalSSLsWithRenewalIn60Days}
                        </h5>
                        <small className="text-muted">Renewal in 60 days</small>
                      </div>
                      <div className="col-5 text-right">
                        <h2 className="m-b-0">
                          {Math.floor(
                            (vulnerableTotals?.totalSSLsWithRenewalIn60Days /
                              vulnerableTotals?.totalSubDomains || 0) * 100
                          )}
                          %
                        </h2>
                      </div>
                      <div className="col-12">
                        <div className="progress progress-xxs progress-transparent custom-color-greenSSL mb-0 mt-3">
                          <div
                            className="progress-bar"
                            data-transitiongoal={Math.floor(
                              (vulnerableTotals?.totalSSLsWithRenewalIn60Days /
                                vulnerableTotals?.totalSubDomains || 0) * 100
                            )}
                            aria-valuenow={Math.floor(
                              (vulnerableTotals?.totalSSLsWithRenewalIn60Days /
                                vulnerableTotals?.totalSubDomains || 0) * 100
                            )}
                            style={{
                              width:
                                Math.floor(
                                  (vulnerableTotals?.totalSSLsWithRenewalIn60Days /
                                    vulnerableTotals?.totalSubDomains || 0) *
                                  100
                                ) + "%",
                            }}
                          ></div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <div className="col-xl-4 col-lg-6 col-md-6 col-sm-12">
                <div className="card">
                  <div
                    className="body"
                    style={{ cursor: "pointer" }}
                    onClick={() => executeScroll("90")}
                  >
                    <div className="row clearfix">
                      <div className="col-7">
                        <h5 className="mb-0">
                          {vulnerableTotals?.totalSSLsWithRenewalIn90Days}
                        </h5>
                        <small className="text-muted">Renewal in 90 days</small>
                      </div>
                      <div className="col-5 text-right">
                        <h2 className="m-b-0">
                          {Math.floor(
                            (vulnerableTotals?.totalSSLsWithRenewalIn90Days /
                              vulnerableTotals?.totalSubDomains || 0) * 100
                          )}
                          %
                        </h2>
                      </div>
                      <div className="col-12">
                        <div className="progress progress-xxs progress-transparent custom-color-greenSSL mb-0 mt-3">
                          <div
                            className="progress-bar"
                            data-transitiongoal={Math.floor(
                              (vulnerableTotals?.totalSSLsWithRenewalIn90Days /
                                vulnerableTotals?.totalSubDomains || 0) * 100
                            )}
                            aria-valuenow={Math.floor(
                              (vulnerableTotals?.totalSSLsWithRenewalIn90Days /
                                vulnerableTotals?.totalSubDomains || 0) * 100
                            )}
                            style={{
                              width:
                                Math.floor(
                                  (vulnerableTotals?.totalSSLsWithRenewalIn90Days /
                                    vulnerableTotals?.totalSubDomains || 0) *
                                  100
                                ) + "%",
                            }}
                          ></div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="row">
              <div className="col-lg-12 col-md-12">
                <div className="card">
                  <div className="body">
                    <h6>Previous scan details</h6>
                    {scanDetails && (
                      <BasicLineColumn
                        dates={scanDetails?.dates}
                        ipArray={scanDetails?.scanDetails}
                        color={["#b0f127", "#ffffff"]}
                      />
                    )}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="row clearfix">
          <div className="col-lg-12 col-md-12">
            <div className="row clearfix">
              <div className="col-lg-12 col-md-12">
                <ul className="nav nav-tabs mb-2" id="myTabs" role="tablist">
                  <li className="nav-item">
                    <a
                      className={`nav-table-tab nav-link ${category === "all" ? "active" : ""
                        }`}
                      onClick={() => {
                        setCategory("all");
                        setPage(1);
                        setfilterData("all");
                        setSearch("");
                        setSelectedIds([]);
                      }}
                    >
                      Current
                    </a>
                  </li>
                  <li className="nav-item">
                    <a
                      className={`nav-link nav-table-tab ${category === "risk-accepted" ? "active" : ""
                        }`}
                      onClick={() => {
                        setCategory("risk-accepted");
                        setPage(1);
                        setfilterData("all");
                        setSearch("");
                        setSelectedIds([]);
                      }}
                    >
                      Risk Accepted
                    </a>
                  </li>
                  <li className="nav-item">
                    <a
                      className={`nav-link nav-table-tab ${category === "false-positive" ? "active" : ""
                        }`}
                      onClick={() => {
                        setCategory("false-positive");
                        setPage(1);
                        setfilterData("all");
                        setSearch("");
                        setSelectedIds([]);
                      }}
                    >
                      False Positive
                    </a>
                  </li>
                  <li className="nav-item">
                    <a
                      className={`nav-link nav-table-tab ${category === "remidiated" ? "active" : ""
                        }`}
                      onClick={() => {
                        setCategory("remidiated");
                        setPage(1);
                        setfilterData("all");
                        setSearch("");
                        setSelectedIds([]);
                      }}
                    >
                      Remediated
                    </a>
                  </li>
                </ul>
                <div className="card" ref={myRef}>
                  <div className="body">
                    <div className="header">
                      <div className="row">
                        <div className="col-sm-4">
                          <select
                            className="custom-select custom-select-sm form-control form-control-sm"
                            value={filterData}
                            onChange={(e) => setfilterData(e.target.value)}
                          >
                            <option value="all">All</option>
                            <option value="expired">Expired</option>
                            <option value="vulnerable">Vulnerable</option>
                            <option value="renewalin60days">
                              Renewal in 60 days
                            </option>
                            <option value="renewalin90days">
                              Renewal in 90 days
                            </option>
                            <option value="Latest">Latest</option>
                          </select>
                        </div>
                        <div className="col-sm-4">
                          <div
                            data-test="datatable-input"
                            class="mdb-datatable-filter flex-row"
                          >
                            <input
                              class="form-control form-control-sm ml-0 my-1"
                              type="text"
                              placeholder="Search"
                              aria-label="Search"
                              value={search}
                              onChange={(e) => setSearch(e.target.value)}
                            />
                          </div>
                        </div>
                        <div className="col-sm-4">
                          {selectedIds.length > 1 && category === "all" && (
                            <Box sx={{ width: 200 }}>
                              <FormControl fullWidth>
                                <Select
                                  labelId="demo-simple-select-label"
                                  id="demo-simple-select"
                                  value={selectedValue}
                                  onChange={(event) => {
                                    setAction(event.target.value);
                                    snooze("", event.target.value);
                                  }}
                                >
                                  <MenuItem value="pleaseSelect">
                                    Snooze Options
                                  </MenuItem>
                                  <MenuItem
                                    value="riskAccepted"
                                    onClick={() => {
                                      addModal();
                                    }}
                                  >
                                    Risk Accepted
                                  </MenuItem>
                                  <MenuItem
                                    value="markAsFalsePositive"
                                    onClick={() => {
                                      addModal();
                                    }}
                                  >
                                    False Positive
                                  </MenuItem>
                                  <MenuItem value="markAsRemidiated">
                                    Remediated
                                  </MenuItem>
                                </Select>
                              </FormControl>
                            </Box>
                          )}
                          {selectedIds.length > 1 &&
                            (category == "false-positive" ||
                              category == "risk-accepted") && (
                              <button
                                className="btn btn-primary btn-sm mr-1 Unsnooze"
                                onClick={() => Unsnoozed("")}
                              >
                                Unsnooze
                              </button>
                            )}
                        </div>
                      </div>
                      <div className="table-responsive">
                        <table className="table table-custom spacing5 mb-0">
                          <thead>
                            <tr>
                              {auth?.accessLevel != "Threat Analyst" && (category === "all" ||
                                category === "risk-accepted" ||
                                category === "false-positive") && (
                                  <th>
                                    <input
                                      type="checkbox"
                                      checked={
                                        selectedIds == 0 ? false : multiCheckTrue
                                      }
                                      onClick={() => {
                                        multicheck(currentTableData);
                                      }}
                                      className="checkUncheck"
                                    />
                                  </th>
                                )}
                              <th>Status</th>
                              <th>Sub-Domain Name</th>
                              <th>Expiry in</th>
                              <th>Country</th>
                              <th>Serial Number</th>
                              <th>Vulnerabilties</th>
                              <th>Last modified on</th>
                              {auth?.accessLevel != "Threat Analyst" && (category === "all" ||
                                category === "risk-accepted" ||
                                category === "false-positive") && (
                                  <th style={{ width: "80" }}>Action</th>
                                )}
                            </tr>
                          </thead>
                          <tbody>
                            {currentTableData?.map((row) => {
                              return (
                                <tr>
                                  {auth?.accessLevel != "Threat Analyst" && (category === "all" ||
                                    category === "risk-accepted" ||
                                    category === "false-positive") && (
                                      <td>
                                        <input
                                          onClick={() => singleClick(row?._id)}
                                          checked={selectedIds?.includes(
                                            row?._id
                                          )}
                                          type="checkbox"
                                          className="checkUncheck"
                                        />
                                      </td>
                                    )}

                                  <td>
                                    <span
                                      className={
                                        row?.status == "Active"
                                          ? "badge badge-success"
                                          : "badge badge-danger"
                                      }
                                    >
                                      {row?.status}
                                    </span>
                                  </td>
                                  <td>
                                    <span>
                                      {row?.subDomainName || "NA"}
                                    </span>
                                  </td>
                                  <td>
                                    {row?.expiresInDays + " days" || "NA"}
                                  </td>
                                  <td>{row?.issuerCountry || "NA"}</td>
                                  <td>
                                    {row?.certificateSerialNumber || "NA"}
                                  </td>
                                  <td>
                                    {row?.vulnerabilties?.map((vul, index) => {
                                      return (
                                        <>
                                          <span
                                            id={`vulnerability${index}`}
                                            data-tip={vul?.finding}
                                            className={
                                              vul?.id == "scanProblem" ||
                                                vul?.id == "scanTime"
                                                ? "badge badge-danger"
                                                : "badge badge-warning"
                                            }
                                          >
                                            {vul?.id == "scanProblem" ||
                                              vul?.id == "scanTime"
                                              ? "ERROR"
                                              : vul?.id}
                                          </span>
                                        </>
                                      );
                                    })}
                                  </td>
                                  <td style={{ whiteSpace: "unset" }}>
                                    <span className="badge badge-success">
                                      {moment(
                                        row?.updatedAt || row?.createdAt
                                      ).format("DD-MM-YYYY")}
                                    </span>
                                  </td>
                                  {auth?.accessLevel != "Threat Analyst" && category === "all" && (
                                    <td>
                                      <Box sx={{ width: 200 }}>
                                        <FormControl fullWidth>
                                          {/* <InputLabel
                                          sx={{ color: "#fff" }}
                                          id="demo-simple-select-label"
                                        >
                                          Action
                                        </InputLabel> */}
                                          <Select
                                            labelId="demo-simple-select-label"
                                            id="demo-simple-select"
                                            value={selectedValue}
                                            onChange={(event) => {
                                              setAction(event.target.value);
                                              snooze(
                                                row?._id,
                                                event.target.value
                                              );
                                            }}
                                          >
                                            <MenuItem value="pleaseSelect">
                                              Snooze Options
                                            </MenuItem>
                                            <MenuItem
                                              value="riskAccepted"
                                              onClick={() => {
                                                addModal();
                                                setLookalikeAppId([row._id]);
                                              }}
                                            >
                                              Risk Accepted
                                            </MenuItem>
                                            <MenuItem
                                              value="markAsFalsePositive"
                                              onClick={() => {
                                                addModal();
                                                setLookalikeAppId([row._id]);
                                              }}
                                            >
                                              False Positive
                                            </MenuItem>
                                            <MenuItem value="markAsRemidiated">
                                              Remediated
                                            </MenuItem>
                                          </Select>
                                        </FormControl>
                                      </Box>
                                    </td>
                                  )}
                                  {auth?.accessLevel != "Threat Analyst" && (category === "risk-accepted" || category === "false-positive") && (
                                    <td>
                                      <button
                                        className="btn btn-primary btn-sm mr-1 Unsnooze"
                                        onClick={() => Unsnoozed(row?._id)}
                                      >
                                        Unsnooze
                                      </button>
                                    </td>
                                  )}
                                </tr>
                              );
                            })}
                          </tbody>
                        </table>
                      </div>
                      <div className="dataTables_paginate">
                        {totalItems > 0 && (
                          <Pagination
                            className="pagination-bar"
                            currentPage={page}
                            totalCount={totalItems}
                            pageSize={PageSize}
                            onPageChange={(page) => setPage(page)}
                          />
                        )}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div
          className={`modal fade Form-Wizard-modal ${isModal === "Form Wizard modal" ? "d-block show" : ""
            }`}
        >
          <div className="modal-dialog modal-lg" id="clientModal">
            <div className="modal-content">
              <div className="modal-header">
                <h5 className="modal-title" id="exampleModalLabel">
                  {action == "markAsFalsePositive"
                    ? "Mark As False Positive"
                    : "Risk Accepted"}
                </h5>
                <button
                  type="button"
                  data-dismiss="modal"
                  aria-label="Close"
                  onClick={() => {
                    setModal("");
                    setActionNote("");
                  }}
                >
                  <span aria-hidden="true">×</span>
                </button>
              </div>
              <div className="modal-body pricing_page modal_mob">
                <div className="col-xl-12 col-lg-12 col-md-12">
                  <div className="card">
                    <div className="row clearfix">
                      <div className="col-md-12">
                        <label className="col-lg-6 col-md-12">Snooze for</label>
                        <div className="form-group ol-lg-12 col-md-12">
                          <select
                            className="custom-select custom-select-sm form-control form-control-sm"
                            onChange={(e) => setForValue(e.target.value)}
                          >
                            <option value="forever">Forever</option>
                            <option value="3 months">3 Months</option>
                            <option value="6 months">6 Months</option>
                            <option value="12 months">12 Months</option>
                          </select>
                        </div>
                      </div>
                      <div className="col-md-12">
                        <label className="col-lg-12 col-md-12">Detail</label>
                        <div className="form-group col-lg-12 col-md-12">
                          <textarea
                            onChange={(e) => setActionNote(e.target.value)}
                            value={actionNote}
                            className="form-control"
                            rows="3"
                          ></textarea>
                        </div>
                      </div>
                    </div>
                    <div className="row">
                      <div
                        className="col-lg-12 col-md-12"
                        style={{ marginRight: "28px" }}
                      >
                        <button
                          type="button"
                          style={{ color: "black", fontWeight: "bold" }}
                          onClick={submit}
                          className="btn btn-round btn-primary mr-1 pull-right"
                        >
                          Submit
                        </button>
                        &nbsp;&nbsp;
                        <button
                          type="button"
                          onClick={() => {
                            setModal("");
                            setActionNote("");
                          }}
                          className="btn btn-round btn-default pull-right"
                          style={{ marginRight: "10px" }}
                        >
                          Cancel
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Dashboard5;
