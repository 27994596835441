import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { Link  , useParams , useHistory} from "react-router-dom";
import { toggleSearchBar } from "../../actions/settingsAction";
import { useSelector, useDispatch } from "react-redux";
import { fetchClients, getClientByLocalStorage } from "../../actions/summary";
import { GETING_DATA_BY_LOCALSTORAGE } from "../../actions/index";
import Pagination from "../Pagination/Pagination";

const Searchbar = ({ isSearchbar, toggleSearchBar }) => {
  const history = useHistory();
  const value = useSelector(
    (state) => state?.summary?.localClient || state?.summary?.clients?.[0]
  );

  const auth = useSelector((state) => state?.auth?.user);
  let clients = useSelector((state) => state?.summary?.totalClient?.data);
  clients = clients?.filter((res) => res?.clientType != 3);
  const dispatch = useDispatch();

  const [searchBy, setSearch] = useState("");
  const [page, setPage] = useState(1);
  const [totalItems, settotalItems] = useState(1);

  const totalClient = useSelector(
    (state) => state?.summary?.totalClient?.totalActiveCount
  );

  const handleSearchChange = (e) => {
    const inputValue = e.target.value;
    if (inputValue.length >= 3) {
      setSearch(inputValue);
    }
    if (inputValue.length === 0) {
      setSearch(inputValue);
    }
  };

  useEffect(() => {
    if (auth.accessLevel === "Client" || auth.accessLevel === "Manager") {
      fetchClients(dispatch, auth.accessLevel, page, searchBy);
      localStorage.setItem("org", JSON.stringify(auth.organisationId || {}));
      dispatch({
        type: GETING_DATA_BY_LOCALSTORAGE,
        localClient: auth.organisationId || {},
      });
    } else {
      fetchClients(dispatch, auth.accessLevel, page, searchBy);
      if (auth.accessLevel !== "Client") {
        getClientByLocalStorage(dispatch, auth.accessLevel);
      }
    }
  }, [dispatch, auth.accessLevel, page, searchBy]);

  useEffect(() => {
    settotalItems(totalClient);
  }, [totalClient]);


  let clientData = clients;

  return (
    <>
      <div
        className={`search_div${isSearchbar ? " open" : ""}`}
        style={{ overflow: "scroll" }}
      >
        <div className="card">
          <div className="body">
            <form id="navbar-search" className="navbar-form search-form">
              <div className="input-group mb-0">
                <input
                  type="text"
                  className="form-control"
                  placeholder="Search..."
                  onChange={handleSearchChange}
                />
                <div className="input-group-append">
                  <span className="input-group-text">
                    <i className="icon-magnifier"></i>
                  </span>
                  <span
                    onClick={() => toggleSearchBar(false)}
                    className="search_toggle btn btn-danger"
                  >
                    <i className="icon-close"></i>
                  </span>
                </div>
              </div>
            </form>
          </div>
        </div>
        <span>
          Search Result <small className="float-right text-muted"></small>
        </span>
        <div className="table-responsive" style={{ overflow: "scroll" }}>
          <table className="table table-hover table-custom spacing5">
            <tbody>
              {clientData?.map((row, index) => {
                return (
                  <tr>
                    <td className="w40">
                      <span>{(page - 1) * 10 + 1 + index}</span>
                    </td>
                    <td>
                      <div className="d-flex align-items-center">
                        <div
                          className="avtar-pic w35 bg-red"
                          data-toggle="tooltip"
                          data-placement="top"
                          title=""
                          data-original-title="Avatar Name"
                        >
                          <span>{row?.orgName?.match(/\b\w/g)?.join("")}</span>
                        </div>
                        <div className="ml-3">
                          <Link
                            onClick={() => {
                              localStorage.setItem("org", JSON.stringify(row));
                              localStorage.setItem(
                                "OrgName",
                                JSON.stringify(row?.orgName)
                              );
                              sessionStorage.clear()
                              // history.replaceState(null, "", "/");
                              window.history.replaceState({}, "", "/");

                              dispatch({
                                type: GETING_DATA_BY_LOCALSTORAGE,
                                localClient: row,
                              });
                              window.location.reload();
                            }}
                            title=""
                          >
                            {row?.orgName}
                          </Link>
                          <p className="mb-0">{row?.orgEmail}</p>
                        </div>
                      </div>
                    </td>
                  </tr>
                );
              })}
              {/* <tr>
                                <td className="w40">
                                    <span>01</span>
                                </td>
                                <td>
                                    <div className="d-flex align-items-center">
                                        <div className="avtar-pic w35 bg-red" data-toggle="tooltip" data-placement="top" title="" data-original-title="Avatar Name"><span>SS</span></div>
                                        <div className="ml-3">
                                            <Link to="/page-invoices-details" title="">South Shyanne</Link>
                                            <p className="mb-0">south.shyanne@example.com</p>
                                        </div>
                                    </div>
                                </td>
                            </tr>
                            <tr>
                                <td>
                                    <span>02</span>
                                </td>
                                <td>
                                    <div className="d-flex align-items-center">
                                        <img src="../assets/images/xs/avatar2.jpg" data-toggle="tooltip" data-placement="top" title="" alt="Avatar" className="w35 h35 rounded" data-original-title="Avatar Name" />
                                        <div className="ml-3">
                                            <a href="/" title="">Zoes Baker</a>
                                            <p className="mb-0">zoe.baker@example.com</p>
                                        </div>
                                    </div>
                                </td>
                            </tr>
                            <tr>
                                <td>
                                    <span>03</span>
                                </td>
                                <td>
                                    <div className="d-flex align-items-center">
                                        <div className="avtar-pic w35 bg-indigo" data-toggle="tooltip" data-placement="top" title="" data-original-title="Avatar Name"><span>CB</span></div>
                                        <div className="ml-3">
                                            <a href="/" title="">Colin Brown</a>
                                            <p className="mb-0">colinbrown@example.com</p>
                                        </div>
                                    </div>
                                </td>
                            </tr>
                            <tr>
                                <td>
                                    <span>04</span>
                                </td>
                                <td>
                                    <div className="d-flex align-items-center">
                                        <div className="avtar-pic w35 bg-green" data-toggle="tooltip" data-placement="top" title="" data-original-title="Avatar Name"><span>KG</span></div>
                                        <div className="ml-3">
                                            <a href="/" title="">Kevin Gill</a>
                                            <p className="mb-0">kevin.gill@example.com</p>
                                        </div>
                                    </div>
                                </td>
                            </tr>
                            <tr>
                                <td>
                                    <span>05</span>
                                </td>
                                <td>
                                    <div className="d-flex align-items-center">
                                        <img src="../assets/images/xs/avatar5.jpg" data-toggle="tooltip" data-placement="top" title="" alt="Avatar" className="w35 h35 rounded" data-original-title="Avatar Name" />
                                        <div className="ml-3">
                                            <a href="/" title="">Brandon Smith</a>
                                            <p className="mb-0">Maria.gill@example.com</p>
                                        </div>
                                    </div>
                                </td>
                            </tr>
                            <tr>
                                <td>
                                    <span>06</span>
                                </td>
                                <td>
                                    <div className="d-flex align-items-center">
                                        <img src="../assets/images/xs/avatar6.jpg" data-toggle="tooltip" data-placement="top" title="" alt="Avatar" className="w35 h35 rounded" data-original-title="Avatar Name" />
                                        <div className="ml-3">
                                            <a href="/" title="">Kevin Baker</a>
                                            <p className="mb-0">kevin.baker@example.com</p>
                                        </div>
                                    </div>
                                </td>
                            </tr>
                            <tr>
                                <td>
                                    <span>07</span>
                                </td>
                                <td>
                                    <div className="d-flex align-items-center">
                                        <img src="../assets/images/xs/avatar2.jpg" data-toggle="tooltip" data-placement="top" title="" alt="Avatar" className="w35 h35 rounded" data-original-title="Avatar Name" />
                                        <div className="ml-3">
                                            <a href="/" title="">Zoe Baker</a>
                                            <p className="mb-0">zoe.baker@example.com</p>
                                        </div>
                                    </div>
                                </td>
                            </tr> */}
            </tbody>
          </table>
          <div className="dataTables_paginate">
            {totalItems > 0 &&
            <Pagination
              className="pagination-bar"
              currentPage={page}
              totalCount={totalItems}
              pageSize={10}
              onPageChange={(page) => {
                setPage(page);
              }}
            />}
          </div>
        </div>
      </div>
    </>
  );
};
const mapStateToProps = (state) => ({
  isSearchbar: state.settings.isSearchbar,
});

const mapDispatchToProps = (dispatch) => ({
  toggleSearchBar: (e) => dispatch(toggleSearchBar(e)),
});
export default connect(mapStateToProps, mapDispatchToProps)(Searchbar);
