import React, { useEffect, useState } from "react";
import { Link, useParams } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import { fetchDashboardReport } from "../../actions/summary";
import Box from "@mui/material/Box";
import LinearProgress from "@mui/material/LinearProgress";
import moment from "moment";
import AccessPage from "../Shared/accessPage";
import Splinechart from "../common/splinechart";
import Linechart from "../common/linechart";

var Model = require("./model");

const ASMDashboard = () => {
  const dispatch = useDispatch();
  // const id = JSON.parse(localStorage.getItem("user")).organisationId;
  const [loading, setLoading] = useState(false);

  const [riskScoreVA, setRiskScoreVa] = useState("");
  const [riskScoreExpiredSSL, setRiskScoreExpiredSSL] = useState("");
  const [riskScoreVulnerableSSL, setRiskScoreVulnerableSSL] = useState("");
  const [riskScoreDangling, setRiskScoreDangling] = useState("");
  const vendorName = sessionStorage.getItem('vendorName');  
  const vendorId = sessionStorage.getItem('vendorId');

const { orgId } = useParams();
  const id = useSelector(state => {
    if (orgId) {
        return orgId;
    } else {
        return state?.summary?.localClient?._id || state?.summary?.clients?.[0]?._id;
    }
});
console.log(id,'idd')
  const client = useSelector(
    (state) => state?.summary?.localClient || state?.summary?.clients?.[0]
  );

  useEffect(() => {
    if (id) fetchDashboardReport(id, "ASM", dispatch);
    else fetchDashboardReport(id, "ASM", dispatch);
  }, [id]);

  let summary = useSelector((state) => state?.summary?.dashboardSummary);
  console.log(summary,'summary')
  let summaryLoading = useSelector((state) => state?.summary?.loading);

  console.log(summary?.issueArray, "issueArray");
  const generateReport = () => {
    //console.log("generateReport");
    if (loading) {
      return false;
    }
    setLoading(true);
    let data = {
      id:id,
      asm:'asm'
    }
    Model.default.generateReport(data).then((response) => {
      setLoading(false);
      if (!response.error) {
        window.open(response.data.docx);
      }
    });
  };

  let securityGraphValue = summary?.Rating?.map((row) => row?.attackSurfaceSecurityImpact);
  let securityGraphDate = summary?.Rating?.map((row) =>
    moment(row?.createdAt).format("DD-MM-YYYY")
  );

  let passiveVaComplaint = Math.sqrt(summary?.ipVulnerability?.toFixed(2));
  let passiveVaBase = Math.sqrt(summary?.assetsDiscovery);

  let passiveVaValue = parseFloat(
    ((passiveVaComplaint / passiveVaBase) * 100)?.toFixed(2)
  );

  if (!isNaN(passiveVaValue) && riskScoreVA == "") {
    // setRiskScoreValue(riskScoreValue);
    if (passiveVaValue == 0) {
      setRiskScoreVa("A1");
    } else if (passiveVaValue < 20) {
      setRiskScoreVa("A2");
    } else if (passiveVaValue < 35) {
      setRiskScoreVa("B1");
    } else if (passiveVaValue < 55) {
      setRiskScoreVa("B2");
    } else if (passiveVaValue < 75) {
      setRiskScoreVa("C1");
    } else {
      setRiskScoreVa("C2");
    }
  }

  let squareRootOfExpiredSSLComplaint = Math.sqrt(summary?.SSlEXpired)?.toFixed(
    2
  );
  let squareRootOfExpiredSSLBase = Math.sqrt(
    summary?.SSlCertificate?.toFixed(2)
  );

  let expiredSSLValue = parseFloat(
    (
      (squareRootOfExpiredSSLComplaint / squareRootOfExpiredSSLBase) *
      100
    )?.toFixed(2)
  );

  if (!isNaN(expiredSSLValue) && riskScoreExpiredSSL == "") {
    if (expiredSSLValue == 0) {
      setRiskScoreExpiredSSL("A1");
    } else if (expiredSSLValue < 20) {
      setRiskScoreExpiredSSL("A2");
    } else if (expiredSSLValue < 35) {
      setRiskScoreExpiredSSL("B1");
    } else if (expiredSSLValue < 55) {
      setRiskScoreExpiredSSL("B2");
    } else if (expiredSSLValue < 75) {
      setRiskScoreExpiredSSL("C1");
    } else {
      setRiskScoreExpiredSSL("C2");
    }
  }

  // End of generate grade for expired SSL

  // Generate Grade for Vulnerable SSL

  let vulnerableSSLComplaint = Math.sqrt(summary?.SSlVulnerable?.toFixed(2));

  let vulnerableSSLValue = parseFloat(
    ((vulnerableSSLComplaint / squareRootOfExpiredSSLBase) * 100)?.toFixed(2)
  );

  if (!isNaN(vulnerableSSLValue) && riskScoreVulnerableSSL == "") {
    if (vulnerableSSLValue == 0) {
      setRiskScoreVulnerableSSL("A1");
    } else if (vulnerableSSLValue < 20) {
      setRiskScoreVulnerableSSL("A2");
    } else if (vulnerableSSLValue < 35) {
      setRiskScoreVulnerableSSL("B1");
    } else if (vulnerableSSLValue < 55) {
      setRiskScoreVulnerableSSL("B2");
    } else if (vulnerableSSLValue < 75) {
      setRiskScoreVulnerableSSL("C1");
    } else {
      setRiskScoreVulnerableSSL("C2");
    }
  }

  // End of generate grade for Vulnerable SSL

  // Genereate grade for dangling domains

  const dangling = summary?.danglingData?.filter(
    (row) =>
      row?.dnsData?.ip &&
      (row?.techStack?.urls?.[`https://${row?.subDomainName}/`]?.status ==
        404 ||
        row?.techStack?.urls?.[`https://${row?.subDomainName}/`]?.status ==
          0) &&
      row?.subDomainName.indexOf("www") == -1
  );

  let danglingBase = Math.sqrt(summary?.subdomains?.toFixed(2));

  let danglingValue = parseFloat(
    ((dangling?.length / danglingBase) * 100)?.toFixed(2)
  );

  if (!isNaN(danglingValue) && riskScoreDangling == "") {
    if (danglingValue == 0) {
      setRiskScoreDangling("A1");
    } else if (danglingValue < 20) {
      setRiskScoreDangling("A2");
    } else if (danglingValue < 35) {
      setRiskScoreDangling("B1");
    } else if (danglingValue < 55) {
      setRiskScoreDangling("B2");
    } else if (danglingValue < 75) {
      setRiskScoreDangling("C1");
    } else {
      setRiskScoreDangling("C2");
    }
  }

  const getArrow = (val) => {
    if (val > 0) return "fa fa-arrow-up fa-1x arrowRed";
    else return "fa fa-arrow-down fa-1x arrowGreen";
  };
  // End of generate grade for danglong domains

  return (
    <div className="container-fluid">
      <AccessPage></AccessPage>
      {(summaryLoading || loading) && (
        <Box sx={{ width: "100%" }}>
          <LinearProgress />
        </Box>
      )}
      <div className="block-header">
        <div className="row clearfix">
          <div className="col-md-6 col-sm-12">
            <h1 style={{ color: "white" }}>Summary</h1>
            <nav aria-label="breadcrumb">
              <ol className="breadcrumb">
                <li className="breadcrumb-item">
                  {vendorId ? <>
                  <Link to="/vendor"
                      onClick={(() => {
                        sessionStorage.clear();
                      })}
                    >Forest View</Link>
                  </> : <Link to="/">Home</Link>}
                </li>
                {
                  vendorName ?
                    <li
                      className="breadcrumb-item active"
                      aria-current="page"
                      style={{ color: "white" }}
                    >
                      {vendorName}
                    </li> : ''
                }
                <li
                  className="breadcrumb-item active"
                  aria-current="page"
                  style={{ color: "white" }}
                >
                  Attack Surface Monitoring Details
                </li>
              </ol>
            </nav>
          </div>
          <div className="col-md-6 col-sm-12 text-right hidden-xs">
            <span
              className="btn btn-sm btn-primary mr-1"
              title=""
              onClick={() => generateReport()}
            >
              <i className="fa fa-file" style={{ color: "#060606",fontWeight:"bold" }}></i>
              <span
                style={{ color: "#060606", padding: "10px", fontWeight: "bold" }}
              >
                Download Report
              </span>
            </span>
          </div>
        </div>
      </div>

      <div className="row clearfix">
        <div className="col-lg-9">
          <div className="card">
            <div className="body" style={{ minHeight: "364px" }}>
              <div className="row">
                <div className="col-lg-12">
                  <h6>ASM Health Tracker</h6>
                  <div className="card">
                    {securityGraphValue && (
                      <Splinechart
                        value={securityGraphValue}
                        date={securityGraphDate}
                        name={"ASM Score"}
                        color="#B0F127"
                      />
                    )}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="col-md-3">
          <div className="card">
            <div className="body" style={{ minHeight: "364px" }}>
              <h6>Top Issues</h6>
              <div className="list-group">
                {
                  summary?.issueArray?.map((row) => {
                    return (
                      <a
                        href={row?.link}
                        className="list-group-item list-group-item-action"
                        style={{ color: "white" }}
                      >
                        {row?.msg}
                      </a>
                    )
                  })
                }
              
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="row clearfix">
        <div className="col-sm-2">
          <span className="card">
            <Link
              to={
                `/assets${vendorId ? '/vendor/' + vendorId : ''}`
              }
            >
              <div className="card w_card3" style={{ cursor: "pointer" }}>
                <div className="body">
                  <div className="text-center">
                    <i
                      className="fa fa-database"
                      style={{ color: "#B0F127" }}
                    ></i>
                    <h5 className="m-t-20 mb-0">
                      {summary?.assetsDiscovery || 0}
                    </h5>
                    <p className="text-muted1" style={{ color: "#ffffff" }}>
                      IP Discovery
                    </p>
                  </div>
                </div>
                <div className="card-footer">
                  <span className="text-muted1">
                    {!(summaryLoading || loading) ? (
                      <span className="dashboardFirstHeading">
                        {summary?.ipDiscoveryNewAdded &&
                        summary?.ipDiscoveryNewAdded !== 0 ? (
                          <i
                            className={getArrow(summary?.ipDiscoveryNewAdded)}
                          ></i>
                        ) : (
                          ""
                        )}
                        {Math.abs(summary?.ipDiscoveryNewAdded || 0)} v/s last
                        scan
                      </span>
                    ) : (
                      ""
                    )}
                  </span>
                </div>
              </div>
            </Link>
          </span>
        </div>
        <div className="col-sm-2">
          <span className="card">
            <Link
              to={`/assets${vendorId ? '/vendor/' + vendorId : ''}`}
            >
              <div className="card w_card3" style={{ cursor: "pointer" }}>
                <div className="body">
                  <div className="text-center">
                    <i className="fa fa-usb" style={{ color: "#B0F127" }}></i>
                    <h5 className="m-t-20 mb-0">{summary?.OpenPort || 0}</h5>
                    <p className="text-muted1">Open Ports</p>
                  </div>
                </div>
                <div className="card-footer">
                  <div>
                    <span className="text-muted1">
                      {!(summaryLoading || loading) ? (
                        <span className="dashboardFirstHeading">
                          {summary?.openPortNewAdded &&
                          summary?.openPortNewAdded !== 0 ? (
                            <i
                              className={getArrow(summary?.openPortNewAdded)}
                            ></i>
                          ) : (
                            ""
                          )}
                          {Math.abs(summary?.openPortNewAdded || 0)} v/s last
                          scan
                        </span>
                      ) : (
                        ""
                      )}
                    </span>
                  </div>
                </div>
              </div>
            </Link>
          </span>
        </div>
        <div className="col-sm-2">
          <span className="card">
            <Link
              to={`/known-vulnerabilities${vendorId ? '/vendor/' + vendorId : ''}`}
            >
              <div className="card w_card3" style={{ cursor: "pointer" }}>
                <div className="body">
                  <div className="text-center">
                    <i className="fa fa-bug"></i>
                    <div className="text-center">
                      <h5 className="m-t-20 mb-0" style={{ color: "#FFFFFF" }}>
                        {/* {riskScoreVA} */}
                        {summary?.CVEDetails || 0 }
                      </h5>
                    </div>
                    <p className="text-muted1">Passive V.A</p>
                  </div>
                </div>
                <div className="card-footer">
                  <div>
                    <span className="text-muted1">
                      {!(summaryLoading || loading) ? (
                        <span className="dashboardFirstHeading">
                          {summary?.passiveVaNewAdded &&
                          summary?.passiveVaNewAdded !== 0 ? (
                            <i
                              className={getArrow(summary?.passiveVaNewAdded)}
                            ></i>
                          ) : (
                            ""
                          )}
                          {Math.abs(summary?.passiveVaNewAdded || 0)} v/s last
                          scan
                        </span>
                      ) : (
                        ""
                      )}
                    </span>
                  </div>
                </div>
              </div>
            </Link>
          </span>
        </div>
        <div className="col-sm-2">
          <span className="card">
            <Link
            to={`/subdomains${vendorId ? '/vendor/' + vendorId : ''}`} 
            >
              <div className="card w_card3" style={{ cursor: "pointer" }}>
                <div className="body">
                  <div className="text-center">
                    <i
                      className="fa fa-search"
                      style={{ color: "#B0F127" }}
                    ></i>
                    <h5 className="m-t-20 mb-0">{summary?.subdomains || 0}</h5>
                    <p className="text-muted1">Subdomains</p>
                  </div>
                </div>
                <div className="card-footer">
                  <div>
                    <span className="text-muted1">
                      {!(summaryLoading || loading) ? (
                        <span className="dashboardFirstHeading">
                          {summary?.subdomainNewAdded &&
                          summary?.subdomainNewAdded !== 0 ? (
                            <i
                              className={getArrow(summary?.subdomainNewAdded)}
                            ></i>
                          ) : (
                            ""
                          )}
                          {Math.abs(summary?.subdomainNewAdded || 0)} v/s last
                          scan
                        </span>
                      ) : (
                        ""
                      )}
                    </span>
                  </div>
                </div>
              </div>
            </Link>
          </span>
        </div>
        {/* <div className="col-sm-2">
          <span className="card">
            <Link
              to={"/danglingdomain"}
            >
              <div className="card w_card3" style={{ cursor: "pointer" }}>
                <div className="body">
                  <div className="text-center">
                    <i className="fa fa-exclamation"></i>
                    <div className="text-center">
                      <h3 className="m-t-20 mb-0" style={{ color: "#FFFFFF" }}>
                        {dangling?.length || 0}
                      </h3>
                      <p className="text-muted1">Dangling Subdomain</p>
                    </div>
                  </div>
                </div>
                <div className="card-footer">
                  <div>
                    <span className="text-muted1">
                      {!(summaryLoading || loading) ? (
                        <span className="dashboardFirstHeading">
                          {summary?.danglinDomainNewAdded &&
                            summary?.danglinDomainNewAdded !== 0 ? (
                            <i
                              className={getArrow(
                                summary?.danglinDomainNewAdded
                              )}
                            ></i>
                          ) : (
                            ""
                          )}
                          {Math.abs(summary?.danglinDomainNewAdded || 0)} v/s
                          last scan
                        </span>
                      ) : (
                        ""
                      )}
                    </span>
                  </div>
                </div>
              </div>
            </Link>
          </span>
        </div> */}
        {/* <div className="col-sm-2">
          <span className="card">
            <Link to={"/malware-credentials"}>
              <div className="card w_card3" style={{ cursor: "pointer" }}>
                <div className="body">
                  <div className="text-center">
                    <i
                      className="fa fa-laptop"
                      style={{ color: "#B0F127" }}
                    ></i>
                    <h5 className="m-t-20 mb-0">
                      {summary?.MalwareCredential || 0}
                    </h5>
                    <p className="text-muted1">Malware Infected Machines</p>
                  </div>
                </div>
                <div className="card-footer">
                  <div>
                    <span className="text-muted1">
                      {!(summaryLoading || loading) ? (
                        <span className="dashboardFirstHeading">
                          {summary?.malwareCredNewAdded &&
                          summary?.malwareCredNewAdded !== 0 ? (
                            <i
                              className={getArrow(summary?.malwareCredNewAdded)}
                            ></i>
                          ) : (
                            ""
                          )}
                          {Math.abs(summary?.malwareCredNewAdded || 0)} v/s last
                          scan
                        </span>
                      ) : (
                        ""
                      )}
                    </span>
                  </div>
                </div>
              </div>
            </Link>
          </span>
        </div> */}

        <div className="col-sm-2">
          <span className="card">
            <Link
              to={`/web-technology${vendorId ? '/vendor/' + vendorId : ''}`}
            >
              <div className="card w_card3" style={{ cursor: "pointer" }}>
                <div className="body">
                  <div className="text-center">
                    <i
                      className="fa fa-window-maximize"
                      style={{ color: "#B0F127" }}
                    ></i>
                    <h5 className="m-t-20 mb-0">{summary?.techStack || 0}</h5>
                    <p className="text-muted1">Web Technologies</p>
                  </div>
                </div>
                <div className="card-footer">
                  <div>
                    <span className="text-muted1">
                      {!(summaryLoading || loading) ? (
                        <span className="dashboardFirstHeading">
                          {summary?.webTechNewAdded &&
                          summary?.webTechNewAdded !== 0 ? (
                            <i
                              className={getArrow(summary?.webTechNewAdded)}
                            ></i>
                          ) : (
                            ""
                          )}
                          {Math.abs(summary?.webTechNewAdded || 0)} v/s last
                          scan
                        </span>
                      ) : (
                        ""
                      )}
                    </span>
                  </div>
                </div>
              </div>
            </Link>
          </span>
        </div>
        <div className="col-sm-2">
          <span className="card">
            <Link
              to={`/spf-dmarc${vendorId ? '/vendor/' + vendorId : ''}`}
            >
              <div className="card w_card3" style={{ cursor: "pointer" }}>
                <div className="body">
                  <div className="text-center">
                    <i
                      className="fa fa-envelope"
                      style={{ color: "#B0F127" }}
                    ></i>
                    <div className="text-center">
                      <h3 className="m-t-20 mb-0" style={{ color: "#FFFFFF" }}>
                        {summary?.spfStatus}
                      </h3>
                    </div>
                    <p className="text-muted1">Email Security</p>
                  </div>
                </div>
                <div className="card-footer">
                  <div>
                    <span className="text-muted1">
                      <span className="dashboardFirstHeading">
                        Non-Compliant
                      </span>{" "}
                      <span className="dashboardSecondHeading">
                        {summary?.spfValid || 0}/1
                      </span>
                    </span>
                  </div>
                </div>
              </div>
            </Link>
          </span>
        </div>

        <div className="col-sm-2">
          <span className="card">
            <Link
              to={`/ssl-certificates${vendorId ? '/vendor/' + vendorId : ''}`}
            >
              <div className="card w_card3" style={{ cursor: "pointer" }}>
                <div className="body">
                  <div className="text-center">
                    <i className="fa fa-certificate"></i>
                    <div className="text-center">
                      <h5 className="m-t-20 mb-0" style={{ color: "#FFFFFF" }}>
                        {/* {riskScoreExpiredSSL} */}
                        {(summary?.SSlEXpired || 0)?.toFixed(0)}
                      </h5>
                      <p className="text-muted1">Expired SSL</p>
                    </div>
                  </div>
                </div>
                <div className="card-footer">
                  <div>
                    <span className="text-muted1">
                      {!(summaryLoading || loading) ? (
                        <span className="dashboardFirstHeading">
                          {summary?.expiredSSlNewAdded &&
                          summary?.expiredSSlNewAdded !== 0 ? (
                            <i
                              className={getArrow(summary?.expiredSSlNewAdded)}
                            ></i>
                          ) : (
                            ""
                          )}
                          {Math.abs(summary?.expiredSSlNewAdded || 0)} v/s last
                          scan
                        </span>
                      ) : (
                        ""
                      )}
                    </span>
                  </div>
                </div>
              </div>
            </Link>
          </span>
        </div>
        <div className="col-sm-2">
          <span className="card">
            <Link
              to={`/vulnerable-certificates${vendorId ? '/vendor/' + vendorId : ''}`}
            >
              <div className="card w_card3" style={{ cursor: "pointer" }}>
                <div className="body">
                  <div className="text-center">
                    <i className="fa fa-certificate"></i>
                    <div className="text-center">
                      <h5 className="m-t-20 mb-0" style={{ color: "#FFFFFF" }}>
                        {/* {riskScoreVulnerableSSL} */}
                        {(summary?.SSlVulnerable || 0)?.toFixed(0)}
                      </h5>
                      <p className="text-muted1">Vulnerable SSL</p>
                    </div>
                  </div>
                </div>
                <div className="card-footer">
                  <div>
                    <span className="text-muted1">
                      {!(summaryLoading || loading) ? (
                        <span className="dashboardFirstHeading">
                          {summary?.vulnerableSSlNewAdded &&
                          summary?.vulnerableSSlNewAdded !== 0 ? (
                            <i
                              className={getArrow(
                                summary?.vulnerableSSlNewAdded
                              )}
                            ></i>
                          ) : (
                            ""
                          )}
                          {Math.abs(summary?.vulnerableSSlNewAdded || 0)} v/s
                          last scan
                        </span>
                      ) : (
                        ""
                      )}
                    </span>
                  </div>
                </div>
              </div>
            </Link>
          </span>
        </div>

        {/* {!id && (
          <div className="col-sm-2">
            <span className="card">
              <Link to={"/cloud-bucket"}>
                <div className="card w_card3" style={{ cursor: "pointer" }}>
                  <div className="body">
                    <div className="text-center">
                      <i
                        className="fa fa-cloud"
                        style={{ color: "#B0F127" }}
                      ></i>
                      <h5 className="m-t-20 mb-0">
                        {summary?.CloudBucket || 0}
                      </h5>
                      <p className="text-muted1">Cloud Storage</p>
                    </div>
                  </div>
                  <div className="card-footer">
                    <div>
                      <span className="text-muted1">
                        {!(summaryLoading || loading) ? (
                          <span className="dashboardFirstHeading">
                            {/* <i className="fa fa-arrow-up fa-1x"
                          style={{ color: "#ff0000",fontSize:"20px" }}></i> 
                            {0} v/s last scan
                          </span>
                        ) : (
                          ""
                        )}
                      </span>
                    </div>
                  </div>
                </div>
              </Link>
            </span>
          </div>
        )} */}

        <div className="col-sm-2">
          <span className="card">
            <Link
              to={`/ip-blacklist${vendorId ? '/vendor/' + vendorId : ''}`}
            >
              <div className="card w_card3" style={{ cursor: "pointer" }}>
                <div className="body">
                  <div className="text-center">
                    <i
                      className="fa fa-database"
                      style={{ color: "#B0F127" }}
                    ></i>
                    <h5 className="m-t-20 mb-0">
                      {summary?.blackListedIp || 0}
                    </h5>
                    <p className="text-muted1">Blacklisted IPs</p>
                  </div>
                </div>
                <div className="card-footer">
                  <div>
                    <span className="text-muted1">
                      {!(summaryLoading || loading) ? (
                        <span className="dashboardFirstHeading">
                          {summary?.blackListedIpNewAdded &&
                          summary?.blackListedIpNewAdded !== 0 ? (
                            <i
                              className={getArrow(
                                summary?.blackListedIpNewAdded
                              )}
                            ></i>
                          ) : (
                            ""
                          )}
                          {Math.abs(summary?.blackListedIpNewAdded || 0)} v/s
                          last scan
                        </span>
                      ) : (
                        ""
                      )}
                    </span>
                  </div>
                </div>
              </div>
            </Link>
          </span>
        </div>
      </div>
    </div>
  );
};
export default ASMDashboard;
