import React, { useEffect, useState } from "react";
import { Link  , useParams} from "react-router-dom";
import { toastr } from "react-redux-toastr";
import { useSelector, useDispatch } from "react-redux";
import {
  createIntegration,
  updateIntegrationByJira,
  getIntegration,
  updateIntegrationById,
  deleteIntegration,
  fetchProjectList,
  fetchJiraIssueTypes,
  createIntegrationjira,
} from "../../actions/integration";
import moment from "moment";
import { Button } from "react-bootstrap";
import AccessPage from "../Shared/accessPage";

const Integrations = () => {
  const dispatch = useDispatch();
  const [isModal, setModal] = useState("");
  const [logoutModal, setLogoutModal] = useState(false);
  const [integrationId, setIntegrationId] = useState("");
  const [logoutId, setLogoutId] = useState("");
  const [isEdit, setIsEdit] = useState(false);

  const [integrationName, setIntegrationName] = useState("");
  const [triggerFor, setTriggerFor] = useState("");
  const [triggerType, setTriggerType] = useState("email");
  const [emailAddress, setEmailAddress] = useState("");
  const [slackUrl, setSlackUrl] = useState("");
  const [teamUrl, setTeamUrl] = useState("");
  const [jiraHostName, setJiraHostName] = useState("");
  const [jiraEmail, setJiraEmail] = useState("");
  const [projectName, setProjectName] = useState("");
  const [jiraToken, setJiraToken] = useState("");
  const [projectKey, setProjectKey] = useState("");
  const [apiKey_secret, setApiKeysecret] = useState("");
  const [domain, setDomain] = useState("");
  const [jiraUserName, setJiraUserName] = useState("");
  const [jiraPorjectList, setJiraProjectList] = useState([]);
  const [jiraIssueTypes, setJiraIssueTypes] = useState([]);
  const [jiraProjectId, setJiraProjectId] = useState("");
  const [projectListFetching, setProjectListFeching] = useState(false);
  const [jiraProjectKey, setJiraProjectKey] = useState("");
  const [jiraParentId, setJiraParentId] = useState("");
  const [jiraIssueType, setJiraIssueType] = useState("");

  const [emailAddressVul, setEmailAddressVul] = useState("");
  const [slackUrlVul, setSlackUrlVul] = useState("");
  const [teamUrlVul, setTeamUrlVul] = useState("");
  const [jiraHostNameVul, setJiraHostNameVul] = useState("");
  const [jiraTokenVul, setJiraTokenVul] = useState("");
  const [jiraEmailVul, setJiraEmailVul] = useState("");
  const [jiraUserNameVul, setJiraUserNameVul] = useState("");
  const [jiraPorjectListVul, setJiraProjectListVul] = useState([]);
  const [jiraIssueTypesVul, setJiraIssueTypesVul] = useState([]);
  const [jiraProjectIdVul, setJiraProjectIdVul] = useState("");
  const [jiraProjectKeyVul, setJiraProjectKeyVul] = useState("");
  const [jiraParentIdVul, setJiraParentIdVul] = useState("");
  const [jiraIssueTypeVul, setJiraIssueTypeVul] = useState("");
  const [email_username, setemail_username] = useState("");
  const [value, setValue] = useState("");
  const [id, setId] = useState("");


  const [emailAddressCloud, setEmailAddressCloud] = useState("");
  const [slackUrlCloud, setSlackUrlCloud] = useState("");
  const [teamUrlCloud, setTeamUrlCloud] = useState("");
  const [jiraHostNameCloud, setJiraHostNameCloud] = useState("");
  const [jiraTokenCloud, setJiraTokenCloud] = useState("");
  const [jiraEmailCloud, setJiraEmailCloud] = useState("");
  const [jiraUserNameCloud, setJiraUserNameCloud] = useState("");
  const [jiraPorjectListCloud, setJiraProjectListCloud] = useState([]);
  const [jiraIssueTypesCloud, setJiraIssueTypesCloud] = useState([]);
  const [jiraProjectIdCloud, setJiraProjectIdCloud] = useState("");
  const [jiraProjectKeyCloud, setJiraProjectKeyCloud] = useState("");
  const [jiraParentIdCloud, setJiraParentIdCloud] = useState("");
  const [jiraIssueTypeCloud, setJiraIssueTypeCloud] = useState("");
  const { orgId } = useParams();
  const routeId = useSelector(state => {
    if (orgId) {
        return orgId;
    } else {
        return state?.summary?.localClient?._id || state?.summary?.clients?.[0]?._id;
    }
});  

  const results = useSelector((state) => state?.integration?.response);
  useEffect(() => {
    getIntegration(routeId, dispatch);
  }, [routeId]);
  const handleSubmit = () => {
    if (integrationName == "") {
      toastr.error("Error", "Please enter integration name.");
      return false;
    }
    // if (triggerFor == "") {
    //   toastr.error("Error", "Please select trigger for.");
    //   return false;
    // }
    if (triggerType == "email") {
      if (emailAddress == "") {
        toastr.error("Error", "Please enter threat management email address.");
        return false;
      }
      // if (emailAddressVul == "") {
      //   toastr.error("Error", "Please enter vulnerability email address.");
      //   return false;
      // }
      // if (emailAddressCloud == "") {
      //   toastr.error("Error", "Please enter cloud security email address.");
      //   return false;
      // }
    } else if (triggerType == "slack") {
      if (slackUrl == "") {
        toastr.error("Error", "Please enter threat management slack url.");
        return false;
      }
      if (slackUrlVul == "") {
        toastr.error("Error", "Please enter vulnerability slack url.");
        return false;
      }
      if (slackUrlCloud == "") {
        toastr.error("Error", "Please enter cloud security slack url.");
        return false;
      }
    } else if (triggerType == "msteams") {
      if (teamUrl == "") {
        toastr.error("Error", "Please enter threat management team url.");
        return false;
      }
      if (teamUrlVul == "") {
        toastr.error("Error", "Please enter vulnerability team url.");
        return false;
      }
      if (teamUrlCloud == "") {
        toastr.error("Error", "Please enter cloud security team url.");
        return false;
      }
    } else if (triggerType == "jira") {
      if (email_username == "") {
        toastr.error("Error", "Please enter user email.");
        return false;
      }
      if (projectKey == "") {
        toastr.error("Error", "Please enter project key.");
        return false;
      }
      if (apiKey_secret == "") {
        toastr.error("Error", "Please enter api secret key.");
        return false;
      }
      if (projectName == "") {
        toastr.error("Error", "Please enter project name.");
        return false;
      }
      if (domain == "") {
        toastr.error("Error", "Please enter domain name.");
        return false;
      }
      // if (jiraToken == "") {
      //   toastr.error("Error", "Please enter threat management token.");
      //   return false;
      // }
      // if (jiraEmail == "") {
      //   toastr.error("Error", "Please enter threat management email.");
      //   return false;
      // }
      //      if (jiraTokenVul == "") {
      //        toastr.error("Error", "Please enter vulnerability token.");
      //        return false;
      //      }
      //      if (jiraEmailVul == "") {
      //        toastr.error("Error", "Please enter vulnerability email.");
      //        return false;
      //      }
      //      if (jiraTokenCloud == "") {
      //        toastr.error("Error", "Please enter cloud security token.");
      //        return false;
      //      }
      //      if (jiraEmailCloud == "") {
      //        toastr.error("Error", "Please enter cloud security email.");
      //        return false;
      //      }
      // if (jiraUserName == "") {
      //   toastr.error("Error", "Please enter threat management user name.");
      //   return false;
      // }
      //      if (jiraUserNameVul == "") {
      //        toastr.error("Error", "Please enter vulnerability user name.");
      //        return false;
      //      }
      //      if (jiraUserNameCloud == "") {
      //        toastr.error("Error", "Please enter cloud security user name.");
      //        return false;
      //      }
      // if (jiraProjectId == "") {
      //   toastr.error("Error", "Please select threat management project.");
      //   return false;
      // }
      //      if (jiraProjectIdVul == "") {
      //        toastr.error("Error", "Please select vulnerability project.");
      //        return false;
      //      }
      //      if (jiraProjectIdCloud == "") {
      //        toastr.error("Error", "Please select cloud security project.");
      //        return false;
      //      }

      // if (jiraIssueType == "") {
      //   toastr.error("Error", "Please select threat management issue type.");
      //   return false;
      // }
      //      if (jiraIssueTypeVul == "") {
      //        toastr.error("Error", "Please select vulnerability issue type.");
      //        return false;
      //      }
      //      if (jiraIssueTypeCloud == "") {
      //        toastr.error("Error", "Please select cloud security issue type.");
      //        return false;
      //      }
    }

    let obj = {
      integrationName: integrationName,
      integrationType: triggerType,
      organisationId: routeId,
      triggerFor: {},
    };
    if (triggerType == "email") {
      let ThreatManagement = {
        email: emailAddress,
      };
      let VulnerabiltyManagement = {
        email: emailAddressVul,
      };
      let CloudSecurity = {
        email: emailAddressCloud,
      };
      obj.triggerFor.ThreatManagement = ThreatManagement;
      obj.triggerFor.VulnerabiltyManagement = VulnerabiltyManagement;
      obj.triggerFor.CloudSecurity = CloudSecurity;
    } else if (triggerType == "slack") {
      let ThreatManagement = {
        webHookUrl: slackUrl,
      };
      let VulnerabiltyManagement = {
        webHookUrl: slackUrlVul,
      };
      let CloudSecurity = {
        webHookUrl: slackUrlCloud,
      };

      obj.triggerFor.ThreatManagement = ThreatManagement;
      obj.triggerFor.VulnerabiltyManagement = VulnerabiltyManagement;
      obj.triggerFor.CloudSecurity = CloudSecurity;
    } else if (triggerType == "msteams") {
      let ThreatManagement = {
        teamsWebHookUrl: teamUrl,
      };
      let VulnerabiltyManagement = {
        teamsWebHookUrl: teamUrlVul,
      };
      let CloudSecurity = {
        teamsWebHookUrl: teamUrlCloud,
      };

      obj.triggerFor.ThreatManagement = ThreatManagement;
      obj.triggerFor.VulnerabiltyManagement = VulnerabiltyManagement;
      obj.triggerFor.CloudSecurity = CloudSecurity;
    } else if (triggerType == "jira") {
      let ThreatManagement = {
        jiraEmail: jiraEmail,
        jiraToken: jiraToken,
        jiraUsername: jiraUserName,
        jiraProjectId: jiraProjectId,
        jiraIssueType: jiraIssueType,
      };
      let VulnerabiltyManagement = {
        jiraToken: jiraTokenVul,
        jiraEmail: jiraEmailVul,
        jiraUsername: jiraUserNameVul,
        jiraProjectId: jiraProjectIdVul,
        jiraIssueType: jiraIssueTypeVul,
      };
      let CloudSecurity = {
        jiraToken: jiraTokenCloud,
        jiraEmail: jiraEmailCloud,
        jiraUsername: jiraUserNameCloud,
        jiraProjectId: jiraProjectIdCloud,
        jiraIssueType: jiraIssueTypeCloud,
      };

      obj.triggerFor.ThreatManagement = ThreatManagement;
      obj.triggerFor.VulnerabiltyManagement = VulnerabiltyManagement;
      obj.triggerFor.CloudSecurity = CloudSecurity;
    }

    if (isEdit) {
      if (triggerType == "jira") {
        let data = {
          integrationType: triggerType,
          organisationId: routeId,
          email_username: email_username,
          projectKey: projectKey,
          apiKey_secret: apiKey_secret,
          projectName: projectName,
          domain: domain,
          integrationName:integrationName,
          customField:value ? value :null
        };
        updateIntegrationByJira(data, dispatch);
      } else {
        let data = {
          integrationName: integrationName,
          integrationType: triggerType,
          integrationEmail:emailAddress,
          organisationId: routeId
        };
        updateIntegrationByJira(data, dispatch);
      }
    } else {
      if (triggerType == "jira") {
        let data = {
          integrationType: triggerType,
          organisationId: routeId,
          email_username: email_username,
          projectKey: projectKey,
          apiKey_secret: apiKey_secret,
          projectName: projectName,
          domain: domain,
          integrationName:integrationName,
          customField:value ? value :null
        };
        createIntegrationjira(data, dispatch);
      } else {
        let data = {
          integrationName: integrationName,
          integrationType: triggerType,
          integrationEmail:emailAddress,
          organisationId: routeId
        };
        createIntegration(data, dispatch);
      }
    }

    setIntegrationName("");
    setTriggerFor("");
    setTriggerType("email");
    setEmailAddress("");
    setSlackUrl("");
    setTeamUrl("");
    setJiraHostName("");
    setJiraToken("");
    setJiraEmail("");
    setJiraUserName("");
    setJiraProjectList([]);
    setJiraProjectListVul([]);
    setJiraProjectListCloud([]);
    setJiraProjectId("");
    setJiraProjectKey("");
    setJiraParentId("");
    setJiraIssueType("");
    setProjectKey("");
    setApiKeysecret("");
    setProjectName("");
    setDomain("");
    setemail_username("");

    setEmailAddressVul("");
    setSlackUrlVul("");
    setTeamUrlVul("");
    setJiraHostNameVul("");
    setJiraTokenVul("");
    setJiraEmailVul("");
    setJiraUserNameVul("");
    setJiraProjectKeyVul("");
    setJiraParentIdVul("");
    setJiraIssueTypeVul("");

    setEmailAddressCloud("");
    setSlackUrlCloud("");
    setTeamUrlCloud("");
    setJiraHostNameCloud("");
    setJiraTokenCloud("");
    setJiraEmailCloud("");
    setJiraUserNameCloud("");
    setJiraProjectKeyCloud("");
    setJiraParentIdCloud("");
    setJiraIssueTypeCloud("");
    setModal("");
    setValue("")
  };

  const editModal = (row) => {
    console.log(row,'rowwwwww')
    setIsEdit(true);
    setIntegrationId(row?._id);
    setIntegrationName(row?.integrationName);
    setTriggerType(row?.integrationType);

    setEmailAddress(row?.triggerFor?.ThreatManagement?.email);
    setSlackUrl(row?.triggerFor?.ThreatManagement?.webHookUrl);
    setTeamUrl(row?.triggerFor?.ThreatManagement?.teamsWebHookUrl);
    setJiraHostName(row?.triggerFor?.ThreatManagement?.jiraHostName);
    setJiraToken(row?.triggerFor?.ThreatManagement?.jiraToken);
    setJiraEmail(row?.triggerFor?.ThreatManagement?.jiraEmail);
    setJiraUserName(row?.triggerFor?.ThreatManagement?.jiraUsername);
    setJiraProjectKey(row?.triggerFor?.ThreatManagement?.jiraProjectkey);
    setJiraParentId(row?.triggerFor?.ThreatManagement?.jiraParentId);
    setJiraIssueType(row?.triggerFor?.ThreatManagement?.jiraIssueType);

    setEmailAddressVul(row?.triggerFor?.VulnerabiltyManagement?.email);
    setSlackUrlVul(row?.triggerFor?.VulnerabiltyManagement?.webHookUrl);
    setTeamUrlVul(row?.triggerFor?.VulnerabiltyManagement?.teamsWebHookUrl);
    setJiraHostNameVul(row?.triggerFor?.VulnerabiltyManagement?.jiraHostName);
    setJiraTokenVul(row?.triggerFor?.VulnerabiltyManagement?.jiraToken);
    setJiraEmailVul(row?.triggerFor?.VulnerabiltyManagement?.jiraEmail);
    setJiraUserNameVul(row?.triggerFor?.VulnerabiltyManagement?.jiraUsername);

    setJiraProjectKeyVul(
      row?.triggerFor?.VulnerabiltyManagement?.jiraProjectkey
    );
    setJiraParentIdVul(row?.triggerFor?.VulnerabiltyManagement?.jiraParentId);
    setJiraIssueTypeVul(row?.triggerFor?.VulnerabiltyManagement?.jiraIssueType);
    setEmailAddressCloud(row?.triggerFor?.CloudSecurity?.email);
    setSlackUrlCloud(row?.triggerFor?.CloudSecurity?.webHookUrl);
    setTeamUrlCloud(row?.triggerFor?.CloudSecurity?.teamsWebHookUrl);
    setJiraHostNameCloud(row?.triggerFor?.CloudSecurity?.jiraHostName);
    setJiraTokenCloud(row?.triggerFor?.CloudSecurity?.jiraToken);
    setJiraEmailCloud(row?.triggerFor?.CloudSecurity?.jiraEmail);
    setJiraUserNameCloud(row?.triggerFor?.CloudSecurity?.jiraUsername);
    setJiraProjectKeyCloud(row?.triggerFor?.CloudSecurity?.jiraProjectkey);
    setJiraParentIdCloud(row?.triggerFor?.CloudSecurity?.jiraParentId);
    setJiraIssueTypeCloud(row?.triggerFor?.CloudSecurity?.jiraIssueType);
    setProjectKey(row?.projectKey);
    setApiKeysecret(row?.apiKey_secret);
    setProjectName(row?.projectName);
    setDomain(row?.domain);
    setTriggerType(row?.integrationType)
    setemail_username(row?.email_username);
    setModal("Create Integration");
    setIntegrationName(row?.integrationName);
  };

  const reset = () => {
    setIsEdit(false);
    setIntegrationName("");
    setTriggerFor("");
    setTriggerType("email");
    setEmailAddress("");
    setSlackUrl("");
    setTeamUrl("");
    setJiraHostName("");
    setJiraToken("");
    setJiraEmail("");
    setJiraUserName("");
    setJiraProjectList([]);
    setJiraProjectListVul([]);
    setJiraProjectListCloud([]);
    setJiraProjectId("");
    setJiraProjectKey("");
    setJiraParentId("");
    setJiraIssueType("");
    setProjectKey("");
    setApiKeysecret("");
    setProjectName("");
    setDomain("");
    setemail_username("");
    setEmailAddressVul("");
    setSlackUrlVul("");
    setTeamUrlVul("");
    setJiraHostNameVul("");
    setJiraTokenVul("");
    setJiraEmailVul("");
    setJiraUserNameVul("");
    setJiraProjectKeyVul("");
    setJiraParentIdVul("");
    setJiraIssueTypeVul("");
    setEmailAddressCloud("");
    setSlackUrlCloud("");
    setTeamUrlCloud("");
    setJiraHostNameCloud("");
    setJiraTokenCloud("");
    setJiraEmailCloud("");
    setJiraUserNameCloud("");
    setJiraProjectKeyCloud("");
    setJiraParentIdCloud("");
    setJiraIssueTypeCloud("");
    setValue("")
  };

  const fetchManagementProjectList = async (e) => {
    if (jiraToken == "") {
      toastr.error("Error", "Please enter threat management token.");
      return false;
    }
    if (jiraEmail == "") {
      toastr.error("Error", "Please enter threat management email.");
      return false;
    }

    if (jiraUserName == "") {
      toastr.error("Error", "Please enter threat management username.");
      return false;
    }

    try {
      setProjectListFeching(true);
      const resp = await fetchProjectList({
        jiraEmail: jiraEmail,
        jiraUsername: jiraUserName,
        jiraToken: jiraToken,
      });
      setProjectListFeching(false);
      setJiraProjectList(resp.data);
    } catch (err) {
      setProjectListFeching(false);
    }
  };

  const fetchVulProjectList = async (e) => {
    if (jiraTokenVul == "") {
      toastr.error("Error", "Please enter vulnerability management token.");
      return false;
    }
    if (jiraEmailVul == "") {
      toastr.error("Error", "Please enter vulnerability management email.");
      return false;
    }

    if (jiraUserNameVul == "") {
      toastr.error("Error", "Please enter vulnerability management username.");
      return false;
    }

    try {
      setProjectListFeching(true);
      const resp = await fetchProjectList({
        jiraEmail: jiraEmailVul,
        jiraUsername: jiraUserNameVul,
        jiraToken: jiraTokenVul,
      });
      setProjectListFeching(false);
      setJiraProjectListVul(resp.data);
    } catch (err) {
      setProjectListFeching(false);
    }
  };

  const fetchCloudProjectList = async (e) => {
    if (jiraTokenCloud == "") {
      toastr.error("Error", "Please enter cloud security token.");
      return false;
    }
    if (jiraEmailCloud == "") {
      toastr.error("Error", "Please enter cloud security email.");
      return false;
    }

    if (jiraUserNameCloud == "") {
      toastr.error("Error", "Please enter cloud security username.");
      return false;
    }

    try {
      setProjectListFeching(true);
      const resp = await fetchProjectList({
        jiraEmail: jiraEmailCloud,
        jiraUsername: jiraUserNameCloud,
        jiraToken: jiraTokenCloud,
      });
      setProjectListFeching(false);
      setJiraProjectListCloud(resp.data);
    } catch (err) {
      setProjectListFeching(false);
    }
  };

  const selectJiraProject = async (e) => {
    try {
      setJiraProjectId(e.target.value);
      const resp = await fetchJiraIssueTypes({
        jiraEmail: jiraEmail,
        jiraUsername: jiraUserName,
        jiraToken: jiraToken,
        jiraProjectId: e.target.value,
      });

      setJiraIssueTypes(resp.data);
    } catch (err) {
      console.error(err);
    }
  };

  const selectJiraProjectVul = async (e) => {
    try {
      setJiraProjectIdVul(e.target.value);
      const resp = await fetchJiraIssueTypes({
        jiraEmail: jiraEmailVul,
        jiraUsername: jiraUserNameVul,
        jiraToken: jiraTokenVul,
        jiraProjectId: e.target.value,
      });

      setJiraIssueTypesVul(resp.data);
    } catch (err) {
      console.error(err);
    }
  };

  const selectJiraProjectCloud = async (e) => {
    try {
      setJiraProjectIdCloud(e.target.value);
      const resp = await fetchJiraIssueTypes({
        jiraEmail: jiraEmailCloud,
        jiraUsername: jiraUserNameCloud,
        jiraToken: jiraTokenCloud,
        jiraProjectId: e.target.value,
      });

      setJiraIssueTypesCloud(resp.data);
    } catch (err) {
      console.error(err);
    }
  };

  return (
    <>
      <div className="container-fluid">
        <AccessPage></AccessPage>
        <div className="block-header">
          <div className="row clearfix">
            <div className="col-md-6 col-sm-12">
              <h2 style={{ color: "white" }}>Integration Details</h2>
              <nav aria-label="breadcrumb">
                <ol className="breadcrumb">
                  <li className="breadcrumb-item">
                    <Link to="/">Home</Link>
                  </li>
                  <li
                    className="breadcrumb-item active"
                    aria-current="page"
                    style={{ color: "white" }}
                  >
                    Details
                  </li>
                </ol>
              </nav>
            </div>
            <div className="col-lg-2 col-md-4 col-sm-6"></div>
            <div className="col-lg-2 col-md-4 col-sm-6"></div>

            <div className="col-lg-2 col-md-4 col-sm-6">
              <button
                type="button"
                className="btn btn-round btn-info"
                onClick={() => {
                  reset();
                  setModal("Create Integration");
                }}
              >
                Create Integration
              </button>
            </div>
          </div>
        </div>
        <div className="row clearfix">
          <div className="col-12">
            <div className="card">
              <div className="body">
                <div className="row">
                  <div className="col-lg-2 col-md-4 col-sm-6">
                    <div className="input-group">
                      <input
                        type="text"
                        className="form-control"
                        placeholder="Search"
                      />
                    </div>
                  </div>

                  <div className="col-lg-2 col-md-4 col-sm-6"></div>
                  <div className="col-lg-2 col-md-4 col-sm-6"></div>
                  <div className="col-lg-2 col-md-4 col-sm-6"></div>
                  <div className="col-lg-2 col-md-4 col-sm-6"></div>
                  <div className="col-lg-2 col-md-4 col-sm-6"></div>
                </div>
              </div>
            </div>
          </div>
          <div className="col-12">
            <div className="table-responsive">
              <table className="table table-custom spacing5 mb-0">
                <thead>
                  <tr>
                    <th>#</th>
                    <th>Name</th>
                    <th>Type</th>
                    <th>Added on</th>
                    <th>Actions</th>
                  </tr>
                </thead>
                <tbody>
                  {results?.map((row, index) => {
                    console.log(row, "rowwww");
                    return (
                      <tr key={index}>
                        <td>{index + 1}</td>
                        <td>{row?.integrationName}</td>
                        <td>{row?.integrationType}</td>
                        <td>
                          <span className="badge badge-success">
                            {moment(row?.createdAt).format("DD-MM-YYYY")}
                          </span>
                        </td>
                        <td>
                          <button
                            type="button"
                            className="btn btn-sm btn-default"
                            title="Edit"
                            data-toggle="tooltip"
                            data-placement="top"
                            onClick={() => editModal(row)}
                          >
                            <i className="fa fa-edit"></i>
                          </button>
                          <button
                            type="button"
                            className="btn btn-sm btn-default"
                            title="Delete"
                            data-toggle="tooltip"
                            data-placement="top"
                            onClick={() => {
                              setLogoutId(row?._id);
                              setLogoutModal(true);
                            }}
                          >
                            <i className="icon-trash"></i>
                          </button>
                        </td>
                      </tr>
                    );
                  })}
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
      <div
        className={`modal fade ${
          isModal === "Create Integration" ? "d-block show" : ""
        }`}
        // onClick={() => setModal("")}
      >
        <div
          className="modal-dialog modal-dialog-centered modal-lg"
          role="document"
        >
          <div className="modal-content">
            <div className="modal-header">
              <h5 className="modal-title" id="exampleModalCenterTitle">
                {isEdit ? "Update Integration" : "Create New Integration"}
              </h5>
              <button
                type="button"
                className="close"
                data-dismiss="modal"
                aria-label="Close"
                onClick={() => setModal("")}
              >
                <span aria-hidden="true">×</span>
              </button>
            </div>
            <div className="modal-body">
              <div className="col-lg-12 col-md-12">
                <div className="form-group">
                  <input
                    type="text"
                    className="form-control"
                    placeholder="Integration Name"
                    value={integrationName}
                    onChange={(e) => setIntegrationName(e.target.value)}
                  />
                </div>
              </div>

              <div className="col-lg-12 col-md-12">
                <div className="form-group">
                  <select
                    className="form-control"
                    value={triggerType}
                    onChange={(e) => setTriggerType(e.target.value)}
                  >
                    {/* <option value="">-- Subscription Level * --</option> */}
                    <option value="email">Email</option>
                    <option value="jira">Jira</option>
                  </select>
                </div>
              </div>
              {/* <div className="col-lg-12 col-md-12">
                <div className="form-group">
                  <select
                    className="form-control"
                    value={triggerFor}
                    onChange={(e) => setTriggerFor(e.target.value)}
                  >
                    <option value="">Trigger for</option>
                    <option value="all">All</option>
                    <option value="Threat Management">Threat Management</option>
                    <option value="Vulnerabilty Management">
                      Vulnerabilty Management
                    </option>
                    <option value="Cloud Security">Cloud Security</option>
                  </select>
                </div>
              </div> */}
              <div className="row clearfix">
                <div className="col-lg-12 col-md-12 col-sm-12">
                  <div className="card">
                    <div className="body">
                      <div id="wizard_horizontal">
                        <div className="row clearfix">
                          <div className="col-xl-12 col-lg-12 col-md-12">
                            {triggerType == "email" && (
                              <div className="col-lg-12 col-md-12">
                                <div className="form-group">
                                  <input
                                    type="text"
                                    className="form-control"
                                    placeholder="Email Address"
                                    value={emailAddress}
                                    onChange={(e) =>
                                      setEmailAddress(e.target.value)
                                    }
                                  />
                                </div>
                              </div>
                            )}
                            {triggerType == "slack" && (
                              <div className="col-lg-12 col-md-12">
                                <div className="form-group">
                                  <input
                                    type="text"
                                    className="form-control"
                                    placeholder="Slack Webhook URL"
                                    value={slackUrl}
                                    onChange={(e) =>
                                      setSlackUrl(e.target.value)
                                    }
                                  />
                                </div>
                              </div>
                            )}
                            {triggerType == "msteams" && (
                              <div className="col-lg-12 col-md-12">
                                <div className="form-group">
                                  <input
                                    type="text"
                                    className="form-control"
                                    placeholder="Teams Webhook URL  "
                                    value={teamUrl}
                                    onChange={(e) => setTeamUrl(e.target.value)}
                                  />
                                </div>
                              </div>
                            )}
                            {triggerType == "jira" && (
                              <>
                                <div className="col-lg-12 col-md-12">
                                  <div className="form-group">
                                    <input
                                      type="text"
                                      className="form-control"
                                      placeholder="Email username"
                                      value={email_username}
                                      onChange={(e) =>
                                        setemail_username(e.target.value)
                                      }
                                    />
                                  </div>
                                  <div className="form-group">
                                    <input
                                      type="text"
                                      className="form-control"
                                      placeholder="Project Name"
                                      value={projectName}
                                      onChange={(e) =>
                                        setProjectName(e.target.value)
                                      }
                                    />
                                  </div>
                                </div>
                                <div className="col-lg-12 col-md-12">
                                  <div className="form-group">
                                    <input
                                      type="text"
                                      className="form-control"
                                      placeholder="Project Key"
                                      value={projectKey}
                                      onChange={(e) =>
                                        setProjectKey(e.target.value)
                                      }
                                    />
                                  </div>
                                </div>
                                <div className="col-lg-12 col-md-12">
                                  <div className="form-group">
                                    <input
                                      type="password"
                                      className="form-control"
                                      placeholder="ApiKey secret"
                                      value={apiKey_secret}
                                      onChange={(e) =>
                                        setApiKeysecret(e.target.value)
                                      }
                                    />
                                  </div>
                                  <div className="form-group">
                                    <input
                                      type="text"
                                      className="form-control"
                                      placeholder="Domain"
                                      value={domain}
                                      onChange={(e) =>
                                        setDomain(e.target.value)
                                      }
                                    />
                                  </div>
                                  <div className="form-group">
                                    <input
                                      type="text"
                                      className="form-control"
                                      placeholder="Custom Field"
                                      value={value}
                                      onChange={(e) =>
                                        setValue(e.target.value)
                                      }
                                    />
                                  </div>
                                </div>
                              </>
                            )}
                          </div>
                        </div>
                        <div className="modal-footer">
                          <button
                            type="button"
                            className="btn btn-round btn-default"
                            data-dismiss="modal"
                            onClick={() => setModal("")}
                          >
                            Close
                          </button>
                          <button
                            type="button"
                            className="btn btn-round btn-primary"
                            onClick={handleSubmit}
                          >
                            {isEdit ? "Update changes" : "Save changes"}
                          </button>
                        </div>
                        {/* <Tabs
                          defaultActiveKey="First Step"
                          id="uncontrolled-tab-example"
                        > */}
                        {/* <Tab
                            eventKey="First Step"
                            title="Threat Management"
                            tabClassName="form_wizard_tab form_wizard_width1"
                          >
                            <div className="row clearfix">
                              <div className="col-xl-12 col-lg-12 col-md-12">
                                {triggerType == "email" && (
                                  <div className="col-lg-12 col-md-12">
                                    <div className="form-group">
                                      <input
                                        type="text"
                                        className="form-control"
                                        placeholder="Email Address"
                                        value={emailAddress}
                                        onChange={(e) =>
                                          setEmailAddress(e.target.value)
                                        }
                                      />
                                    </div>
                                  </div>
                                )}
                                {triggerType == "slack" && (
                                  <div className="col-lg-12 col-md-12">
                                    <div className="form-group">
                                      <input
                                        type="text"
                                        className="form-control"
                                        placeholder="Slack Webhook URL"
                                        value={slackUrl}
                                        onChange={(e) =>
                                          setSlackUrl(e.target.value)
                                        }
                                      />
                                    </div>
                                  </div>
                                )}
                                {triggerType == "msteams" && (
                                  <div className="col-lg-12 col-md-12">
                                    <div className="form-group">
                                      <input
                                        type="text"
                                        className="form-control"
                                        placeholder="Teams Webhook URL  "
                                        value={teamUrl}
                                        onChange={(e) =>
                                          setTeamUrl(e.target.value)
                                        }
                                      />
                                    </div>
                                  </div>
                                )}
                                {triggerType == "jira" && (
                                  <>
                                 
                                    <div className="col-lg-12 col-md-12">
                                      <div className="form-group">
                                        <input
                                          type="text"
                                          className="form-control"
                                          placeholder="Jira Email"
                                          value={jiraEmail}
                                          onChange={(e) =>
                                            setJiraEmail(e.target.value)
                                          }
                                        />
                                      </div>
                                    </div>
                                    <div className="col-lg-12 col-md-12">
                                      <div className="form-group">
                                        <input
                                          type="text"
                                          className="form-control"
                                          placeholder="Jira Token"
                                          value={jiraToken}
                                          onChange={(e) =>
                                            setJiraToken(e.target.value)
                                          }
                                        />
                                      </div>
                                    </div>
                                    <div className="col-lg-12 col-md-12">
                                      <div className="form-group">
                                        <input
                                          type="text"
                                          className="form-control"
                                          placeholder="Jira Username"
                                          value={jiraUserName}
                                          onChange={(e) =>
                                            setJiraUserName(e.target.value)
                                          }
                                        />
                                      </div>
                                    </div>
                                    {jiraPorjectList[0] && (
                                      <>
                                        <div className="col-lg-12 col-md-12">
                                          <div className="form-group">
                                          <select
                                            className="form-control"
                                            value={jiraProjectId}
                                            onChange={(e) => selectJiraProject(e)}
                                          >
                                            <option value="">-- Select Project * --</option>
                                            {jiraPorjectList.map((project)=>(
                                              <option value={project.id}>{project.name} - {project.key}</option>
                                            ))}
                                          </select>
                                          </div>
                                        </div>
                                        <div className="col-lg-12 col-md-12">
                                          <div className="form-group">
                                            <select
                                              className="form-control"
                                              value={jiraIssueType}
                                              onChange={(e) => setJiraIssueType(e.target.value)}
                                            >
                                              <option value="">-- Select Issue Type * --</option>
                                              {jiraIssueTypes.map((issue)=>(
                                                <option value={issue.id}>{issue.name}</option>
                                              ))}
                                            </select>
                                          </div>
                                        </div>
                                      </>
                                    )}
                                    {!jiraPorjectList[0] && <div className="col-lg-12 col-md-12">
                                      <div className="form-group">
                                        <Button
                                          type="button"
                                          primary
                                          disabled={projectListFetching}
                                          onClick={fetchManagementProjectList}
                                        >
                                          Next {projectListFetching && <i className="fa fa-spinner"></i>}
                                        </Button>
                                      </div>
                                    </div>}
                                  </>
                                )}
                              </div>
                            </div>
                          </Tab> */}
                        {/* <Tab
                            eventKey="Second Step"
                            title="Vulnerability Management"
                            tabClassName="form_wizard_tab form_wizard_width1"
                          >
                            <div className="row clearfix">
                              <div className="col-xl-12 col-lg-12 col-md-12">
                                {triggerType == "email" && (
                                  <div className="col-lg-12 col-md-12">
                                    <div className="form-group">
                                      <input
                                        type="text"
                                        className="form-control"
                                        placeholder="Email Address"
                                        value={emailAddressVul}
                                        onChange={(e) =>
                                          setEmailAddressVul(e.target.value)
                                        }
                                      />
                                    </div>
                                  </div>
                                )}
                                {triggerType == "slack" && (
                                  <div className="col-lg-12 col-md-12">
                                    <div className="form-group">
                                      <input
                                        type="text"
                                        className="form-control"
                                        placeholder="Slack Webhook URL"
                                        value={slackUrlVul}
                                        onChange={(e) =>
                                          setSlackUrlVul(e.target.value)
                                        }
                                      />
                                    </div>
                                  </div>
                                )}
                                {triggerType == "msteams" && (
                                  <div className="col-lg-12 col-md-12">
                                    <div className="form-group">
                                      <input
                                        type="text"
                                        className="form-control"
                                        placeholder="Teams Webhook URL  "
                                        value={teamUrlVul}
                                        onChange={(e) =>
                                          setTeamUrlVul(e.target.value)
                                        }
                                      />
                                    </div>
                                  </div>
                                )}
                                {triggerType == "jira" && (
                                  <>
                                 
                                  <div className="col-lg-12 col-md-12">
                                    <div className="form-group">
                                      <input
                                        type="text"
                                        className="form-control"
                                        placeholder="Jira Email"
                                        value={jiraEmailVul}
                                        onChange={(e) =>
                                          setJiraEmailVul(e.target.value)
                                        }
                                      />
                                    </div>
                                  </div>
                                  <div className="col-lg-12 col-md-12">
                                    <div className="form-group">
                                      <input
                                        type="text"
                                        className="form-control"
                                        placeholder="Jira Token"
                                        value={jiraTokenVul}
                                        onChange={(e) =>
                                          setJiraTokenVul(e.target.value)
                                        }
                                      />
                                    </div>
                                  </div>
                                  <div className="col-lg-12 col-md-12">
                                    <div className="form-group">
                                      <input
                                        type="text"
                                        className="form-control"
                                        placeholder="Jira Username"
                                        value={jiraUserNameVul}
                                        onChange={(e) =>
                                          setJiraUserNameVul(e.target.value)
                                        }
                                      />
                                    </div>
                                  </div>
                                  {jiraPorjectListVul[0] && (
                                    <>
                                      <div className="col-lg-12 col-md-12">
                                        <div className="form-group">
                                        <select
                                          className="form-control"
                                          value={jiraProjectIdVul}
                                          onChange={(e) => selectJiraProjectVul(e)}
                                        >
                                          <option value="">-- Select Project * --</option>
                                          {jiraPorjectListVul.map((project)=>(
                                            <option value={project.id}>{project.name} - {project.key}</option>
                                          ))}
                                        </select>
                                        </div>
                                      </div>
                                      <div className="col-lg-12 col-md-12">
                                        <div className="form-group">
                                          <select
                                            className="form-control"
                                            value={jiraIssueTypeVul}
                                            onChange={(e) => setJiraIssueTypeVul(e.target.value)}
                                          >
                                            <option value="">-- Select Issue Type * --</option>
                                            {jiraIssueTypesVul.map((issue)=>(
                                              <option value={issue.id}>{issue.name}</option>
                                            ))}
                                          </select>
                                        </div>
                                      </div>
                                    </>
                                  )}
                                  {!jiraPorjectListVul[0] && <div className="col-lg-12 col-md-12">
                                    <div className="form-group">
                                      <Button
                                        type="button"
                                        primary
                                        disabled={projectListFetching}
                                        onClick={fetchVulProjectList}
                                      >
                                        Next {projectListFetching && <i className="fa fa-spinner"></i>}
                                      </Button>
                                    </div>
                                  </div>}
                                </>
                                )}
                              </div>
                            </div>
                          </Tab>
                          <Tab
                            eventKey="Third Step"
                            title="Cloud Security"
                            tabClassName="form_wizard_tab form_wizard_width1"
                          >
                            <div className="row clearfix">
                              <div className="col-xl-12 col-lg-12 col-md-12">
                                {triggerType == "email" && (
                                  <div className="col-lg-12 col-md-12">
                                    <div className="form-group">
                                      <input
                                        type="text"
                                        className="form-control"
                                        placeholder="Email Address"
                                        value={emailAddressCloud}
                                        onChange={(e) =>
                                          setEmailAddressCloud(e.target.value)
                                        }
                                      />
                                    </div>
                                  </div>
                                )}
                                {triggerType == "slack" && (
                                  <div className="col-lg-12 col-md-12">
                                    <div className="form-group">
                                      <input
                                        type="text"
                                        className="form-control"
                                        placeholder="Slack Webhook URL"
                                        value={slackUrlCloud}
                                        onChange={(e) =>
                                          setSlackUrlCloud(e.target.value)
                                        }
                                      />
                                    </div>
                                  </div>
                                )}
                                {triggerType == "msteams" && (
                                  <div className="col-lg-12 col-md-12">
                                    <div className="form-group">
                                      <input
                                        type="text"
                                        className="form-control"
                                        placeholder="Teams Webhook URL  "
                                        value={teamUrlCloud}
                                        onChange={(e) =>
                                          setTeamUrlCloud(e.target.value)
                                        }
                                      />
                                    </div>
                                  </div>
                                )}
                                {triggerType == "jira" && (
                                     <>
                                 
                                     <div className="col-lg-12 col-md-12">
                                       <div className="form-group">
                                         <input
                                           type="text"
                                           className="form-control"
                                           placeholder="Jira Email"
                                           value={jiraEmailCloud}
                                           onChange={(e) =>
                                             setJiraEmailCloud(e.target.value)
                                           }
                                         />
                                       </div>
                                     </div>
                                     <div className="col-lg-12 col-md-12">
                                       <div className="form-group">
                                         <input
                                           type="text"
                                           className="form-control"
                                           placeholder="Jira Token"
                                           value={jiraTokenCloud}
                                           onChange={(e) =>
                                             setJiraTokenCloud(e.target.value)
                                           }
                                         />
                                       </div>
                                     </div>
                                     <div className="col-lg-12 col-md-12">
                                       <div className="form-group">
                                         <input
                                           type="text"
                                           className="form-control"
                                           placeholder="Jira Username"
                                           value={jiraUserNameCloud}
                                           onChange={(e) =>
                                             setJiraUserNameCloud(e.target.value)
                                           }
                                         />
                                       </div>
                                     </div>
                                     {jiraPorjectListCloud[0] && (
                                       <>
                                         <div className="col-lg-12 col-md-12">
                                           <div className="form-group">
                                           <select
                                             className="form-control"
                                             value={jiraProjectIdCloud}
                                             onChange={(e) => selectJiraProjectCloud(e)}
                                           >
                                             <option value="">-- Select Project * --</option>
                                             {jiraPorjectListCloud.map((project)=>(
                                               <option value={project.id}>{project.name} - {project.key}</option>
                                             ))}
                                           </select>
                                           </div>
                                         </div>
                                         <div className="col-lg-12 col-md-12">
                                           <div className="form-group">
                                             <select
                                               className="form-control"
                                               value={jiraIssueTypeCloud}
                                               onChange={(e) => setJiraIssueTypeCloud(e.target.value)}
                                             >
                                               <option value="">-- Select Issue Type * --</option>
                                               {jiraIssueTypesCloud.map((issue)=>(
                                                 <option value={issue.id}>{issue.name}</option>
                                               ))}
                                             </select>
                                           </div>
                                         </div>
                                       </>
                                     )}
                                     {!jiraPorjectListCloud[0] && <div className="col-lg-12 col-md-12">
                                       <div className="form-group">
                                         <Button
                                           type="button"
                                           primary
                                           disabled={projectListFetching}
                                           onClick={fetchCloudProjectList}
                                         >
                                           Next {projectListFetching && <i className="fa fa-spinner"></i>}
                                         </Button>
                                       </div>
                                     </div>}
                                   </>
                                )}
                              </div>
                            </div>
                          </Tab> */}
                        {/* </Tabs> */}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div
        className={`modal fade  ${logoutModal ? "d-block show" : ""}`}
        id="exampleModal"
        onClick={() => setLogoutModal(false)}
      >
        <div className="modal-dialog" role="document">
          <div className="modal-content">
            <div className="modal-header">
              <h5 className="modal-title" id="exampleModalLabel">
                Delete
              </h5>
              <button
                type="button"
                className="close"
                data-dismiss="modal"
                aria-label="Close"
              >
                <span aria-hidden="true">×</span>
              </button>
            </div>
            <div className="modal-body">
              <p>Are you sure you want to delete?</p>
            </div>
            <div className="modal-footer">
              <button
                type="button"
                className="btn btn-round btn-default"
                data-dismiss="modal"
                onClick={() => setLogoutModal(false)}
              >
                Cancel
              </button>
              <button
                type="button"
                className="btn btn-round btn-primary"
                onClick={() => {
                  deleteIntegration(logoutId, dispatch);
                  setLogoutModal(false);
                }}
              >
                Confirm
              </button>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
export default Integrations;
