import React, { useEffect, useState } from "react";
import { axios } from "axios";
import { useSelector, useDispatch } from "react-redux";
import { Link, useHistory, useParams } from "react-router-dom";
import { searchCVE } from "../../actions/cveInformation";
import { toastr } from "react-redux-toastr";
import Pagination from "../Pagination/Pagination";
import moment from "moment";

const CVEInformation = () => {
  const dispatch = useDispatch();
  const { data } = useParams();
  const [vendor, setVendor] = useState("");
  const [name, setName] = useState("");
  const [versionMajor, setVersionMajor] = useState("");
  const [versionMinor, setVersionMinor] = useState("");
  const [versionPatch, setVersionPatch] = useState("");
  const [page, setPage] = useState(1);
  const [limit, setLimit] = useState(10);
  const forestName = sessionStorage.getItem('vendorName');
  const vendorId = sessionStorage.getItem('vendorId');
  const { vendorName, productName } = Object.fromEntries(
    new URLSearchParams(data)
  );
  let send = "";
  const cveFunction = () => {
    setPage(1)
    searchCVE(
      dispatch,
      (send = {
        vendor: vendor,
        name: name,
        versionMajor: versionMajor,
        versionMinor: versionMinor,
        versionPatch: versionPatch,
      }),
      1,
      limit
    );
  };

  const cveData = useSelector((state) => state?.cveInformation?.response?.data);

  useEffect(() => {
    if (vendorName !== undefined && vendorName !== "") {
      setVendor(vendorName);
    }

    if (productName !== undefined && productName !== "") {
      setName(productName);
    }
    if (vendorName != undefined && productName != undefined) {
      searchCVE(
        dispatch,
        (send = {
          vendor: vendorName,
          name: productName,
          versionMajor: versionMajor,
          versionMinor: versionMinor,
          versionPatch: versionPatch,
        }),
        page,
        limit
      );
    }
  }, [vendorName, productName, page, limit]);

  return (
    <div className="container-fluid">
      <div className="block-header">
        <div className="row clearfix">
          <div className="col-md-6 col-sm-12">
            <h2 style={{ color: "white" }}>CVE Information</h2>
            <nav aria-label="breadcrumb">
              <ol className="breadcrumb">
                <li className="breadcrumb-item">
                  {vendorId ? <>
                  <Link to="/vendor"
                      onClick={(() => {
                        sessionStorage.clear();
                      })}
                    >Forest View</Link>
                  </> : <Link to="/">Home</Link>}
                </li>
                {
                  forestName ?
                    <li
                      className="breadcrumb-item active"
                      aria-current="page"
                      style={{ color: "white" }}
                    >
                      {forestName}
                    </li> : ''
                }
                <li
                  className="breadcrumb-item active"
                  aria-current="page"
                  style={{ color: "white" }}
                >
                  CVE Information
                </li>
              </ol>
            </nav>
          </div>
          {/* <div className="col-md-6 col-sm-12 text-right hidden-xs">
              <Link to="/" className="btn btn-sm btn-primary mr-1" title="">
                Create Campaign
              </Link>
              <Link
                to="https://themeforest.net/item/BrandSek-bootstrap-4x-admin-dashboard-clean-modern-ui-kit/23091507"
                className="btn btn-sm btn-success"
                title="Themeforest"
              >
                <i className="icon-basket"></i> Buy Now
              </Link>
            </div> */}
        </div>
      </div>
      <div className="row">
        <div className="col-lg-2 col-md-6">
          <div className="input-group">
            <input
              placeholder="Vendor Name"
              value={vendor}
              name="name"
              onChange={(e) => {
                setVendor(e.target.value);
              }}
              className="form-control"
            ></input>
          </div>
        </div>
        <div className="col-lg-2 col-md-6">
          <div className="input-group">
            <input
              placeholder="Product Name"
              value={name}
              name="name"
              onChange={(e) => {
                setName(e.target.value);
              }}
              className="form-control"
            ></input>
          </div>
        </div>
        <div className="col-lg-2 col-md-6">
          <div className="input-group">
            <input
              placeholder="Version Major"
              value={versionMajor}
              name="version-major"
              type="text"
              step={1}
              onChange={(e) => {
                setVersionMajor(Math.round(e.target.value));
              }}
              className="form-control"
            ></input>
          </div>
        </div>
        <div className="col-lg-2 col-md-6">
          <div className="input-group">
            <input
              value={versionMinor}
              name="version-minor"
              placeholder="version-minor"
              type="text"
              step={1}
              onChange={(e) => {
                setVersionMinor(Math.round(e.target.value));
              }}
              className="form-control"
            ></input>
          </div>
        </div>
        <div className="col-lg-2 col-md-6">
          <div className="input-group">
            <input
              placeholder="version-patch"
              value={versionPatch}
              name="version-patch"
              type="text"
              step={1}
              onChange={(e) => {
                setVersionPatch(Math.round(e.target.value));
              }}
              className="form-control"
            ></input>
          </div>
        </div>
        <div className="col-lg-2 col-md-6">
          <button
            onClick={cveFunction}
            type="button"
            style={{ fontWeight: "bold" }}
            className="btn btn-sm btn-success"
          >
            Search
          </button>
        </div>
      </div>
      {cveData && cveData.matchedCve.length ? (
        <div className="col-md-12">
          <div className="table-responsive">
            <table className="table table-custom spacing5 mb-0 mt-3">
              <thead>
                <tr>
                  <th>CVEs</th>
                  <th>Exploitability Score</th>
                  <th>Description</th>
                  <th>Published Date</th>
                </tr>
              </thead>
              <tbody>
                {cveData?.matchedCve?.map((cve, i) => {
                  return (
                    <tr>
                      <td>
                        <Link
                          to={`/cve/${cve?.cveId}`}
                          className="p-2 rounded-md bg-slate-200"
                          key={i}
                        >
                          {cve?.cveId}
                        </Link>
                      </td>
                      <td>{cve?.exploitabilityScore}</td>
                      <td>
                        <p style={{ maxWidth: "400px", textWrap: "balance" }}>
                          {cve?.description}
                        </p>
                      </td>
                      <td>
                        {cve?.publishedDate
                          ? moment(cve?.publishedDate).format(
                              "DD-MM-YYYY HH:MM"
                            )
                          : ""}
                      </td>
                    </tr>
                  );
                })}
                {/* <tr>
               <td>
                 <img
                   src="../assets/images/xs/avatar1.jpg"
                   alt="Avatar"
                   className="w30 rounded-circle mr-2"
                 />{" "}
                 <span>Isidore Dilao</span>
               </td>
               <td>
                 <h6 className="mb-0">account@something.com</h6>
               </td>
               <td>
                 <h6 className="mb-0">+1 (561) 289-0454</h6>
               </td>
               <td>
                 <h6 className="mb-0">Threat Analyst</h6>
               </td>
               <td>
                 <span className="badge badge-success">Completed</span>
               </td>
               <td>
                 <h6 className="mb-0">22/05/2022 14:14</h6>
               </td>
               <td>
                 <button className="btn btn-primary btn-sm mr-1">
                   Edit
                 </button>
                 <button className="btn btn-danger btn-sm">
                   Delete
                 </button>
               </td>
             </tr> */}
              </tbody>
            </table>
          </div>
          <div className="dataTables_paginate">
            {cveData?.totalPages > 0 && (
              <Pagination
                className="pagination-bar"
                currentPage={page}
                totalCount={cveData?.totalMatchedCves}
                pageSize={10}
                onPageChange={(page) => {
                  setPage(page);
                  searchCVE(
                    dispatch,
                    (send = {
                      vendor: vendor,
                      name: name,
                      versionMajor: versionMajor,
                      versionMinor: versionMinor,
                      versionPatch: versionPatch,
                    }),
                    page,
                    limit
                  );
                }}
              />
            )}
          </div>
        </div>
      ) : (
        <p className="text-center mt-5">No cves</p>
      )}
    </div>
  );
};
export default CVEInformation;
