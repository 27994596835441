import React, { useEffect, useState, useRef } from "react";
import MarketingChart from "../common/widgets/MarketingChart";
import { Link, useParams } from "react-router-dom";
import { fetchVendorSummary } from "../../actions/summary";
import { useSelector, useDispatch } from "react-redux";
import { toastr } from "react-redux-toastr";
import moment from "moment";
import * as FileSaver from "file-saver";
import * as XLSX from "xlsx";
import { uploadexcelforest ,updateCustomerforest,createCustomerforestView} from "../../actions/customer";
import DatePicker from "react-datepicker";
import { IMAGE_URL } from "../../utils/constant";
import $ from "jquery";


const Widgets = () => {
  const dispatch = useDispatch();
  const auth = useSelector((state) => state?.auth?.user)
  // const id = JSON.parse(localStorage.getItem("user")).organisationId;
  const [filterData, setfilterData] = useState("all");
  const { orgId } = useParams();
  const vendorName = sessionStorage.getItem('vendorName');
  const vendorId = sessionStorage.getItem('vendorId');
  const [isEdit, setIsEdit] = useState(false);
  const [isModal, setModal] = useState("");
  const [refreshModal, setRefreshModal] = useState(false);
  const [selectedOrgId, setSelectedOrgId] = useState("");
  const [services, setServices] = useState([]);
  const [updateId, setUpdateId] = useState("");
  var date = new Date();
  date = moment(date).add(1, "year").toDate();
  const [expiredAt, selectDate] = useState(date);
  const [status, setStatus] = useState("all");
  const [firstLoad, setFirstLoad] = useState(true);
  const [PageSize, setPerPage] = useState(10);
  const [totalItems, setTotalItems] = useState(0);
  const [search, setSearch] = useState("");
  const [sortField, setSortField] = useState("");
  const [sortOrder, setSortOrder] = useState("");
  const [scanType, setscanType] = useState("No");
  const [scanScheduleDay, setscanScheduleDay] = useState("Sunday");
  const [orgName, setOrgName] = useState("");
  const [orgEmail, setOrgEmail] = useState("");
  const [orgContactName, setOrgContactName] = useState("");
  const [orgContact, setOrgContact] = useState("");
  const [website, setWebsite] = useState("");
  const [emailAccess, setEmailAccess] = useState(false);
  const [clientType, setClientType] = useState("1");
  const [plan, setPlan] = useState("basic");
  const [industryType, setindustryType] = useState("Government");
  const [domains, setDomain] = useState([]);
  const [authMethod, setAuthMethod] = useState("default");
  const [serverType, setServerType] = useState("");
  const [loginUrl, setLoginUrl] = useState("");
  const [orgLogo, setOrgLogo] = useState("");
  const [subscriptionType, setSubscriptionType] = useState("");
  const [address, setAddress] = useState("");
  const [country, setCountry] = useState("");
  const [state, setState] = useState("");
  const [city, setCity] = useState("");
  const [loginPage, setLoginPage] = useState("");
  const [facebookUrl, setFacebookUrl] = useState("");
  const [twitterUrl, setTwitterUrl] = useState("");
  const [linkedinUrl, setLinkedinUrl] = useState("");
  const [instagramUrl, setInstagramUrl] = useState("");
  const [keyWord, setKeyword] = useState("");
  const isMSSP = JSON.parse(localStorage.getItem("user")).accessLevel === "MSSP";

 
  const id = useSelector(state => {
    if (orgId) {
      return orgId;
    } else {
      return state?.summary?.localClient?._id || state?.summary?.clients?.[0]?._id;
    }
  });
  useEffect(() => {
    fetchVendorSummary(id, dispatch);
  }, [id]);

  let summary = useSelector((state) => state?.summary?.vendorSummary);

  summary = summary?.sort((a, b) =>
    (moment(a?.createdOn).isBefore(moment(b?.createdOn))) ? 1 : (moment(b?.createdOn).isBefore(moment(a?.createdOn))) ? -1 : 0
    // a.dec > b.dec ? -1 : b.dec > a.dec ? 1 : 0
  )

  const vendorWithA1andA2 = summary?.filter((row) =>
    row?.rowStatus == "A1" ||
    row?.rowStatus == "A2"
  )

  const vendorWithB1andB2 = summary?.filter((row) =>
    row?.rowStatus == "B1" ||
    row?.rowStatus == "B2"
  )

  const vendorWithC1andC2 = summary?.filter((row) =>
    row?.rowStatus == "C1" ||
    row?.rowStatus == "C2"
  )

  const vendorWithA1 = summary?.filter((row) =>
    row?.rowStatus == "A1"
  )

  const vendorWithA2 = summary?.filter((row) =>
    row?.rowStatus == "A2"
  )

  const vendorWithB1 = summary?.filter((row) =>
    row?.rowStatus == "B1"
  )

  const vendorWithB2 = summary?.filter((row) =>
    row?.rowStatus == "B2"
  )

  const vendorWithC1 = summary?.filter((row) =>
    row?.rowStatus == "C1"
  )

  const vendorWithC2 = summary?.filter((row) =>
    row?.rowStatus == "C2"
  )

  const vulnerableA1andA2Percent = ((vendorWithA1andA2?.length / summary?.length) * 100)?.toFixed(0);
  const vulnerableB1andB2Percent = ((vendorWithB1andB2?.length / summary?.length) * 100)?.toFixed(0);
  const vulnerableC1andC2Percent = ((vendorWithC1andC2?.length / summary?.length) * 100)?.toFixed(0);


  let mapData =
    filterData === "all"
      ? summary
      : filterData === "a1"
        ? vendorWithA1
        : filterData === "a2"
          ? vendorWithA2
          : filterData === "b1"
            ? vendorWithB1
            : filterData === "b2"
              ? vendorWithB2
              : filterData === "c1"
                ? vendorWithC1
                : filterData === "c2"
                  ? vendorWithC2
                  : summary;

  const downloadCsvData = summary?.map((res) => ({
    Vendor: res?.vendor + " " + moment(res?.createdOn).format("DD-MMM-YYYY hh:mm a"),
    OverallRating: res?.rowStatus,
    VulnerableIPs: res?.vulnerableIp,
    BlacklistedIPs: res?.blacklistedIPValue,
    SPFRecord: res?.spfRating,
    DMARCRecord: res?.dmarcRating,
    ExpiredSSL: res?.expiredSSLRating,
    VulnerableSSL: res?.vulnerableSSLRating,
    DanglingSubdomain: res?.danglingRating,
    // LastUpdated: res?.updatedAt
    //   ? moment(res?.updatedAt).format("DD-MM-YYYY HH:MM")
    //   : res?.createdAt
    //     ? moment(res?.createdAt).format("DD-MM-YYYY HH:MM")
    //     : "NA",
  }));

  const exportToCSV = (csvData, fileName) => {
    const ws = XLSX.utils.json_to_sheet(csvData);
    const wb = { Sheets: { data: ws }, SheetNames: ["data"] };
    const excelBuffer = XLSX.write(wb, { bookType: "xlsx", type: "array" });
    const data = new Blob([excelBuffer], {
      type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8",
    });
    FileSaver.saveAs(data, fileName + ".xlsx");
  };

  const setBadge = (val) => {
    if (val == "Pending")
      return "font-12 mb-0 badge badge-info";
    else if (val == "A1" || val == "A2")
      return "font-12 mb-0 badge badge-success";
    else if (val == "B1" || val == "B2")
      return "font-12 mb-0 badge badge-warning";
    else if (val == "C1" || val == "C2")
      return "font-12 mb-0 badge badge-danger";
  }

  const addModal = () => {
    setIsEdit(false);
    setOrgName("");
    setOrgEmail("");
    setOrgContact("");
    setWebsite("");
    // setEmailAccess("")
    // setClientType("")
    // setSubscriptionType("")
    setDomain([]);
    setAuthMethod("");
    // setAssignTo("");
    setOrgLogo("");
    // setSubscriptionType("")
    setAddress("");
    setCountry("");
    setState("");
    setCity("");
    setFacebookUrl("");
    setTwitterUrl("");
    setLinkedinUrl("");
    setInstagramUrl("");
    setKeyword("");
    setOrgContactName("");
    setscanScheduleDay("Sunday");
    // setServices(['AttackSurface','DarkWeb','BrandSecurity','TPSA'])
    setServices(["AttackSurface", "DarkWeb", "BrandSecurity"]);
    setscanType("No");
    setModal("Form Wizard modal");
  };

  let clientTypeData = [];
  if (JSON.parse(localStorage.getItem("user")).accessLevel === "MSSP")
    clientTypeData = [{ id: 1, text: "Client" }];
  else
    clientTypeData = [
      { id: 1, text: "Client" },
      { id: 3, text: "MSSP" },
    ];

  let subscriptionTypeData = [];
  let subscriptionValueMSSP = [
    { val: "", text: "MSSP Subscription Type" },
    { val: "trial", text: "Trial" },
    { val: "regular", text: "Regular Client" },
  ];
  let subscriptionValueClient = [
    { val: "", text: "Subscription Type" },
    // {val: "trial", text: "Trial" },
    // {val: "one-time", text: "One time" },
    // {val: "gold", text: "Gold" },
    { val: "platinum", text: "Platinum" },
  ];
  if (JSON.parse(localStorage.getItem("user")).accessLevel === "MSSP") {
    if (
      JSON.parse(localStorage.getItem("user")).organisationId
        ?.subscriptionType == "trial"
    ) {
      subscriptionValueMSSP = [
        { val: "", text: "Subscription Type" },
        { val: "trial", text: "Trial" },
      ];
    } else {
      subscriptionValueMSSP = [
        { val: "", text: "Subscription Type" },
        // {val: "trial", text: "Trial" },
        // {val: "one-time", text: "One time" },
        // {val: "gold", text: "Gold" },
        { val: "platinum", text: "Platinum" },
      ];
    }
  }


  const [selectedFile, setSelectedFile] = useState(null);
  const [blank, setblank] = useState(null);

  const handleFileSelect = (event) => {
    console.log(event, 'event')
    setSelectedFile(event.target.files[0]);
  };

  const handleFileUpload = () => {
    if (selectedFile) {
      const formData = new FormData();
      formData.append("excelFile", selectedFile);
      uploadexcelforest(formData, id, dispatch)
      setblank('');
    } else {
      toastr.error("Error", 'No file selected.');
    }
  };

  const handleClick = (id) => {
    if (id == "keyword") {
      let val = $("#keyword").val();
      let _keyWord = keyWord || [];
      if (plan == "pro" && _keyWord.length >= 5) {
        toastr.error("Error", "Maximum keyword limit reached for this plan.");
        return false;
      }
      if (plan == "corporate" && _keyWord.length >= 50) {
        toastr.error("Error", "Maximum keyword limit reached for this plan.");
        return false;
      }
      if (_keyWord.indexOf(val) != -1) {
        toastr.error("Error", "This keyword is already added.");
        return false;
      }
      $("#keyword").val("");
      // _keyWord.push(val);
      setKeyword([..._keyWord, val]);
    } else if (id == "facebook") {
      let val = $("#facebookUrl").val();
      let _keyWord = facebookUrl || [];
      if (_keyWord.indexOf(val) != -1) {
        toastr.error("Error", "This keyword is already added.");
        return false;
      }
      $("#facebookUrl").val("");
      // _keyWord.push(val);
      setFacebookUrl([..._keyWord, val]);
    } else if (id == "linkedin") {
      let val = $("#liknedinUrl").val();
      let _keyWord = linkedinUrl || [];
      if (_keyWord.indexOf(val) != -1) {
        toastr.error("Error", "This keyword is already added.");
        return false;
      }
      $("#liknedinUrl").val("");
      // _keyWord.push(val);
      setLinkedinUrl([..._keyWord, val]);
    } else if (id == "twitter") {
      let val = $("#twitterUrl").val();
      let _keyWord = twitterUrl || [];
      if (_keyWord.indexOf(val) != -1) {
        toastr.error("Error", "This keyword is already added.");
        return false;
      }
      $("#twitterUrl").val("");
      // _keyWord.push(val);
      setTwitterUrl([..._keyWord, val]);
    } else if (id == "instagram") {
      let val = $("#instagramUrl").val();
      let _keyWord = instagramUrl || [];
      if (_keyWord.indexOf(val) != -1) {
        toastr.error("Error", "This keyword is already added.");
        return false;
      }
      $("#instagramUrl").val("");
      // _keyWord.push(val);
      setInstagramUrl([..._keyWord, val]);
    } else {
      let val = $("#domain").val();
      let _domains = domains || [];
      if ((plan == "basic" || plan == "pro") && _domains.length >= 1) {
        toastr.error("Error", "Maximum domain limit reached for this plan.");
        return false;
      }
      if (plan == "corporate" && _domains.length >= 3) {
        toastr.error("Error", "Maximum domain limit reached for this plan.");
        return false;
      }
      if (_domains.indexOf(val) != -1) {
        toastr.error("Error", "This domain is already added.");
        return false;
      }
      var re = new RegExp(
        /^((?:(?:(?:\w[\.\-\+]?)*)\w)+)((?:(?:(?:\w[\.\-\+]?){0,62})\w)+)\.(\w{2,6})$/
      );
      var valid = val.match(re);
      if (valid) {
        $("#domain").val("");
        // _domains.push(val);
        setDomain([..._domains, val]);
      } else {
        toastr.error("Error", "Please enter a valid domain.");
        return false;
      }
    }
  };

  const removeKeyword = (_id, _keyword) => {
    if (_id == "keyword") {
      let newkeyword = keyWord;
      newkeyword.splice(newkeyword.indexOf(_keyword), 1);
      setKeyword([...newkeyword]);
    } else if (_id == "facebook") {
      let newkeyword = facebookUrl;
      newkeyword.splice(newkeyword.indexOf(_keyword), 1);
      setFacebookUrl([...newkeyword]);
    } else if (_id == "linkedin") {
      let newkeyword = linkedinUrl;
      newkeyword.splice(newkeyword.indexOf(_keyword), 1);
      setLinkedinUrl([...newkeyword]);
    } else if (_id == "twitter") {
      let newkeyword = twitterUrl;
      newkeyword.splice(newkeyword.indexOf(_keyword), 1);
      setTwitterUrl([...newkeyword]);
    } else if (_id == "instagram") {
      let newkeyword = instagramUrl;
      newkeyword.splice(newkeyword.indexOf(_keyword), 1);
      setInstagramUrl([...newkeyword]);
    } else {
      let _domain = domains;
      _domain.splice(_domain.indexOf(_keyword), 1);
      setDomain([..._domain]);
    }
  };

  const removeOrgLogo = () => {
    setOrgLogo("");
  };

  const submitForm = () => {
    if (scanType !== "weekly") {
      setscanScheduleDay("");
    }

    if (orgName == "") {
      toastr.error("Error", "Please enter organisation name.");
      return false;
    }

    var regex =
      /https?:\/\/(www\.)?[-a-zA-Z0-9@:%._\+~#=]{1,256}\.[a-zA-Z0-9()]{1,6}\b([-a-zA-Z0-9()@:%_\+.~#?&//=]*)/;
    if (!regex.test(website)) {
      toastr.error("Error", "Website address is incorrect.");
      return false;
    }

    if (country == "") {
      toastr.error("Error", "Please select the country.");
      return false;
    }

    if (industryType == "") {
      toastr.error("Error", "Please select the industry type.");
      return false;
    }

    if (clientType == "") {
      toastr.error("Error", "Please select client type.");
      return false;
    }

    if (subscriptionType == "") {
      toastr.error("Error", "Please select subscription level.");
      return false;
    }

    if (keyWord == "") {
      toastr.error("Error", "Please add keywords.");
      return false;
    }

    if (isEdit) {
      updateCustomerforest(
        {
          orgName,
          website,
          plan,
          clientType,
          domains,
          subscriptionType,
          country,
          facebookUrl,
          twitterUrl,
          keyWord,
          expiredAt,
          industryType,
        },
        id,
        updateId,
        dispatch
      );
    } else {
      createCustomerforestView(
        {
          orgName,
          website,
          plan,
          clientType,
          domains,
          subscriptionType,
          country,
          facebookUrl,
          twitterUrl,
          keyWord,
          expiredAt,
          industryType,
        },
        id,
        auth,
        dispatch
      );
    }

    setModal("");
  };

  //console.log(summary, "summary", vendorWithA1andA2?.length, vendorWithB1andB2?.length, vendorWithC1andC2?.length);
  return (
    <div className="container-fluid">
      <div className="block-header">
        <div className="row clearfix">
          <div className="col-md-6 col-sm-12">
            <h1>Vendor Summary</h1>
            <nav aria-label="breadcrumb">
              <ol className="breadcrumb">
                <li className="breadcrumb-item">
                  {vendorId ? <>
                    <Link to="/vendor"
                      onClick={(() => {
                        sessionStorage.clear();
                      })}
                    >Forest View</Link>
                  </> : <Link to="/">Home</Link>}
                </li>
                {
                  vendorName ?
                    <li
                      className="breadcrumb-item active"
                      aria-current="page"
                      style={{ color: "white" }}
                    >
                      {vendorName}
                    </li> : ''
                }
                <li className="breadcrumb-item active" aria-current="page">
                  Summary
                </li>
              </ol>
            </nav>
          </div>
          <div className="col-md-6 col-sm-12 text-right hidden-xs">
            <span
              onClick={(e) => {
                e.preventDefault();
                exportToCSV(downloadCsvData, "Vendor Summary");
              }}
              // style={{ float: "right" }}
              // href="/add-report"
              className="btn btn-primary mr-1"
            >
              <i className="fa fa-file" style={{ color: "black" }}></i> <span style={{ color: "black", fontWeight: "bold" }}>Download Report</span>
            </span>
          </div>
        </div>
      </div>
      <div className="row clearfix">
        {/* <div className="col-lg-4 col-md-12">
          <div className="card">
            <div className="body">
              <div className="row text-center">
                <div className="col-lg-12 col-sm-12 border-right pb-4 pt-4">
                  <label className="mb-0">Total Vendors</label>
                  <h4 className="font-30 font-weight-bold text-col-blue">
                    {summary?.length || 0}
                  </h4>
                </div>
              </div>
              <hr />
              <div className="mb-4 mt-3">
                <label className="d-block">
                  Vendor with <strong>A (A1 & A2)</strong> rating.
                  <span className="float-right">{vulnerableA1andA2Percent || 0}%</span>
                </label>
                <div className="progress progress-xxs">
                  <div
                    className="progress-bar progress-bar-success"
                    role="progressbar"
                    aria-valuenow={vulnerableA1andA2Percent || 0}
                    aria-valuemin="0"
                    aria-valuemax="100"
                    style={{ width: vulnerableA1andA2Percent || 0 + "%" }}
                  ></div>
                </div>
              </div>

              <div className="mb-4">
                <label className="d-block">
                  Vendor with <strong>B (B1 & B2)</strong> rating.
                  <span className="float-right">{vulnerableB1andB2Percent || 0}%</span>
                </label>
                <div className="progress progress-xxs">
                  <div
                    className="progress-bar progress-bar-info"
                    role="progressbar"
                    aria-valuenow={vulnerableB1andB2Percent}
                    aria-valuemin="0"
                    aria-valuemax="100"
                    style={{ width: vulnerableB1andB2Percent || 0 + "%" }}
                  ></div>
                </div>
              </div>

              <div className="mb-4">
                <label className="d-block">
                  Vendor with <strong>C (C1 & C2)</strong> rating.
                  <span className="float-right">{vulnerableC1andC2Percent || 0}%</span>
                </label>
                <div className="progress progress-xxs">
                  <div
                    className="progress-bar progress-bar-danger"
                    role="progressbar"
                    aria-valuenow={vulnerableC1andC2Percent}
                    aria-valuemin="0"
                    aria-valuemax="100"
                    style={{ width: vulnerableC1andC2Percent || 0 + "%" }}
                  ></div>
                </div>
              </div>
            </div>
          </div>
        </div> */}
        {/* <div className="col-lg-8 col-md-12">
          <div className="card">
            <div className="body">
              <div className="header">
                <h2>Security ratings over time </h2>
              </div>
              <MarketingChart />
            </div>
          </div>
        </div> */}

        <div className="col-12">
          <div className="card">
            <div className="body">
              <div className="header">
                <div className="row">
                  <div className="col-sm-8">
                    <h2>Vendor wise exposure </h2>
                  </div>
                  <div className="col-sm-4">
                    <select
                      className="custom-select custom-select-sm form-control form-control-sm"
                      value={filterData}
                      onChange={(e) => setfilterData(e.target.value)}
                    >
                      <option value="all">All</option>
                      <option value="a1">A1</option>
                      <option value="a2">A2</option>
                      <option value="b1">B1</option>
                      <option value="b2">B2</option>
                      <option value="c1">C1</option>
                      <option value="c2">C2</option>
                    </select>
                  </div>
                </div>
                <div className="row mt-3">
                <div className="col-md-2 col-sm-12">
                  {auth?.accessLevel != "Threat Analyst" && (
                    <button
                      type="button"
                      className="btn btn-primary mr-1"
                      style={{ color: "black", fontWeight: "bold" }}
                      onClick={() => addModal()}
                    >
                      Add New
                    </button>
                  )}
                </div>
                <div className="d-flex justify-content-end col-md-10">
                  <input
                    type="file"
                    accept=".xls,.xlsx"
                    value={blank}
                    onChange={handleFileSelect}
                  />
                  <button
                    type="button"
                    className="btn btn-primary mr-1"
                    style={{ color: "black", fontWeight: "bold" }}
                    onClick={handleFileUpload}
                  >
                    Excel Upload
                  </button>
                </div>
                </div>
              </div>
              <div className="table-responsive">
                <table className="table table-custom spacing5 mb-0">
                  <thead>
                    <tr>
                      <th>#</th>
                      <th>
                        {" "}
                        <b>Vendor</b>
                      </th>{" "}
                      {/* <th>
                      Overall Rating
                    </th> */}
                      <th>
                        {" "}
                        <b>Vulnerable IPs</b>
                      </th>
                      <th>
                        {" "}
                        <b>Blacklisted IPs</b>
                      </th>
                      <th>
                        {" "}
                        <b>SPF Record</b>
                      </th>
                      <th>
                        {" "}
                        <b>DMARC Record</b>
                      </th>
                      <th>
                        {" "}
                        <b>Expired SSL</b>
                      </th>
                      <th>
                        {" "}
                        <b>Vulnerable SSL</b>
                      </th>
                      <th>
                        {" "}
                        <b>Dangling Subdomain</b>
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    {mapData?.map((row, index) => {
                      return (
                        <tr>
                          <td>
                            <strong>
                              <span>{index + 1}</span>
                            </strong>
                          </td>
                          <td>
                            <a href={`/vendorDetails/${row?._id}`}>{row?.vendor}</a>
                            <p style={{ color: "white" }}>
                              Added on{" "}
                              {moment(row?.createdOn).format(
                                "DD-MMM-YYYY hh:mm a"
                              )}
                            </p>
                          </td>
                          {/* <td className="text-right">
                          <h6 className={setBadge(row?.rowStatus)}>{row?.rowStatus}</h6>
                          <div className="d-flex bd-highlight mt-2">
                            
                              <div className="flex-fill bd-highlight">
                              <small> <br/>
                              </small>
                              </div>
                            </div>
                        </td> */}
                          <td className="text-medium">
                            <h6 className={setBadge(row?.vulnerableIp)}>{row?.vulnerableIp}</h6>
                            {row?.vulnerableIp == "Pending" && (
                              <div className="d-flex bd-highlight mt-2">
                                <div className="flex-fill bd-highlight">
                                  <small> <br />
                                  </small>
                                </div>
                              </div>
                            )}
                            {row?.vulnerableIp != "Pending" && (
                              <div className="d-flex bd-highlight mt-2">
                                <div className="flex-fill bd-highlight">
                                  <small style={{ color: "white" }}>
                                    {row?.totalVulnerableIp}/{row?.totalIp} found vulnearable
                                  </small>
                                </div>
                                {/* <div className="flex-fill bd-highlight">
                              <small>
                                {row?.highIp}/{row?.totalIp}
                              </small>
                            </div>
                            <div className="flex-fill bd-highlight">
                              <small>
                                {row?.mediumIp}/{row?.totalIp}
                              </small>
                            </div> */}
                              </div>
                            )}
                          </td>
                          <td className="text-medium">
                            <h6 className={setBadge(row?.blacklistedIPValue)}>
                              {row?.blacklistedIPValue}
                            </h6>
                            {row?.blacklistedIPValue == "Pending" && (
                              <div className="d-flex bd-highlight mt-2">
                                <div className="flex-fill bd-highlight">
                                  <small> <br />
                                  </small>
                                </div>
                              </div>
                            )}
                            {row?.blacklistedIPValue != "Pending" && (
                              <div className="d-flex bd-highlight mt-2">
                                <div className="flex-fill bd-highlight">
                                  <small>
                                    {row?.blacklistedIp}/{row?.totalBlackListedIp}{" "}
                                    Blacklisted IPs
                                  </small>
                                </div>
                              </div>
                            )}
                          </td>
                          <td className="text-medium">
                            <h6 className={setBadge(row?.spfRating)}>{row?.spfRating}</h6>
                            {row?.spfRating == "Pending" && (
                              <div className="d-flex bd-highlight mt-2">
                                <div className="flex-fill bd-highlight">
                                  <small> <br />
                                  </small>
                                </div>
                              </div>
                            )}
                            {row?.spfRating != "Pending" && (
                              <div className="d-flex bd-highlight mt-2">
                                <div className="flex-fill bd-highlight">
                                  <small>{row?.spfNumber}/1 compliant</small>
                                </div>
                              </div>
                            )}
                          </td>
                          <td className="text-medium">
                            <h6 className={setBadge(row?.dmarcRating)}>{row?.dmarcRating}</h6>
                            {row?.dmarcRating == "Pending" && (
                              <div className="d-flex bd-highlight mt-2">
                                <div className="flex-fill bd-highlight">
                                  <small> <br />
                                  </small>
                                </div>
                              </div>
                            )}
                            {row?.dmarcRating != "Pending" && (
                              <div className="d-flex bd-highlight mt-2">
                                <div className="flex-fill bd-highlight">
                                  <small>{row?.dmarcNumber}/1 compliant</small>
                                </div>
                              </div>
                            )}
                          </td>
                          <td className="text-medium">
                            <h6 className={setBadge(row?.expiredSSLRating)}>
                              {row?.expiredSSLRating}
                            </h6>
                            {row?.expiredSSLRating == "Pending" && (
                              <div className="d-flex bd-highlight mt-2">
                                <div className="flex-fill bd-highlight">
                                  <small> <br />
                                  </small>
                                </div>
                              </div>
                            )}
                            {row?.expiredSSLRating != "Pending" && (
                              <div className="d-flex bd-highlight mt-2">
                                <div className="flex-fill bd-highlight">
                                  <small>
                                    {row?.expiredSSL}/{row?.subDomain} expired
                                  </small>
                                </div>
                              </div>
                            )}
                          </td>
                          <td className="text-medium">
                            <h6 className={setBadge(row?.vulnerableSSLRating)}>
                              {row?.vulnerableSSLRating}
                            </h6>
                            {row?.vulnerableSSLRating == "Pending" && (
                              <div className="d-flex bd-highlight mt-2">
                                <div className="flex-fill bd-highlight">
                                  <small> <br />
                                  </small>
                                </div>
                              </div>
                            )}
                            {row?.vulnerableSSLRating != "Pending" && (
                              <div className="d-flex bd-highlight mt-2">
                                <div className="flex-fill bd-highlight">
                                  <small>
                                    {row?.vulnerabbleSSL}/{row?.subDomain}{" "}
                                    vulnerable
                                  </small>
                                </div>
                              </div>
                            )}
                          </td>
                          <td className="text-medium">
                            <h6 className={setBadge(row?.danglingRating)}>{row?.danglingRating}</h6>
                            {row?.danglingRating == "Pending" && (
                              <div className="d-flex bd-highlight mt-2">
                                <div className="flex-fill bd-highlight">
                                  <small> <br />
                                  </small>
                                </div>
                              </div>
                            )}
                            {row?.danglingRating != "Pending" && (
                              <div className="d-flex bd-highlight mt-2">
                                <div className="flex-fill bd-highlight">
                                  <small>
                                    {row?.danglingValue}/
                                    {row?.totalDanglingSubdomains}{" "}
                                  </small>
                                </div>
                              </div>
                            )}
                          </td>
                        </tr>
                      );
                    })}
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div
              className={`modal fade Form-Wizard-modal ${isModal === "Form Wizard modal" ? "d-block show" : ""
                }`}
            >
              <div className="modal-dialog modal-lg clientPageModal" id="clientModal">
                <div className="modal-content">
                  <div className="modal-header">
                    <h6>Add Forest View</h6>
                    <button
                      type="button"
                      data-dismiss="modal"
                      aria-label="Close"
                      onClick={() => setModal("")}
                    >
                      <span aria-hidden="true">×</span>
                    </button>
                  </div>
                  <div className="modal-body pricing_page">
                    <div className="col-xl-12 col-lg-12 col-md-12">
                      <div className="card">
                        <div className="header">
                          <h2 style={{ color: "white" }}>Basic Information</h2>
                        </div>
                        <div className="row clearfix">
                          <div className="col-lg-6 col-md-12">
                            <div className="form-group">
                              <input
                                type="text"
                                className="form-control"
                                placeholder="Client Name *"
                                value={orgName}
                                onChange={(e) => setOrgName(e.target.value)}
                              />
                            </div>
                          </div>
                          <div className="col-lg-6 col-md-12">
                            <div className="form-group">
                              <div className="input-group">
                                <div className="input-group-prepend">
                                  <span className="input-group-text">
                                    <i className="icon-globe"></i>
                                  </span>
                                </div>
                                <input
                                  type="text"
                                  className="form-control"
                                  placeholder="http://"
                                  value={website}
                                  onChange={(e) => setWebsite(e.target.value)}
                                />
                              </div>
                            </div>
                          </div>
                          {!isMSSP ? (
                            <div className="col-lg-6 col-md-12">
                              <div className="form-group">
                                <select
                                  className="form-control"
                                  value={clientType}
                                  onChange={(e) => setClientType(e.target.value)}
                                >
                                  {clientTypeData?.map((row) => {
                                    return <option value={row.id}>{row.text}</option>;
                                  })}
                                </select>
                              </div>
                            </div>
                          ) : null}
                          <div className="col-lg-6 col-md-12">
                            <select
                              className="form-control"
                              value={industryType}
                              onChange={(e) => {
                                setindustryType(e.target.value);
                              }}
                            >
                              <option value="Government">Government</option>
                              <option value="NGO">NGO</option>
                              <option value="Finance">Finance</option>
                              <option value="Technology">Technology</option>
                              <option value="Healthcare">Healthcare</option>
                              <option value="Education">Education</option>
                              <option value="Telecommunications">
                                Telecommunications
                              </option>
                              <option value="Manufacturing">Manufacturing</option>
                              <option value="Construction">Construction</option>
                              <option value="Energy">Energy</option>
                              <option value="Chemical">Chemical</option>
                              <option value="Media">Media</option>
                              <option value="Aerospace">Aerospace</option>
                              <option value="Transportation">Transportation</option>
                              <option value="Agriculture">Agriculture</option>
                              <option value="Retail">Retail</option>
                            </select>
                          </div>
                          <div className="col-lg-6 col-md-12 mb-3">
                            <select
                              className="form-control"
                              value={plan}
                              onChange={(e) => {
                                setPlan(e.target.value);
                                setDomain([]);
                              }}
                            >
                              <option value="basic">Basic plan</option>
                              <option value="pro">Pro plan</option>
                              <option value="corporate">Corporate plan</option>
                            </select>
                          </div>

                          <div className="col-lg-6 col-md-12">
                            <div className="form-group">
                              <select
                                className="form-control"
                                value={subscriptionType}
                                onChange={(e) => setSubscriptionType(e.target.value)}
                              >
                                {/* <option value="">-- Subscription Level * --</option> */}
                                {JSON.parse(localStorage.getItem("user"))
                                  .accessLevel == "MSSP"
                                  ? subscriptionValueMSSP?.map((val) => {
                                    return (
                                      <option value={val.val}>{val.text}</option>
                                    );
                                  })
                                  : clientType == 3
                                    ? subscriptionValueMSSP?.map((val) => {
                                      return (
                                        <option value={val.val}>{val.text}</option>
                                      );
                                    })
                                    : subscriptionValueClient?.map((val) => {
                                      return (
                                        <option value={val.val}>{val.text}</option>
                                      );
                                    })}
                              </select>
                            </div>
                          </div>

                          {clientType != 3 && (
                            <div className="col-lg-6 col-md-12 mb-3">
                              <div className="form-group">
                                <input
                                  type="text"
                                  className="form-control"
                                  placeholder="Domains"
                                  name="domain"
                                  id="domain"
                                  style={{ float: "left", width: "89%" }}
                                />
                                <button
                                  type="button"
                                  style={{ float: "left" }}
                                  className="btn btn-primary"
                                  onClick={() => handleClick("domain")}
                                >
                                  <i
                                    className="fa fa-plus"
                                    style={{ fontSize: "22px", color: "black" }}
                                  ></i>
                                </button>
                              </div>
                            </div>
                          )}

                          {domains && domains.length > 0 && (
                            <div className="col-lg-6 col-md-12">
                              <div className="row">
                                {domains?.map((keyword) => {
                                  return (
                                    <div className="col-md-4">
                                      <label
                                        style={{
                                          color: "white",
                                          background: "#43a5f5",
                                          padding: "4px 6px",
                                          margin: "0px 5px",
                                        }}
                                      >
                                        <span>
                                          {keyword}{" "}
                                          <span
                                            className="fa fa-times-circle"
                                            onClick={() =>
                                              removeKeyword("domain", keyword)
                                            }
                                          ></span>
                                        </span>
                                      </label>
                                    </div>
                                  );
                                })}
                              </div>
                            </div>
                          )}
                          {/* <div className="col-lg-6 col-md-12">
                        <div className="form-group">
                          <select
                            className="form-control"
                            value={authMethod}
                            onChange={(e) => setAuthMethod(e.target.value)}
                          >
                            <option value="default">Default</option>
                            <option value="microsoft">Microsoft</option>
                            <option value="google">Google</option>
                          </select>
                        </div>
                      </div> */}
                          {/* <div className="col-lg-6 col-md-12">
                        <div className="form-group">
                          <select
                            className="form-control"
                            value={assignTo}
                            onChange={(e) => setAssignTo(e.target.value)}
                          >
                            {threatAnalyist?.map((row) => {
                              return (
                                <option value={row?._id}>
                                  {row?.fullName}
                                </option>
                              );
                            })}
                            <option value="default">Analyst 1</option>
                            <option value="microsoft">Analyst 2</option>
                          </select>
                        </div>
                      </div> */}

                          {plan !== "basic" && (
                            <div className="col-lg-6 col-md-12">
                              <div className="form-group">
                                <input
                                  type="text"
                                  className="form-control"
                                  placeholder="Key Words"
                                  name="keyword"
                                  id="keyword"
                                  style={{ float: "left", width: "89%" }}
                                />
                                <button
                                  type="button"
                                  // style={{float:'left'}}
                                  className="btn btn-primary"
                                  onClick={() => handleClick("keyword")}
                                >
                                  <i
                                    className="fa fa-plus"
                                    style={{ fontSize: "22px", color: "black" }}
                                  ></i>
                                </button>
                              </div>
                            </div>
                          )}

                          {/* {!isMSSP ? (
                      <div className="col-lg-6 col-md-12">
                        <div className="custom-file">
                          <input
                            type="file"
                            className="custom-file-input"
                            id="inputGroupFile01"
                            onChange={handleLogoChange}
                          />
                          <label
                            className="custom-file-label"
                            htmlFor="inputGroupFile01"
                          >
                            Upload logo
                          </label>
                        </div>
                      </div>
                    ) : null} */}

                          {plan !== "basic" && (
                            <div className="col-lg-6 col-md-12">
                              {keyWord && keyWord.length > 0 && (
                                <div className="row">
                                  {keyWord.map((keyword) => {
                                    return (
                                      <div className="col-md-4">
                                        <label
                                          style={{
                                            color: "white",
                                            background: "#43a5f5",
                                            padding: "4px 6px",
                                          }}
                                        >
                                          <span>
                                            {keyword}{" "}
                                            <span
                                              className="fa fa-times-circle"
                                              onClick={() =>
                                                removeKeyword("keyword", keyword)
                                              }
                                            ></span>
                                          </span>
                                        </label>
                                      </div>
                                    );
                                  })}
                                </div>
                              )}
                            </div>
                          )}

                          <div className="col-lg-6 col-md-12">
                            {orgLogo && orgLogo != "" && (
                              <div className="col-lg-6 col-md-12">
                                <div className="row">
                                  <img
                                    style={{ width: "100px" }}
                                    src={`${IMAGE_URL}${orgLogo}`}
                                  />
                                  <span
                                    className="fa fa-times-circle"
                                    style={{
                                      position: "absolute",
                                      marginTop: "-8px",
                                      marginLeft: "-8px",
                                      color: "red",
                                    }}
                                    onClick={() => removeOrgLogo()}
                                  ></span>
                                </div>
                              </div>
                            )}
                          </div>

                          <hr />
                        </div>
                        <div style={{ marginTop: "20px" }}>
                          <h6>License Duration</h6>
                          &nbsp;&nbsp;
                          <div className="row clearfix">
                            <div className="col-lg-12 col-md-12">
                              <div className="form-group">
                                <div className="input-group">
                                  <div className="col-lg-12 col-md-12 clientCalendar">
                                    <div className="form-group">
                                      <DatePicker
                                        selected={expiredAt}
                                        minDate={new Date()}
                                        // showTimeSelect
                                        timeFormat="p"
                                        timeIntervals={1}
                                        onChange={(date) => selectDate(date)}
                                        className="form-control datetimepicker"
                                        dateFormat="MMMM d, yyyy h:mm aa"
                                      />
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>

                            <hr />
                          </div>
                        </div>
                        <div style={{ marginTop: "20px" }}>
                          <h6>Continuous Monitoring</h6>
                          &nbsp;&nbsp;
                        </div>
                        {/* <div className="header">
                      <h2>Email Server Information</h2>
                      &nbsp;&nbsp;
                      <div className="row clearfix">
                      <div className="col-lg-6 col-md-12">
                        <div className="form-group">
                          <select
                            className="form-control"
                            value={serverType}
                            onChange={(e) => setServerType(e.target.value)}
                          >
                            <option value="">--Server Type --</option>
                            <option value="google">Google</option>
                            <option value="microsoft">Microsoft</option>
                            <option value="other">others</option>
                          </select>
                        </div>
                      </div>

                      {serverType && serverType != "" && (
                        <div className="col-lg-6 col-md-12">
                            <div className="form-group">
                              <input
                                type="text"
                                className="form-control"
                                placeholder="Email Login Url"
                                value={loginUrl}
                                onChange={(e) => setLoginUrl(e.target.value)}
                              />
                            </div>
                        </div>
                      )}
                      </div>
                    </div> */}
                        <div>
                          <h6>Social Media Information (Optional)</h6>
                          &nbsp;&nbsp;
                          <div className="row clearfix">
                            <div className="col-lg-6 col-md-12">
                              <div className="form-group">
                                <div className="input-group">
                                  <div className="input-group-prepend">
                                    <span className="input-group-text">
                                      <i className="fa fa-facebook"></i>
                                    </span>
                                  </div>
                                  <input
                                    type="text"
                                    className="form-control"
                                    placeholder="http://"
                                    id="facebookUrl"
                                    // value={facebookUrl}
                                    style={{ float: "left", width: "86%" }}
                                  // onChange={(e) => setFacebookUrl(e.target.value)}
                                  />
                                  <button
                                    type="button"
                                    style={{ float: "left" }}
                                    className="btn btn-primary"
                                    onClick={() => handleClick("facebook")}
                                  >
                                    <i
                                      className="fa fa-plus"
                                      style={{ fontSize: "22px", color: "black" }}
                                    ></i>
                                  </button>
                                </div>
                              </div>
                            </div>
                            {/* <div className="col-lg-6 col-md-12">
                        <div className="form-group">
                          <div className="input-group">
                            <div className="input-group-prepend">
                              <span className="input-group-text">
                                <i className="fa fa-linkedin"></i>
                              </span>
                            </div>
                            <input
                              type="text"
                              className="form-control"
                              placeholder="http://"
                              id="liknedinUrl"
                              // value={linkedinUrl}
                              style={{ float: "left", width: "86%" }}
                              // onChange={(e) => setLinkedinUrl(e.target.value)}
                            />
                            <button
                              type="button"
                              style={{ float: "left" }}
                              className="btn btn-primary"
                              onClick={() => handleClick("linkedin")}
                            >
                              <i
                                className="fa fa-plus"
                                style={{ fontSize: "22px", color: "black" }}
                              ></i>
                            </button>
                          </div>
                        </div>
                      </div> */}

                            {facebookUrl && facebookUrl.length > 0 && (
                              <div className="col-lg-6 col-md-12">
                                <div className="row">
                                  {facebookUrl?.map((keyword) => {
                                    if (keyword == "") return;
                                    return (
                                      <div className="col-md-4">
                                        <label
                                          style={{
                                            color: "white",
                                            background: "#43a5f5",
                                            padding: "4px 6px",
                                          }}
                                        >
                                          <span>
                                            {keyword}{" "}
                                            <span
                                              className="fa fa-times-circle"
                                              onClick={() =>
                                                removeKeyword("facebook", keyword)
                                              }
                                            ></span>
                                          </span>
                                        </label>
                                      </div>
                                    );
                                  })}
                                </div>
                              </div>
                            )}

                            {/* {linkedinUrl && linkedinUrl.length > 0 && (
                        <div className="col-lg-6 col-md-12">
                          <div className="row">
                            {linkedinUrl?.map((keyword) => {
                              if (keyword == "") return;
                              return (
                                <div className="col-md-4">
                                  <label
                                    style={{
                                      color: "white",
                                      background: "#43a5f5",
                                      padding: "4px 6px",
                                    }}
                                  >
                                    <span>
                                      {keyword}{" "}
                                      <span
                                        className="fa fa-times-circle"
                                        onClick={() =>
                                          removeKeyword("linkedin", keyword)
                                        }
                                      ></span>
                                    </span>
                                  </label>
                                </div>
                              );
                            })}
                          </div>
                        </div>
                      )} */}

                            <div className="col-lg-6 col-md-12">
                              <div className="form-group">
                                <div className="input-group">
                                  <div className="input-group-prepend">
                                    <span className="input-group-text">
                                      <i className="fa fa-twitter"></i>
                                    </span>
                                  </div>
                                  <input
                                    type="text"
                                    className="form-control"
                                    placeholder="http://"
                                    id="twitterUrl"
                                    // value={twitterUrl}
                                    style={{ float: "left", width: "86%" }}
                                  // onChange={(e) => setTwitterUrl(e.target.value)}
                                  />
                                  <button
                                    type="button"
                                    style={{ float: "left" }}
                                    className="btn btn-primary"
                                    onClick={() => handleClick("twitter")}
                                  >
                                    <i
                                      className="fa fa-plus"
                                      style={{ fontSize: "22px", color: "black" }}
                                    ></i>
                                  </button>
                                </div>
                              </div>
                            </div>
                            {/* <div className="col-lg-6 col-md-12">
                        <div className="form-group">
                          <div className="input-group">
                            <div className="input-group-prepend">
                              <span className="input-group-text">
                                <i className="fa fa-instagram"></i>
                              </span>
                            </div>
                            <input
                              type="text"
                              className="form-control"
                              placeholder="http://"
                              id="instagramUrl"
                              // value={instagramUrl}
                              style={{ float: "left", width: "86%" }}
                              // onChange={(e) =>
                              //   setInstagramUrl(e.target.value)
                              // }
                            />
                            <button
                              type="button"
                              style={{ float: "left" }}
                              className="btn btn-primary"
                              onClick={() => handleClick("instagram")}
                            >
                              <i
                                className="fa fa-plus"
                                style={{ fontSize: "22px", color: "black" }}
                              ></i>
                            </button>
                          </div>
                        </div>
                      </div> */}

                            {twitterUrl && twitterUrl.length > 0 && (
                              <div className="col-lg-6 col-md-12">
                                <div className="row">
                                  {twitterUrl?.map((keyword) => {
                                    if (keyword == "") return;
                                    return (
                                      <div className="col-md-4">
                                        <label
                                          style={{
                                            color: "white",
                                            background: "#43a5f5",
                                            padding: "4px 6px",
                                          }}
                                        >
                                          <span>
                                            {keyword}{" "}
                                            <span
                                              className="fa fa-times-circle"
                                              onClick={() =>
                                                removeKeyword("twitter", keyword)
                                              }
                                            ></span>
                                          </span>
                                        </label>
                                      </div>
                                    );
                                  })}
                                </div>
                              </div>
                            )}

                            {/* {instagramUrl && instagramUrl.length > 0 && (
                        <div className="col-lg-6 col-md-12">
                          <div className="row">
                            {instagramUrl?.map((keyword) => {
                              if (keyword == "") return;
                              return (
                                <div className="col-md-4">
                                  <label
                                    style={{
                                      color: "white",
                                      background: "#43a5f5",
                                      padding: "4px 6px",
                                    }}
                                  >
                                    <span>
                                      {keyword}{" "}
                                      <span
                                        className="fa fa-times-circle"
                                        onClick={() =>
                                          removeKeyword("instagram", keyword)
                                        }
                                      ></span>
                                    </span>
                                  </label>
                                </div>
                              );
                            })}
                          </div>
                        </div>
                      )} */}

                            <hr />
                          </div>
                        </div>
                        <hr></hr>
                        <div>
                          <h6>Address information (Optional)</h6>
                          &nbsp;&nbsp;
                          <div className="row clearfix">
                            <div className="col-lg-4 col-md-12">
                              <div className="form-group">
                                <select
                                  className="form-control"
                                  value={country}
                                  onChange={(e) => setCountry(e.target.value)}
                                >
                                  <option value="">-- Select Country --</option>
                                  <option value="AF">Afghanistan</option>
                                  <option value="AX">Ã…land Islands</option>
                                  <option value="AL">Albania</option>
                                  <option value="DZ">Algeria</option>
                                  <option value="AS">American Samoa</option>
                                  <option value="AD">Andorra</option>
                                  <option value="AO">Angola</option>
                                  <option value="AI">Anguilla</option>
                                  <option value="AQ">Antarctica</option>
                                  <option value="AG">Antigua and Barbuda</option>
                                  <option value="AR">Argentina</option>
                                  <option value="AM">Armenia</option>
                                  <option value="AW">Aruba</option>
                                  <option value="AU">Australia</option>
                                  <option value="AT">Austria</option>
                                  <option value="AZ">Azerbaijan</option>
                                  <option value="BS">Bahamas</option>
                                  <option value="BH">Bahrain</option>
                                  <option value="BD">Bangladesh</option>
                                  <option value="BB">Barbados</option>
                                  <option value="BY">Belarus</option>
                                  <option value="BE">Belgium</option>
                                  <option value="BZ">Belize</option>
                                  <option value="BJ">Benin</option>
                                  <option value="BM">Bermuda</option>
                                  <option value="BT">Bhutan</option>
                                  <option value="BO">
                                    Bolivia, Plurinational State of
                                  </option>
                                  <option value="BQ">
                                    Bonaire, Sint Eustatius and Saba
                                  </option>
                                  <option value="BA">Bosnia and Herzegovina</option>
                                  <option value="BW">Botswana</option>
                                  <option value="BV">Bouvet Island</option>
                                  <option value="BR">Brazil</option>
                                  <option value="IO">
                                    British Indian Ocean Territory
                                  </option>
                                  <option value="BN">Brunei Darussalam</option>
                                  <option value="BG">Bulgaria</option>
                                  <option value="BF">Burkina Faso</option>
                                  <option value="BI">Burundi</option>
                                  <option value="KH">Cambodia</option>
                                  <option value="CM">Cameroon</option>
                                  <option value="CA">Canada</option>
                                  <option value="CV">Cape Verde</option>
                                  <option value="KY">Cayman Islands</option>
                                  <option value="CF">Central African Republic</option>
                                  <option value="TD">Chad</option>
                                  <option value="CL">Chile</option>
                                  <option value="CN">China</option>
                                  <option value="CX">Christmas Island</option>
                                  <option value="CC">Cocos (Keeling) Islands</option>
                                  <option value="CO">Colombia</option>
                                  <option value="KM">Comoros</option>
                                  <option value="CG">Congo</option>
                                  <option value="CD">
                                    Congo, the Democratic Republic of the
                                  </option>
                                  <option value="CK">Cook Islands</option>
                                  <option value="CR">Costa Rica</option>
                                  <option value="CI">CÃ´te d'Ivoire</option>
                                  <option value="HR">Croatia</option>
                                  <option value="CU">Cuba</option>
                                  <option value="CW">CuraÃ§ao</option>
                                  <option value="CY">Cyprus</option>
                                  <option value="CZ">Czech Republic</option>
                                  <option value="DK">Denmark</option>
                                  <option value="DJ">Djibouti</option>
                                  <option value="DM">Dominica</option>
                                  <option value="DO">Dominican Republic</option>
                                  <option value="EC">Ecuador</option>
                                  <option value="EG">Egypt</option>
                                  <option value="SV">El Salvador</option>
                                  <option value="GQ">Equatorial Guinea</option>
                                  <option value="ER">Eritrea</option>
                                  <option value="EE">Estonia</option>
                                  <option value="ET">Ethiopia</option>
                                  <option value="FK">
                                    Falkland Islands (Malvinas)
                                  </option>
                                  <option value="FO">Faroe Islands</option>
                                  <option value="FJ">Fiji</option>
                                  <option value="FI">Finland</option>
                                  <option value="FR">France</option>
                                  <option value="GF">French Guiana</option>
                                  <option value="PF">French Polynesia</option>
                                  <option value="TF">
                                    French Southern Territories
                                  </option>
                                  <option value="GA">Gabon</option>
                                  <option value="GM">Gambia</option>
                                  <option value="GE">Georgia</option>
                                  <option value="DE">Germany</option>
                                  <option value="GH">Ghana</option>
                                  <option value="GI">Gibraltar</option>
                                  <option value="GR">Greece</option>
                                  <option value="GL">Greenland</option>
                                  <option value="GD">Grenada</option>
                                  <option value="GP">Guadeloupe</option>
                                  <option value="GU">Guam</option>
                                  <option value="GT">Guatemala</option>
                                  <option value="GG">Guernsey</option>
                                  <option value="GN">Guinea</option>
                                  <option value="GW">Guinea-Bissau</option>
                                  <option value="GY">Guyana</option>
                                  <option value="HT">Haiti</option>
                                  <option value="HM">
                                    Heard Island and McDonald Islands
                                  </option>
                                  <option value="VA">
                                    Holy See (Vatican City State)
                                  </option>
                                  <option value="HN">Honduras</option>
                                  <option value="HK">Hong Kong</option>
                                  <option value="HU">Hungary</option>
                                  <option value="IS">Iceland</option>
                                  <option value="IN">India</option>
                                  <option value="ID">Indonesia</option>
                                  <option value="IR">
                                    Iran, Islamic Republic of
                                  </option>
                                  <option value="IQ">Iraq</option>
                                  <option value="IE">Ireland</option>
                                  <option value="IM">Isle of Man</option>
                                  <option value="IL">Israel</option>
                                  <option value="IT">Italy</option>
                                  <option value="JM">Jamaica</option>
                                  <option value="JP">Japan</option>
                                  <option value="JE">Jersey</option>
                                  <option value="JO">Jordan</option>
                                  <option value="KZ">Kazakhstan</option>
                                  <option value="KE">Kenya</option>
                                  <option value="KI">Kiribati</option>
                                  <option value="KP">
                                    Korea, Democratic People's Republic of
                                  </option>
                                  <option value="KR">Korea, Republic of</option>
                                  <option value="KW">Kuwait</option>
                                  <option value="KG">Kyrgyzstan</option>
                                  <option value="LA">
                                    Lao People's Democratic Republic
                                  </option>
                                  <option value="LV">Latvia</option>
                                  <option value="LB">Lebanon</option>
                                  <option value="LS">Lesotho</option>
                                  <option value="LR">Liberia</option>
                                  <option value="LY">Libya</option>
                                  <option value="LI">Liechtenstein</option>
                                  <option value="LT">Lithuania</option>
                                  <option value="LU">Luxembourg</option>
                                  <option value="MO">Macao</option>
                                  <option value="MK">
                                    Macedonia, the former Yugoslav Republic of
                                  </option>
                                  <option value="MG">Madagascar</option>
                                  <option value="MW">Malawi</option>
                                  <option value="MY">Malaysia</option>
                                  <option value="MV">Maldives</option>
                                  <option value="ML">Mali</option>
                                  <option value="MT">Malta</option>
                                  <option value="MH">Marshall Islands</option>
                                  <option value="MQ">Martinique</option>
                                  <option value="MR">Mauritania</option>
                                  <option value="MU">Mauritius</option>
                                  <option value="YT">Mayotte</option>
                                  <option value="MX">Mexico</option>
                                  <option value="FM">
                                    Micronesia, Federated States of
                                  </option>
                                  <option value="MD">Moldova, Republic of</option>
                                  <option value="MC">Monaco</option>
                                  <option value="MN">Mongolia</option>
                                  <option value="ME">Montenegro</option>
                                  <option value="MS">Montserrat</option>
                                  <option value="MA">Morocco</option>
                                  <option value="MZ">Mozambique</option>
                                  <option value="MM">Myanmar</option>
                                  <option value="NA">Namibia</option>
                                  <option value="NR">Nauru</option>
                                  <option value="NP">Nepal</option>
                                  <option value="NL">Netherlands</option>
                                  <option value="NC">New Caledonia</option>
                                  <option value="NZ">New Zealand</option>
                                  <option value="NI">Nicaragua</option>
                                  <option value="NE">Niger</option>
                                  <option value="NG">Nigeria</option>
                                  <option value="NU">Niue</option>
                                  <option value="NF">Norfolk Island</option>
                                  <option value="MP">Northern Mariana Islands</option>
                                  <option value="NO">Norway</option>
                                  <option value="OM">Oman</option>
                                  <option value="PK">Pakistan</option>
                                  <option value="PW">Palau</option>
                                  <option value="PS">
                                    Palestinian Territory, Occupied
                                  </option>
                                  <option value="PA">Panama</option>
                                  <option value="PG">Papua New Guinea</option>
                                  <option value="PY">Paraguay</option>
                                  <option value="PE">Peru</option>
                                  <option value="PH">Philippines</option>
                                  <option value="PN">Pitcairn</option>
                                  <option value="PL">Poland</option>
                                  <option value="PT">Portugal</option>
                                  <option value="PR">Puerto Rico</option>
                                  <option value="QA">Qatar</option>
                                  <option value="RE">RÃ©union</option>
                                  <option value="RO">Romania</option>
                                  <option value="RU">Russian Federation</option>
                                  <option value="RW">Rwanda</option>
                                  <option value="BL">Saint BarthÃ©lemy</option>
                                  <option value="SH">
                                    Saint Helena, Ascension and Tristan da Cunha
                                  </option>
                                  <option value="KN">Saint Kitts and Nevis</option>
                                  <option value="LC">Saint Lucia</option>
                                  <option value="MF">
                                    Saint Martin (French part)
                                  </option>
                                  <option value="PM">
                                    Saint Pierre and Miquelon
                                  </option>
                                  <option value="VC">
                                    Saint Vincent and the Grenadines
                                  </option>
                                  <option value="WS">Samoa</option>
                                  <option value="SM">San Marino</option>
                                  <option value="ST">Sao Tome and Principe</option>
                                  <option value="SA">Saudi Arabia</option>
                                  <option value="SN">Senegal</option>
                                  <option value="RS">Serbia</option>
                                  <option value="SC">Seychelles</option>
                                  <option value="SL">Sierra Leone</option>
                                  <option value="SG">Singapore</option>
                                  <option value="SX">
                                    Sint Maarten (Dutch part)
                                  </option>
                                  <option value="SK">Slovakia</option>
                                  <option value="SI">Slovenia</option>
                                  <option value="SB">Solomon Islands</option>
                                  <option value="SO">Somalia</option>
                                  <option value="ZA">South Africa</option>
                                  <option value="GS">
                                    South Georgia and the South Sandwich Islands
                                  </option>
                                  <option value="SS">South Sudan</option>
                                  <option value="ES">Spain</option>
                                  <option value="LK">Sri Lanka</option>
                                  <option value="SD">Sudan</option>
                                  <option value="SR">Suriname</option>
                                  <option value="SJ">Svalbard and Jan Mayen</option>
                                  <option value="SZ">Swaziland</option>
                                  <option value="SE">Sweden</option>
                                  <option value="CH">Switzerland</option>
                                  <option value="SY">Syrian Arab Republic</option>
                                  <option value="TW">
                                    Taiwan, Province of China
                                  </option>
                                  <option value="TJ">Tajikistan</option>
                                  <option value="TZ">
                                    Tanzania, United Republic of
                                  </option>
                                  <option value="TH">Thailand</option>
                                  <option value="TL">Timor-Leste</option>
                                  <option value="TG">Togo</option>
                                  <option value="TK">Tokelau</option>
                                  <option value="TO">Tonga</option>
                                  <option value="TT">Trinidad and Tobago</option>
                                  <option value="TN">Tunisia</option>
                                  <option value="TR">Turkey</option>
                                  <option value="TM">Turkmenistan</option>
                                  <option value="TC">Turks and Caicos Islands</option>
                                  <option value="TV">Tuvalu</option>
                                  <option value="UG">Uganda</option>
                                  <option value="UA">Ukraine</option>
                                  <option value="AE">United Arab Emirates</option>
                                  <option value="GB">United Kingdom</option>
                                  <option value="US">United States</option>
                                  <option value="UM">
                                    United States Minor Outlying Islands
                                  </option>
                                  <option value="UY">Uruguay</option>
                                  <option value="UZ">Uzbekistan</option>
                                  <option value="VU">Vanuatu</option>
                                  <option value="VE">
                                    Venezuela, Bolivarian Republic of
                                  </option>
                                  <option value="VN">Viet Nam</option>
                                  <option value="VG">Virgin Islands, British</option>
                                  <option value="VI">Virgin Islands, U.S.</option>
                                  <option value="WF">Wallis and Futuna</option>
                                  <option value="EH">Western Sahara</option>
                                  <option value="YE">Yemen</option>
                                  <option value="ZM">Zambia</option>
                                  <option value="ZW">Zimbabwe</option>
                                </select>
                              </div>
                            </div>
                            <hr />
                          </div>
                        </div>
                        <button
                          type="button"
                          style={{ color: "black", fontWeight: "bold" }}
                          className="btn btn-round btn-primary mr-1"
                          onClick={submitForm}
                        >
                          {isEdit ? "Update" : "Create"}
                        </button>{" "}
                        &nbsp;&nbsp;
                        <button type="button" className="btn btn-round btn-default">
                          Cancel
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
      {/* <div className="row clearfix"> */}

      {/* </div> */}
    </div>
  );
};

export default Widgets;
