import React from "react";
import { Link  , useParams} from "react-router-dom";
import Dropzone from "../common/DropzoneExample";
import { Col, Nav, Row, Tab, Tabs } from "react-bootstrap";
import { Accordion, Button } from "react-bootstrap";
import { White } from "react-dial-knob";

const Configuration = () => {
  return (
    <>
      <div className="container-fluid">
        <div className="block-header">
          <div className="row clearfix">
            <div className="col-md-6 col-sm-12">
              <h2>Settings</h2>
              <nav aria-label="breadcrumb">
                <ol className="breadcrumb">
                  <li className="breadcrumb-item">
                    <Link to="/">Home</Link>
                  </li>

                  <li className="breadcrumb-item active" aria-current="page">
                    Details
                  </li>
                </ol>
              </nav>
            </div>
            {/* <div className="col-md-6 col-sm-12 text-right hidden-xs">
              <a href="/" className="btn btn-sm btn-primary btn-round" title="">
                Add New
              </a>
            </div> */}
          </div>
        </div>
        <div className="row clearfix">
          <div className="col-xl-12 col-lg-4 col-md-5">
            <div className="card">
              <div className="body">
                <div className="header">
                  <h2>Manage scan</h2>
                </div>
                <div className="row clearfix">
                  <div className="col-lg-12">
                    <div className="card">
                      <Accordion
                        className="accordion"
                        id="accordion"
                        defaultActiveKey="0"
                      >
                        <div>
                          <div
                            className="card-header"
                            id="headingOne"
                            style={{ background: "white", fontStyle: "bold" }}
                          >
                            <h5 className="mb-0">
                              <Accordion.Toggle
                                as={Button}
                                variant="link"
                                eventKey="0"
                                className="btn btn-link"
                              >
                                <span
                                  style={{ color: "black", fontStyle: "bold" }}
                                >
                                  <b>ATTACK SURACE MANAGEMENT </b>
                                </span>

                                <span className="float-right badge  badge-primary bg-orange text-light">
                                  11 Attack Vectors
                                </span>
                              </Accordion.Toggle>
                            </h5>
                          </div>
                          <Accordion.Collapse eventKey="0" className="collapse">
                            <div className="row clearfix">
                              <div className="col-lg-12">
                                <div className="card">
                                  <table
                                    id="mainTable"
                                    className="table m-b-0 table-striped table-dark"
                                  >
                                    <thead>
                                      <tr>
                                        <th>
                                          <b>Service Name</b>
                                        </th>
                                        <th>
                                          <b>Frequency</b>
                                        </th>
                                      </tr>
                                    </thead>
                                    <tbody>
                                      <tr>
                                        <td>
                                          Shadow IP Discovery & Open Ports
                                        </td>
                                        <td>1 week</td>
                                      </tr>
                                      <tr>
                                        <td>Passive Vulnerability Scan</td>
                                        <td>1 week</td>
                                      </tr>
                                      <tr>
                                        <td>
                                          Subdomain Enumeration & Dangling
                                          Subdomains
                                        </td>
                                        <td>1 week</td>
                                      </tr>
                                      <tr>
                                        <td>
                                          DNS Records Lookup (Domains &
                                          Subdomains)
                                        </td>
                                        <td>1 week</td>
                                      </tr>
                                      <tr>
                                        <td>Outdated web technology lookup</td>
                                        <td>1 week</td>
                                      </tr>
                                      <tr>
                                        <td>Web Application security scan</td>
                                        <td>1 week</td>
                                      </tr>
                                      <tr>
                                        <td>Outdated web technology lookup</td>
                                        <td>1 week</td>
                                      </tr>
                                      <tr>
                                        <td>
                                          Expired & Vulnerable SSL Certificates
                                        </td>
                                        <td>1 week</td>
                                      </tr>
                                      <tr>
                                        <td>Cloud Storage</td>
                                        <td>1 week</td>
                                      </tr>
                                      <tr>
                                        <td>Stealer Logs</td>
                                        <td>Daily</td>
                                      </tr>

                                      <tr>
                                        <td>Blacklisted IP address scanning</td>
                                        <td>Daily</td>
                                      </tr>
                                    </tbody>
                                  </table>
                                </div>
                              </div>
                            </div>
                          </Accordion.Collapse>
                        </div>

                        <div>
                          <div className="card-header" id="headingOne">
                            <h5 className="mb-0">
                              <Accordion.Toggle
                                as={Button}
                                variant="link"
                                eventKey="1"
                                ƒ
                                className="btn btn-link"
                                style={{
                                  background: "white",
                                  fontStyle: "bold",
                                }}
                              >
                                <span
                                  style={{ color: "white", fontStyle: "bold" }}
                                >
                                  <span
                                    style={{
                                      color: "black",
                                      fontStyle: "bold",
                                    }}
                                  >
                                    <b>DARKWEB & DATA LEAKAGE </b>
                                  </span>
                                </span>

                                <span className="float-right badge   bg-purple text-light">
                                  5 Attack Vectors
                                </span>
                              </Accordion.Toggle>
                            </h5>
                          </div>
                          <Accordion.Collapse eventKey="1" className="collapse">
                            <div className="row clearfix">
                              <div className="col-lg-12">
                                <div className="card">
                                  <table
                                    id="mainTable"
                                    className="table m-b-0 table-striped table-dark"
                                  >
                                    <thead>
                                      <tr>
                                        <th>
                                          <b>Service Name</b>
                                        </th>
                                        <th>
                                          <b>Frequency</b>
                                        </th>
                                      </tr>
                                    </thead>
                                    <tbody>
                                      <tr>
                                        <td>Stolen Credentials</td>
                                        <td>Daily</td>
                                      </tr>
                                      <tr>
                                        <td>Leaked Credentials</td>
                                        <td>Daily</td>
                                      </tr>
                                      <tr>
                                        <td>
                                          Personal Information Leakgage Scanning
                                        </td>
                                        <td>Daily</td>
                                      </tr>
                                      <tr>
                                        <td>Source Code Leakage Scanning</td>
                                        <td>Daily</td>
                                      </tr>
                                      <tr>
                                        <td>Pastebin Search</td>
                                        <td>Daily</td>
                                      </tr>
                                    </tbody>
                                  </table>
                                </div>
                              </div>
                            </div>
                          </Accordion.Collapse>
                        </div>

                        <div>
                          <div className="card-header" id="headingOne">
                            <h5 className="mb-0">
                              <Accordion.Toggle
                                as={Button}
                                variant="link"
                                eventKey="2"
                                className="btn btn-link"
                                style={{
                                  background: "white",
                                  fontStyle: "bold",
                                }}
                              >
                                <span
                                  style={{
                                    color: "black",
                                    fontStyle: "bold",
                                  }}
                                >
                                  <b>BRAND PROTECTION </b>
                                </span>

                                <span className="float-right badge   bg-pink text-light">
                                  6 Attack Vectors
                                </span>
                              </Accordion.Toggle>
                            </h5>
                          </div>
                          <Accordion.Collapse eventKey="2" className="collapse">
                            <div className="row clearfix">
                              <div className="col-lg-12">
                                <div className="card">
                                  <table
                                    id="mainTable"
                                    className="table m-b-0 table-striped table-dark"
                                  >
                                    <thead>
                                      <tr>
                                        <th>
                                          <b>Service Name</b>
                                        </th>
                                        <th>
                                          <b>Frequency</b>
                                        </th>
                                      </tr>
                                    </thead>
                                    <tbody>
                                      <tr>
                                        <td>Look-alike Domains</td>
                                        <td>Daily</td>
                                      </tr>
                                      <tr>
                                        <td>
                                          Fake Social Media Profiles Check
                                        </td>
                                        <td>Weekly</td>
                                      </tr>
                                      <tr>
                                        <td>Fake Mobile Applications Scan</td>
                                        <td>Daily</td>
                                      </tr>
                                      <tr>
                                        <td>Web Defacement Monitoring</td>
                                        <td>Daily</td>
                                      </tr>
                                      <tr>
                                        <td>Phishing Domains</td>
                                        <td>Daily</td>
                                      </tr>
                                    </tbody>
                                  </table>
                                </div>
                              </div>
                            </div>
                          </Accordion.Collapse>
                        </div>
                      </Accordion>
                      <button
                        className="btn btn-primary"
                        type="button"
                        style={{ marginTop: "40px" }}
                      >
                        Submit
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
export default Configuration;
