import React, { Component } from "react";
import { useLocation } from 'react-router-dom';
import DefaultLink from "./DefaultLink";
import MetisMenu from "react-metismenu";

const SideBar = (props) => {
  console.log(props.location.pathname, "sidebar props")
  const orgId = sessionStorage.getItem('vendorId');
  const location = useLocation();

  const isVendorDashboard = (url) => {
    const pattern = /^\/dashboard\/vendor\/[0-9a-fA-F]{24}$/;
    return pattern.test(url);
  };

  const toggleSubMenu = (e) => {

    let menucClass = "";
    if (e.itemId) {
      const subClass = e.items.map((menuItem) => {
        if (
          menuItem.id === "main" ||
          menuItem.id === "app" ||
          menuItem.id === "account" ||
          menuItem.id === "extra" ||
          menuItem.id === "ui"
        ) {
          menucClass = "header";
        }
        if (menuItem.to === props.location.pathname) {
          menucClass = "";
        } else {
          menucClass = "collapse";
        }
        return menucClass;
      });
      return subClass;
      // return "collapse";
    } else {
      return e.visible ? "collapse" : "metismenu";
    }
  };

  let metisMenu = [];

  if (props.props.accessLevel !== "Threat Analyst") {
    metisMenu.push({
      id: "main",
      label: "Main",
    });
  }

  if (props.props.accessLevel !== "Threat Analyst" && (props?.props?.organisationId?.services.indexOf("AttackSurface") > -1 || props?.props?.organisationId?.services.indexOf("BrandSecurity") > -1 || props?.props?.organisationId?.services.indexOf("Data Exposure") > -1) || isVendorDashboard(location.pathname)) {
    metisMenu.push({
      id: 101,
      icon: "icon-home",
      label: "Dashboard",
      to: `/${orgId ? 'dashboard/vendor/' + orgId : ''}`,
      // to: "/new-summary",
    });
    //   metisMenu.push({
    //     id: 107,
    //     label: "Assets Discovery",
    //     to: "/assets",
    //   });
  }

  // if (props.props.accessLevel !== "Threat Analyst") {
  //   metisMenu.push({
  //     id: 7002,
  //     icon: "icon-lock",
  //     label: "Asset Discovery",
  //     to: "/",
  //     content: [
  //     ],
  //   });
  // }

  if (props.props.accessLevel === "MSSP" || props.props.accessLevel === "Admin" || props.props.accessLevel === "Threat Analyst" || (props.props.accessLevel === "Client" && props?.props?.organisationId?.services.indexOf("AttackSurface") > -1 || isVendorDashboard(location.pathname))
    || (props.props.accessLevel === "Manager" && props.props.services.indexOf('AttackSurface') > -1 && props?.props?.organisationId?.services.indexOf("AttackSurface") > -1)) {
    // if (props.props.accessLevel !== "Threat Analyst") {
    metisMenu.push({
      id: 1001,
      icon: "icon-shield",
      label: "Attack Surface",
      to: "/",
      content: [
        {
          id: 7160,
          label: "Summary",
          to: `/asm-details${orgId ? '/vendor/' + orgId : ''}`,
        },

        {
          id: 716,
          label: "IP Discovery & Open Port",
          to: `/assets${orgId ? '/vendor/' + orgId : ''}`,
        },

        {
          id: 109,
          label: "Passive Vulnerability Assessment",
          to: `/known-vulnerabilities${orgId ? '/vendor/' + orgId : ''}`,
        },

        // {
        //   id: 7162,
        //   label: "Open Ports",
        //   to: "/assets",
        // },

        {
          id: 716,
          label: "Subdomain Enumeration",
          to: `/subdomains${orgId ? '/vendor/' + orgId : ''}`,
        },

        // {
        //   id: 11,
        //   label: "Dangling Subdomains",
        //   to: "/danglingdomain",
        // },

        // {
        //   id: 718,
        //   label: "DNS Records",
        //   to: "/domains",
        // },

        {
          id: 717,
          label: "Outdated web technologies",
          to: `/web-technology${orgId ? '/vendor/' + orgId : ''}`,
        },

        // {
        //   id: 7170,
        //   label: "Web Security",
        //   to: "/security-header",
        // },

        {
          id: 111,
          label: "SPF & DMARC Records",
          to: `/spf-dmarc${orgId ? '/vendor/' + orgId : ''}`,
        },

        {
          id: 110,
          label: "Expired SSL Certificates",
          to: `/ssl-certificates${orgId ? '/vendor/' + orgId : ''}`,
        },

        {
          id: 112,
          label: "Vulnerable SSL Certificates",
          to: `/vulnerable-certificates${orgId ? '/vendor/' + orgId : ''}`,
        },

        {
          id: 118,
          label: "Blacklisted IP",
          to: `/ip-blacklist${orgId ? '/vendor/' + orgId : ''}`,
        },
      ],
    });
  }

  // if (props.props.accessLevel !== "Threat Analyst") {
  if (props.props.accessLevel === "MSSP" || props.props.accessLevel === "Admin" || props.props.accessLevel === "Threat Analyst" || (props.props.accessLevel === "Client" && props?.props?.organisationId?.services.indexOf("Data Exposure") > -1) || (props.props.accessLevel === "Manager" && props.props.services.indexOf('Data Exposure') > -1 && props?.props?.organisationId?.services.indexOf("Data Exposure") > -1) || isVendorDashboard(location.pathname)) {
    metisMenu.push({
      id: 2002,
      icon: "icon-lock",
      label: 'Data Exposure',
      to: "/",
      content: [
        {
          id: 1160,
          label: "Summary",
          to: `/data-details${orgId ? '/vendor/' + orgId : ''}`,
        },

        {
          id: 116,
          label: "Stolen Credentials",
          to: `/stolen-credentials${orgId ? '/vendor/' + orgId : ''}`,
        },
        {
          id: 1161,
          label: "Malware Infected Machines",
          to: `/malware-credentials${orgId ? '/vendor/' + orgId : ''}`,
        },

        {
          id: 1162,
          label: "Leaked Sessions",
          to: `/leaked-sessions${orgId ? '/vendor/' + orgId : ''}`,
        },

        {
          id: 115,
          label: "Source Code Leakage",
          to: `/source-code${orgId ? '/vendor/' + orgId : ''}`,
        },

        {
          id: 1261,
          label: "Pastebin Search",
          to: `/pastebin-search${orgId ? '/vendor/' + orgId : ''}`,
        },
        {
          id: 1262,
          label: "Personal Information",
          to: `/pii${orgId ? '/vendor/' + orgId : ''}`,
        },
        {
          id: 1263,
          label: "Telegram monitoring",
          to: `/telegram-monitoring${orgId ? '/vendor/' + orgId : ''}`,
        },
        {
          id: 1264,
          label: "Postman Monitoring",
          to: `/postman-monitoring${orgId ? '/vendor/' + orgId : ''}`,
        },
        {
          id: 117,
          label: "Open Cloud Buckets",
          to: `/cloud-bucket${orgId ? '/vendor/' + orgId : ''}`,
        },
        {
          id: 1260,
          label: "Dark web monitoring",
          to: `/dark-web${orgId ? '/vendor/' + orgId : ''}`,
        },

      ],
    });
  }
  // if (props.props.accessLevel !== "Threat Analyst") {
  if (props.props.accessLevel === "MSSP" || props.props.accessLevel === "Admin" || props.props.accessLevel === "Threat Analyst" || (props.props.accessLevel === "Client" && props?.props?.organisationId?.services.indexOf("BrandSecurity") > -1) || (props.props.accessLevel === "Manager" && props.props.services.indexOf('BrandSecurity') > -1 && props?.props?.organisationId?.services.indexOf("BrandSecurity") > -1) || isVendorDashboard(location.pathname)) {
    metisMenu.push({
      id: 2001,
      icon: "icon-layers",
      label: "Brand Security",
      to: "/",
      content: [
        {
          id: 11020,
          label: "Summary",
          to: `/brand-details${orgId ? '/vendor/' + orgId : ''}`,
        },
        {
          id: 1102,
          label: "Look-alike Domains",
          to: `/look-alike${orgId ? '/vendor/' + orgId : ''}`,
        },
        {
          id: 113,
          label: "Rogue Mobile Apps",
          to: `/mobile-apps${orgId ? '/vendor/' + orgId : ''}`,
        },
        {
          id: 114,
          label: "Social Media Profiles",
          to: `/social-media${orgId ? '/vendor/' + orgId : ''}`,
        },
        {
          id: 116,
          label: "Brand Mentions",
          to: `/brand-mentions${orgId ? '/vendor/' + orgId : ''}`,
        },
        // {
        //   id: 115,
        //   label: "Web defacement",
        //   to: "/web-defacement",
        // },
        // {
        //   id: 1162,
        //   label: "Phishing Domains",
        //   to: "/phishing",
        // },
      ],
    });
  }

  if ((props.props.accessLevel === "Client" && props?.props?.organisationId?.services.indexOf("TPSA") > -1) || props.props.accessLevel === "Admin" || (props.props.accessLevel === "Manager" && props.props.services.indexOf('TPSA') > -1 && props?.props?.organisationId?.services.indexOf("TPSA") > -1)) {
    metisMenu.push({
      id: 4001,
      icon: "icon-layers",
      label: "Forest View",
      to: "/",
      content: [
        {
          id: 41101,
          label: "Dashboard",
          to: `/vendor${orgId ? '/' + orgId : ''}`,
        },
        {
          id: 41102,
          label: "Manage Vendor",
          to: `/vendor-summary${orgId ? '/vendor/' + orgId : ''}`,
        },
      ],
    });
  }

  metisMenu.push({
    id: "main",
    label: "Manage",
  });

  if (props.props.accessLevel === "Admin" || props.props.accessLevel === "Threat Analyst" || (props.props.accessLevel === "Client" && props.props.orgAdmin)) {
    metisMenu.push({
      id: 106,
      icon: "icon-users",
      label: "Invite User",
      to: `/invite-user${orgId ? '/vendor/' + orgId : ''}`,
    });
  }

  //
  if (props.props.accessLevel === "Admin" || props.props.accessLevel === "Threat Analyst" || (props.props.accessLevel === "Client" && props.props.orgAdmin)) {
    if (props.props.accessLevel === "Client" && props.props.orgAdmin) {
      metisMenu.push({
        id: 2050,
        icon: "fa-solid fa-database",
        label: "CVE Information",
        to: "/",
        content: [
          {
            id: 111,
            label: "CVE Monitoring",
            to: `/cve-monitoring${orgId ? '/vendor/' + orgId : ''}`,
          }
        ],
      });
    } else {
      metisMenu.push({
        id: 2050,
        icon: "fa-solid fa-database",
        label: "CVE Information",
        to: "/",
        content: [
          {
            id: 107,
            label: "CVE Information",
            to: `/cve${orgId ? '/vendor/' + orgId : ''}`,
          },
          {
            id: 111,
            label: "CVE Monitoring",
            to: `/cve-monitoring${orgId ? '/vendor/' + orgId : ''}`,
          },
          {
            id: 110,
            label: "Vendors & Product",
            to: `/vendor-product${orgId ? '/vendor/' + orgId : ''}`,
          },
        ],
      });
    }

  }
  if (props.props.accessLevel === "Admin" || props.props.accessLevel === "Threat Analyst") {
    metisMenu.push({
      id: 2051,
      icon: "fa-solid fa-database",
      label: "Technology",
      to: `/technology${orgId ? '/vendor/' + orgId : ''}`,
    });
  }



  if (props.props.accessLevel !== "Threat Analyst") {
    metisMenu.push({
      id: 501,
      icon: "icon-user",
      label: "Profile",
      to: `/profile${orgId ? '/vendor/' + orgId : ''}`,
    });
  }
  if (
    props.props.accessLevel !== "Client" &&
    props.props.accessLevel !== "Manager" &&
    props.props.accessLevel !== "Vendor"
  ) {
    metisMenu.push({
      id: 105,
      icon: "icon-briefcase",
      label: "Clients",
      to: `/clients${orgId ? '/vendor/' + orgId : ''}`,
    });

    //   if (props.props.accessLevel !== "Threat Analyst") {
    //   metisMenu.push({
    //     id: 503,
    //     icon: "icon-credit-card",
    //     label: "My plan",
    //     to: "/subscription",
    //   });
    // }
    if (props.props.accessLevel == "Admin" || props.props.accessLevel === "Threat Analyst") {
      metisMenu.push({
        id: 1050,
        icon: "icon-briefcase",
        label: "Trial Requests",
        to: `/trial-request${orgId ? '/vendor/' + orgId : ''}`,
      });
    }
  }

  if (props.props.accessLevel !== "Threat Analyst") {
    metisMenu.push({
      id: 502,
      icon: "icon-envelope",
      label: "Integrations",
      to: `/integrations${orgId ? '/vendor/' + orgId : ''}`,
    });
  }



  return (
    <MetisMenu
      content={metisMenu}
      noBuiltInClassNames={true}
      classNameItemActive="active"
      classNameContainer={(e) => toggleSubMenu(e)}
      classNameContainerVisible="in"
      iconNamePrefix=""
      LinkComponent={(e) => <DefaultLink itemProps={e} />}
    />
  );
};

export default SideBar;