import React, { useEffect, useState } from "react";
import { axios } from "axios";
import { useSelector, useDispatch } from "react-redux";
import { Link, useHistory, useParams, useLocation } from "react-router-dom";
import Pagination from "../Pagination/Pagination";
import 'react-datepicker/dist/react-datepicker.css';
import { forestLowestRating, forestRatingDecline } from "../../actions/customer";


const ForestRatingPage = () => {
    const location = useLocation();
    const tab = new URLSearchParams(location.search).get('tab');
    const id = useSelector((state) => state?.summary?.localClient?._id || state?.summary?.clients?.[0]?._id);
    const dispatch = useDispatch();
    const vendorName = sessionStorage.getItem('vendorName');
    const vendorId = sessionStorage.getItem('vendorId');
    const [tableFilter, setTableFilter] = useState("lowest");
    const [page, setPage] = useState(1);
    const [pageDecline, setPageDecline] = useState(1);
    const [totalItems, setTotalItems] = useState(0);
    const [totalItemsDecline, setTotalItemsDecline] = useState(0);
    const [PageSize, setPerPage] = useState(10);


    let forestRatingList = useSelector(
        (state) => state?.customer?.forestRatingList?.data
    );
    let mapDataDecline = forestRatingList?.ratingDeltaInPercentageList;
    let currentTableDataDecline = [];
    if (mapDataDecline && mapDataDecline.length > 0) {
        currentTableDataDecline = mapDataDecline;
    }



    let forestLowestRatingList = useSelector(
        (state) => state?.customer?.forestLowestList?.data
    );
    let mapData = forestLowestRatingList?.forestViewClients;
    let currentTableData = [];
    if (mapData && mapData.length > 0) {
        currentTableData = mapData;
    }


    useEffect(() => {
        setTableFilter(tab)
    }, [tab])

    useEffect(() => {
        if (id) {
            forestRatingDecline(id, dispatch,pageDecline)
        }
    }, [id,pageDecline])

    useEffect(() => {
        if (id) {
            forestLowestRating(id, dispatch,page)
        }
    }, [id,page])


    useEffect(() => {
        setTotalItems(forestLowestRatingList?.totalForestViewClients)
    }, [forestLowestRatingList?.totalForestViewClients])


    useEffect(() => {
        setTotalItemsDecline(forestRatingList?.totalRecords)
    }, [forestLowestRatingList?.totalRecords])
    console.log(forestRatingList?.totalRecords)

    return (
        <div className="container-fluid">
            <div className="block-header">
                <div className="row clearfix">
                    <div className="col-md-6 col-sm-12">
                        <h2 style={{ color: "white" }}>Forest Rating List</h2>
                        <nav aria-label="breadcrumb">
                            <ol className="breadcrumb">
                                <li className="breadcrumb-item">
                                    {vendorId ? <>
                                        <Link to="/vendor"
                                            onClick={(() => {
                                                sessionStorage.clear();
                                            })}
                                        >Forest View</Link>
                                    </> : <Link to="/">Home</Link>}
                                </li>
                                {
                                    vendorName ?
                                        <li
                                            className="breadcrumb-item active"
                                            aria-current="page"
                                            style={{ color: "white" }}
                                        >
                                            {vendorName}
                                        </li> : ''
                                }
                                <li
                                    className="breadcrumb-item active"
                                    aria-current="page"
                                    style={{ color: "white" }}
                                >
                                    forest-rating
                                </li>
                            </ol>
                        </nav>
                    </div>
                </div>
                <div className="row clearfix">
                    <ul className="nav nav-tabs mt-5 mb-2" id="myTabs" role="tablist">
                        <li className="nav-item">
                            <a
                                className={`nav-table-tab nav-link ${tableFilter === "lowest" ? 'active' : ''}`}
                                onClick={() => {
                                    setTableFilter('lowest')
                                    // setPageDecline(1)
                                    setPage(1)
                                }}
                            >
                                Lowest Rated
                            </a>
                        </li>
                        <li className="nav-item">
                            <a
                                className={`nav-link nav-table-tab ${tableFilter === "decline" ? 'active' : ''}`}
                                onClick={() => {
                                    setTableFilter('decline')
                                    setPageDecline(1)
                                }}
                            >
                                Rating Decline
                            </a>
                        </li>
                    </ul>
                    {tableFilter == 'lowest' ?
                        (<div className="col-lg-12 col-md-12 p-0">
                            <div className="card">
                                <div className="body">
                                    <div className="table-responsive">
                                        <table className="table table-custom spacing5 mb-0">
                                            <thead>
                                                <tr>
                                                    <th style={{ width: "100px" }}>#</th>
                                                    <th>Name</th>
                                                    <th>Average Score</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                {currentTableData?.map((res, index) => {
                                                    const pageCount = (page - 1) * 10 + 1;
                                                    return (
                                                        <tr>
                                                            <td><span>{pageCount + index}</span></td>
                                                            <td>
                                                                {res?.orgName || "N/A"}
                                                            </td>
                                                            <td>
                                                                {res?.totalImpact?.toFixed(2) || "N/A"}
                                                            </td>
                                                        </tr>
                                                    );
                                                })}
                                            </tbody>
                                        </table>
                                    </div>
                                    <div className="dataTables_paginate">
                                        {totalItems > 0 && (
                                            <Pagination
                                                className="pagination-bar"
                                                currentPage={page}
                                                totalCount={totalItems}
                                                pageSize={PageSize}
                                                onPageChange={(page) => setPage(page)}
                                            />
                                        )}
                                    </div>
                                </div>
                            </div>
                        </div>) :
                        (
                            <div className="col-lg-12 col-md-12 p-0">
                                <div className="card">
                                    <div className="body">
                                        <div className="table-responsive">
                                            <table className="table table-custom spacing5 mb-0">
                                                <thead>
                                                    <tr>
                                                        <th style={{ width: "100px" }}>#</th>
                                                        <th>Name</th>
                                                        <th>Percentage Decrease</th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    {currentTableDataDecline?.map((res, index) => {
                                                        const pageCount = (pageDecline - 1) * 10 + 1;
                                                        return (
                                                            <tr>
                                                                <td><span>{pageCount + index}</span></td>
                                                                <td>
                                                                    {res?.orgName || "N/A"}
                                                                </td>
                                                                <td>
                                                                    {res?.ratingDeltaInPercentage?.toFixed(2) + ' %' || "N/A"}
                                                                </td>
                                                            </tr>
                                                        );
                                                    })}
                                                </tbody>
                                            </table>
                                        </div>
                                        <div className="dataTables_paginate">
                                            {totalItemsDecline > 0 && (
                                                <Pagination
                                                    className="pagination-bar"
                                                    currentPage={pageDecline}
                                                    totalCount={totalItemsDecline}
                                                    pageSize={PageSize}
                                                    onPageChange={(page) => setPageDecline(page)}
                                                />
                                            )}
                                        </div>
                                    </div>
                                </div>
                            </div>
                        )
                    }
                </div>
            </div>
        </div>
    );
};
export default ForestRatingPage