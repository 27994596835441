import React, { useEffect, useState, useRef } from "react";
import { useSelector, useDispatch } from "react-redux";
import {
    technology,
    technologyDelete,
    technologyEdit,
    technologyCreate
} from "../../actions/sub-domain";
import Pagination from "../Pagination/Pagination";
import ReactTooltip from "react-tooltip";
import ScanMessage from "../common/scanningMessage";
import AccessPage from "../Shared/accessPage";
import { Link, useParams } from "react-router-dom";
import moment from "moment";
import $ from "jquery";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSortUp, faSortDown } from '@fortawesome/free-solid-svg-icons';


const Technology = () => {
    let subdomains = useSelector(
        (state) => state?.subDomain?.response?.data?.domains
    );
    let totalData = useSelector((state) => state?.subDomain?.response?.data);

    let scanCompleted = useSelector((state) => state.subDomain?.scanCompleted);

    let scanDetails = useSelector(
        (state) => state?.subDomain?.garphData?.payload?.data
    );

    let totalCount = ''
    const [filterData, setfilterData] = useState("all");
    subdomains = subdomains?.filter(
        (row) => row?.sslDetails?.[`${row?.subDomainName}`]?.cert_sn
    );
    const [page, setPage] = useState(1);
    const [PageSize, setPerPage] = useState(10);
    const [search, setSearch] = useState("");
    const [totalItems, setTotalItems] = useState(0);
    const [tech, setTech] = useState("");
    const [version, setVersion] = useState("");
    const [isModal, setModal] = useState("");
    const [lastLibrariesIOScanned, setLastLibrariesIOScanned] = useState("");
    const [librariesIOPlatform, setLibrariesIOPlatform] = useState("");
    const [isEdit, setIsEdit] = useState(false);
    const [editId, setEditId] = useState('');
    const vendorName = sessionStorage.getItem('vendorName');
    const vendorId = sessionStorage.getItem('vendorId');
    const [sortOrder, setSortOrder] = useState("");
    const myRef = useRef(null);
    const dispatch = useDispatch();
    const { orgId } = useParams();
    const id = useSelector(state => {
        if (orgId) {
            return orgId;
        } else {
            return state?.summary?.localClient?._id || state?.summary?.clients?.[0]?._id;
        }
    });

    const auth = useSelector((state) => state?.auth?.user);

    const client = useSelector(
        (state) => state?.summary?.localClient || state?.summary?.clients?.[0]
    );

    let currentTableData = [];
    const techData = useSelector(
        (state) => state?.subDomain?.technology?.data || []
    );
    useEffect(() => {
        technology(id, page, dispatch, search, sortOrder)
    }, [id, page, search, sortOrder])


    useEffect(() => {
        setTotalItems(techData?.totalIps);
    }, [techData?.totalIps]);


    let mapData = techData?.data;

    mapData = mapData;

    if (mapData && mapData.length > 0) {
        currentTableData = mapData;
    }

    if (mapData && mapData.length > 0) {
        setTimeout(function () {
            ReactTooltip.rebuild();
        }, 1000);
    }

    const deleteTech = (id) => {
        const self = this;
        $.confirm({
            title: "Are you sure?",
            content: "Do you really want to delete this technology?",
            type: "red",
            buttons: {
                ok: {
                    text: "Delete",
                    btnClass: "btn-danger",
                    keys: ["enter"],
                    action: function () {
                        technologyDelete(id, dispatch);
                    },
                },
                cancel: function () {
                    //console.log("the user clicked cancel");
                },
            },
        });
    };

    const addModal = () => {
        setIsEdit(false)
        setModal('Form Wizard modal')
        setVersion('')
        setLibrariesIOPlatform('')
        setTech('')
    }

    const editModal = (res) => {
        setIsEdit(true)
        setEditId(res?._id)
        setModal('Form Wizard modal')
        setVersion(res?.Version)
        setLibrariesIOPlatform(res?.librariesIOPlatform)
        setTech(res?.technology)
    }

    const submitForm = () => {
        let data = {
            "technology": tech,
            "Version": version,
            "librariesIOPlatform": librariesIOPlatform
        }
        if (editId != '') {
            technologyEdit(editId, dispatch, data)
        } else {
            technologyCreate(id, dispatch, data)
        }
        setModal('')
    }

    return (
        <>
            <div className="container-fluid">
                <AccessPage></AccessPage>
                <ReactTooltip className="reactTooltipClass" multiline={true} />
                <div className="block-header">
                    <div className="row clearfix">
                        <div className="col-md-6 col-sm-12">
                            <h1 style={{ color: "white" }}>Technology</h1>
                            <nav aria-label="breadcrumb">
                                <ol className="breadcrumb">
                                    <li className="breadcrumb-item">
                                        {vendorId ? <>
                                            <Link to="/vendor"
                                                onClick={(() => {
                                                    sessionStorage.clear();
                                                })}
                                            >Forest View</Link>
                                        </> : <Link to="/">Home</Link>}
                                    </li>
                                    {
                                        vendorName ?
                                            <li
                                                className="breadcrumb-item active"
                                                aria-current="page"
                                                style={{ color: "white" }}
                                            >
                                                {vendorName}
                                            </li> : ''
                                    }
                                    <li
                                        className="breadcrumb-item active"
                                        aria-current="page"
                                        style={{ color: "white" }}
                                    >
                                        Technology
                                    </li>
                                </ol>
                                <br />
                                {/* <span><b>Rating : {totalCount?.individualRating}</b></span> */}
                            </nav>
                        </div>
                    </div>
                </div>

                {typeof scanCompleted !== "undefined" && !scanCompleted && (
                    <div className="card border-success">
                        <ScanMessage />
                    </div>
                )}

                <div className="row clearfix">
                    <div className="col-lg-12 col-md-12">
                        <div className="row clearfix">
                            <div className="col-lg-12 col-md-12">
                                <div className="card" ref={myRef}>
                                    <div className="header">
                                        <div className="body">
                                            <div className="row">
                                                <div className="col-sm-3">
                                                    <div
                                                        data-test="datatable-input"
                                                        class="mdb-datatable-filter flex-row"
                                                    >
                                                        <input
                                                            class="form-control form-control-sm ml-0 my-1"
                                                            type="text"
                                                            placeholder="Search"
                                                            aria-label="Search"
                                                            onChange={((res) => {
                                                                setSearch(res.target.value)
                                                                setPage(1)
                                                            })}
                                                        />
                                                    </div>
                                                </div>
                                                {auth?.accessLevel != "Threat Analyst" && (
                                                    <div className="col-sm-3">
                                                        <button
                                                            type="button"
                                                            className="btn btn-primary mr-1"
                                                            style={{ color: "black", fontWeight: "bold" }}
                                                            onClick={() => addModal()}
                                                        >
                                                            Add
                                                        </button>
                                                    </div>
                                                )}
                                            </div>
                                            <div className="table-responsive">
                                                <table className="table table-custom spacing5 mb-0">
                                                    <thead>
                                                        <tr>
                                                            <th>Technology
                                                                <FontAwesomeIcon icon={faSortUp} style={{ marginLeft: '5px', cursor: 'pointer' }}
                                                                    onClick={() => { setSortOrder('asc') }}
                                                                />
                                                                <FontAwesomeIcon icon={faSortDown} style={{ cursor: 'pointer' }}
                                                                    onClick={() => { setSortOrder('desc') }}
                                                                />
                                                            </th>
                                                            <th>Version</th>
                                                            <th>Libraries IO Platform</th>
                                                            <th>Updated At</th>
                                                            {auth?.accessLevel != "Threat Analyst" && (
                                                                <th>Action</th>
                                                            )}
                                                        </tr>
                                                    </thead>
                                                    <tbody>
                                                        {currentTableData?.map((res, index) => {
                                                            return (
                                                                <tr key={index}>
                                                                    <td>
                                                                        {res?.technology}
                                                                    </td>
                                                                    <td>{res?.Version}</td>
                                                                    <td>{res?.librariesIOPlatform}</td>
                                                                    <td>
                                                                        {res?.updatedAt ?
                                                                            moment(res?.updatedAt).format(
                                                                                "DD-MM-YYYY HH:MM"
                                                                            ) : "N/A"
                                                                        }
                                                                    </td>
                                                                    {auth?.accessLevel != "Threat Analyst" && (
                                                                        <td>
                                                                            <button
                                                                                className="btn btn-danger btn-sm"
                                                                                style={{ color: "white", fontWeight: "bold" }}
                                                                                onClick={() => {
                                                                                    deleteTech(res?._id);
                                                                                }}
                                                                            >
                                                                                Delete
                                                                            </button>
                                                                            <button
                                                                                className="btn btn-primary btn-sm ml-1"
                                                                                style={{ color: "black", fontWeight: "bold" }}
                                                                                onClick={(() => editModal(res))}
                                                                            >
                                                                                Edit
                                                                            </button>
                                                                        </td>
                                                                    )}
                                                                </tr>
                                                            );
                                                        })}
                                                    </tbody>
                                                </table>
                                            </div>
                                            <div className="dataTables_paginate">
                                                {totalItems > 0 && (
                                                    <Pagination
                                                        className="pagination-bar"
                                                        currentPage={page}
                                                        totalCount={totalItems}
                                                        pageSize={PageSize}
                                                        onPageChange={(page) => {
                                                            setPage(page);
                                                        }}
                                                    />
                                                )}
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div
                    className={`modal fade Form-Wizard-modal ${isModal === "Form Wizard modal" ? "d-block show" : ""
                        }`}
                >
                    <div className="modal-dialog modal-lg" id="clientModal">
                        <div className="modal-content">
                            <div className="modal-header">
                                <h5 className="modal-title" id="exampleModalLabel">
                                    {isEdit ? "Edit" : "Add"} Technology
                                </h5>
                                <button
                                    type="button"
                                    data-dismiss="modal"
                                    aria-label="Close"
                                    onClick={() => setModal("")}
                                >
                                    <span aria-hidden="true">×</span>
                                </button>
                            </div>
                            <div className="modal-body h-auto">
                                <div className="row clearfix">
                                    <div className="col-lg-6 col-md-12">
                                        <div className="form-group">
                                            <input
                                                type="text"
                                                className="form-control"
                                                placeholder="technology*"
                                                value={tech}
                                                onChange={(e) => setTech(e.target.value)}
                                            />
                                        </div>
                                    </div>
                                    <div className="col-lg-6 col-md-12">
                                        <div className="form-group">
                                            <input
                                                type="email"
                                                className="form-control"
                                                placeholder="version*"
                                                value={version}
                                                onChange={(e) => setVersion(e.target.value)}
                                            />
                                        </div>
                                    </div>
                                    <div className="col-lg-6 col-md-12">
                                        <div className="form-group">
                                            <input
                                                type="text"
                                                className="form-control"
                                                placeholder="librariesIOPlatform"
                                                value={librariesIOPlatform}
                                                onChange={(e) => setLibrariesIOPlatform(e.target.value)}
                                            />
                                        </div>
                                    </div>
                                    <div className="col-md-12 text-center">
                                        <button
                                            type="button"
                                            style={{ color: "black", fontWeight: "bold" }}
                                            className="btn btn-round btn-primary mr-1"
                                            onClick={submitForm}
                                        >
                                            {isEdit ? "Update" : "Create"}
                                        </button>{" "}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
};

export default Technology;
