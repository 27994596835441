import React, { useEffect, useState, useRef } from "react";
import { Link  , useParams} from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import { fetchSecurityHeader } from "../../actions/sub-domain";
import Pagination from "../Pagination/Pagination";
import ReactTooltip from "react-tooltip";
import * as FileSaver from "file-saver";
import * as XLSX from "xlsx";
import Autocomplete from "@material-ui/lab/Autocomplete";
import { TextField } from "@material-ui/core";
import { Donut } from "react-dial-knob";
import AccessPage from "../Shared/accessPage";

const SecurityHeader = () => {

  const styles = {
    vulns: {
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
    },
    badges: {
      marginBottom: "20px",
    },
    issueBadges: {
      display: "flex",
      alignItems: "center",
    },
    textBadge: {
      border: "2px solid #b7b7b7",
      padding: "6px 8px",
      borderRadius: "6px",
    },
    textBadge1: {
      border: "2px solid #b7b7b7",
      padding: "4px",
      borderRadius: "6px",
      fontSize: "12px",
      margin: "0 6px 6px 0",
    },
    textBadgeShowMore: {
      cursor: "pointer",
    },
    tdWidth: {
      width: "30% !important",
    },
  };
  const { orgId } = useParams();
  let subdomains = useSelector((state) => state?.subDomain?.securityHeader);
  let scanCompleted = useSelector((state) => state.subDomain?.scanCompleted);

  //   console.log(subdomains,"subdomains")

  const [applyTechSearch, setApplyTechSearch] = useState([]);
  const [screenshotURL, setScreenShotURL] = useState([]);
  const [secondModal, setSecondModal] = useState(false);

  const [filterData, setfilterData] = useState("all");

  const [page, setPage] = useState(1);
  const [PageSize, setPerPage] = useState(10);
  const [search, setSearch] = useState("");

  const myRef = useRef(null);

  //   //console.log(graphObj, "sslDetails");
  const dispatch = useDispatch();
  const id = useSelector(state => {
    if (orgId) {
        return orgId;
    } else {
        return state?.summary?.localClient?._id || state?.summary?.clients?.[0]?._id;
    }
});
  let noDuplicateSubDomain = [];
  subdomains?.reduce((curr, row) => {
    if (curr[row.subDomainName]) {

    } else {
      curr[row.subDomainName] = row.subDomainName;
      noDuplicateSubDomain.push(row.subDomainName)
    }
    return curr;
  }, {})


  useEffect(() => {
    fetchSecurityHeader(id, dispatch);
  }, [id]);

  let currentTableData = [];



  const downloadCsvData = subdomains?.map((res) => ({
    URL: res?.header.url || "NA",
    Severity: res?.header?.severity || "NA",
    Header: res?.header?.header || "NA",
    FType: res?.header?.ftype || "NA",
    Value: res?.header?.value || "NA",
    Description: res?.header?.description || "NA"
  }));

  let mapData = subdomains;

  let filterTech = mapData?.filter((row) => applyTechSearch?.indexOf(row.subDomainName) != -1);

  mapData = applyTechSearch?.length === 0 ? mapData : filterTech;

  //   console.log(filterTech,'filterTech')

  const executeScroll = (val) => {
    //console.log(val, "executeScroll");
    setfilterData(val);
    myRef.current.scrollIntoView(); // run this function from an event handler or pass it to useEffect to execute scroll
  };

  const criticalRecord = mapData?.filter((row) => row?.header?.severity == "CRITICAL");
  const highRecord = mapData?.filter(
    (row) => (row?.header?.severity == "HIGH" || row?.header?.severity == "HIGH_MAYBE")
  );
  const mediumRecord = mapData?.filter(
    (row) => row?.header?.severity == "MEDIUM"
  );
  const lowRecord = mapData?.filter((row) => (row?.header?.severity == "LOW" || row?.header?.severity == "NONE"));

  const infoRecord = mapData?.filter((row) => row?.header?.severity == "INFO");

  let recordSet = mapData?.filter((row) => row?.header?.severity == "CRITICAL");
  highRecord?.map((row) => {
    recordSet.push(row);
  });
  mediumRecord?.map((row) => {
    recordSet.push(row);
  });
  lowRecord?.map((row) => {
    recordSet.push(row);
  });
  infoRecord?.map((row) => {
    recordSet.push(row);
  });

  mapData = recordSet;

  mapData =
    filterData === "all"
      ? mapData
      : filterData === "critical"
        ? criticalRecord
        : filterData === "high"
          ? highRecord
          : filterData === "medium"
            ? mediumRecord
            : filterData === "low"
              ? lowRecord
              : filterData === "info"
                ? infoRecord
                : mapData;

  mapData = mapData?.filter((res) => {
    if (search === "") {
      return res;
    } else if (
      res?.subDomainName?.toLowerCase()?.indexOf(search.toLowerCase()) != -1
    ) {
      return res;
    }
  });

  const exportToCSV = (csvData, fileName) => {
    const ws = XLSX.utils.json_to_sheet(csvData);
    const wb = { Sheets: { data: ws }, SheetNames: ["data"] };
    const excelBuffer = XLSX.write(wb, { bookType: "xlsx", type: "array" });
    const data = new Blob([excelBuffer], {
      type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8",
    });
    FileSaver.saveAs(data, fileName + ".xlsx");
  };

  //console.log(mapData, "mapData");
  if (mapData && mapData.length > 0) {
    const firstPageIndex = (page - 1) * PageSize;
    const lastPageIndex = firstPageIndex + PageSize;
    currentTableData = mapData?.slice(firstPageIndex, lastPageIndex);
  }
  if (mapData && mapData.length > 0) {
    setTimeout(function () {
      ReactTooltip.rebuild();
    }, 1000);
  }

  const severityBatch = (val) => {
    if (val == "NONE" || val == "LOW") {
      return "badge badge-low";
    }
    if (val == "INFO") {
      return "badge badge-info";
    }
    if (val == "MEDIUM") {
      return "badge badge-warning";
    }
    if (val >= 7 && val < 9) {
      return "badge badge-high";
    }
    if (val == "HIGH_MAYBE" || val == "HIGH") {
      return "badge badge-critical";
    }
  };

  const Modalbody = (
    <div className="modal-dialog  modal-lg" role="document" id="webTech">
      <div className="modal-content">
        <div className="modal-header">
          <h5 className="modal-title" id="exampleModalLabel">
            Sub Domain
          </h5>
          <button
            type="button"
            className="close"
            data-dismiss="modal"
            aria-label="Close"
            onClick={() => setSecondModal(false)}
          >
            <span aria-hidden="true">×</span>
          </button>
        </div>
        <div className="modal-body">
          <div className="row">
            {screenshotURL?.map((tech, index) => (
              <span className="col-md-6" style={styles.badges}>
                {/* <span style={styles.textBadge}>{tech.name}</span> */}
                <span className="badge badge-success">{tech}</span>
              </span>
            ))}
          </div>
        </div>
      </div>
    </div>
  );

  return (
    <>
      <div className="container-fluid">
        <AccessPage></AccessPage>
        <ReactTooltip className="reactTooltipClass" multiline={true} />
        <div className="block-header">
          <div className="row clearfix">
            <div className="col-md-6 col-sm-12">
              <h1>Web Security</h1>
              <nav aria-label="breadcrumb">
                <ol className="breadcrumb">
                  <li className="breadcrumb-item">
                    <Link to="/">Home</Link>
                  </li>

                  <li className="breadcrumb-item active" aria-current="page">
                    Details
                  </li>
                </ol>
              </nav>
            </div>
            <div className="col-md-6 col-sm-12 text-right hidden-xs">
              {downloadCsvData?.length === 0 ? null : (
                <span>
                <a
                  onClick={(e) => {
                    e.preventDefault();
                    exportToCSV(
                      downloadCsvData,
                      "Security Header"
                    );
                  }}
                  href="/add-report"
                  className="btn btn-primary mr-1"
                >
                  <i className="fa fa-file-excel-o"></i> Download Details
                </a>
                </span>
              )}
            </div>
          </div>
        </div>

        <div style={{ marginBottom: "30px" }}>
          <div className="row">
            <div className="securityHeader first">
              <div className="card w_card3">
                <div
                  className="body w_summary"
                  style={{
                    backgroundColor: "#23252A",
                    cursor: "pointer",
                  }}
                  onClick={() => executeScroll("critical")}
                >
                  <div className="c_know mt-1 mr-3">
                    <Donut
                      className="knob"
                      diameter={45}
                      min={0}
                      max={100}
                      step={1}
                      value={criticalRecord?.length || 0}
                      theme={{
                        donutThickness: 2,
                        donutColor: "#DC2B2B",
                        centerColor: "#141414",
                        bgrColor: "#DC2B2B",
                      }}
                    />
                  </div>
                  <div className="s_detail">
                    {/* <h4 className="mb-0">34.80%</h4> */}
                    <h4 className="mb-0">Critical</h4>
                    {/* <span>Vulnerabilities</span> */}
                  </div>
                </div>
              </div>
            </div>
            <div className="securityHeader">
              <div className="card w_card3">
                <div
                  className="body w_summary"
                  style={{
                    backgroundColor: "#23252A",
                    cursor: "pointer",
                  }}
                  onClick={() => executeScroll("high")}
                >
                  <div className="c_know mt-1 mr-3">
                    <Donut
                      className="knob"
                      diameter={45}
                      min={0}
                      max={100}
                      step={1}
                      value={highRecord?.length || 0}
                      theme={{
                        donutThickness: 2,
                        donutColor: "#EE4B2B",
                        centerColor: "#141414",
                        bgrColor: "#EE4B2B",
                      }}
                    />
                  </div>
                  <div className="s_detail">
                    <h4 className="mb-0">High</h4>
                    {/* <span>Vulnerabilities</span> */}
                  </div>
                </div>
              </div>
            </div>
            <div className="securityHeader">
              <div className="card w_card3">
                <div
                  className="body w_summary"
                  style={{
                    backgroundColor: "#23252A",
                    cursor: "pointer",
                  }}
                  onClick={() => executeScroll("medium")}
                >
                  <div className="c_know mt-1 mr-3">
                    <Donut
                      className="knob"
                      diameter={45}
                      min={0}
                      max={100}
                      step={1}
                      value={mediumRecord?.length || 0}
                      theme={{
                        donutThickness: 2,
                        donutColor: "#FF8C00",
                        centerColor: "#141414",
                        bgrColor: "#FF8C00",
                      }}
                    />
                  </div>
                  <div className="s_detail">
                    {/* <h4 className="mb-0">34.80%</h4> */}
                    <h4 className="mb-0">Medium</h4>
                    {/* <span>Vulnerabilities</span> */}
                  </div>
                </div>
              </div>
            </div>
            <div className="securityHeader">
              <div className="card w_card3">
                <div
                  className="body w_summary"
                  style={{
                    backgroundColor: "#23252A",
                    cursor: "pointer",
                  }}
                  onClick={() => executeScroll("low")}
                >
                  <div className="c_know mt-1 mr-3">
                    <Donut
                      className="knob"
                      diameter={45}
                      min={0}
                      max={100}
                      step={1}
                      value={lowRecord?.length || 0}
                      theme={{
                        donutThickness: 2,
                        donutColor: "#48B461",
                        centerColor: "#141414",
                        bgrColor: "#48B461",
                      }}
                    />
                  </div>
                  <div className="s_detail">
                    {/* <h4 className="mb-0">34.80%</h4> */}
                    <h4 className="mb-0">Low</h4>
                    {/* <span>Vulnerabilities</span> */}
                  </div>
                </div>
              </div>
            </div>
            <div className="securityHeader last">
              <div className="card w_card3">
                <div
                  className="body w_summary"
                  style={{
                    backgroundColor: "#23252A",
                    cursor: "pointer",
                  }}
                  onClick={() => executeScroll("info")}
                >
                  <div className="c_know mt-1 mr-3">
                    <Donut
                      className="knob"
                      diameter={45}
                      min={0}
                      max={100}
                      step={1}
                      value={infoRecord?.length || 0}
                      theme={{
                        donutThickness: 2,
                        donutColor: "#3C89DA",
                        centerColor: "#141414",
                        bgrColor: "#3C89DA",
                      }}
                    />
                  </div>
                  <div className="s_detail">
                    {/* <h4 className="mb-0">34.80%</h4> */}
                    <h4 className="mb-0">Info</h4>
                    {/* <span>Vulnerabilities</span> */}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        {/* {typeof scanCompleted !== "undefined" && !scanCompleted && (
          <div className="card border-success">
            <ScanMessage />
          </div>
        )} */}

        <div className="row clearfix">
          <div className="col-lg-12 col-md-12">
            <div className="row clearfix">
              <div className="col-lg-12 col-md-12">
                <div className="card" ref={myRef}>
                  <div className="header">
                    <div className="body">
                      <div className="row">
                        <div className="col-sm-4">
                          <Autocomplete
                            multiple
                            id="tags-standard"
                            onChange={(event, newValue) => {
                              //   onPageChange('',1)
                              setApplyTechSearch(newValue);
                            }}
                            value={applyTechSearch}
                            options={noDuplicateSubDomain}
                            getOptionLabel={(option) => option.toString()}
                            // defaultValue={[noDuplicatesPorts[0]]}
                            renderInput={(params) => (
                              <TextField
                                {...params}
                                variant="standard"
                                // label="Multiple values"
                                placeholder="Security Header Search"
                              />
                            )}
                          />
                        </div>
                        <div className="col-sm-4">
                          <select
                            className="custom-select custom-select-sm form-control form-control-sm"
                            value={filterData}
                            onChange={(e) => setfilterData(e.target.value)}
                          >
                            <option value="all">All</option>

                            <option value="critical">Critical</option>
                            <option value="high">High</option>
                            <option value="medium">Medium</option>
                            <option value="low">Low</option>
                            <option value="info">Info</option>
                          </select>
                        </div>
                        <div className="col-sm-3">
                          <div
                            data-test="datatable-input"
                            class="mdb-datatable-filter flex-row"
                          >
                            <input
                              class="form-control form-control-sm ml-0 my-1"
                              type="text"
                              placeholder="Search"
                              aria-label="Search"
                              value={search}
                              onChange={(e) => setSearch(e.target.value)}
                            />
                          </div>
                        </div>

                      </div>
                      <div className="table-responsive">
                        <table className="table table-hover table-custom spacing8">
                          <thead>
                            <tr>
                              {/* <th>Subdomain</th> */}
                              <th>Url</th>
                              <th>Severity</th>
                              <th>Header</th>
                              <th>FType</th>
                              <th>Value</th>
                              <th>Description</th>
                            </tr>
                          </thead>
                          <tbody>
                            {currentTableData?.map((res, index) => {
                              return (
                                <tr key={index}>

                                  <td
                                  >
                                    {/* {res?.version} */}
                                    <a href={`${res?.header?.url}`} style={{ cursor: "pointer" }} target="_blank">
                                      {/* <Link to={`${res?.header?.url}`} target="_blank"> */}
                                      {res?.header?.url}
                                    </a>
                                  </td>
                                  <td id="info">
                                    {/* {res?.header?.severity} */}
                                    <span
                                      className={severityBatch(res?.header?.severity)}
                                    >
                                      {res?.header?.severity}
                                    </span>
                                  </td>
                                  <td>{res?.header?.header || "NA"}</td>
                                  <td>{res?.header?.ftype || "NA"}</td>
                                  <td>{res?.header?.value || "NA"}</td>
                                  <td>{res?.header?.description || "NA"}</td>
                                </tr>
                              );
                            })}
                          </tbody>
                        </table>
                      </div>
                      <div className="dataTables_paginate">
                        {mapData && mapData.length > 0 && (
                          <Pagination
                            className="pagination-bar"
                            currentPage={page}
                            totalCount={mapData?.length}
                            pageSize={PageSize}
                            onPageChange={(page) => setPage(page)}
                          />
                        )}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div
          className={`modal fade  ${secondModal ? "d-block show" : ""}`}
          id="exampleModal"
        // onClick={() => setSecondModal()}
        >
          {/* <div className="modal-dialog" role="document"> */}
          {Modalbody}
          {/* </div> */}
        </div>
      </div>
    </>
  );
};

export default SecurityHeader;
