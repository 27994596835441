import React, { useEffect, useState } from "react";
import { Link, useParams } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import { fetchDashboardReport, fetchTopIssue } from "../../actions/summary";
import PieChart from "../../components/common/dashboard12/piechart.js";
import { VectorMap } from "react-jvectormap";
import { countryToCode } from "../../utils/countryCode";
import SparkLineBoxChart from "../common/dashboard12/lineChart";
import LinearProgress from "@mui/material/LinearProgress";
import moment from "moment";
import AccessPage from "../Shared/accessPage";
import CircularProgressBar from "react-circle-progress-bar";
import Splinechart from "../common/dashboard_chart";
import { red } from "@mui/material/colors";
import Loader from "../Shared/loader";
import { Select } from "@mui/material";
import MenuItem from "@mui/material/MenuItem";
import Box from "@mui/material/Box";
import FormControl from "@mui/material/FormControl";
import axios from "axios";
import { API_URL } from "../../utils/constant";
var Model = require("./model");

const Dashboard = () => {
  const { orgid } = useParams();
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(false);
  const [isModal, setModal] = useState('');
  const [selectedValue, setSelectedValue] = useState("Download Reports");
  const vendorName = sessionStorage.getItem('vendorName');
  const vendorId = sessionStorage.getItem('vendorId');
  console.log(vendorName, 'vendorName')

  if (orgid) {
    sessionStorage.setItem('vendorId', orgid);
  } else {
    console.error('orgid is undefined');
  }

  const showSpinner = () => {
    setLoading(true);
  };

  const hideSpinner = () => {
    setLoading(false);
  };

  const { orgId } = useParams();
  const id = useSelector(state => {
    if (orgId) {
      return orgId;
    } else {
      return state?.summary?.localClient?._id || state?.summary?.clients?.[0]?._id;
    }
  });
  const client = useSelector(
    (state) => state?.summary?.localClient || state?.summary?.clients?.[0]
  );

  useEffect(() => {
    if (orgid) fetchDashboardReport(orgid, "all", dispatch);
    else fetchDashboardReport(id, "all", dispatch);
  }, [id]);

  // useEffect(()=>{
  //   fetchTopIssue(id,dispatch)
  // },[id])

  let summary = useSelector((state) => state?.summary?.dashboardSummary);
  let summaryLoading = useSelector((state) => state?.summary?.loading);

  useEffect(() => {
    if (summary === null) {
      showSpinner();
    } else {
      hideSpinner(false);
    }
  }, [summary]);


  const rating = summary?.totalScoreDashboard;

  let securityGraphValueASM = summary?.Rating?.map(
    (row) => row?.attackSurfaceSecurityImpact
  );
  let securityGraphValueData = summary?.Rating?.map(
    (row) => row?.darkWebSecurityImpact
  );
  let securityGraphValueBrand = summary?.Rating?.map(
    (row) => row?.brandSecuritySecurityImpact
  );
  let securityGraphDate = summary?.Rating?.map((row) =>
    moment(row?.createdAt).format("DD-MM-YYYY")
  );
  const valueResult = Math.floor(
    Number(rating?.attackSurfaceSecurityImpact) +
    Number(rating?.darkWebSecurityImpact) +
    Number(rating?.brandSecuritySecurityImpact)
  );

  let graphLabels = Object.keys(summary?.services || {}).map((key) => key);
  let graphValue = Object.keys(summary?.services || {}).map((key) => {
    return summary?.services[key];
  });

  let location = summary?.assetMap?.map((value) => value?.location);
  location = location?.filter(function (element) {
    return element !== undefined;
  });
  let counts = location?.reduce((acc, curr) => {
    if (curr) {
      const str = JSON.stringify(curr["country"]);
      acc[str] = (acc[str] || 0) + 1;
      return acc;
    } else return acc;
  }, {});
  // const distinctLocationData = useSelector(
  //   (state) => state?.inventory?.assetsgraph?.data?.scanDetails
  // );

  const distinctLocationData = summary?.assetMap;

  const values = {
    // Your object with region values
    // us: 500, // Example value for the United States
    // Add more region values as needed
  };
  let ipArray = summary?.scanDetailArray?.scanDetails;

  const downloadFile = async () => {
    try {
      const session = localStorage.getItem("session");
      axios.defaults.headers.common["Authorization"] = `Bearer ${session}`;
      const response = await axios.get(`${API_URL}/downloadPptReport/${id}`, {
        responseType: "blob",
      });
      const url = window.URL.createObjectURL(new Blob([response.data]));
      const link = document.createElement("a");
      link.href = url;
      link.setAttribute(
        "download",
        `Contextualised_Threat_Intelligence_Report_for_${id}.pptx`
      );
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
      window.URL.revokeObjectURL(url);
    } catch (error) {
      console.error(error);
    }
  };

  const generateReport = (v) => {
    if (v == "Full") {
      if (loading) {
        return false;
      }
      setLoading(true);
      var val = { id: id, asm: "Full" }
      Model.default.generateReport(val).then((response) => {
        setLoading(false);
        if (!response.error) {
          window.open(response.data.docx);
        }
      });
    }
    if (v == "Executive") {
      downloadFile();
    }
  };

  // if (loading) {
  //   return (
  //     <div className="container-fluid">
  //       <Loader type="Puff" color="#00BFFF" height={100} width={100} />
  //     </div>
  //   );
  // }

  return (
    <div className="container-fluid">
      {loading && <Loader />}
      <AccessPage></AccessPage>
      {(summaryLoading || loading) && (
        <Box sx={{ width: "100%" }}>
          <LinearProgress />
        </Box>
      )}
      <div className="block-header">
        <div className="row clearfix">
          <div className="col-md-6 col-sm-12">
            <h1 style={{ color: "white" }}>Dashboard</h1>
            <nav aria-label="breadcrumb">
              <ol className="breadcrumb">
                <li className="breadcrumb-item">
                  {vendorId ? <>
                    <Link to="/vendor"
                      onClick={(() => {
                        sessionStorage.clear();
                      })}
                    >Forest View</Link>
                  </> : <Link to="/">Home</Link>}
                </li>
                {
                  vendorName ?
                    <li
                      className="breadcrumb-item active"
                      aria-current="page"
                      style={{ color: "white" }}
                    >
                      {vendorName}
                    </li> : ''
                }
                <li
                  className="breadcrumb-item active"
                  aria-current="page"
                  style={{ color: "white" }}
                >
                  Summary
                </li>
              </ol>
            </nav>
          </div>
          <div className="col-md-6 col-sm-12 text-left hidden-xs">
            <Box sx={{ width: 200, marginLeft: "auto" }}>
              <FormControl fullWidth>
                <Select
                  displayEmpty
                  renderValue={(value) =>
                    value === "" ? <em>Download Reports</em> : value
                  }
                  labelId="demo-simple-select-label"
                  id="demo-simple-select"
                  style={{ color: "black", fontWeight: "bold" }}
                  value={selectedValue}
                  onChange={(event) => generateReport(event.target.value)}
                >
                  <MenuItem value="Full">Full Report</MenuItem>
                  <MenuItem value="Executive">Executive Report</MenuItem>
                </Select>
              </FormControl>
            </Box>
            {/* <span
              className="btn btn-sm btn-primary mr-1"
              title=""
              style={{ color: "black", fontWeight: "bold" }}
              onClick={() => generateReport()}
            >
              <i className="fa fa-file" style={{ color: "black" }}></i>
              <span
                style={{ color: "#060606", padding: "10px", fontStyle: "bold" }}
              >
                Download Report
              </span>
            </span> */}
          </div>
        </div>
      </div>

      <div className="row clearfix">
        <div className="col-lg-9">
          <div className="card">
            <div className="body" style={{ minHeight: "364px" }}>
              <div className="row">
                <div className="col-lg-6">
                  <div className="d-flex justify-content-left summaryGraph">
                    <CircularProgressBar
                      strokeWidth={20}
                      // subtitle={<span className="progressRating">{rating?.averageRating}</span>}
                      subtitle={
                        valueResult ? `${valueResult} / 900` : "0 / 900"
                      }
                      hideValue={true}
                      fontSize={19}
                      fill={red}
                      progress={valueResult ? valueResult / 10 : 0}
                      ballStrokeWidth={30}
                    />
                    <div className="row">
                      <div className="d-flex justify-content-left">
                        <div
                          className="mr-8"
                          style={{ marginTop: "50px", marginLeft: "50px" }}
                        >
                          <h4>Overall Security Health Score</h4>
                          {/* <h6>{rating?.averageRating}% in last month</h6> */}
                        </div>
                      </div>
                    </div>
                  </div>
                  <hr style={{ background: "white" }} />
                  <div className="row text-center">
                    <div className="col-lg-4 border-right">
                      <label className="mb-0" style={{ height: "42px" }}>
                        Attack Surface Managment
                      </label>
                      <h4 className="font-20 total-h4">
                        {rating?.attackSurfaceSecurityImpact || 0}{" "}
                        <span className="span_total"> / 600</span>{" "}
                      </h4>
                    </div>
                    <div className="col-lg-4 border-right">
                      <label className="mb-0" style={{ height: "42px" }}>
                        Data exposure
                      </label>
                      <h4 className="font-20 total-h4">
                        {rating?.darkWebSecurityImpact || 0}{" "}
                        <span className="span_total"> / 200</span>
                      </h4>
                    </div>
                    <div className="col-lg-4">
                      <label className="mb-0" style={{ height: "42px" }}>
                        Brand Security
                      </label>
                      <h4 className="font-20 total-h4">
                        {rating?.brandSecuritySecurityImpact || 0}{" "}
                        <span className="span_total"> / 100</span>
                      </h4>
                    </div>
                  </div>
                  {(id === '662a35449c6fa7b6d3a5b5db' || orgId === '662a35449c6fa7b6d3a5b5db') && (
                    <>
                      <hr style={{ background: "white" }} />
                      <div className="row text-center">
                        <div className="col-lg-12 border-right">
                          <label className="mb-0">
                            Industry benchmark score 753
                          </label>
                        </div>
                      </div>
                    </>
                  )}
                </div>

                <div className="col-lg-6">
                  <h6>Security Health Score Tracker</h6>
                  <div className="card">
                    {securityGraphValueASM && (
                      <Splinechart
                        value={securityGraphValueASM}
                        data={securityGraphValueData}
                        brand={securityGraphValueBrand}
                        date={securityGraphDate}
                        name={"Average"}
                        color="#B0F127"
                      />
                    )}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="col-md-3">
          <div className="card">
            <div className="body" style={{ minHeight: "364px" }}>
              <h6>Top Issues</h6>
              <div className="list-group">
                {summary?.issueArray?.slice(0, 4)?.map((row) => (
                  <Link
                    to={row?.link}
                    className="list-group-item list-group-item-action"
                    style={{ color: "white" }}
                  >
                    {row?.msg}
                  </Link>
                ))}
                {summary?.issueArray?.length > 5 &&
                  (
                    <span className="text-center mt-2"
                      style={{
                        justifyContent: "center",
                        alignItems: "center",
                        display: "flex"
                      }}
                    >
                      <a
                        onClick={() => {
                          setModal('Form Wizard modal')
                        }}
                        style={{
                          float: "right",
                          color: "black",
                          fontWeight: "bold",

                        }}
                        className="btn btn-primary"
                      >
                        <i
                          className="fa fa-file-excel-o"
                          style={{ color: "black" }}
                        ></i>{" "}
                        View All
                      </a>
                    </span>
                  )
                }
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="row clearfix">
        <div className="col-lg-12 col-md-12 col-sm-12">
          <div className="row clearfix">
            <div className="col-sm-4">
              <div className="card">
                <div className="body" style={{ minHeight: "460px" }}>
                  <div>
                    <h6>Assets Count Trend</h6>
                  </div>
                  {summary?.scanDetailArray && (
                    <SparkLineBoxChart
                      dates={summary?.scanDetailArray?.dates}
                      ipArray={ipArray}
                    />
                  )}
                </div>
              </div>
            </div>
            <div className="col-sm-2">
              <div className="card">
                <div className="body" style={{ minHeight: "460px" }}>
                  <div>
                    <h6>Application Cloud</h6>
                  </div>
                  <ul className="list-group list-group-flush">
                    {summary?.applicationCloud?.map((record, index) => {
                      if (index < 5) {
                        return (
                          <li
                            style={{ fontSize: "12px" }}
                            className="list-group-item"
                          >
                            {record}
                          </li>
                        );
                      }
                    })}
                  </ul>
                </div>
              </div>
            </div>

            <div className="col-sm-2">
              <div className="card">
                <div className="body" style={{ minHeight: "460px" }}>
                  <div>
                    <h6>Open Ports</h6>
                  </div>
                  <div>
                    {graphValue && graphValue.length > 0 && (
                      <PieChart series={graphValue} label={graphLabels} />
                    )}
                  </div>
                </div>
              </div>
            </div>

            <div className="col-sm-4">
              <div className="card">
                <div className="body" style={{ minHeight: "460px" }}>
                  <div>
                    <h6>Asset Graph</h6>
                  </div>
                  <div id="world-map-markers" className="jvector-map main-map">
                    <VectorMap
                      containerStyle={{
                        height: "400px",
                      }}
                      map={"world_mill"}
                      backgroundColor="transparent"
                      borderColor="#fff"
                      borderOpacity={0.25}
                      borderWidth={0}
                      color="#e6e6e6"
                      regionStyle={{ initial: { fill: "#6c757d" } }}
                      markerStyle={{
                        initial: {
                          r: 5,
                          fill: "#fff",
                          "fill-opacity": 1,
                          stroke: "#000",
                          "stroke-width": 1,
                          "stroke-opacity": 0.4,
                        },
                      }}
                      markers={distinctLocationData}
                      series={{
                        regions: [
                          {
                            values: values,
                            attribute: "fill",
                          },
                        ],
                      }}
                      hoverOpacity={null}
                      normalizeFunction="linear"
                      zoomOnScroll={false}
                      scaleColors={["#000000", "#000000"]}
                      selectedColor="#000000"
                      selectedRegions={[]}
                      enableZoom={false}
                      hoverColor="#fff"
                    />
                    {/* <VectorMap
                      containerStyle={{
                        height: "400px",
                      }}
                      map={"world_mill"}
                      backgroundColor="transparent"
                      borderColor="#fff"
                      borderOpacity={0.25}
                      borderWidth={0}
                      color="#e6e6e6"
                      regionStyle={{ initial: { fill: "#6c757d" } }}
                      markerStyle={{
                        initial: {
                          r: 5,
                          fill: "#fff",
                          "fill-opacity": 1,
                          stroke: "#000",
                          "stroke-width": 1,
                          "stroke-opacity": 0.4,
                        },
                      }}
                      markers={markers}
                      series={{
                        regions: [
                          {
                            values: values,
                            attribute: "fill",
                          },
                        ],
                      }}
                      hoverOpacity={null}
                      normalizeFunction="linear"
                      zoomOnScroll={false}
                      scaleColors={["#000000", "#000000"]}
                      selectedColor="#000000"
                      selectedRegions={[]}
                      enableZoom={false}
                      hoverColor="#fff"
                    /> */}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="row clearfix">
        <div className="col-lg-12 col-md-12 col-sm-12">
          <div className="row clearfix"></div>
        </div>
      </div>
      <div
        className={`modal fade Form-Wizard-modal ${isModal === "Form Wizard modal" ? "d-block show" : ""
          }`}
      >
        <div className="modal-dialog modal-lg" id="clientModal">
          <div className="modal-content">
            <div className="modal-header">
              <h5 className="modal-title" id="exampleModalLabel">
                Top Issues
              </h5>
              <button
                type="button"
                data-dismiss="modal"
                aria-label="Close"
                onClick={() => setModal("")}
              >
                <span aria-hidden="true">×</span>
              </button>
            </div>
            <div className="modal-body pricing_page">
              <div className="col-xl-12 col-lg-12 col-md-12">
                <div className="card">
                  <div className="row clearfix">
                    <div className="col-lg-12 col-md-12">
                      <div className="list-group">
                        {summary?.issueArray?.map((row) => (
                          <Link
                            to={row?.link}
                            className="list-group-item list-group-item-action"
                            style={{ color: "white" }}
                          >
                            {row?.msg}
                          </Link>
                        ))}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
export default Dashboard;
