import React, { useEffect, useState } from "react";
import { Link, useParams } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import { fetchDashboardReport } from "../../actions/summary";
import PieChart from "../../components/common/dashboard12/piechart.js";
import { VectorMap } from "react-jvectormap";
import { countryToCode } from "../../utils/countryCode";
import SparkLineBoxChart from "../common/dashboard12/lineChart";
import Box from "@mui/material/Box";
import LinearProgress from "@mui/material/LinearProgress";
import moment from "moment";
import AccessPage from "../Shared/accessPage";
import CircularProgressBar from "react-circle-progress-bar";
import Splinechart from "../common/splinechart";
import { red } from "@mui/material/colors";

var Model = require("./model");

const Dashboard = () => {
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(false);

const { orgId } = useParams();
const vendorName = sessionStorage.getItem('vendorName');
const vendorId = sessionStorage.getItem('vendorId');

  const id = useSelector(state => {
    if (orgId) {
        return orgId;
    } else {
        return state?.summary?.localClient?._id || state?.summary?.clients?.[0]?._id;
    }
});
  const client = useSelector(
    (state) => state?.summary?.localClient || state?.summary?.clients?.[0]
  );

  useEffect(() => {
    if (orgId) fetchDashboardReport(orgId, "all", dispatch);
    else fetchDashboardReport(id, "all", dispatch);
  }, [id]);

  let summary = useSelector((state) => state?.summary?.dashboardSummary);
  let summaryLoading = useSelector((state) => state?.summary?.loading);


  const rating = summary?.Rating?.filter((row) => row?.scanCounter == client?.scanCounter)?.pop();
  console.log(rating, 'rating')

  let securityGraphValue = summary?.Rating?.map((row) => row?.averageRating);
  let securityGraphDate = summary?.Rating?.map((row) => moment(row?.createdAt).format('DD-MM-YYYY'));

  let graphLabels = Object.keys(summary?.services || {}).map((key) => key);
  let graphValue = Object.keys(summary?.services || {}).map((key) => {
    return summary?.services[key];
  });

  let location = summary?.assetMap?.map((value) => value?.location);
  location = location?.filter(function (element) {
    return element !== undefined;
  });
  let counts = location?.reduce((acc, curr) => {
    if (curr) {
      const str = JSON.stringify(curr["country"]);
      acc[str] = (acc[str] || 0) + 1;
      return acc;
    } else return acc;
  }, {});
  const distinctLocationData = [
    ...new Map(
      location?.map((item) => [item && item["country"], item])
    ).values(),
  ];
  let markers = [],
    values = {};
  for (let marker = 0; marker < distinctLocationData?.length; marker++) {
    if (distinctLocationData[marker] == "") break;
    let obj = {};
    obj.latLng = [
      distinctLocationData[marker].coordinates.latitude,
      distinctLocationData[marker].coordinates.longitude,
    ];
    obj.name =
      distinctLocationData[marker].country +
      " - " +
      counts['"' + distinctLocationData[marker].country + '"'];

    markers.push(obj);
    values[
      (countryToCode[distinctLocationData[marker].country] || "").toUpperCase()
    ] = "#ffec94";
  }
  // //console.log(graphLabels, "graphLabels", graphValue);

  let ipArray = [];
  for (
    let details = 0;
    details < summary?.scanDetailArray?.scanDetails?.length;
    details++
  ) {
    ipArray.push(summary?.scanDetailArray?.scanDetails[details]?.length);
  }
  // //console.log(ipArray, "ipArray");
  const generateReport = () => {
    //console.log("generateReport");
    if (loading) {
      return false;
    }
    setLoading(true);
    Model.default.generateReport(id).then((response) => {
      setLoading(false);
      if (!response.error) {
        //console.log(response.data, "data");
        window.open(response.data.docx);
      }
    });
  };

  const generateSummary = () => {
    //console.log("generateReport");
    if (loading) {
      return false;
    }
    setLoading(true);
    Model.default.generateSummary(id).then((response) => {
      setLoading(false);
      if (!response.error) {
        //console.log(response.data, "data");
        window.open(response.data.pdf);
      }
    });
  };


  return (
    <div className="container-fluid">
      <AccessPage></AccessPage>
      {(summaryLoading || loading) && (
        <Box sx={{ width: "100%" }}>
          <LinearProgress />
        </Box>
      )}
      <div className="block-header">
        <div className="row clearfix">
          <div className="col-md-6 col-sm-12">
            <h1 style={{ color: 'white' }}>Dashboard</h1>
            <nav aria-label="breadcrumb">
              <ol className="breadcrumb">
                <li className="breadcrumb-item">
                  {vendorId ? <>
                  <Link to="/vendor"
                      onClick={(() => {
                        sessionStorage.clear();
                      })}
                    >Forest View</Link>
                  </> : <Link to="/">Home</Link>}
                </li>
                {
                  vendorName ?
                    <li
                      className="breadcrumb-item active"
                      aria-current="page"
                      style={{ color: "white" }}
                    >
                      {vendorName}
                    </li> : ''
                }
                <li className="breadcrumb-item active" aria-current="page" style={{ color: 'white' }}>
                  Summary
                </li>
              </ol>
            </nav>
          </div>
          <div className="col-md-6 col-sm-12 text-right hidden-xs">
            {/* <span
              className="btn btn-sm btn-primary mr-1"
              title=""
              onClick={() => generateSummary()}
            >
              <i className="fa fa-file"></i> Download Summary
            </span> */}
            <span
              className="btn btn-sm btn-primary mr-1"
              title=""
              onClick={() => generateReport()}
            >
              <i className="fa fa-file" style={{color:'#060606'}}></i>
               <span style={{color: '#060606',padding:'10px',fontStyle:'bold'}}>Download Report</span>
            </span>
          </div>
        </div>
      </div>


      <div className="row clearfix">
        <div className="col-lg-9">
          <div className="card">
            <div className="body" style={{ minHeight: "364px" }}>
              <div className="row">
                <div className="col-lg-6">
                  <div className="d-flex justify-content-left summaryGraph">
                    <CircularProgressBar
                      strokeWidth={20}
                      // subtitle={<span className="progressRating">{rating?.averageRating}</span>}
                      subtitle={rating?.averageRating}
                      hideValue={true}
                      fontSize={19}
                      fill={red}
                      progress={rating?.averageRating}
                      ballStrokeWidth={30}
                    />
                    <div className="row">
                      <div className="d-flex justify-content-left">
                        <div
                          className="mr-8"
                          style={{ marginTop: "50px", marginLeft: "50px" }}
                        >
                          <h4>Overall Security Health Score</h4>
                          {/* <h6>{rating?.averageRating}% in last month</h6> */}
                        </div>
                      </div>
                    </div>
                  </div>
                  <hr style={{ background: "white" }} />
                  <div className="row text-center">
                    <div className="col-lg-4 border-right">
                      <label className="mb-0">ASM</label>
                      <h4 className="font-30">{rating?.attackSurface || ""}</h4>
                    </div>
                    <div className="col-lg-4 border-right">
                      <label className="mb-0">Data exposure</label>
                      <h4 className="font-30">{rating?.darkWeb || ""}</h4>
                    </div>
                    <div className="col-lg-4">
                      <label className="mb-0">Brand Security</label>
                      <h4 className="font-30">{rating?.brandSecurity || ""}</h4>
                    </div>
                  </div>
                </div>

                <div className="col-lg-6">
                  <h6>Security Health Score Tracker</h6>
                  <div className="card">
                    {securityGraphValue && (
                      <Splinechart value={securityGraphValue} date={securityGraphDate} name={"Average"} color="#B0F127" />
                    )}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="col-md-3">
          <div className="card">
            <div className="body" style={{ minHeight: "364px" }}>
              <h6>Top Issues</h6>
              <div className="list-group">
                {
                  summary?.issueArray?.map((row) => {
                    return (
                      <a
                        href={row?.link}
                        className="list-group-item list-group-item-action"
                        style={{ color: "white" }}
                      >
                        {row?.msg}
                      </a>
                    )
                  })
                }
                {/* <a
                  href="/assets" 
                  className="list-group-item list-group-item-action active"
                  style={{ color: "white" }}
                >
                  40 open ports are found to be open.
                </a>
              
                <a
                  href="/ssl-certificates" 
                  className="list-group-item list-group-item-action"
                  style={{ color: "white" }}
                >
                  5 expired ssl certificates are found.
                </a>
                <a
                  href="/malware-credentials" 
                  className="list-group-item list-group-item-action"
                  style={{ color: "white" }}
                >
                  1403 stealer logs are found.
                </a>
                <a
                  href="/stolen-credentials" 
                  className="list-group-item list-group-item-action"
                  style={{ color: "white" }}
                >
                  284 breached credentials are found.
                </a>
                <a
                  href="/brand-mentions"
                  className="list-group-item list-group-item-action"
                  style={{ color: "white" }}
                >
                56 brand mentions are found.
                </a> */}
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="row clearfix">
        <div className="col-lg-12 col-md-12 col-sm-12">
          <div className="row clearfix">
            <div className="col-sm-4">
              <div className="card">
                <div className="body" style={{ minHeight: "460px" }}>
                  <div >
                    <h6>Assets Count Trend</h6>
                  </div>
                  {summary?.scanDetailArray && (
                    <SparkLineBoxChart
                      dates={summary?.scanDetailArray?.dates}
                      ipArray={ipArray}
                    />
                  )}
                </div>
              </div>
            </div>
            <div className="col-sm-2">
              <div className="card">
                <div className="body" style={{ minHeight: "460px" }}>
                  <div >
                    <h6>Application Cloud</h6>
                  </div>
                  <ul className="list-group list-group-flush">
                    {summary?.applicationCloud?.map((record, index) => {
                      if (index < 5) {
                        return (
                          <li
                            style={{ fontSize: "12px" }}
                            className="list-group-item"
                          >
                            {record}
                          </li>
                        );
                      }
                    })}
                  </ul>
                </div>
              </div>
            </div>

            <div className="col-sm-2">
              <div className="card">
                <div className="body" style={{ minHeight: "460px" }}>
                  <div >
                    <h6>Open Ports</h6>
                  </div>
                  <div>
                    {graphValue && graphValue.length > 0 && (
                      <PieChart series={graphValue} label={graphLabels} />
                    )}
                  </div>
                </div>
              </div>
            </div>

            <div className="col-sm-4">
              <div className="card">
                <div className="body" style={{ minHeight: "460px" }}>
                  <div >
                    <h6>Asset Graph</h6>
                  </div>
                  <div id="world-map-markers" className="jvector-map main-map">
                    <VectorMap
                      containerStyle={{
                        height: "400px",
                      }}
                      map={"world_mill"}
                      backgroundColor="transparent"
                      borderColor="#fff"
                      borderOpacity={0.25}
                      borderWidth={0}
                      color="#e6e6e6"
                      regionStyle={{ initial: { fill: "#6c757d" } }}
                      markerStyle={{
                        initial: {
                          r: 5,
                          fill: "#fff",
                          "fill-opacity": 1,
                          stroke: "#000",
                          "stroke-width": 1,
                          "stroke-opacity": 0.4,
                        },
                      }}
                      markers={markers}
                      series={{
                        regions: [
                          {
                            values: values,
                            attribute: "fill",
                          },
                        ],
                      }}
                      hoverOpacity={null}
                      normalizeFunction="linear"
                      zoomOnScroll={false}
                      scaleColors={["#000000", "#000000"]}
                      selectedColor="#000000"
                      selectedRegions={[]}
                      enableZoom={false}
                      hoverColor="#fff"
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="row clearfix">
        <div className="col-lg-12 col-md-12 col-sm-12">
          <div className="row clearfix"></div>
        </div>
      </div>
    </div>
  );
};
export default Dashboard;